import {
  Anchor,
  Button,
  Checkbox,
  FileInput,
  Grid,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { AttestationStatus } from "../../Shared/DropDownOptions/AttestationStatus";
import {
  FiscalYear,
  OverrideSubmissionMethodClinic,
} from "../../Shared/DropDownOptions/DropDownList";
import axios from "axios";
import { ApiLink } from "../../Constant/ApiLink";
import { v4 as uuidv4 } from "uuid";
import { BlobServiceClient } from "@azure/storage-blob";
import { useSelector } from "react-redux";
import { getFileUri } from "../../Shared/Constant";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

export const ClinicAttestationRow = ({
  attestions,
  onDelete,
  entityList,
  updateAddNew,
  index,
  defaultsubmission,
  groupMeetingStatus,
  groupMvp,
}) => {
  const [isEditMode, setEditMode] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const groupDropDownList = useSelector(
    (store) => store.partenerTree.groupDropDownList
  );


  useEffect(() => {
    if (attestions?.fiscalYear && attestions?.entityID) {

      GetEntityRecords(attestions?.fiscalYear, attestions?.entityID);
    }
    if (attestions.isNewRow) {
      setEditMode(true);
    }
  }, [attestions]);

  const form = useForm({
    initialValues: {
      id: attestions?.id,
      clinicId: attestions?.clinicId,
      fiscalYear: attestions?.fiscalYear,
      entityID: attestions?.entityID,
      chert: attestions?.chert ?? "-",
      concent: attestions?.concent ?? null,
      concentFile: null,
      cpiaDoc: attestions?.cpiaDoc ?? null,
      cpiadocFile: null,
      meetingStatus: attestions?.meetingStatus,
      overridSubMethod: attestions?.overridSubMethod ?? defaultsubmission,
      PI: attestions?.pi ?? false,
      CQM: attestions?.cqm ?? false,
      RU: attestions?.ru ?? false,
      CPIA: attestions?.cpia ?? false,
      PIlabel: "PI",
      CQMlabel: "CQM",
      RUlabel: "RU",
      CPIAlabel: "CPIA",
      cost: attestions?.cost,
      groupId: attestions?.groupId,
      smallGroupPractitioner: attestions?.smallGroupPractitioner,
      phyNPI: attestions.phyNPI,
      isMvp: attestions?.isMvp ?? false,
      mvpId: attestions?.mvpId
    },
    validate: {
      meetingStatus: (value) => (!value || value == "" ? "MeetingStatus is required!" : null),
    }
  });

  const rowEdit = (e) => {
    setEditMode(true);
  };

  const rowUpdate = async (e) => {
    var concent = form.values.concentFile;
    if (concent !== null) {
      const fileUri1 = await getFileUri(form.values.concentFile);
      form.values.concent = fileUri1;
    }
    var cpiaDoc = form.values.cpiadocFile;
    if (cpiaDoc !== null) {
      const fileUri2 = await getFileUri(form.values.cpiadocFile);
      form.values.cpiaDoc = fileUri2;
    }

    if (!form.values.meetingStatus) {
      alert("Meeting Status is required!");
      return;
    }

    if (attestions.isNewRow === true) {

      ApiCall.axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}${ApiLink.ClinicAttestationHistory}`,
          form.values
        )
        .then((response) => {
          alert("Record Created Succesfully");

          updateAddNew(true);
          setEditMode(false);
          //   setAddMode(false);
        })
        .catch((error) => {
          console.error("Error Group fetching data:", error);
          window.alert('Record is already created!');
        });
    } else {

      ApiCall.axiosInstance
        .put(
          `${process.env.REACT_APP_API_URL}${ApiLink.ClinicAttestationHistory}`,
          form.values
        )
        .then((response) => {
          alert("Record Update Succesfully");

          setEditMode(false);
        })
        .catch((error) => {
          console.error("Error Group fetching data:", error);
        });
    }
  };

  const rowEditCancel = (e) => {
    setEditMode(false);
    updateAddNew(false);
  };

  const renameFile = (file, newFileName) => {
    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts.pop();
    const newName = `${newFileName}.${fileExtension}`;

    return new File([file], newName, { type: file.type });
  };

  const generateUniqueId = () => {
    return uuidv4();
  };

  async function uploadImage(containerName, file) {
    const blobServiceClient = new BlobServiceClient(
      "https://macrafilestorage.blob.core.windows.net/macrafiles?sp=racwdl&st=2023-08-25T16:32:39Z&se=2023-12-31T00:32:39Z&sv=2022-11-02&sr=c&sig=d%2FOsbbexy37cCqPnbkQGp3zaRhtn%2BAZ0%2BwKSVvE8mk4%3D"
    );
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(file.name);
    const blockBlobClient = blobClient.getBlockBlobClient();
    const result = await blockBlobClient.uploadBrowserData(file, {
      blockSize: 4 * 1024 * 1024,
      concurrency: 20,
      onProgress: (ev) => console.log(ev),
    });

  }

  const customLableStyle = {
    fontSize: "0.75rem",
    fontWeight: "500",
    color: "#212529",
    marginRight: "10px",
  };

  const customAnchoreTagStyle = {
    fontWeight: "500",
  };

  //fiscalyear
  function handlefiscalyear(selectedValue) {
    // setSelectedFiscalYear(selectedValue);
    form.setValues({ fiscalYear: selectedValue });
    GetEntityRecords(selectedValue, form.values?.entityID);
  }

  const handleEntity = (selectedValue) => {
    // setSelectedEntity(selectedValue);
    form.setValues({ entityID: selectedValue });
    GetEntityRecords(form.values.fiscalYear, selectedValue);
  };

  const GetEntityRecords = (year, entityid) => {
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.Clinic_Uri_Attestation}/${entityid}/${year}`
      )
      .then((response) => {

        if (attestions.isNewRow) {
          form.setValues({
            PI: response.data.qppPi,
            CQM: response.data.qppCqm,
            RU: response.data.qppRu,
            CPIA: response.data.qppCpia,
            PIlabel: "PI " + response.data.mipsPI + "%",
            CQMlabel: "CQM " + response.data.mipsCqm + "%",
            RUlabel: "RU " + response.data.mipsRu + "%",
            CPIAlabel: "CPIA " + response.data.mipsCpia + "%",
          });
        } else {
          form.setValues({
            PIlabel: "PI " + response.data.mipsPI + "%",
            CQMlabel: "CQM " + response.data.mipsCqm + "%",
            RUlabel: "RU " + response.data.mipsRu + "%",
            CPIAlabel: "CPIA " + response.data.mipsCpia + "%",
          });
        }
      })
      .catch((error) => {
        console.error("Error Group fetching data:", error);
      });
  };

  const getSelectedGroup = () => {
    return groupDropDownList.find((ele) => ele.value == attestions?.groupId)
      ?.label;
  };

  return (
    <>
      <tr key={index}>
        <td>
          <Select
            size="xs"
            label="Fiscal Year"
            {...form.getInputProps("fiscalYear")}
            disabled={isEditMode ? false : true}
            data={FiscalYear}
            onChange={(selectedValue) => handlefiscalyear(selectedValue)}
          />
        </td>

        <td>
          <Select
            size="xs"
            withAsterisk
            label="Reporting Category"
            disabled={isEditMode ? false : true}
            {...form.getInputProps("entityID")}
            data={entityList.map((ele) => ({
              value: +ele.id,
              label: ele.name,
            }))}
            onChange={(selectedValue) => handleEntity(selectedValue)}
          />
        </td>

        <td>
          <TextInput
            size="xs"
            label="CEHRT"
            disabled={isEditMode ? false : true}
            {...form.getInputProps("chert")}
          />
        </td>
        <td>
          <TextInput
            size="xs"
            label="Cost"
            disabled={isEditMode ? false : true}
            {...form.getInputProps("cost")}
          />
        </td>
        <td>
          <div>
            <label style={customLableStyle}>Consent</label>
            {form.values.concent && (
              <Anchor href={form.values.concent}>
                <span style={customAnchoreTagStyle}>Download</span>
              </Anchor>
            )}
          </div>
          <FileInput
            placeholder="Upload File"
            size="xs"
            disabled={isEditMode ? false : true}
            {...form.getInputProps("concentFile")}
          />
        </td>
        <td>
          <div>
            <label style={customLableStyle}>CPIA Doc</label>
            {form.values.cpiaDoc && (
              <Anchor href={form.values.cpiaDoc}>
                <span style={customAnchoreTagStyle}>Download</span>
              </Anchor>
            )}
          </div>
          <FileInput
            placeholder="Upload File"
            size="xs"
            disabled={isEditMode ? false : true}
            {...form.getInputProps("cpiadocFile")}
          />
        </td>
      </tr>

      <tr>
        <td>
          <Select
            label="Meeting Status"
            size="xs"
            withAsterisk
            {...form.getInputProps("meetingStatus")}
            disabled={isEditMode ? false : true}
            data={groupMeetingStatus.map((ele) => ({
              value: ele.id,
              label: ele.name,
            }))}
          />
        </td>
        <td>
          <Select
            label="Override Submission Method:"
            size="xs"
            {...form.getInputProps("overridSubMethod")}
            disabled={isEditMode ? false : true}
            data={OverrideSubmissionMethodClinic}
          />
        </td>
        <td colSpan={2}>
          <Grid>
            <Grid.Col span={4}>
              <b>QPP API Submit : </b>
            </Grid.Col>
            <Grid.Col span={4}>
              <b>Selected Group : {getSelectedGroup()}</b>
            </Grid.Col>
            <Grid.Col span={4}>
              <b>Small Practitioner : {attestions?.smallGroupPractitioner ? 'True' : 'False'}</b>
            </Grid.Col>
            <Grid.Col span={3}>
              <Checkbox
                labelPosition="left"
                label={form.getInputProps("PIlabel").value}
                radius="xs"
                size="xs"
                disabled={isEditMode ? false : true}
                {...form.getInputProps(`PI`, { type: "checkbox" })}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <Checkbox
                labelPosition="left"
                label={form.getInputProps("CQMlabel").value}
                radius="xs"
                size="xs"
                disabled={isEditMode ? false : true}
                {...form.getInputProps("CQM", { type: "checkbox" })}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <Checkbox
                labelPosition="left"
                label={form.getInputProps("RUlabel").value}
                radius="xs"
                size="xs"
                disabled={isEditMode ? false : true}
                {...form.getInputProps("RU", { type: "checkbox" })}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <Checkbox
                labelPosition="left"
                label={form.getInputProps("CPIAlabel").value}
                radius="xs"
                size="xs"
                disabled={isEditMode ? false : true}
                {...form.getInputProps("CPIA", { type: "checkbox" })}
              />
            </Grid.Col>
          </Grid>
        </td>
        <td colSpan={2}>
          <Grid>
            <Grid.Col span={12}>
              <Checkbox
                labelPosition="left"
                label="IsMvp"
                radius="xs"
                size="xs"
                disabled={isEditMode ? false : true}
                // Spread default input props
                {...form.getInputProps("isMvp", { type: "checkbox" })}
                onChange={(e) => {
                  const checked = e.currentTarget.checked;
                  // Update isMvp value in form
                  form.setFieldValue("isMvp", checked);
                  // If unchecked, clear the select value
                  if (!checked) {
                    form.setFieldValue("mvpId", null);
                  }
                }}
              />
            </Grid.Col>

            <Grid.Col span={12}>
              <Select
                label="Select Mvp"
                size="xs"
                withAsterisk
                // Spread input props for mvpId
                {...form.getInputProps("mvpId")}
                // Enable select only when isMvp is true
                disabled={!isEditMode || !form.values.isMvp}
                data={groupMvp.map((ele) => ({
                  value: ele.id,
                  label: ele.name,
                }))}
              />
            </Grid.Col>
          </Grid>
        </td>

      </tr>

      <tr>
        <td colSpan={6}>
          <Button.Group>
            {isEditMode ? (
              <>
                <Button onClick={rowUpdate}>Save</Button>
                <Button onClick={rowEditCancel}>Cancel</Button>
              </>
            ) : (
              <>
                <Button onClick={rowEdit}>Edit</Button>
                <Button onClick={() => onDelete(attestions)}>Delete</Button>
              </>
            )}
          </Button.Group>
        </td>
      </tr>
    </>
  );
};
