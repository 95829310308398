export const PhysicianColumn = [
  {
    header: "First Name",
    accessorKey: "firstName",
  },
  {
    header: "Last Name",
    accessorKey: "lastName",
  },
  {
    header: "NPI",
    accessorKey: "npi",
  },
  {
    header: "Attestation Clinics",
    accessorKey: "pecosClinic",
    // Cell: ({ cell }) => {
    //   return <>{cell.getValue().map(item=>{
    //     return <li>{item.orgName }</li>
    //    })}</>;
    // },
    accessorFn: (row) => {
      return row.pecosClinic?.map(item=>{
        return <li>{item.orgName }</li>
       })
    },
    filterFn: (row, id, filterValue) => {
      const clinics = row?.original?.pecosClinic;
      const found = clinics.some((item) =>
        item.orgName.toLowerCase().includes(filterValue.toLowerCase())
      );
      return found;
    }
  },
  {
    header: "Account Type",
    accessorKey: "acccountType",
    Cell: ({ cell }) => {
      return <div>{cell.getValue() ? "Active" : "InActive"}</div>;
    },
  },
  {
    header: "Speciality",
    accessorKey: "speciality",
  }

];