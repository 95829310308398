export const csvdata = {
  id: 0,
  formatName: "",
  formatType: "",
  jsonFormats: [
    {
      id: 0,
      csvFormatId: 0,
      index: "",
      name: "",
      defaultName: "NPI",
    },
    {
      id: 0,
      csvFormatId: 0,
      index: "",
      name: "",
      defaultName: "TIN",
    },
    {
      id: 0,
      csvFormatId: 0,
      index: "",
      name: "",
      defaultName: "PerformanceMet",
    },
    {
      id: 0,
      csvFormatId: 0,
      index: "",
      name: "",
      defaultName: "PerformanceNotMet",
    },
    {
      id: 0,
      csvFormatId: 0,
      index: "",
      name: "",
      defaultName: "Excl",
    },
    {
      id: 0,
      csvFormatId: 0,
      index: "",
      name: "",
      defaultName: "Excp",
    },
    {
      id: 0,
      csvFormatId: 0,
      index: "",
      name: "",
      defaultName: "EligiblePopulation",
    },
    {
      id: 0,
      csvFormatId: 0,
      index: "",
      name: "",
      defaultName: "Measure",
    },
    {
      id: 0,
      csvFormatId: 0,
      index: "",
      name: "",
      defaultName: "StartDate",
    },
    {
      id: 0,
      csvFormatId: 0,
      index: "",
      name: "",
      defaultName: "EndDate",
    },
    {
      id: 0,
      csvFormatId: 0,
      index: "",
      name: "",
      defaultName: "dataCompletenessNotMet",
    },
  ],
};
