
import { useMemo } from "react";

const PhysicianIncomeColumn =
    [
        {
            header: "Name",
            accessorKey: 'name'
        },
        {
            header: "UUID",
            accessorKey: "uuid",
        },
        {
            header: "Year",
            accessorKey: "year",
        },
       
    ];
export default PhysicianIncomeColumn;