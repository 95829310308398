import { Button, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import axios from "axios";
import { ApiLink } from "../../Constant/ApiLink";
import {
  EHRinterfaceMap,
  FiscalYear,
} from "../../Shared/DropDownOptions/DropDownList";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

export const AttestationRow = ({
  addNew,
  enrollmentid,
  pecos,
  attestions,
  onDelete,
  updateAddNew,
}) => {
  const [isEditMode, setEditMode] = useState(false);
  const [isAddMode, setAddMode] = useState(false);

  const [data, setdata] = useState(attestions);
  // const [pecosdata, setpecosdata] = useState([]);

  const form = useForm({
    initialValues: {
      id: data?.id,
      physicianId: data?.physicianId,
      stageFiscalYear: data?.stageFiscalYear ?? "-",
      clinicId: data?.clinicId ?? "-",
      ehrInterfaceMap: data?.ehrInterfaceMap ?? "-",
      submissionMethod: data?.submissionMethod ?? "Registry",
      lowVolume: data?.lowVolume ?? false,
      entityName: data?.entityTypes.name,
      entityType: data?.entityType ?? "10",
      status: true,
      isMannual: data?.isMannual ?? false,
      isOnline: false,

      // {
      //     "physicianId": "string",
      //     "stageFiscalYear": "string",
      //     "clinicId": "string",
      //     "ehrInterfaceMap": "string",
      //     "submissionMethod": "string",
      //     "lowVolume": true,
      //     "entityType": "string",
      //     "status": true
      //   }
    },
  });

  //show attestation div base on conditions

  useEffect(() => {
    if (addNew === true) {
      setAddMode(true);
      setEditMode(true);
      form.setValues({ physicianId: enrollmentid });
    } else {
      setAddMode(false);
    }
  }, [addNew]);

  // useEffect(() => {
  //     setpecosdata(pecos);
  //     //setdata(attestions);
  // }, []);

  const rowEdit = (e) => {
    setEditMode(true);
  };

  //For Save
  const rowUpdate = (e) => {
    if (addNew === true) {
     

      ApiCall.axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}${ApiLink.AttestationHistory}`,
          form.values
        )
        .then((response) => {
          alert("Record Created Succesfully");
          
          updateAddNew(true);
          setEditMode(false);
          setAddMode(false);
        })
        .catch((error) => {
          alert("Record already created ");
          console.error("Error Group fetching data:", error);
        });
    } else {
      
      ApiCall.axiosInstance
        .put(
          `${process.env.REACT_APP_API_URL}${ApiLink.AttestationHistory}`,
          form.values
        )
        .then((response) => {
          alert("Record Update Succesfully");
          
          setEditMode(false);
        })
        .catch((error) => {
          console.error("Error Group fetching data:", error);
        });
    }
  };

  const rowEditCancel = (e) => {
    setEditMode(false);
    updateAddNew(true);
  };

  //forAttestation Clinic
  function GetOrgName() {
    const filterClinic = pecos.filter((row) => {
      return row.clinicId === form.getInputProps("clinicId").value;
    });
    if (filterClinic === undefined || filterClinic.length === 0) return "";
    return filterClinic[0].orgName;
  }

  return (
    <>
      <tr>
        <td>
          {isAddMode ? (
            <Select
              {...form.getInputProps("stageFiscalYear")}
              data={FiscalYear}
            />
          ) : (
            form.getInputProps("stageFiscalYear").value
          )}
        </td>

        <td>
          {isAddMode ? (
            <Select
              {...form.getInputProps("clinicId")}
              data={pecos.map((ele) => ({
                value: ele.clinicId,
                label: ele.orgName,
              }))}
            />
          ) : (
            GetOrgName()
          )}
        </td>

        <td>
          {" "}
          {isEditMode ? (
            <Select
              {...form.getInputProps("ehrInterfaceMap")}
              data={EHRinterfaceMap}
            />
          ) : (
            form.getInputProps("ehrInterfaceMap").value
          )}
        </td>
      </tr>
      <tr>
        <td>
          <b>LowVolume : </b>{" "}
          {form.getInputProps("lowVolume").value == true ? "Yes" : "No"}
        </td>
        <td>
          <b>Entity Type : </b> {form.getInputProps("entityName").value}
        </td>
        <td>
          <b>Submission Method : </b>{" "}
          {form.getInputProps("submissionMethod").value}
        </td>
      </tr>
      <tr>
        <td colSpan={3}>
          <Button.Group>
            {isEditMode ? (
              <>
                <Button onClick={rowUpdate}>Save</Button>
                <Button onClick={rowEditCancel}>Cancel</Button>
              </>
            ) : (
              <>
                <Button onClick={rowEdit}>Edit</Button>
                <Button onClick={() => onDelete(attestions)}>Delete</Button>
              </>
            )}
          </Button.Group>
        </td>
      </tr>
    </>
  );
};
