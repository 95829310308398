import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useSelector } from "react-redux";
import { ApiLink } from "../../../Constant/ApiLink";
import axios from "axios";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";

const ECComparisionTool = () => {
  const { id } = useParams();
  const [rowData, setRowData] = useState();
  const [loading, setIsLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const selectedGroupId = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );

  const columnDef = useMemo(() => [
    {
      header: "Order",
      accessorKey: "order",
    },
    {
      header: "Rank",
      accessorKey: "rank",
    },
    {
      header: "EC-Name",
      accessorKey: "ecName",
    },
    {
      header: "Speciality",
      accessorKey: "speciality",
    },
    {
      header: "EC-Value",
      accessorKey: "ecValue",
    },
    {
      header: "Numer",
      accessorKey: "numer",
    },
    {
      header: "Denom",
      accessorKey: "denom",
    },
    {
      header: "State",
      accessorKey: "state",
    },
    {
      header: "Zip Code",
      accessorKey: "zipcode",
    },
  ]);

  useEffect(() => {
    getECToolData();
  }, [reportSelection.selectedYear, selectedGroupId, reportSelection.clinicId]);

  const getECToolData = (id) => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.ECComparisionTool}${selectedGroupId}/${reportSelection.clinicId}/${reportSelection.selectedYear}/1`
      ) //neeed to fix measure id things
      .then((response) => {
        setRowData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <Box style={{ marginTop: "15px" }}>
      <MantineReactTable
        columns={columnDef}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};

export default ECComparisionTool;
