import {  Container, LoadingOverlay } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useNavigate, useRoutes } from 'react-router';
import { Router } from './routes/Router';
import { RouteLink } from './Constant/RouterLink';
import { useDispatch, useSelector } from 'react-redux';
import { addClinicForSeletedGroup, addGroupDropDownList, addGuiData, replacePartnerTreeItem, setSelectedGrupId } from './utils/patenerTreeSlice';
import { addClinicId, addScallingFactor, addSelectedYear } from './utils/selectedClinicSlice';
import { ApiCall } from './Shared/ApiCall/ApiCall';
import { ApiLink } from './Constant/ApiLink';



export default function DashboardLayout() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const refreshCounter = useSelector((store) => store.partenerTree.treeRefreshCount);
  const routing = useRoutes(Router);
  const partenerTreeItems = useSelector((store) => store.partenerTree.partenerTreeItem);
  const {user} = useSelector((store) => store.auth);
 
  // useEffect(()=>{
  //   if(!localStorage.getItem('state') || (localStorage.getItem('refreshCount') < refreshCounter) || (partenerTreeItems == [])){
  //      getPratenerData(); 
  //     getGUIData();
  //     dispatch(addSelectedYear(new Date()?.getFullYear()));
  //     localStorage.setItem('scallingFactor',"0.6");
  //     dispatch(addScallingFactor("0.6"));
  //   }else{
  //     getGUIData();
  //     const partenersData = JSON.parse(localStorage.getItem('state'));
  //     const scallingFactor = localStorage.getItem('scallingFactor');
  //     if(partenersData){
  //       dispatch(replacePartnerTreeItem(partenersData));
  //       dispatch(addScallingFactor(scallingFactor));
  //       if(localStorage.getItem('groupId')){
  //         setFromatedGroupHeaderDropDownmOnRefresh(partenersData);
  //       }else{
  //         setFromatedGroupHeaderDropDownm(partenersData);
  //         dispatch(addSelectedYear(new Date()?.getFullYear()));
  //       }
  //       if(localStorage.getItem('year')){
  //         dispatch(addSelectedYear(localStorage.getItem('year')));
  //       }else{
  //         dispatch(addSelectedYear(new Date()?.getFullYear()));
  //       }
  //     }
  //   }
  // },[refreshCounter])

  // const  getPratenerData = () => {

  //   setLoading(true)
  //   ApiCall.axiosInstance
  //   .get(
  //     `${process.env.REACT_APP_API_URL}${ApiLink.GetPartnersTreeById}/${(user && user.level==1?user.organizationId:0)}/${new Date()?.getFullYear()}`
  //   )
  //   .then((response) => {
  //     response.data[0].group = response.data[0].group.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
  //     localStorage.setItem('state', JSON.stringify(response?.data));
  //     localStorage.setItem('refreshCount', refreshCounter);
  //     dispatch(replacePartnerTreeItem(response?.data));
  //     setFromatedGroupHeaderDropDownm(response?.data);
  //     setLoading(false)
  //   })
  //   .catch((error) => {
  //     console.error("Error while fetching Partener data:", error);
  //     // alert(`Error while fatching parteners data, Please refresh the page again! ${JSON.stringify(error)}`);
  //     setLoading(false)
  //   })
  // }

  // const getGUIData = () => {
  //   ApiCall.axiosInstance
  //   .get(
  //     `${process.env.REACT_APP_API_URL}${ApiLink.GUIDYear}`
  //   )
  //   .then((response) => {
  //     dispatch(addGuiData(response.data));
  //   })
  //   .catch((error) => {
  //     console.error("Error while fetching Partener data:", error);
  //     // alert("Error while fatching GUI year, Please refresh the page again!")
  //   })
  // }

  useEffect(() => {
    const loadData = async () => {
      if (
        !localStorage.getItem('state') ||
        Number(localStorage.getItem('refreshCount')) < refreshCounter ||
        !Array.isArray(partenerTreeItems) || partenerTreeItems.length === 0
      ) {
        setLoading(true);
        await getPratenerData(); // wait until this finishes
        await getGUIData();
        dispatch(addSelectedYear(new Date().getFullYear()));
        localStorage.setItem('scallingFactor', "0.6");
        dispatch(addScallingFactor("0.6"));
        setLoading(false);
      } else {
        await getGUIData();
        const partenersData = JSON.parse(localStorage.getItem('state')) || [];
        const scallingFactor = localStorage.getItem('scallingFactor') || "0.6";
  
        dispatch(replacePartnerTreeItem(partenersData));
        dispatch(addScallingFactor(scallingFactor));
  
        if (localStorage.getItem('groupId')) {
          setFromatedGroupHeaderDropDownmOnRefresh(partenersData);
        } else {
          setFromatedGroupHeaderDropDownm(partenersData);
          dispatch(addSelectedYear(new Date().getFullYear()));
        }
  
        if (localStorage.getItem('year')) {
          dispatch(addSelectedYear(localStorage.getItem('year')));
        } else {
          dispatch(addSelectedYear(new Date().getFullYear()));
        }
      }
    };
  
    loadData(); // call the async function
  }, [refreshCounter, user]);


  const getPratenerData = () => {
    setLoading(true);
    return ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.GetPartnersTreeById}/${(user && user.level == 1 ? user.organizationId : 0)}/${new Date().getFullYear()}`)
      .then((response) => {
        response.data[0].group = response.data[0].group.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
        localStorage.setItem('state', JSON.stringify(response?.data));
        localStorage.setItem('refreshCount', refreshCounter);
        dispatch(replacePartnerTreeItem(response?.data));
        setFromatedGroupHeaderDropDownm(response?.data);
      })
      .catch((error) => {
        console.error("Error while fetching Partener data:", error);
        setLoading(false);
      });
  };

  const getGUIData = () => {
    return ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.GUIDYear}`)
      .then((response) => {
        dispatch(addGuiData(response.data));
      })
      .catch((error) => {
        console.error("Error while fetching GUI year:", error);
      });
  };



  const setFromatedGroupHeaderDropDownm = (partenerTreeItems) => {
    let dropDownList = [];
    partenerTreeItems[0].group?.forEach((element) => {
      let dropDownItem = {};
      dropDownItem["value"] = element.id;
      dropDownItem["label"] = element.name;
      dropDownList.push(dropDownItem);
    });
    dispatch(addGroupDropDownList(dropDownList));
    dispatch(addClinicForSeletedGroup(partenerTreeItems[0]?.group[0]?.clinic));
    dispatch(setSelectedGrupId(partenerTreeItems[0]?.group[0]?.id));
    dispatch(addClinicId(partenerTreeItems[0]?.group[0]?.clinic[0]?.id));
  }

  const setFromatedGroupHeaderDropDownmOnRefresh = (partenerTreeItems) => {
    let dropDownList = [];
    partenerTreeItems[0]?.group?.forEach((element) => {
      let dropDownItem = {};
      dropDownItem["value"] = element.id;
      dropDownItem["label"] = element.name;
      dropDownList.push(dropDownItem);
    });
    dispatch(addGroupDropDownList(dropDownList));
    dispatch(addClinicForSeletedGroup(JSON.parse(localStorage.getItem('selectedClinicList'))));
    dispatch(setSelectedGrupId(+localStorage.getItem('groupId')));
    dispatch(addClinicId(+localStorage.getItem('clinicId')));
  }



  return (
    <>
      <Container fluid>{routing}</Container>
      {loading &&
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      }
    </>

  );
}
