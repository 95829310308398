import { useState } from "react";
import { Carousel } from "@mantine/carousel";
import { Modal, Button } from "@mantine/core";

// Utility function to extract YouTube video id from URL
const extractVideoId = (url) => {
  try {
    const urlObj = new URL(url);
    return urlObj.searchParams.get("v");
  } catch (error) {
    return null;
  }
};

const YouTubeCarousel = () => {
  const [opened, setOpened] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const videos = [
    "https://www.youtube.com/watch?v=9pAqiS-fkDI",
    "https://www.youtube.com/watch?v=WidonPfMjKo",
    "https://www.youtube.com/watch?v=vrQV13FHzyA",
    "https://www.youtube.com/watch?v=vrQV13FHzyA",
    "https://www.youtube.com/watch?v=vrQV13FHzyA",
    "https://www.youtube.com/watch?v=N8tVsXNH4cw"
  ];

  const handleVideoClick = (videoUrl) => {
    setCurrentVideo(videoUrl);
    setOpened(true);
  };

  return (
    <>
      <Carousel
        slideSize="25%"
        slideGap="md"
        align="start"
        slidesToScroll={1}
        loop // Enables cyclic navigation
        withControls // Enables default left/right controls
      >
        {videos.map((video, index) => {
          const videoId = extractVideoId(video);
          const thumbnail = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
          return (
            <Carousel.Slide key={index} onClick={() => handleVideoClick(video)}>
              <img
                src={thumbnail}
                alt={`Video ${index}`}
                style={{ cursor: "pointer", width: "100%" }}
              />
            </Carousel.Slide>
          );
        })}
      </Carousel>

      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Video Player"
        size="lg"
      >
        {currentVideo && (
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%", // 16:9 aspect ratio
              height: 0,
              overflow: "hidden"
            }}
          >
            <iframe
              src={`https://www.youtube.com/embed/${extractVideoId(currentVideo)}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube Video Player"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}
            ></iframe>
          </div>
        )}
        <Button
          mt="md"
          variant="outline"
          color="red"
          onClick={() => setOpened(false)}
        >
          Close
        </Button>
      </Modal>
    </>
  );
};

export default YouTubeCarousel;
