import React, { useState, useRef, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate } from "react-router-dom";
import useAxios from "../../Shared/useApi";
import { ActionIcon, Box, Button, Grid, Switch } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { IconEdit, IconHttpDelete } from "@tabler/icons-react";
import { RowAction } from "../../Shared/RowAction";
import { useDisclosure } from "@mantine/hooks";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import { ApiLink } from "../../Constant/ApiLink";
import { useSelector } from "react-redux";
import axios from "axios";
import GuiColumn from "./GuiColumn";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const GuiList = () => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  var navigate = useNavigate();
  const guiColumnDef = useMemo(() => GuiColumn);
  const [checked, setChecked] = useState(true);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [loading, setIsLoading] = useState(false);
  const [deletingRow, setDeletingRow] = useState({});

  const defaultColDef = useMemo(() => ({
    sortable: true,
    flex: 1,
  }));

  useEffect(() => {
    if (checked) {
      getPublicData(reportSelection.selectedYear);
    } else {
      getPublicData("0");
    }
  }, [checked, reportSelection]);

  const getPublicData = (year) => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.GUIDYear}`)
      .then((response) => {
        const data = response?.data;
        setRowData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    
  };

  const onCreate = () => {
    navigate(RouteLink.Gui_Create_Uri, {
      state: { data: null, method: "post" },
    });
  };

  const onUpdate = (value) => {
    navigate(RouteLink.Gui_Update_Uri, {
      state: { data: value, method: "put" },
    });
  };

  const onDelete = (value) => {
   
    setDeletingRow(value);
    open();
  };
  const [opened, { close, open }] = useDisclosure(false);
  function IsConfirmed(value) {
    if (value === true) {
      setIsLoading(true);
      ApiCall.axiosInstance["delete"](
        `${process.env.REACT_APP_API_URL}${ApiLink.GUIDYear}?id=${deletingRow.id}`
      )
        .then((response) => {
         
          setIsLoading(false);
          setRowData((oldValues) => {
            return oldValues.filter((rowData) => rowData.id !== deletingRow.id);
          });
        })
        .catch((error) => {
          console.error("Failed Request:", error);
          setIsLoading(false);
        });
    }
  }

  return (
    <Box style={{ marginTop: "15px" }}>
      <DeleteConfirmationModal
        IsConfirmed={IsConfirmed}
        opened={opened}
        open={open}
        close={close}
      />

      <Grid>
        {/* <Grid.Col span={3}>
          <Button type="primary" onClick={() => onCreate()}>
            Create Gui Year
          </Button>
        </Grid.Col> */}
        {/* <Grid.Col span={9}>
          <Switch  style={{float: 'right'}}
            label='Show Yearly Data'
            checked={checked}
            onChange={(event) => setChecked(event.currentTarget.checked)}
          />
        </Grid.Col> */}
      </Grid>

     
      <MantineReactTable
        columns={guiColumnDef}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn="first"
        renderRowActions={(row) => (
          <Delete
            onUpdate={() => onUpdate(row.row.original)}
            //  onDelete={()=>onDelete(row.row.original)}
          />
        )}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};

export default GuiList;

const Delete = (props) => {
  return (
    <Grid>
        <Button.Group>
            {<Button variant="filled" color='green' size="xs" onClick={props.onUpdate} >
                <IconEdit size="1.2rem"></IconEdit>
            </Button>}
        </Button.Group>
    </Grid>
);
}