

export const SubCategoryColumn =[
        {
           
            header: "Name",
            accessorKey: "subCatName"
        },
        {
           
            header: "Category Name",
            accessorKey: "category.name",
            // Cell:(cell=>{
            
                
            //     return cell.categoy.Name ;
                
            // })
        },
        {
           
            header: "Abbrevation",
            accessorKey: "abbrevation",
        },
        {
            accessorKey: "account",
            header: "Account Status",
            Cell:(cell=>(<span>{cell?"Active":"InActive"}</span>))
        },
    ];
    
