import { MantineReactTable } from 'mantine-react-table';
import { UserColumn } from './UserColumn';
import { useNavigate } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { RouteLink } from '../../Constant/RouterLink';
import { RowAction } from '../../Shared/RowAction';
import { useDisclosure } from "@mantine/hooks";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import { useDispatch, useSelector } from 'react-redux';
import { ApiLink } from '../../Constant/ApiLink';
import { ApiCall } from '../../Shared/ApiCall/ApiCall';
import { Box, Button } from '@mantine/core';

export const UserList = () => {
  const UserColumnDefs = useMemo(() => UserColumn);
  var navigate = useNavigate();
  const [rowData, setRowData] = useState();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((store) => store.auth);
  const partenerTreeItems = useSelector((state) => state.partenerTree.partenerTreeItem);
  const selectedGroupId = useSelector((store) => store.partenerTree.selectedGroupId);
  const reportSelection = useSelector((store) => store.selectedClinic.item);


  useEffect(() => {

    let levelId = 0;

    if (user.level === 0) {
      levelId = 0
    } else if (user.level === 1) {
      levelId = partenerTreeItems[0]?.id;
    } else if (user.level === 2) {
      levelId = selectedGroupId;
    } else if (user.level === 3) {
      levelId = reportSelection.clinicId;
    }

    setLoading(true);
    ApiCall.getUsers(user.level, levelId).then((response) => {
      setRowData(response);
    }).catch((error) => {

    }).finally(() => {
      setLoading(false);
    });
  }, []);



  function openCreateForm() {
    navigate(RouteLink.CreateUser_Uri, {
      state: { data: null, method: "post" },
    });
  }

  const onUpdate = (value) => {

    navigate(RouteLink.UpdateUser_Uri, {
      state: { data: value, method: "put" },
    });
  };


  const [deletingRow, setDeletingRow] = useState({});
  const onDelete = (value) => {

    setDeletingRow(value);
    open();

  }
  const [opened, { close, open }] = useDisclosure(false);


  function IsConfirmed(value) {
    if (value === true) {
      setLoading(true);
      ApiCall.axiosInstance["delete"](
        `${process.env.REACT_APP_API_URL}${ApiLink.User_Uri}?id=${deletingRow.id}`
      )
        .then((response) => {
          setLoading(false);
          setRowData(oldValues => {
            return oldValues.filter(rowData => rowData.id !== deletingRow.id)
          })
        })
        .catch((error) => {
          console.error("Failed Request:", error);
          setLoading(false);
        });
    }

  }


  return (
    <Box style={{ marginTop: "15px" }}>
      <DeleteConfirmationModal IsConfirmed={IsConfirmed} opened={opened} open={open} close={close} />
      <Button onClick={openCreateForm}>Create User</Button>
      <MantineReactTable
        columns={UserColumnDefs}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn='first'
        renderRowActions={(row) => (
          <RowAction onUpdate={() => onUpdate(row.row.original)} onDelete={() => onDelete(row.row.original)} />
        )}
        mantinePaperProps={{ shadow: '0', withBorder: false }}
      />
    </Box>
  );
};
