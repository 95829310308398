import { useMemo } from "react";

export const ViewColumns = [
  {
    header: "Measure Title",
    accessorKey: "measureTitle",
  },
  {
    header: "Measure ID",
    accessorKey: "measureId",
  },
  {
    header: "CMS ElectronicHealthRecord ID",
    accessorKey: "cmsElectronicHeadlthRecordId",
  },
  {
    header: "Collection Type",
    accessorKey: "submissionMethod",
  },
  {
    header: "Measure Type",
    accessorKey: "measureType",
  },
  {
    header: "High Priority",
    accessorKey: "isHighPriority",
  },
  {
    header: "Average Performance Rate",
    accessorKey: "avgPerformanceRate",
  },
  {
    header: "Measure Has Benchmark",
    accessorKey: "measurehasBenchmark",
  },
  {
    header: "Benchmark Type",
    accessorKey: "benchmarkType",
  },
  {
    header: "Measure Potential",
    accessorKey: "measurePotential",
  },
  {
    header: "Decile 1",
    accessorKey: "decile1",
    accessorFn: (row) => {
      return row?.decile1 ? row?.decile1 : "-";
    },
  },
  {
    header: "Decile 2",
    accessorKey: "decile2",
    accessorFn: (row) => {
      return row?.decile2 ? row?.decile2 : "-";
    },
  },
  {
    header: "Decile 3",
    accessorKey: "decile3",
    accessorFn: (row) => {
      return row?.decile3 ? row?.decile3 : "-";
    },
  },
  {
    header: "Decile 4",
    accessorKey: "decile4",
    accessorFn: (row) => {
      return row?.decile4 ? row?.decile4 : "-";
    },
  },
  {
    header: "Decile 5",
    accessorKey: "decile5",
    accessorFn: (row) => {
      return row?.decile5 ? row?.decile5 : "-";
    },
  },
  {
    header: "Decile 6",
    accessorKey: "decile6",
    accessorFn: (row) => {
      return row?.decile6 ? row?.decile6 : "-";
    },
  },
  {
    header: "Decile 7",
    accessorKey: "decile7",
    accessorFn: (row) => {
      return row?.decile7 ? row?.decile7 : "-";
    },
  },
  {
    header: "Decile 8",
    accessorKey: "decile8",
    accessorFn: (row) => {
      return row?.decile8 ? row?.decile8 : "-";
    },
  },
  {
    header: "Decile 9",
    accessorKey: "decile9",
    accessorFn: (row) => {
      return row?.decile9 ? row?.decile9 : "-";
    },
  },
  {
    header: "Decile 10",
    accessorKey: "decile10",
    accessorFn: (row) => {
      return row?.decile10 ? row?.decile10 : "-";
    },
  },
  {
    header: "Topped Out",
    accessorKey: "isToppedOut",
  },
  {
    header: "Seven Point Cap",
    accessorKey: "isToppedOutByProgram",
  },
  {
    header: "No Benchmark Score",
    accessorKey: "noBenchmarkScore",
  }
  
];
