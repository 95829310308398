import React, { useState, useRef, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Button, Box, Switch, Grid } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import LoaderOverlay from "../../Shared/LoaderOverlay";
import { ApiLink } from "../../Constant/ApiLink";
import useAxios from "../../Shared/useApi";
import { RouteLink } from "../../Constant/RouterLink";
import { RowAction } from "../../Shared/RowAction";
import { MantineReactTable } from "mantine-react-table";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import { useDisclosure } from "@mantine/hooks";
import { PhysicianColumn } from "./PhysicianColumn";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { label } from "../../Shared/Constant";
import { setDisableYear } from "../../utils/patenerTreeSlice";
import { IconEdit } from "@tabler/icons-react";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const PhysicianList = () => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  var navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [checked, setChecked] = useState(true);
  const dispatch = useDispatch();

  const defaultColDef = useMemo(() => ({
    sortable: true,
    flex: 1,
    wrapHeaderText: true,
    minWidth: 150,
  }));

  // const { response, loading, error } = useAxios({
  //   method: "get",
  //   url: ApiLink.Physician_Uri,
  //   headers: null,
  //   body: null,
  // });

  useEffect(() => {
    if (checked) {
      getPhysicianListData(reportSelection.clinicId);
    } else {
      getPhysicianListData("0");
    }
    dispatch(setDisableYear(true));
    return () => {
      dispatch(setDisableYear(false));
    };
  }, [checked, reportSelection]);

  const getPhysicianListData = (clinicId) => {
    if (reportSelection.selectedYear && clinicId) {
      setLoading(true);
      ApiCall.axiosInstance
        .get(
          `${process.env.REACT_APP_API_URL}${ApiLink.Physician_Uri}?enrollmentid=${clinicId}&year=${reportSelection.selectedYear}`
        )
        .then((response) => {
          setRowData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Failed Request:", error);
          setLoading(false);
        });
    }
  };

  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
  
  };

  const onCreate = () => {
    // navigate(RouteLink.Physician_Create_Uri, {
    //   state: { data: null, method: "post" },
    // });

    const physicianData = { data: null, method: "post" };
    localStorage.setItem("physicianData", JSON.stringify(physicianData));
    setTimeout(() => {
      navigate(RouteLink.Physician_Create_Uri);
    }, 200);
  };

  const onUpdate = (value) => {
    setLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.getPhysicianByEnrollmentId}/${value?.id}/${reportSelection.selectedYear}`
      )
      .then((response) => {
        if (response?.data?.length) {
          // navigate(RouteLink.Physician_Update_Uri, {
          //   state: { data: response?.data[0], method: "put" },
          // });

          const physicianData = { data: response?.data[0], method: "put" };
          localStorage.setItem("physicianData", JSON.stringify(physicianData));
          setTimeout(() => {
            navigate(RouteLink.Physician_Update_Uri);
          }, 200);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        setLoading(false);
      });
  };

  const onDelete = (value) => {
   
    open();
  };
  const [opened, { close, open }] = useDisclosure(false);
  const columnDefs = useMemo(() => PhysicianColumn);
  function IsConfirmed(value) {}

  return (
    <Box style={{ marginTop: "15px" }}>
      <DeleteConfirmationModal
        IsConfirmed={IsConfirmed}
        opened={opened}
        open={open}
        close={close}
      />

      <Grid>
        <Grid.Col span={3}>
          {" "}
          <Button type="primary" onClick={() => onCreate()}>
            Create Physician
          </Button>
        </Grid.Col>
        <Grid.Col span={9}>
          <Switch
            style={{ float: "right" }}
            label={label("Show TIN wise Physicians")}
            checked={checked}
            onChange={(event) => setChecked(event.currentTarget.checked)}
          />
        </Grid.Col>
      </Grid>

   
      <MantineReactTable
        columns={columnDefs}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn="first"
        initialState={{ columnVisibility: { firstName: true, lastName: true } }}
        renderRowActions={(row) => (
          // <RowAction
          //   onUpdate={() => onUpdate(row.row.original)}
          //   onDelete={() => onDelete(row.row.original)}
          // />

          <Button
            variant="filled"
            color="green"
            size="xs"
            onClick={() => onUpdate(row.row.original)}
          >
            <IconEdit size="1.2rem"></IconEdit>
          </Button>
        )}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};

export default PhysicianList;
