import { isNotEmpty, useForm } from "@mantine/form";
import React, { useState, useEffect } from "react";
import {
  TextInput,
  Button,
  Box,
  Grid,
  Select,
  Space,
  FileInput,
} from "@mantine/core";
import { ApiLink } from "../../Constant/ApiLink";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Loader } from "@mantine/core";
import { Sandivider } from "../../Shared/Theme/Sandivider";
import style from "../../Shared/Theme/CommanStyle.module.css";
import { ShowHideStatus } from "../../Shared/DropDownOptions/ShowHideStatus";
import { ActiveStatus } from "../../Shared/DropDownOptions/ActiveStatus";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const PartnerForm = () => {
  const navigate = useNavigate();
  const { data, method } = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(null);

  const form = useForm({
    initialValues: {
      id: data?.id,
      name: data?.name,
      description: data?.description,
      isDisplay: data?.isDisplay ?? true,
      isActive: data?.isActive ?? true,
      parentGroup: data?.parentGroup,
      logo: data?.logo || "", // stores the uploaded file name
      file: null,
    },
    validate: {
      name: (value) =>
        !value || value.length < 2 ? "Name must have at least 2 letters" : null,
    },
  });

  // When editing (PUT), fetch and display the existing image
  useEffect(() => {
    if (method === "put" && data?.logo) {
      ApiCall.axiosInstance.get(
          `${process.env.REACT_APP_API_URL}${ApiLink.Download_Logo}/${data.logo}`,
          { responseType: "blob" }
        )
        .then((response) => {
          const imageUrl = URL.createObjectURL(response.data);
          setPreview(imageUrl);
        })
        .catch((error) => {
          console.error("Error fetching logo image", error);
        });
    }
  }, [method, data]);

  const handleBack = () => {
    navigate(RouteLink.Partners_Uri);
    setLoading(false);
  };

  
  const handleFileChange = async (file) => {
    form.setFieldValue("file", file);
  
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setPreview(fileUrl);
  
      const formData = new FormData();
      formData.append("file", file, file.name);
  
      try {
        const response = await ApiCall.axiosInstance.post(
          `${process.env.REACT_APP_API_URL}${ApiLink.Upload_Logo}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "text/plain",
            },
          }
        );
  
        const uploadedFileName = response.data;
        form.setFieldValue("logo", uploadedFileName);
      } catch (error) {
        console.error("File upload error:", error);
        // Optional: show error toast or fallback
      }
    } else {
      setPreview(null);
      form.setFieldValue("logo", ""); // Optional: reset logo if no file
    }
  };
  
  // Remove the image and clear related form fields
  const handleRemoveImage = () => {
    setPreview(null);
    form.setFieldValue("logo", null);
    form.setFieldValue("file", null);
  };

  const handleSubmit = (formValues) => {
    if (!formValues.defaultInvidualEntityType) {
      formValues.defaultInvidualEntityType = "";
    }
    setLoading(true);

    // Submit the form (you may remove the file field if not needed)
    ApiCall.axiosInstance[method](
      `${process.env.REACT_APP_API_URL}${ApiLink.Partner_Uri}`,
      formValues
    )
      .then((response) => {
        setLoading(false);
        navigate(RouteLink.Partners_Uri);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <Box className={style.mt2}>
      {loading && <Loader size={48} />}
      <h5>Category : Add New</h5>
      <Space />

      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid>
          <Grid.Col span={6}>
            <TextInput label="Partner Name:" {...form.getInputProps("name")} />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Partner Description:"
              {...form.getInputProps("description")}
            />
          </Grid.Col>
        </Grid>



        <Grid>
          <Grid.Col span={4}>
            <FileInput
              label="Upload Logo"
              placeholder="Select file"
              onChange={handleFileChange}
            />
            {preview && (
              <>
                <img
                  src={preview}
                  alt="Preview"
                  style={{ maxWidth: "150px", maxHeight: "auto", marginTop: "1rem" }}
                />
                <br />
                <Button
                  mt="sm"
                  variant="outline"
                  color="red"
                  onClick={handleRemoveImage}
                >
                  Remove Image
                </Button>
              </>
            )}
          </Grid.Col>
        </Grid>


        <Sandivider />

        <Grid>
          <Grid.Col span={3}>
            <Select
              label="Partner Status:"
              {...form.getInputProps("isDisplay")}
              data={ShowHideStatus}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Select
              label="Account Status:"
              {...form.getInputProps("isActive")}
              data={ActiveStatus}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "0.5rem",
              }}
            >
              <div>
                <Button type="button" onClick={handleBack}>
                  Back
                </Button>
                <Button style={{ marginLeft: "10px" }} type="submit">
                  Submit
                </Button>
              </div>
            </div>
          </Grid.Col>
        </Grid>
      </form>
    </Box>
  );
};

export default PartnerForm;
