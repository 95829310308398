import axios from "axios";

// Service function that accepts parameters
const getTinMeasureData = async (params, currentTinId) => {
  const options = {
    method: "GET",
    url: `https://cms-dev-db.herokuapp.com/api/measures/filter/encounters/${currentTinId}`,
    params,
    headers: {
      cookie:
        "session=%22%5C%22a220c10e-959a-432d-8d63-9f3b2aa99e3a%5C%5C054session%3D5de9f0c2-7873-448f-ae2e-faf3316b574c%5C%22%5C054session%3D5de9f0c2-7873-448f-ae2e-faf3316b574c%22",
      accept: "application/json",
      token: "1a8f045698702028cbd9d8e04e206354",
      Cookie: "session=5de9f0c2-7873-448f-ae2e-faf3316b574c",
    },
  };

  try {
    const response = await axios.request(options);
    return response; // Or return response.data if you only need the data
  } catch (error) {
    console.error("Error fetching measure data:", error);
    throw error; // Rethrow the error so it can be handled by the caller
  }
};

export default getTinMeasureData;
