import { Box, Container } from "@mantine/core";
import { Outlet } from "react-router";

export function HomeLayout()
{
    return(
        <Box>
            <Outlet></Outlet>
        </Box>
    );
}