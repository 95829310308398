import { Menu, Button, Text, Avatar } from '@mantine/core';
import { IconSettings, IconSearch, IconPhoto, IconMessageCircle, IconTrash, IconArrowsLeftRight, IconUser, IconLogout } from '@tabler/icons-react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { logout } from '../Pages/Login/AuthSlice';
import { replacePartnerTreeItem } from '../utils/patenerTreeSlice';



export const UserMenu=({user})=> {
    const dispatch = useDispatch();
    var navigate = useNavigate();

    const logoutUser = () => {
        localStorage.clear();
       
        dispatch(logout());
        dispatch(replacePartnerTreeItem([]));
        navigate("/");
        window.location.reload();
      };

      const gotoRestpassword = () => {
       
        navigate("/user/resetpassword", { state: { caller: "user" } });
       
      };
    
  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <Avatar color="cyan">
            <IconUser size="1.5rem" /> 
        </Avatar>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>{user.name}</Menu.Label>
        {/* <Menu.Item icon={<IconSettings size={14} />}>Settings</Menu.Item>
        <Menu.Item icon={<IconMessageCircle size={14} />}>Messages</Menu.Item>
        <Menu.Item icon={<IconPhoto size={14} />}>Gallery</Menu.Item>
        <Menu.Item
          icon={<IconSearch size={14} />}
          rightSection={<Text size="xs" color="dimmed">⌘K</Text>}
        >
          Search
        </Menu.Item> */}

        <Menu.Divider />
        <Menu.Item  onClick={gotoRestpassword} icon={<IconLogout size={14} />}>Reset Password</Menu.Item>
        <Menu.Item color="red" onClick={logoutUser} icon={<IconLogout size={14} />}>Logout</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}