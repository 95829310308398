import React, { useState, useRef, useEffect, useMemo } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate } from "react-router-dom";
import useAxios from "../../Shared/useApi";
import { ActionIcon, Box, Button, Grid, Notification } from "@mantine/core";
import CategoryColumn from "./CategoryColumn";
import { MantineReactTable } from "mantine-react-table";
import { IconEdit, IconHttpDelete } from "@tabler/icons-react";
import { RowAction } from "../../Shared/RowAction";
import { useDisclosure } from "@mantine/hooks";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import axios from "axios";
import { ApiLink } from "../../Constant/ApiLink";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const CategoryList = () => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();

  var navigate = useNavigate();

  const categoryColumnDefs = useMemo(() => CategoryColumn);

  const { response, loading, error } = useAxios({
    method: "get",
    url: "/Category",
    headers: null,
    body: null,
  });

  useEffect(() => {
    if (response !== null) {
      setRowData(response);
    }
    if (error) {
    }
  }, [response, error]);

  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
  };

  const onCreate = () => {
    navigate(RouteLink.Catagory_Create_Uri, {
      state: { data: null, method: "post" },
    });
  };

  const onUpdate = (value) => {
    navigate(RouteLink.Catagory_Create_Uri, {
      state: { data: value, method: "put" },
    });
  };

  const [deletingRow, setDeletingRow] = useState({});
  const onDelete = (value) => {
    setDeletingRow(value);
    open();
  };

  const [opened, { close, open }] = useDisclosure(false);
  const [formLoading, setLoading] = useState(false);

  function IsConfirmed(value) {
    if (value === true) {
      setLoading(true);
      ApiCall.axiosInstance["delete"](
        `${process.env.REACT_APP_API_URL}${ApiLink.Category_Uri}?id=${deletingRow.categoryId}`
      )
        .then((response) => {
          setLoading(false);
          setRowData((oldValues) => {
            return oldValues.filter(
              (rowData) => rowData.categoryId !== deletingRow.categoryId
            );
          });
        })
        .catch((error) => {
          console.error("Failed Request:", error);
          setLoading(false);
        });
    }
  }

  return (
    <Box style={{ marginTop: "15px" }}>
      <DeleteConfirmationModal
        IsConfirmed={IsConfirmed}
        opened={opened}
        open={open}
        close={close}
      />
      <Button type="primary" onClick={() => onCreate()}>
        Create Category
      </Button>

      <MantineReactTable
        columns={categoryColumnDefs}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={(row) => (
          <RowAction
            onUpdate={() => onUpdate(row.row.original)}
            onDelete={() => onDelete(row.row.original)}
          />
        )}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};

export default CategoryList;
