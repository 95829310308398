import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Anchor,
  Box,
  Grid,
  Group,
  Radio,
  SegmentedControl,
  Select,
  Text,
  Tooltip,
} from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useSelector } from "react-redux";
import { ApiLink } from "../../../Constant/ApiLink";
import axios from "axios";
import { IconEye } from "@tabler/icons-react";
import MeasureDetailsModel from "../GroupCQM/MeasureDetailsModel";
import getTinMeasureData from "../../../services/tinMeasureService";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";


const TinCqmDetail = () => {
  const [rowData, setRowData] = useState();
  const [submissionType, setSubmissionType] = useState("Registry");
  const [sysgen, setSysgen] = useState("false");
  const [weightage, setWeightage] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [headerText, setHeaderText] = useState("");
  const [program, setProgram] = useState("mips");
  const [loading, setIsLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const selectedGroupId = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );
  const [uniqueMeasureIds, setUniqueMeasureId] = useState([]);
  const selectedGroupClnicsList = useSelector(
    (store) => store.partenerTree.selectedGroupClnics
  );

  const defaultVisibleColumns = useMemo(
    () => ({
      measureId: true,
      title: true,
      eMeasureId: false,
      performanceMet: true,
      eligiblePopulation: false,
      nonEligiblePopulation: false,
      prPercentage: true,
      measurePotential: false,
      decileValue: true,
      measureType: false,
      metricType: false,
      performanceNotMet: false,
      excl: false,
      excp: false,
      dataCompletnessValue: true,
      dataCompletenessNotMet: false,
      IsToppedOut: false,
      IsHighPriority: false,
      IsToppedOutByProgram: false,
      noBenchmarkScore: false,
      isInverse: false,
      startDate: true,
      endDate: true,
      denominator: true,
    }),
    []
  );

  const [columnVisibility, setColumnVisibility] = useState(
    defaultVisibleColumns
  );

  const columnDef = useMemo(() => [
    {
      header: "Measure Id",
      accessorKey: "measureId",
      filterVariant: "select",
      mantineFilterSelectProps: {
        data: uniqueMeasureIds,
      },
    },
    {
      header: "Title",
      accessorFn: (row) => (
        <Tooltip multiline w={400} label={row.title}>
          <span
            className="text-ellipsiss"
            style={{
              display: "inline-block",
              maxWidth: "200px",
              minWidth: "200px",
              overflow: "hidden",
              whiteSpace: "normal", // allows wrapping to multiple lines
              textOverflow: "ellipsis",
              lineClamp: 2, // limits text to two lines
              WebkitLineClamp: 2, // for webkit browsers
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
            }}
          >
            {row.title}
          </span>
        </Tooltip>
      ),
      size: 10,
    },
    {
      header: "Performance Rate",
      accessorKey: "prPercentage",
    },
    {
      header: "Decile",
      accessorKey: "decileValue",
    },

    {
      header: "Performance Met",
      accessorKey: "performanceMet",
      Cell: ({ row }) => (
        <Anchor onClick={() => handleRowClick(row.original, "performance_met")}>
          {row.original.performanceMet}
        </Anchor>
      ),
    },
    {
      header: "Denominator",
      accessorKey: "denominator",
      accessorFn: (row) => {
        // Calculate the data completeness value
        const dataCompletenessNotMetValue =
          row.dataCompletenessNotMet === 0 ||
          row.dataCompletenessNotMet === null
            ? row.eligiblePopulation -
              (row.performanceMet + row.performanceNotMet + row.excp)
            : row.dataCompletenessNotMet;
        // Calculate the final denominator value
        const denominatorValue =
          row.eligiblePopulation - (row.excp + dataCompletenessNotMetValue);

        return denominatorValue;
      },
    },
    {
      header: "E-Measure Id",
      accessorKey: "eMeasureId",
    },
    {
      header: "Data Completness Rate",
      accessorKey: "dataCompletnessValue",
      accessorFn: (row) => {
        return parseFloat(row?.dcPercentage).toFixed(2);
      },
    },
    {
      header: "Eligible Population",
      accessorKey: "eligiblePopulation",
      Cell: ({ row }) => (
        <Anchor
          onClick={() => handleRowClick(row.original, "eligible_population")}
        >
          {row.original.eligiblePopulation}
        </Anchor>
      ),
    },
    {
      header: "Non Eligible Population",
      accessorKey: "nonEligiblePopulation",
      Cell: ({ row }) => (
        <Anchor
          onClick={() =>
            handleRowClick(row.original, "non_eligible_population")
          }
        >
          <IconEye />
        </Anchor>
      ),
    },

    {
      header: "Measure Potential",
      accessorKey: "measurePotential",
    },

    {
      header: "Measure Type",
      accessorKey: "measureType",
    },
    {
      header: "Metric Type",
      accessorKey: "metricType",
    },

    {
      header: "Performance Not Met",
      accessorKey: "performanceNotMet",
      Cell: ({ row }) => (
        <Anchor
          onClick={() => handleRowClick(row.original, "performance_not_met")}
        >
          {row.original.performanceNotMet}
        </Anchor>
      ),
    },
    {
      header: "Excl",
      accessorKey: "excl",
      Cell: ({ row }) => (
        <Anchor
          onClick={() => handleRowClick(row.original, "denominator_exclusion")}
        >
          {row.original.excl}
        </Anchor>
      ),
    },
    {
      header: "Excp",
      accessorKey: "excp",
      Cell: ({ row }) => (
        <Anchor
          onClick={() => handleRowClick(row.original, "denominator_exception")}
        >
          {row.original.excp}
        </Anchor>
      ),
    },
    {
      header: "Data Completeness Not Met",
      accessorKey: "dataCompletenessNotMet",
      Cell: ({ row }) => {
        const { original } = row;
        const value =
          original.dataCompletenessNotMet === 0 ||
          original.dataCompletenessNotMet === null
            ? original.eligiblePopulation -
              (original.performanceMet +
                original.performanceNotMet +
                original.excp)
            : original.dataCompletenessNotMet;

        return (
          <Anchor
            onClick={() => handleRowClick(original, "datacompleteness_not_met")}
          >
            {value}
          </Anchor>
        );
      },
    },

    {
      header: "IsToppedOut",
      accessorKey: "IsToppedOut",
      accessorFn: (row) => {
        return row?.IsToppedOut == true ? "Yes" : "No";
      },
    },
    {
      header: "IsHighPriority",
      accessorKey: "IsHighPriority",
      accessorFn: (row) => {
        return row?.IsHighPriority == true ? "Yes" : "No";
      },
    },
    {
      header: "IsToppedOutByProgram",
      accessorKey: "IsToppedOutByProgram",
      accessorFn: (row) => {
        return row?.IsToppedOutByProgram == true ? "Yes" : "No";
      },
    },
    {
      header: "No. Benchmark Score",
      accessorKey: "noBenchmarkScore",
    },
    {
      header: "Is Inverse",
      accessorKey: "isInverse",
      accessorFn: (row) => {
        return row?.isInverse == true ? "Yes" : "No";
      },
    },
    {
      header: "StartDate",
      accessorKey: "startDate",
      accessorFn: (row) => {
        if (row?.startDate) {
          const [year, month, day] = row.startDate.split("-");
          const formattedDate = `${month}-${day}-${year}`;
          return formattedDate;
        }
        return row?.startDate;
      },
    },

    {
      header: "End Date",
      accessorKey: "endDate",
      accessorFn: (row) => {
        if (row?.endDate) {
          const [year, month, day] = row.endDate.split("-");
          const formattedDate = `${month}-${day}-${year}`;
          return formattedDate;
        }
        return row?.endDate;
      },
    },
  ]);

  useEffect(() => {
    if (
      submissionType != undefined &&
      reportSelection.selectedYear != undefined &&
      reportSelection.clinicId != undefined
    ) {
      getQualityCalculationTinWise();
    }
  }, [
    reportSelection.selectedYear,
    reportSelection.clinicId,
    submissionType,
    sysgen,
    program,
  ]);

  const handleRowClick = async (singleRowData, colName) => {
    const currentClinicId = selectedGroupClnicsList.find(
      (ele) => ele.value === reportSelection.clinicId
    )?.tinID;

    setIsLoading(true);
    try {
      const params = {
        metric: colName,
        npi: "",
        measure: singleRowData?.measureId,
        entity_type: "group",
      };

      const response = await getTinMeasureData(params, currentClinicId);

      // Handle the different response data as needed
      setRecords(response?.data?.encounters);
      setIsModalOpen(true);
      setHeaderText("Measure Details");
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch different measure data:", error);
      setIsLoading(false);
    }
  };

  const getQualityCalculationTinWise = () => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.QualityCalculationTinWise}${reportSelection.clinicId}/${reportSelection.selectedYear}/${submissionType}/${sysgen}/${program}`
      )
      .then((response) => {
        const modifiedData = response?.data?.map((item) => {
          // Assuming 'numerostr' is a field in the 'performancemet' object
          const updatedItem = {
            ...item,
            denominator: item.eligiblePopulation - item.excp,
          };

          // Return the item with the updated performancemet
          delete updatedItem.performancemet;
          return updatedItem;
        });

        // setRowData(response.data);
        setRowData(modifiedData);
        const uniqueMeasures = [
          ...new Set(response?.data?.map((item) => item.measureId)),
        ].sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        setUniqueMeasureId(uniqueMeasures);
        setWeightage({
          weightageValue: response?.data[0]?.weightageValue,
          finalTarget: response?.data[0]?.finalTarget,
          cqmWeightage: response?.data[0]?.cqmWeightage,
          mipsScore: response?.data[0]?.mipsScore,
          cost:
            response?.data?.[0]?.clinicCost && response?.data?.[0]?.mipsCpia
              ? (response?.data?.[0]?.clinicCost *
                  response?.data?.[0]?.mipsCpia) /
                100
              : 0,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  function calculatePercentage(numerator, denominator) {
    if (denominator === 0) {
      return 0; // Return 0 when denominator is zero
    }
    const percentage = (numerator / denominator) * 100;
    return percentage.toFixed(2); // Rounds to two decimal places
  }

  const handleColumnVisibilityChange = (updatedColumnVisibility) => {
    setColumnVisibility(updatedColumnVisibility);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Box style={{ marginTop: "15px" }}>
      <Grid>
        <Grid.Col span={3}>
          <Radio.Group
            value={submissionType}
            onChange={setSubmissionType}
            name="favoriteFramework"
            label="Select Submission Method"
            withAsterisk
          >
            <Group mt="xs">
              <Radio value="Registry" label="Registry" />
              <Radio value="ElectronicHealthRecord" label="EHR" />
            </Group>
          </Radio.Group>
        </Grid.Col>
        <Grid.Col span={3}>
          <Radio.Group
            value={sysgen}
            onChange={setSysgen}
            name="favoriteFrameworks"
            label="Select Method"
            withAsterisk
          >
            <Group mt="xs">
              <Radio value="false" label="System Generated" />
              <Radio value="true" label="Client Generated" />
            </Group>
          </Radio.Group>
        </Grid.Col>
                 <Grid.Col span={2}>
                 <Text size="sm"  mb="xs">
    Select Program:
  </Text>
                    <SegmentedControl
                      label="Submitted For:"
                      color="rgba(43, 138, 66, 1)"
                      fullWidth
                      data={[
                        { label: "MIPS", value: "mips" },
                        { label: "MVP", value: "mvp" },
                      ]}
                      value={program} 
                      onChange={(value) => setProgram(value)} 
                    />
                  </Grid.Col>

        <Grid.Col span={4} style={{ textAlign: "right" }}>
          <Text>
            <span style={{ fontWeight: "bold" }}>Overall Score:</span>{" "}
            {weightage &&
              (weightage.weightageValue + weightage.cost).toFixed(2)}
          </Text>

          {weightage && (
            <Tooltip
              label={
                // weightage.weightageValue + "/" + weightage.cqmWeightage
                calculatePercentage(
                  weightage.weightageValue,
                  weightage.cqmWeightage
                ) + "%"
              }
              position="top"
              withArrow
            >
              <Text>
                <span style={{ fontWeight: "bold" }}>
                  Quality Contributions:
                </span>{" "}
                {
                  weightage.weightageValue + "/" + weightage.cqmWeightage
                  // calculatePercentage(
                  //   weightage.weightageValue,
                  //   weightage.cqmWeightage
                  // ) + "%"
                }
              </Text>
            </Tooltip>
          )}
          {weightage && (
            <Tooltip
              label={
                // weightage.mipsScore + "/" + weightage.finalTarget * 10
                calculatePercentage(
                  weightage.mipsScore,
                  weightage.finalTarget * 10
                ) + "%"
              }
              position="top"
              withArrow
            >
              <Text>
                <span style={{ fontWeight: "bold" }}>Standalone Quality:</span>{" "}
                {
                  weightage.mipsScore + "/" + weightage.finalTarget * 10
                  // calculatePercentage(
                  //   weightage.mipsScore,
                  //   weightage.finalTarget * 10
                  // ) + "%"
                }
              </Text>
            </Tooltip>
          )}
        </Grid.Col>
      </Grid>
      <MantineReactTable
        columns={columnDef}
        data={rowData ?? []}
        enableExpanding
        getSubRows={(row) => row.subRows || undefined}
        renderToolbarInternalActions={() => null}
        renderRowProps={({ row }) => ({
          style: {
            backgroundColor: row.original.rowColor, // 🔥 Use rowColor from data
            color: row.original.rowColor === "red" ? "white" : "black",
            fontWeight: "bold",
            transition: "background-color 0.3s ease-in-out",
            "&:hover": {
              backgroundColor:
                row.original.rowColor === "red" ? "darkred" : "lightgreen", // Hover effect
            },
          },
        })}
        state={{ isLoading: loading, columnVisibility }}
        enableHiding={true}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
      {isModalOpen && (
        <MeasureDetailsModel
          records={records}
          header={headerText}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  );
};

export default TinCqmDetail;
