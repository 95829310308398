import { useForm } from "@mantine/form";
import React, { useState } from "react";
import { TextInput, Button, Box, Grid, Select, Space } from "@mantine/core";
import { ApiLink } from "../../Constant/ApiLink";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Loader } from "@mantine/core";
import { Abbrevation } from "../../Shared/DropDownOptions/AbbrevationList";
import { ShowHideStatus } from "../../Shared/DropDownOptions/ShowHideStatus";
import { Sandivider } from "../../Shared/Theme/Sandivider";
import style from "../../Shared/Theme/CommanStyle.module.css";
import { ActiveStatus } from "../../Shared/DropDownOptions/ActiveStatus";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

function CategoryForm() {
  const navigate = useNavigate();
  const { data, method } = useLocation().state;
  const [loading, setLoading] = useState(false);
  const handleSubmit = (formValues) => {

   
    setLoading(true);
    ApiCall.axiosInstance[method](
      `${process.env.REACT_APP_API_URL}${ApiLink.Category_Uri}`,
      formValues
    )
      .then((response) => {
        
        setLoading(false);
        navigate(RouteLink.Category_Uri);
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        setLoading(false);
      });
  };

  const handleBack = () => {
    navigate(RouteLink.Category_Uri);
    setLoading(false);
  };

  const thisForm = useForm({
    initialValues: {
      categoryId: data?.categoryId,
      catName: data?.catName,
      description:data?.description,
      abbrevation: data?.abbrevation,
      sortOrder_1: data?.sortOrder_1,
      sortOrder_2: data?.sortOrder_2,
      catStatus: data?.catStatus,
      account: data?.account,
    },

  });

  return (
    <Box className={style.mt2}>
      {loading && <Loader size={48} />}
      <h5>Category : Add New</h5>
      <Space></Space>

      <form onSubmit={thisForm.onSubmit((values) => handleSubmit(values))}>
        <Grid>
        <Grid.Col span={4}>
            <TextInput
              label="Category Name"
              withAsterisk
              {...thisForm.getInputProps("catName")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput
              label="Abbrevation"
              withAsterisk
              {...thisForm.getInputProps("abbrevation")}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label="Description"
              {...thisForm.getInputProps("description")}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={2}>
            <TextInput
              label="Sort Order 1"
              withAsterisk
              {...thisForm.getInputProps("sortOrder_1")}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <TextInput
              label="Sort Order 2"
              withAsterisk
              {...thisForm.getInputProps("sortOrder_2")}
            />
          </Grid.Col>
        </Grid>
        <Sandivider></Sandivider>
        <Grid>
          <Grid.Col span={3}>
            <Select
              label="Category Status:"
              {...thisForm.getInputProps("catStatus")}
              data={ShowHideStatus}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Select
              label="Account Status:"
              defaultValue={data?.account}
              {...thisForm.getInputProps("account")}
              data={ActiveStatus}
            />
          </Grid.Col>
        </Grid>
        <div style={{ marginTop: "1rem", float: "right" }}></div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div>
            <Button type="primary" onClick={() => handleBack()}>
              Back
            </Button>
            <Button style={{ marginLeft: "10px" }} type="submit" mt="sm">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Box>
  );
}

export default CategoryForm;
