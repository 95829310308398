export const CqmCategory = [
  { value: "Normal", label: "Normal" },
  { value: "ToppedOut", label: "Topped Out" },
  { value: "CaseMinimium", label: "Case Minimium" },
];

export const QualityTemperoryData = [
  {
    cqmId: 'MSN12',
    npi: "1407102338",
    lastName: "ABDYRAKOV ",
    firstName:"ABDYRAKOV  ALMAZ  , M.D.",
    speciality: "Internal Medicine",
    totBen: 276,
    potencialCcm: 0.75,
    actualCcm: 0,
    _99490: 0,
    _99491: 0,
    _99426: 0,
    _99424: 0,
  },
  {
    cqmId: 'JKN12',
    npi: "1942615075                                        ",
    lastName: "AUNG                                              ",
    firstName:
      "AUNG                                               HTOO HTOO                                         , MD",
    speciality: "Internal Medicine",
    totBen: 56,
    potencialCcm: 0.75,
    actualCcm: 0,
    _99490: 0,
    _99491: 0,
    _99426: 0,
    _99424: 0,
  },
  {
    cqmId: 'MS1202',
    npi: "1023559259                                        ",
    lastName: "CAMILON                                           ",
    firstName:
      "CAMILON                                            MARINELLE                                         , ",
    speciality: "Family Medicine",
    totBen: 224,
    potencialCcm: 0.75,
    actualCcm: 0,
    _99490: 0,
    _99491: 0,
    _99426: 0,
    _99424: 0,
  },
  {
    cqmId: 'LKM82',
    npi: "1225387137                                        ",
    lastName: "CHEN                                              ",
    firstName:
      "CHEN                                               KELVIN                                            , MD",
    speciality: "Internal Medicine",
    totBen: 427,
    potencialCcm: 0.75,
    actualCcm: 0,
    _99490: 0,
    _99491: 0,
    _99426: 0,
    _99424: 0,
  },
  {
    cqmId: 'SDK12',
    npi: "1073742193                                        ",
    lastName: "CHIANG                                            ",
    firstName:
      "CHIANG                                             BEN                                               , M.D.",
    speciality: "Hospitalist",
    totBen: 352,
    potencialCcm: 0.75,
    actualCcm: 0,
    _99490: 0,
    _99491: 0,
    _99426: 0,
    _99424: 0,
  },
  {
    cqmId: 'LKI122',
    npi: "1144573288                                        ",
    lastName: "GUO                                               ",
    firstName:
      "GUO                                                GARY                                              , M.D.",
    speciality: "Hospitalist",
    totBen: 120,
    potencialCcm: 0.75,
    actualCcm: 0,
    _99490: 0,
    _99491: 0,
    _99426: 0,
    _99424: 0,
  },
  {
    cqmId: 'OOP78',
    npi: "1184952681                                        ",
    lastName: "HAMIDJAJA                                         ",
    firstName:
      "HAMIDJAJA                                          LINDA                                             , MD",
    speciality: "Internal Medicine",
    totBen: 0,
    potencialCcm: 0,
    actualCcm: 0,
    _99490: 0,
    _99491: 0,
    _99426: 0,
    _99424: 0,
  },
];
