import React, { useEffect, useMemo, useState } from "react";
import { Button, Box, Grid, Space, Text } from "@mantine/core";
import { useNavigate, useLocation, json } from "react-router-dom";
import axios from "axios";
import { ApiLink } from "../../../Constant/ApiLink";
import { MantineReactTable } from "mantine-react-table";
import { ViewColumns } from "./ViewBenchMarkColumn";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";

const ViewBenchmark = () => {
  const navigate = useNavigate();
  const { data } = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [singleBenchmarkData, setSingleBenchmarkData] = useState([]);

  const defaultVisibleColumns = useMemo(
    () => ({
      measureTitle: false,
      measureId: true,
      cmsElectronicHeadlthRecordId: false,
      submissionMethod: true,
      measureType: false,
      isHighPriority: true,
      avgPerformanceRate: false,
      measurehasBenchmark: true,
      benchmarkType: false,
      decile1: true,
      decile2: true,
      decile3: true,
      decile4: true,
      decile5: true,
      decile6: true,
      decile7: true,
      decile8: true,
      decile9: true,
      decile10: true,
      isToppedOut: true,
      isToppedOutByProgram: true,
      noBenchmarkScore: true,
      measurePotential: true,
    }),
    []
  );

  const [columnVisibility, setColumnVisibility] = useState(
    defaultVisibleColumns
  );

  const handleColumnVisibilityChange = (updatedColumnVisibility) => {
    setColumnVisibility(updatedColumnVisibility);
  };

  useEffect(() => {
    GetSingleBenchmarkBasedonmYear(data?.year);
  }, [data?.year]);

  const GetSingleBenchmarkBasedonmYear = (year) => {
    setLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.GetSingleBenchmarkBasedonmYear}${year}`
      )
      .then((response) => {
        let tranformedData = transformData(response.data);
        setSingleBenchmarkData(tranformedData);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function transformData(inputArray) {
    return inputArray.map((item) => {
      let transformedItem = {
        id: item.id,
        measureId: item.measureId,
        benchmarkYear: item.benchmarkYear,
        performanceYear: item.performanceYear,
        measurePotential: item.measurePotential,
        submissionMethod: item.submissionMethod,
        isToppedOut: item.isToppedOut == true ? "Yes" : "No",
        isHighPriority: item.isHighPriority == true ? "Yes" : "No",
        isInverse: item.isInverse,
        metricType: item.metricType,
        isToppedOutByProgram: item.isToppedOutByProgram == true ? "Yes" : "No",
        noBenchmarkScore: item.noBenchmarkScore,
        measurehasBenchmark: item.measurehasBenchmark == true ? "Yes" : "No",
        measureType: item.measureType ?? "",
        measureTitle: item.measureTitle ?? "",
        cmsElectronicHeadlthRecordId: item.cmsElectronicHeadlthRecordId ?? "",
        benchmarkType: item.benchmarkType ?? "",
      };

      item.percentilesses.forEach((percentile) => {
        let decileKey = "decile" + percentile.decile;
        transformedItem[decileKey] = percentile.from + " - " + percentile.to;
        // {
        //   id: percentile.id,
        //   bkId: percentile.bkId,
        //   decile: percentile.decile,
        //   from: percentile.from,
        //   to: percentile.to,
        // };
      });

      return transformedItem;
    });
  }

  const handleBack = () => {
    navigate("/user/utilities/benchmark-list");
  };

  function mapColumnHeaders(columnDef) {
    return columnDef.map((column) => ({
      key: column.accessorKey,
      displayLabel: column.header,
    }));
  }

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    columnHeaders: mapColumnHeaders(ViewColumns),
    filename: "Benchmark_Report_" + data?.year,
  });

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(singleBenchmarkData);
    download(csvConfig)(csv);
  };

  return (
    <Box>
      <Grid>
        <Grid.Col span={3}>
          <Button type="primary" onClick={handleExportData}>
            Export Benchmark
          </Button>
        </Grid.Col>
      </Grid>
      <MantineReactTable
        columns={ViewColumns}
        data={singleBenchmarkData ?? []}
        state={{ isLoading: loading, columnVisibility }}
        enableHiding={true}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />

      <Grid justify="end">
        <Grid.Col span={12} align="end">
          <Button type="primary" onClick={() => handleBack()}>
            Back
          </Button>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default ViewBenchmark;
