export const  	PIScoringType=[
    { value: "1", label: "0" },
    { value: "2", label: "0 or 10" },
    { value: "3", label: "Up to 10" },
    { value: "4", label: "Up to 20" },
    { value: "5", label: "Up to 30" },
    { value: "6", label: "0 or 5" },
    { value: "7", label: "Up to 40 " },
    { value: "8", label: "0 or 40 " },
]

