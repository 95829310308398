import { useForm } from "@mantine/form";
import React, { useState } from "react";
import { TextInput, Button, Box, Grid, Select, Space, ScrollArea, Textarea, MultiSelect, } from "@mantine/core";
import { ApiLink } from "../../Constant/ApiLink";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Loader } from "@mantine/core";
import style from "../../Shared/Theme/CommanStyle.module.css";
import { YesNo } from "../../Shared/DropDownOptions/YesNo";
import { PIScoringType } from "../../Shared/DropDownOptions/PIScoringType.jsx";
import { CqmCategory } from "../../Shared/DropDownOptions/CqmCategory";
import { MetricType, ReqCategory, ReqMeasureType } from "../../Shared/DropDownOptions/DropDownList";
import { ApiCall } from "../../Shared/ApiCall/ApiCall.jsx";
import { useSelector } from "react-redux";

function RequirementForm() {
  const navigate = useNavigate();
  const { data, method } = useLocation().state;
  console.log("benchmarks", data)
  const [loading, setLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);


  const thisForm = useForm({
    initialValues: {
      ID: data?.id || 0,
      measureId: data?.measureId || "",
      category: data?.category || "",
      title: data?.title || "",
      shortname: data?.shortname || "",
      eMeasureId: data?.eMeasureId || "",
      createdby: data?.createdby || "",
      description: data?.description || "",
      metricType: data?.metricType || "",
      isInverse: data?.isInverse || false,
      submissionMethods: data?.submissionMethods || [],
      year: reportSelection?.selectedYear || "",
      isHighPriority: data?.isHighPriority || false,
      eMeasureUUID: data?.eMeasureUUID || "",
      initialPopulationUUID: data?.initialPopulationUUID || "",
      numeratorUUID: data?.numeratorUUID || "",
      denominatorUUID: data?.denominatorUUID || "",
      denominatorExceptionUUID: data?.denominatorExceptionUUID || "",
      denominatorExclusionUUID: data?.denominatorExclusionUUID || "",
      benchmarks: data?.benchmarks?.map(benchmark => ({
        ...benchmark,
        percentilesses: Array.from({ length: 10 }, (_, index) => {
          const existing = benchmark.percentilesses?.find(
            (percentile) => percentile.decile === index + 1
          );
          return (
            existing || {
              id: 0,
              bkId: benchmark.id || null,
              decile: index + 1,
              from: "",
              to: "",
            }
          );
        }),
      })) || [],
    },
  });



  const handleSubmit = (formValues) => {


    // const cleanedFormValues = {
    //   ...formValues,
    //   benchmarks: formValues.benchmarks.map(benchmark => ({
    //       ...benchmark,
    //       percentilesses: benchmark.percentilesses
    //           .filter(p => p.from !== "" && p.to !== "") // Remove empty `from` and `to`
    //           .sort((a, b) => a.decile - b.decile) // Sort by `decile`
    //   }))
    // };

    const cleanedFormValues = {
      ...formValues,
      benchmarks: [] // Always set benchmarks to an empty array
    };

    console.log("Submit", cleanedFormValues);
    setLoading(true);

    ApiCall.axiosInstance[method](
      `${process.env.REACT_APP_API_URL}${ApiLink.RequirementCreate}`,
      cleanedFormValues
    ).then((response) => {
      console.log(response);
      setLoading(false);
      navigate(RouteLink.Requirement_Uri);
    }).catch((error) => {
      console.error("Failed Request:", error);
      setLoading(false);
    });
  };


  const handleBack = () => {
    navigate(RouteLink.Requirement_Uri);
    setLoading(false);
  };

  return (
    <Box className={style.mt2}>
      {loading && <Loader size={48} />}
      <h5>Requirement : Add New</h5>
      <Space></Space>

      <form onSubmit={thisForm.onSubmit((values) => handleSubmit(values))}>
        <Grid>
          <Grid.Col span={4}>
            <TextInput
              label="Measure ID"
              withAsterisk
              {...thisForm.getInputProps("measureId")}
            />
          </Grid.Col>

          <Grid.Col span={8}>
            <TextInput
              label="Tittle"
              withAsterisk
              {...thisForm.getInputProps("title")}
            />
          </Grid.Col>

          <Grid.Col span={4}>
            <TextInput
              label="Short Name"
              withAsterisk
              {...thisForm.getInputProps("shortname")}
            />
          </Grid.Col>

          <Grid.Col span={4}>
            <TextInput
              label="E- Mesaure-ID"
              withAsterisk
              {...thisForm.getInputProps("eMeasureId")}
            />
          </Grid.Col>

          <Grid.Col span={4}>
            <Select
              label="Is Inverse"
              withAsterisk
              {...thisForm.getInputProps("isInverse")}
              data={YesNo}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select
              label="Category"
              withAsterisk
              {...thisForm.getInputProps("category")}
              data={ReqCategory}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select
              label="Metric Type"
              withAsterisk
              {...thisForm.getInputProps("metricType")}
              data={MetricType}
            />
          </Grid.Col>

          <Grid.Col span={4}>
            <TextInput
              label="Created By"
              withAsterisk
              {...thisForm.getInputProps("createdby")}
            />
          </Grid.Col>
          <Grid.Col span={8}>
            <MultiSelect
              label="Submission Methods"
              withAsterisk
              {...thisForm.getInputProps("submissionMethods")}
              clearButtonProps={{ 'aria-label': 'Clear selection' }}
              data={[
                "Claims",
                "ElectronicHealthRecord",
                "CmsWebInterface",
                "Registry"
              ]}
            />
          </Grid.Col>


          <Grid.Col span={12}>
            <Textarea
              placeholder="Descriptions"
              label="Descriptions"
              withAsterisk
              autosize
              minRows={5}
              {...thisForm.getInputProps("description")}
            />
          </Grid.Col>
        </Grid>
        {
          (thisForm.getInputProps("category").value === "Pi") ?
            <div className="container">
              <div className="mycontainer">PI Information</div>
              <Grid>
                <Grid.Col span={4}>
                  <Select
                    label="Required for Base Score "
                    withAsterisk
                    {...thisForm.getInputProps("reqForBase")}
                    data={YesNo}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextInput
                    label="PI Performance Bonus Points:"
                    withAsterisk
                    {...thisForm.getInputProps("piPerformance")}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Select
                    label="PI Scoring Type"
                    withAsterisk
                    {...thisForm.getInputProps("piScoringType")}
                    data={PIScoringType}
                  />
                </Grid.Col>
              </Grid>
            </div>
            : ""
        }


        {
          (thisForm.getInputProps("category").value === "Quality") ?
            <>
              <div className="container">
                <div className="mycontainer">Quality Information</div>
                <Grid>
                  <Grid.Col span={4}>
                    <Select
                      label="CQM Type"
                      withAsterisk
                      {...thisForm.getInputProps("cqmType")}
                      data={YesNo}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Select
                      label="Is High Priority:"
                      withAsterisk
                      {...thisForm.getInputProps("isHighPriority")}
                      data={YesNo}
                    />

                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Select
                      label="CQM Overall Algorithm:"
                      withAsterisk
                      {...thisForm.getInputProps("cqmAlgo")}
                      data={PIScoringType}
                    />
                  </Grid.Col>



                  <Grid.Col span={4}>
                    <Select
                      label="Stratum Name"
                      withAsterisk
                      {...thisForm.getInputProps("stratumName")}
                      data={PIScoringType}
                    />
                  </Grid.Col>
                </Grid>
                <hr></hr>
                <Grid>
                  <Grid.Col span={4}>
                    <TextInput
                      label="eMeasure UUID"
                      {...thisForm.getInputProps("eMeasureUUID")}
                    />
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <TextInput
                      label="Initial Population UUID:"
                      {...thisForm.getInputProps("initialPopulationUUID")}
                    />
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <TextInput
                      label="Numerator UUID"
                      {...thisForm.getInputProps("numeratorUUID")}
                    />
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <TextInput
                      label="Denominator UUID"
                      {...thisForm.getInputProps("denominatorUUID")}
                    />
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <TextInput
                      label="Denominator Exception UUID"
                      {...thisForm.getInputProps("denominatorExceptionUUID")}
                    />
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <TextInput
                      label="Denominator Exclusion UUID:"
                      {...thisForm.getInputProps("denominatorExclusionUUID")}
                    />
                  </Grid.Col>
                </Grid>
              </div>
              <hr></hr>

              <div className="container">
                <div className="mycontainer">Benchmarks</div>

                <div className="scrolldiv">
                  <table className="tbl-style">
                    <thead>
                      <tr>
                        <td>Submission Method</td>
                        <td>CQM Category</td>
                        <td>Low Outcome</td>
                        <td>Measure_Potential</td>
                        <td>1st Decile</td>
                        <td>2nd Decile</td>
                        <td>3rd Decile</td>
                        <td>4th Decile</td>
                        <td>5th Decile</td>
                        <td>6th Decile</td>
                        <td>7th Decile</td>
                        <td>8th Decile</td>
                        <td>9th Decile</td>
                        <td>10th Decile</td>
                      </tr>
                    </thead>
                    <tbody>
                      {thisForm.values.benchmarks.map((benchmark, benchmarkIndex) => (
                        <tr key={`benchmark-${benchmarkIndex}`}>
                          <td>{benchmark.submissionMethod || "Registry"}</td>
                          <td>
                            <Select
                              {...thisForm.getInputProps(`benchmarks.${benchmarkIndex}.subCatStatus`)}
                              data={CqmCategory}
                              size="xs"
                              w={156}
                            />
                          </td>
                          <td>
                            <Select
                              {...thisForm.getInputProps(`benchmarks.${benchmarkIndex}.isToppedOut`)}
                              data={YesNo}
                              size="xs"
                              w={86}
                            />
                          </td>
                          <td>{benchmark.measurePotential}</td>
                          {/* Render deciles 1–10 */}
                          {Array.from({ length: 10 }, (_, decileIndex) => {
                            const decile = decileIndex + 1;

                            return (
                              <td key={`decile-${benchmarkIndex}-${decile}`}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <TextInput
                                    size="xs"
                                    w={50}
                                    className="fsize"
                                    value={
                                      thisForm.values.benchmarks[benchmarkIndex].percentilesses[
                                        decileIndex
                                      ]?.from || ""
                                    }
                                    onChange={(e) => {
                                      const value = e.target.value;

                                      const updatedPercentilesses = [
                                        ...thisForm.values.benchmarks[benchmarkIndex].percentilesses,
                                      ];

                                      // Ensure the decile exists before updating
                                      if (!updatedPercentilesses[decileIndex]) {
                                        updatedPercentilesses[decileIndex] = { decile, from: "", to: "" };
                                      }
                                      updatedPercentilesses[decileIndex].from = value; // Store as string

                                      thisForm.setFieldValue(
                                        `benchmarks.${benchmarkIndex}.percentilesses`,
                                        updatedPercentilesses
                                      );
                                    }}
                                    onBlur={(e) => {
                                      const floatValue = parseFloat(e.target.value) || 0;

                                      const updatedPercentilesses = [
                                        ...thisForm.values.benchmarks[benchmarkIndex].percentilesses,
                                      ];

                                      updatedPercentilesses[decileIndex].from = floatValue; // Convert to float on blur

                                      thisForm.setFieldValue(
                                        `benchmarks.${benchmarkIndex}.percentilesses`,
                                        updatedPercentilesses
                                      );
                                    }}
                                  />
                                  {" "}To{" "}
                                  <TextInput
                                    size="xs"
                                    w={50}
                                    value={
                                      thisForm.values.benchmarks[benchmarkIndex].percentilesses[
                                        decileIndex
                                      ]?.to || ""
                                    }
                                    onChange={(e) => {
                                      const value = e.target.value;

                                      const updatedPercentilesses = [
                                        ...thisForm.values.benchmarks[benchmarkIndex].percentilesses,
                                      ];

                                      // Ensure the decile exists before updating
                                      if (!updatedPercentilesses[decileIndex]) {
                                        updatedPercentilesses[decileIndex] = { decile, from: "", to: "" };
                                      }
                                      updatedPercentilesses[decileIndex].to = value; // Store as string

                                      thisForm.setFieldValue(
                                        `benchmarks.${benchmarkIndex}.percentilesses`,
                                        updatedPercentilesses
                                      );
                                    }}
                                    onBlur={(e) => {
                                      const floatValue = parseFloat(e.target.value) || 0;

                                      const updatedPercentilesses = [
                                        ...thisForm.values.benchmarks[benchmarkIndex].percentilesses,
                                      ];

                                      updatedPercentilesses[decileIndex].to = floatValue; // Convert to float on blur

                                      thisForm.setFieldValue(
                                        `benchmarks.${benchmarkIndex}.percentilesses`,
                                        updatedPercentilesses
                                      );
                                    }}
                                  />
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </> : ""
        }

        <div style={{ marginTop: "1rem", float: "right" }}></div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div>
            <Button type="primary" onClick={() => handleBack()}>
              Back
            </Button>
            <Button style={{ marginLeft: "10px" }} type="submit" mt="sm">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Box>
  );
}

export default RequirementForm;
