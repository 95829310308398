import React, { useState, useEffect, useMemo } from "react";
import Swal from "sweetalert2";
import { Table, Box, LoadingOverlay, Text, Button, Group, Divider, Checkbox, Grid, Tooltip, Stack, TextInput, } from "@mantine/core";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
// Import helper functions and constants from PIFormUtils
import {
  computePoints,
  buildPayloadForMeasures,
  validateProportionFields,
  mandatoryMeasures,
  publichealthandClinicData,
  electronicPrescribingMeasures,
  healthInformationExchange,
  providerToPatientExchange,
} from "./PIFormUtils";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";
import { ApiLink } from "../../Constant/ApiLink";
import { RouteLink } from "../../Constant/RouterLink";
import { DatePicker } from "@mantine/dates";

export default function PIForm() {
  const [loading, setIsLoading] = useState(false);
  const [weightage, setWeightage] = useState({});
  const [mandatorySelected, setMandatorySelected] = useState({});
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const selectedGroupId = useSelector((store) => store.partenerTree.selectedGroupId);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  // ----- Mandatory Section -----
  const handleMandatoryCheckboxChange = (index) => {
    setMandatorySelected((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const renderMandatoryTable = () => (
    <Box my="md">
      <Text fw={700} size="lg" mb="sm" style={{ color: "#3d8a3d" }}>
        Mandatory
      </Text>
      <Table striped highlightOnHover withBorder>
        <thead className="green">
          <tr>
            <th>Measure</th>
            <th>Title</th>
            <th>Required/Optional</th>
            <th>Select</th>
          </tr>
        </thead>
        <tbody>
          {mandatoryMeasures.map((row, index) => (
            <tr key={index} style={{ backgroundColor: "#ebfbee" }}>
              <td>{row.measureId}</td>
              <td style={{ whiteSpace: "pre-line" }}>{row.measure}</td>
              <td>{row.required}</td>
              <td>
                <Checkbox
                  checked={mandatorySelected[index] !== undefined ? mandatorySelected[index] : row.value}
                  onChange={() => handleMandatoryCheckboxChange(index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Box>
  );

  // ----- Electronic Prescribing (EP) Section -----
  const [epSelection, setEpSelection] = useState({
    group1: { selected: null, num: "", den: "" },
    group2: { selected: null, num: "", den: "" },
  });

  const handleEPSelect = (group, measure) => {
    setEpSelection((prev) => {
      const groupState = prev[group];
      if (groupState.selected === measure.measureId) {
        return { ...prev, [group]: { selected: null, num: "", den: "" } };
      } else {
        return { ...prev, [group]: { selected: measure.measureId, num: "", den: "" } };
      }
    });
  };

  const updateEPNum = (group, value) => {
    setEpSelection((prev) => ({ ...prev, [group]: { ...prev[group], num: value } }));
  };

  const updateEPDen = (group, value) => {
    setEpSelection((prev) => ({ ...prev, [group]: { ...prev[group], den: value } }));
  };

  const renderEPTable = () => {
    const groups = electronicPrescribingMeasures.reduce((acc, measure) => {
      if (!acc[measure.group]) acc[measure.group] = [];
      acc[measure.group].push(measure);
      return acc;
    }, {});
    const { totalPoints: epTotalPoints } = buildPayloadForMeasures(epSelection, electronicPrescribingMeasures);
    const bgColor = "#ebfbee";
    return (
      <Box my="md">
        <Text fw={700} size="lg" mb="sm" style={{ color: "#3d8a3d" }}>
          Electronic Prescribing ({epTotalPoints.toFixed(2)} points)
        </Text>
        <Table striped highlightOnHover withBorder>
          <thead className="green">
            <tr>
              <th>Measure</th>
              <th>Title</th>
              <th>Required/Optional</th>
              <th>Value</th>
              <th>Points</th>
              <th>Select</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groups).map((groupKey, idx) => (
              <React.Fragment key={groupKey}>
                {idx > 0 && (
                  <tr>
                    <td colSpan={6} style={{ height: "10px", backgroundColor: "#fff" }}></td>
                  </tr>
                )}
                {groups[groupKey].map((measure) => {
                  const groupState = epSelection[groupKey];
                  const isSelected = groupState.selected === measure.measureId;
                  return (
                    <tr key={measure.measureId} style={{ backgroundColor: bgColor }}>
                      <td>{measure.measureId}</td>
                      <td style={{ whiteSpace: "pre-line" }}>{measure.measure}</td>
                      <td>{measure.required}</td>
                      <td>
                        {measure.metrictype === "Proportion" ? (
                          <Box style={{ display: "flex", alignItems: "center" }}>
                            <Text size="xs" mr={4}>Num:</Text>
                            <input
                              type="text"
                              value={groupState.num}
                              onChange={(e) => updateEPNum(groupKey, e.target.value)}
                              disabled={!isSelected}
                              style={{ marginRight: "10px", width: "60px" }}
                            />
                            <Text size="xs" mr={4}>Den:</Text>
                            <input
                              type="text"
                              value={groupState.den}
                              onChange={(e) => updateEPDen(groupKey, e.target.value)}
                              onBlur={(e) => {
                                if (
                                  isSelected &&
                                  groupState.num &&
                                  e.target.value &&
                                  parseFloat(e.target.value) <= parseFloat(groupState.num)
                                ) {
                                  Swal.fire({
                                    icon: "error",
                                    title: "Invalid Value",
                                    text: "Denominator must be greater than Numerator for proportion measures.",
                                  });
                                  updateEPDen(groupKey, "");
                                }
                              }}
                              disabled={!isSelected}
                              style={{ width: "60px" }}
                            />
                          </Box>
                        ) : (
                          <Text>{measure.exclusion}</Text>
                        )}
                      </td>
                      <td>
                        {isSelected ? (
                          <Text fw={600}>{computePoints(groupState, measure).toFixed(2)}</Text>
                        ) : (
                          <Text c="dimmed">0</Text>
                        )}
                      </td>
                      <td>
                        <Checkbox
                          checked={isSelected}
                          onChange={() => handleEPSelect(groupKey, measure)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </Box>
    );
  };

  // ----- Health Information Exchange (HIE) Section -----
  const [hieSelection, setHieSelection] = useState({
    group3: { selected: null, num: "", den: "" },
    group4: { selected: null, num: "", den: "" },
    group5: { selected: null },
    group6: { selected: null },
  });

  const handleHIESelect = (group, measure) => {
    setHieSelection((prev) => {
      const newState = { ...prev };
      if (group === "group5") {
        newState.group3 = { selected: null, num: "", den: "" };
        newState.group4 = { selected: null, num: "", den: "" };
        newState.group6 = { selected: null };
        newState[group].selected =
          newState[group].selected === measure.measureId ? null : measure.measureId;
      } else if (group === "group6") {
        newState.group3 = { selected: null, num: "", den: "" };
        newState.group4 = { selected: null, num: "", den: "" };
        newState.group5 = { selected: null };
        newState[group].selected =
          newState[group].selected === measure.measureId ? null : measure.measureId;
      } else if (group === "group3" || group === "group4") {
        newState.group5 = { selected: null };
        newState.group6 = { selected: null };
        newState[group] = { selected: newState[group].selected === measure.measureId ? null : measure.measureId, num: "", den: "" };
      }
      return newState;
    });
  };

  const updateHIENum = (group, value) => {
    setHieSelection((prev) => ({ ...prev, [group]: { ...prev[group], num: value } }));
  };

  const updateHIEDen = (group, value) => {
    setHieSelection((prev) => ({ ...prev, [group]: { ...prev[group], den: value } }));
  };

  const renderHIETable = () => {
    const { totalPoints: hieTotalPoints } = buildPayloadForMeasures(hieSelection, healthInformationExchange);
    const groups = healthInformationExchange.reduce((acc, measure) => {
      if (!acc[measure.group]) acc[measure.group] = [];
      acc[measure.group].push(measure);
      return acc;
    }, {});
    const bgColor = "#ebfbee";
    return (
      <Box my="md">
        <Text fw={700} size="lg" mb="sm" style={{ color: "#3d8a3d" }}>
          Health Information Exchange ({hieTotalPoints.toFixed(2)} points)
        </Text>
        <Table striped highlightOnHover withBorder>
          <thead className="green">
            <tr>
              <th>Measure</th>
              <th>Title</th>
              <th>Required/Optional</th>
              <th>Value</th>
              <th>Points</th>
              <th>Select</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groups).map((groupKey, idx) => (
              <React.Fragment key={groupKey}>
                {idx > 0 && (
                  <tr>
                    <td colSpan={6} style={{ height: "10px", backgroundColor: "#fff" }}></td>
                  </tr>
                )}
                {groups[groupKey].map((measure) => {
                  const groupState = hieSelection[groupKey];
                  const isSelected = groupState.selected === measure.measureId;
                  const disableRow =
                    (groupKey === "group3" || groupKey === "group4")
                      ? !!(hieSelection.group5.selected || hieSelection.group6.selected)
                      : !!(hieSelection.group3.selected || hieSelection.group4.selected);
                  const measurePoints = computePoints(groupState, measure);
                  return (
                    <tr key={measure.measureId} style={{ backgroundColor: bgColor }}>
                      <td>{measure.measureId}</td>
                      <td style={{ whiteSpace: "pre-line" }}>{measure.measure}</td>
                      <td>{measure.required}</td>
                      <td>
                        {measure.metrictype === "Proportion" ? (
                          <Box style={{ display: "flex", alignItems: "center" }}>
                            <Text size="xs" mr={4}>Num:</Text>
                            <input
                              type="text"
                              value={groupState.num}
                              onChange={(e) => updateHIENum(groupKey, e.target.value)}
                              disabled={!isSelected || disableRow}
                              style={{ marginRight: "10px", width: "60px" }}
                            />
                            <Text size="xs" mr={4}>Den:</Text>
                            <input
                              type="text"
                              value={groupState.den}
                              onChange={(e) => updateHIEDen(groupKey, e.target.value)}
                              onBlur={(e) => {
                                if (
                                  isSelected &&
                                  groupState.num &&
                                  e.target.value &&
                                  parseFloat(e.target.value) <= parseFloat(groupState.num)
                                ) {
                                  Swal.fire({
                                    icon: "error",
                                    title: "Invalid Value",
                                    text: "Denominator must be greater than Numerator for proportion measures.",
                                  });
                                  updateHIEDen(groupKey, "");
                                }
                              }}
                              disabled={!isSelected || disableRow}
                              style={{ width: "60px" }}
                            />
                          </Box>
                        ) : (
                          <Text>{measure.exclusion}</Text>
                        )}
                      </td>
                      <td>
                        {isSelected ? <Text fw={600}>{measurePoints.toFixed(2)}</Text> : <Text c="dimmed">0</Text>}
                      </td>
                      <td>
                        <Checkbox
                          checked={isSelected}
                          onChange={() => handleHIESelect(groupKey, measure)}
                          disabled={disableRow}
                        />
                      </td>
                    </tr>
                  );
                })}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </Box>
    );
  };

  // ----- Provider To Patient Exchange (PTPE) Section -----
  const [ptpeSelection, setPtpeSelection] = useState({
    group7: { selected: null, num: "", den: "" },
  });

  const handlePTPESelect = (group, measure) => {
    setPtpeSelection((prev) => {
      const groupState = prev[group];
      if (groupState.selected === measure.measureId) {
        return { ...prev, [group]: { ...groupState, selected: null, num: "", den: "" } };
      } else {
        return { ...prev, [group]: { ...groupState, selected: measure.measureId, num: "", den: "" } };
      }
    });
  };

  const updatePTPENum = (group, value) => {
    setPtpeSelection((prev) => ({ ...prev, [group]: { ...prev[group], num: value } }));
  };

  const updatePTPEDen = (group, value) => {
    setPtpeSelection((prev) => ({ ...prev, [group]: { ...prev[group], den: value } }));
  };

  const renderPTPETable = () => {
    const { totalPoints: ptpeTotalPoints } = buildPayloadForMeasures(ptpeSelection, providerToPatientExchange);
    const bgColor = "#ebfbee";
    return (
      <Box my="md">
        <Text fw={700} size="lg" mb="sm" style={{ color: "#3d8a3d" }}>
          Provider To Patient Exchange ({ptpeTotalPoints.toFixed(2)} points)
        </Text>
        <Table striped highlightOnHover withBorder>
          <thead className="green">
            <tr>
              <th>Measure</th>
              <th>Title</th>
              <th>Required/Optional</th>
              <th>Value</th>
              <th>Points</th>
              <th>Select</th>
            </tr>
          </thead>
          <tbody>
            {providerToPatientExchange.map((measure) => {
              const groupState = ptpeSelection[measure.group];
              const isSelected = groupState.selected === measure.measureId;
              const measurePoints = computePoints(groupState, measure);
              return (
                <tr key={measure.measureId} style={{ backgroundColor: bgColor }}>
                  <td>{measure.measureId}</td>
                  <td style={{ whiteSpace: "pre-line" }}>{measure.measure}</td>
                  <td>{measure.required}</td>
                  <td>
                    {measure.metrictype === "Proportion" ? (
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Text size="xs" mr={4}>Num:</Text>
                        <input
                          type="text"
                          value={groupState.num}
                          onChange={(e) => updatePTPENum(measure.group, e.target.value)}
                          disabled={!isSelected}
                          style={{ marginRight: "10px", width: "60px" }}
                        />
                        <Text size="xs" mr={4}>Den:</Text>
                        <input
                          type="text"
                          value={groupState.den}
                          onChange={(e) => updatePTPEDen(measure.group, e.target.value)}
                          onBlur={(e) => {
                            if (
                              isSelected &&
                              groupState.num &&
                              e.target.value &&
                              parseFloat(e.target.value) <= parseFloat(groupState.num)
                            ) {
                              Swal.fire({
                                icon: "error",
                                title: "Invalid Value",
                                text: "Denominator must be greater than Numerator for proportion measures.",
                              });
                              updatePTPEDen(measure.group, "");
                            }
                          }}
                          disabled={!isSelected}
                          style={{ width: "60px" }}
                        />
                      </Box>
                    ) : (
                      <Text>Exclusion</Text>
                    )}
                  </td>
                  <td>
                    {isSelected ? <Text fw={600}>{measurePoints.toFixed(2)}</Text> : <Text c="dimmed">0</Text>}
                  </td>
                  <td>
                    <Checkbox checked={isSelected} onChange={() => handlePTPESelect(measure.group, measure)} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Box>
    );
  };

  // ----- Public Health & Clinic (PHC) Section -----
  const [phcSelection, setPhcSelection] = useState({
    group8: { selected: null, num: "", den: "" },
    group9: { selected: null, num: "", den: "" },
    group10: { selected: [] },
    group11: { selected: [] },
    group12: { selected: [] },
  });
 // Example “type checks” for measureId
const isExclusion = (id) => id.includes("_EX_"); // e.g. "PI_PHCDRR_1_EX_1"
const isPre = (id) => id.endsWith("_PRE");       // e.g. "PI_PHCDRR_1_PRE"
const isProd = (id) => id.endsWith("_PROD");     // e.g. "PI_PHCDRR_1_PROD"

// For "main" measure, we assume it's not an exclusion, not _PRE, not _PROD
const isMain = (id) =>
  !isExclusion(id) && !isPre(id) && !isProd(id);

  const handlePHCSelect = (group, measure) => {
    setPhcSelection((prev) => {
      // Current array of selected measureIds in this group
      let selectedArr = Array.isArray(prev[group]?.selected)
        ? [...prev[group].selected]
        : [];
  
      const measureId = measure.measureId;
      const alreadySelected = selectedArr.includes(measureId);
  
      if (alreadySelected) {
        // ----------- UNSELECT -----------
        selectedArr = selectedArr.filter((m) => m !== measureId);
      } else {
        // ----------- SELECT -----------
        if (isExclusion(measureId)) {
          // Exclusion => unselect everything else, keep only this measure
          selectedArr = [measureId];
        } else if (isMain(measureId)) {
          // Unselect any exclusions and any other main measure
          selectedArr = selectedArr.filter((m) => !isExclusion(m) && !isMain(m));
          // Then select this main measure
          selectedArr.push(measureId);
        } else if (isPre(measureId)) {
          // Unselect any exclusions, unselect any PROD
          selectedArr = selectedArr.filter((m) => !isExclusion(m) && !isProd(m));
          // Keep main if it’s there, then add PRE
          selectedArr.push(measureId);
        } else if (isProd(measureId)) {
          // Unselect any exclusions, unselect any PRE
          selectedArr = selectedArr.filter((m) => !isExclusion(m) && !isPre(m));
          // Keep main if it’s there, then add PROD
          selectedArr.push(measureId);
        }
      }
  
      return {
        ...prev,
        [group]: {
          selected: selectedArr,
        },
      };
    });
  };
  

  const renderPHCTable = () => {
    const groups = publichealthandClinicData.reduce((acc, measure) => {
      if (!acc[measure.group]) acc[measure.group] = [];
      acc[measure.group].push(measure);
      return acc;
    }, {});
    const phcResult = buildPayloadForMeasures(phcSelection, publichealthandClinicData);
    const bgColor = "#ebfbee";
    return (
      <Box my="md">
        <Text fw={700} size="lg" mb="sm" style={{ color: "#3d8a3d" }}>
          Public Health & Clinic ({phcResult.totalPoints.toFixed(2)} points)
        </Text>
        <Table striped highlightOnHover withBorder>
          <thead className="green">
            <tr>
              <th>Measure</th>
              <th>Title</th>
              <th>Required/Optional</th>
              <th>Value</th>
              <th>Points</th>
              <th>Select</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groups).map((groupKey, groupIndex) => (
              <React.Fragment key={groupKey}>
                {groupIndex > 0 && (
                  <tr>
                    <td colSpan={6} style={{ height: "10px", backgroundColor: "#fff" }}></td>
                  </tr>
                )}
                {groups[groupKey].map((measure) => {
                  const groupState = phcSelection[groupKey] || { selected: null, num: "", den: "" };
                  const isSelected = Array.isArray(groupState.selected)
                    ? groupState.selected.includes(measure.measureId)
                    : groupState.selected === measure.measureId;
                  return (
                    <tr key={measure.measureId} style={{ backgroundColor: bgColor }}>
                      <td>{measure.measureId}</td>
                      <td style={{ whiteSpace: "pre-line" }}>{measure.measure}</td>
                      <td>{measure.required}</td>
                      <td>{measure.exclusion}</td>
                      <td>
                        {isSelected ? <Text fw={600}>{measure.point.toFixed(2)}</Text> : <Text c="dimmed">0</Text>}
                      </td>
                      <td>
                        <Checkbox checked={isSelected} onChange={() => handlePHCSelect(groupKey, measure)} />
                      </td>
                    </tr>
                  );
                })}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </Box>
    );
  };

  // ----- Compute Total Points -----
  // const totals = useMemo(() => {
  //   const epRes = buildPayloadForMeasures(epSelection, electronicPrescribingMeasures);
  //   const hieRes = buildPayloadForMeasures(hieSelection, healthInformationExchange);
  //   const ptpeRes = buildPayloadForMeasures(ptpeSelection, providerToPatientExchange);
  //   const phcRes = buildPayloadForMeasures(phcSelection, publichealthandClinicData);
  //   return {
  //     ElectronicPrescribing: epRes.totalPoints,
  //     HealthInformationExchange: hieRes.totalPoints,
  //     ProviderToPatientExchange: ptpeRes.totalPoints,
  //     PublicHealthAndClinic: phcRes.totalPoints,
  //     Combined: epRes.totalPoints + hieRes.totalPoints + ptpeRes.totalPoints + phcRes.totalPoints,
  //   };
  // }, [epSelection, hieSelection, ptpeSelection, phcSelection]);

  const totals = useMemo(() => {
    // Check if all mandatory measures are selected
    const allMandatoryChecked = mandatoryMeasures.every(
      (_, index) => mandatorySelected[index]
    );
  
    // If at least one mandatory measure is NOT checked, display zeros
    if (!allMandatoryChecked) {
      return {
        ElectronicPrescribing: 0,
        HealthInformationExchange: 0,
        ProviderToPatientExchange: 0,
        PublicHealthAndClinic: 0,
        Combined: 0,
      };
    }
  
    // Otherwise, compute actual points
    const epRes = buildPayloadForMeasures(epSelection, electronicPrescribingMeasures);
    const hieRes = buildPayloadForMeasures(hieSelection, healthInformationExchange);
    const ptpeRes = buildPayloadForMeasures(ptpeSelection, providerToPatientExchange);
    const phcRes = buildPayloadForMeasures(phcSelection, publichealthandClinicData);
  
    return {
      ElectronicPrescribing: epRes.totalPoints,
      HealthInformationExchange: hieRes.totalPoints,
      ProviderToPatientExchange: ptpeRes.totalPoints,
      PublicHealthAndClinic: phcRes.totalPoints,
      Combined: epRes.totalPoints + hieRes.totalPoints + ptpeRes.totalPoints + phcRes.totalPoints,
    };
    // Notice we add 'mandatorySelected' so that if the user checks/unchecks
    // a mandatory measure, totals will recalculate.
  }, [
    epSelection,
    hieSelection,
    ptpeSelection,
    phcSelection,
    mandatorySelected // important!
  ]);



  // ----- Build Final Payload -----
  const buildFinalPayload = () => {
    const mandatoryPayload = mandatoryMeasures
      .map((measure, index) => {
        if (mandatorySelected[index]) {
          return {
            group: "",
            measureId: measure.measureId,
            metrictype: "Mandatory",
            points: 0,
            num: "0",
            den: "0",
            category: "Mandatory",
            ...totals,
          };
        }
        return null;
      })
      .filter(Boolean);

    const epRes = buildPayloadForMeasures(epSelection, electronicPrescribingMeasures);
    const epPayload = epRes.payload.map((item) => ({
      ...item,
      category: "ElectronicPrescribing",
      ...totals,
    }));

    const hieRes = buildPayloadForMeasures(hieSelection, healthInformationExchange);
    const hiePayload = hieRes.payload.map((item) => ({
      ...item,
      category: "HealthInformationExchange",
      ...totals,
    }));

    const ptpeRes = buildPayloadForMeasures(ptpeSelection, providerToPatientExchange);
    const ptpePayload = ptpeRes.payload.map((item) => ({
      ...item,
      category: "ProviderToPatientExchange",
      ...totals,
    }));

    const phcRes = buildPayloadForMeasures(phcSelection, publichealthandClinicData);
    const phcPayload = phcRes.payload.map((item) => ({
      ...item,
      category: "PublicHealthAndClinic",
      ...totals,
    }));

    const finalPayload = [
      ...mandatoryPayload,
      ...epPayload,
      ...hiePayload,
      ...ptpePayload,
      ...phcPayload,
    ];

    return finalPayload.map((item) => ({
      ...item,
      clinicId: reportSelection.clinicId,
      groupId: selectedGroupId,
      year: reportSelection.selectedYear,
      randomFileId: weightage.randomFileId,
      StartDate : startDate,
      EndDate : endDate,
    }));
  };
  // Fetch data whenever selectedGroupId or reportSelection changes or year
  // Function to fetch table data based on selectedGroupId and reportSelection
  const getCCMEnableData = async () => {
    setIsLoading(true);
    try {
      // 1) Make the API call
      const response = await ApiCall.axiosInstance.post(
        `${process.env.REACT_APP_API_URL}${ApiLink.PIMeasure}/${selectedGroupId}/${reportSelection.clinicId}/${reportSelection.selectedYear}`
      );
      const iaRequirementObject = response.data;
      console.log(iaRequirementObject);
  
      // 2) If empty, reset all states
      if (!iaRequirementObject || iaRequirementObject.length === 0) {
        setMandatorySelected({});
        setEpSelection({
          group1: { selected: null, num: "", den: "" },
          group2: { selected: null, num: "", den: "" },
        });
        setHieSelection({
          group3: { selected: null, num: "", den: "" },
          group4: { selected: null, num: "", den: "" },
          group5: { selected: null },
          group6: { selected: null },
        });
        setPtpeSelection({
          group7: { selected: null, num: "", den: "" },
        });
        setPhcSelection({
          // Notice: we initialize group8 and group9 as arrays, 
          // if we want them multi-select
          group8: { selected: [] },
          group9: { selected: [] },
          group10: { selected: [] },
          group11: { selected: [] },
          group12: { selected: [] },
        });
        setWeightage({});
        setStartDate("");
        setEndDate("");
        return;
      }
  
      // 3) Update Mandatory measures
      const newMandatorySelected = {};
      mandatoryMeasures.forEach((measure, index) => {
        if (
          iaRequirementObject.some(
            (item) =>
              item.category === "Mandatory" && item.measureId === measure.measureId
          )
        ) {
          newMandatorySelected[index] = true;
        }
      });
      setMandatorySelected(newMandatorySelected);
      // 4) Electronic Prescribing
      const newEPSelection = { ...epSelection };
      iaRequirementObject
        .filter((item) => item.category === "ElectronicPrescribing")
        .forEach((item) => {
          newEPSelection[item.group] = {
            selected: item.measureId,
            num: parseFloat(item.num) || 0,
            den: parseFloat(item.den) || 0,
          };
        });
      setEpSelection(newEPSelection);
  
      // 5) Health Information Exchange
      const newHIESelection = { ...hieSelection };
      iaRequirementObject
        .filter((item) => item.category === "HealthInformationExchange")
        .forEach((item) => {
          newHIESelection[item.group] = {
            selected: item.measureId,
            num: parseFloat(item.num) || 0,
            den: parseFloat(item.den) || 0,
          };
        });
      setHieSelection(newHIESelection);
  
      // 6) Provider To Patient Exchange
      const newPTPESelection = { ...ptpeSelection };
      iaRequirementObject
        .filter((item) => item.category === "ProviderToPatientExchange")
        .forEach((item) => {
          newPTPESelection[item.group] = {
            selected: item.measureId,
            num: parseFloat(item.num) || 0,
            den: parseFloat(item.den) || 0,
          };
        });
      setPtpeSelection(newPTPESelection);
  
      // 7) Public Health & Clinic
      const newPHCSelection = { ...phcSelection };
  
      iaRequirementObject
        .filter((item) => item.category === "PublicHealthAndClinic")
        .forEach((item) => {
          // If it's one of these groups, store in an array
          if (["group8", "group9", "group10", "group11", "group12"].includes(item.group)) {
            // Already an array?
            if (
              newPHCSelection[item.group] &&
              Array.isArray(newPHCSelection[item.group].selected)
            ) {
              newPHCSelection[item.group].selected.push(item.measureId);
            } else {
              // Otherwise, create a new array
              newPHCSelection[item.group] = { selected: [item.measureId] };
            }
          } else {
            // For any other group, single‑select
            newPHCSelection[item.group] = {
              selected: item.measureId,
              num: parseFloat(item.num) || 0,
              den: parseFloat(item.den) || 0,
            };
          }
        });
      setPhcSelection(newPHCSelection);
  
      // 8) Weightage
      const newWeightage = {
        entity: iaRequirementObject[0].entity,
        mipsWt: iaRequirementObject[0].mipsWt,
        piscore: iaRequirementObject[0].piScore,
        totalpoint: iaRequirementObject[0].combined,
        randomFileId: iaRequirementObject[0].randomFileId
      };
      setWeightage(newWeightage);
      //
      setStartDate(iaRequirementObject[0].startDate?.split('T')[0]);
      setEndDate(iaRequirementObject[0].endDate?.split('T')[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  



  useEffect(() => {
    if (selectedGroupId && reportSelection) {
      getCCMEnableData();
    }
  }, [selectedGroupId, reportSelection]);




  // ----- Save Handler -----
  const handleSave = () => {

    // 1. Check all mandatory measures
    const allMandatoryChecked = mandatoryMeasures.every(
      (_, index) => mandatorySelected[index]
    );

    // if (!allMandatoryChecked) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Missing Mandatory Measures",
    //     text: "Please select all Mandatory measures before saving.",
    //   });
    //   return; // Stop here if any mandatory measure is not checked
    // }


    const epValidation = validateProportionFields(epSelection, electronicPrescribingMeasures);
    if (!epValidation.valid) {
      Swal.fire({
        icon: "error",
        title: "Missing Value",
        text: `Please enter both Num and Den for Electronic Prescribing measure ${epValidation.measureId}.`,
      });
      return;
    }
    const hieValidation = validateProportionFields(hieSelection, healthInformationExchange);
    if (!hieValidation.valid) {
      Swal.fire({
        icon: "error",
        title: "Missing Value",
        text: `Please enter both Num and Den for Health Information Exchange measure ${hieValidation.measureId}.`,
      });
      return;
    }
    const ptpeValidation = validateProportionFields(ptpeSelection, providerToPatientExchange);
    if (!ptpeValidation.valid) {
      Swal.fire({
        icon: "error",
        title: "Missing Value",
        text: `Please enter both Num and Den for Provider To Patient Exchange measure ${ptpeValidation.measureId}.`,
      });
      return;
    }
    const group8Selected = phcSelection.group8 && phcSelection.group8.selected !== null;
    const group9Selected = phcSelection.group9 && phcSelection.group9.selected !== null;
    if ((group8Selected && !group9Selected) || (group9Selected && !group8Selected)) {
      Swal.fire({
        icon: "error",
        title: "Public Health & Clinic Selection Error",
        text: "Please ensure that if you select a measure in Immunization Registry Reporting, you also select one in Electronic Case Reporting, and vice versa.",
      });
      return;
    }
    let finalPayload = buildFinalPayload();
    // If not all mandatory measures are checked, override totals to 0 in the payload
    if (!allMandatoryChecked) {
      finalPayload = finalPayload.map((item) => ({
        ...item,
        ElectronicPrescribing: 0,
        HealthInformationExchange: 0,
        ProviderToPatientExchange: 0,
        PublicHealthAndClinic: 0,
        Combined: 0,
        StartDate : startDate,
        EndDate : endDate,
      }));
    }


    setIsLoading(true);
    ApiCall.axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}${ApiLink.PIMeasureSave}`,
        finalPayload
      )
      .then((response) => {

        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Saved!",
          text: "Your data has been saved successfully.",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate(RouteLink.PiList_Uri);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(JSON.stringify(error));
      });

  };

  return (
    <Box style={{ marginTop: "15px" }}>
      <LoadingOverlay visible={false} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      <Grid align="center">
        <Grid.Col span={5}>
          <Stack spacing={12}>
            <Group spacing={50} align="center">
              <Text fw={700} size="sm" c="gray">
                Entity:{" "}
                <Text span fw={500} size="md" c="black">
                  {weightage.entity}
                </Text>
              </Text>
              <Text fw={700} size="sm" c="gray">
                Total Points.:{" "}
                <Text span fw={500} size="md" c="black">
                  {totals.Combined.toFixed(2)}
                </Text>
              </Text>
            </Group>
            <Group spacing={50} align="center">
              <Text fw={700} size="sm" c="gray">
                MIPS Weightage:{" "}
                <Text span fw={500} size="md" c="black">
                  {weightage.mipsWt}
                </Text>
              </Text>
              <Text fw={700} size="sm" c="gray">
                PI Score Den.:{" "}
                <Text span fw={500} size="md" c="black">
                  {weightage.piscore}
                </Text>
              </Text>
            </Group>
          </Stack>
        </Grid.Col>
        <Grid.Col span={2} style={{ textAlign: "right" }}>
        <TextInput
          label="Start Date"
          placeholder="MM/DD/YYYY"
          type="date"
          value={startDate}
          onChange={(event) => setStartDate(event.currentTarget.value)}
        />
      </Grid.Col>
      <Grid.Col span={2} style={{ textAlign: "right" }}>
        <TextInput
          label="End Date"
          placeholder="MM/DD/YYYY"
          type="date"
          value={endDate}
          onChange={(event) => setEndDate(event.currentTarget.value)}
        />
      </Grid.Col>
       

        <Grid.Col span={3} style={{ textAlign: "right" }}>
          <Group position="right" spacing="lg">
            <Button
              type="primary"
              mt="sm"
              loading={loading}
              loaderPosition="right"
              loaderProps={{ size: "sm", color: "white" }}
              onClick={handleSave}
            >
              Save
            </Button>
            {weightage && (
              <Stack spacing={8} align="flex-end">
                {/*
      1. Safely parse numeric values or default to 0
    */}
                {(() => {
                  const safeCombined = isNaN(totals.Combined) ? 0 : Number(totals.Combined);
                  const safeMipsWt = isNaN(weightage?.mipsWt) ? 0 : Number(weightage?.mipsWt);
                  const safePiScore = isNaN(weightage?.piscore) ? 0 : Number(weightage?.piscore);
                  // 2. Compute PI Contributions (numerator = Combined * MipsWt, denominator = piScore)
                  //    If piScore is 0, fallback to 0 to avoid division by zero.
                  const piContributionsVal =
                    safePiScore !== 0 ? (safeCombined * safeMipsWt) / safePiScore : 0;
                  const piContributionsValFixed = isNaN(piContributionsVal)
                    ? 0
                    : piContributionsVal.toFixed(2);

                  // Tooltip %: ((piContributionsVal / mipsWt) * 100) if mipsWt != 0
                  const piContributionsPercent =
                    safeMipsWt !== 0 ? (piContributionsVal / safeMipsWt) * 100 : 0;
                  const piContributionsPercentFixed = isNaN(piContributionsPercent)
                    ? 0
                    : piContributionsPercent.toFixed(2);

                  // 3. Compute Standalone PI (numerator = Combined, denominator = piScore)
                  //    If piScore is 0, fallback to 0.
                  const standaloneVal = safePiScore !== 0 ? safeCombined : 0;
                  const standaloneValFixed = isNaN(standaloneVal)
                    ? 0
                    : standaloneVal.toFixed(2);

                  // Tooltip %: (Combined / piScore) * 100
                  const standalonePercent =
                    safePiScore !== 0 ? (safeCombined / safePiScore) * 100 : 0;
                  const standalonePercentFixed = isNaN(standalonePercent)
                    ? 0
                    : standalonePercent.toFixed(2);

                  return (
                    <>
                      <Tooltip
                        label={
                          safePiScore !== 0 && safeMipsWt !== 0
                            ? `${piContributionsPercentFixed}%`
                            : "N/A"
                        }
                        position="top"
                        withArrow
                      >
                        <Text>
                          <span style={{ fontWeight: "bold" }}>PI Contributions:</span>{" "}
                          {`${piContributionsValFixed}/${safeMipsWt}`}
                        </Text>
                      </Tooltip>

                      <Tooltip
                        label={
                          safePiScore !== 0
                            ? `${standalonePercentFixed}%`
                            : "N/A"
                        }
                        position="top"
                        withArrow
                      >
                        <Text>
                          <span style={{ fontWeight: "bold" }}>Standalone PI:</span>{" "}
                          {`${standaloneValFixed}/${safePiScore}`}
                        </Text>
                      </Tooltip>
                    </>
                  );
                })()}
              </Stack>
            )}

          </Group>
        </Grid.Col>
      </Grid>
      {renderMandatoryTable()}
      <Divider my="md" />
      {renderEPTable()}
      <Divider my="md" />
      {renderHIETable()}
      <Divider my="md" />
      {renderPTPETable()}
      <Divider my="md" />
      {renderPHCTable()}
      <Group position="right" mt="md">
        <Button onClick={handleSave}>Save</Button>
      </Group>
    </Box>
  );
}
