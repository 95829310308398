import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import { TextInput, Button, Box, Grid, Space, PasswordInput, SegmentedControl, Text, Autocomplete, Badge } from "@mantine/core";
import { ApiLink } from "../../Constant/ApiLink";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate, useLocation } from "react-router-dom";
import { Loader } from "@mantine/core";
import { Sandivider } from "../../Shared/Theme/Sandivider";
import style from "../../Shared/Theme/CommanStyle.module.css";
import { SecurityLevel, SecurityLevelType, UserType, UserTypeType } from "../../Shared/DropDownOptions/UsersOptions";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";
import { getGroups, getPartners, getTins } from "./RegistrationSlice";
import { useDispatch, useSelector } from "react-redux";

const Registration = () => {
    const navigate = useNavigate();
    const { data, method } = useLocation().state;

    const [loading, setLoading] = useState(false);
    const [orgIds, setOrgIds] = useState([]);
    const [selectedSecurityLevel, setSelectedSecurityLevel] = useState('');
     const [selectedOrgData, setSelectedOrgData] = useState('');
    // const [groupDetails, SetGroupDetails] = useState([]);
   
    const dispatch = useDispatch();
    const { orgGroupIds } = useSelector((state) => state.registration);
    const { user } = useSelector((store) => store.auth);
    const partenerTreeItems = useSelector((state) => state.partenerTree.partenerTreeItem);
    const selectedGroupId = useSelector((store) => store.partenerTree.selectedGroupId);
    const reportSelection = useSelector((store) => store.selectedClinic.item);

    const form = useForm({
        initialValues: {
            id: data?.id,
            prefix: data?.prefix,
            firstName: data?.firstName,
            lastName: data?.lastName,
            alternateEmail: data?.alternateEmail,
            groupRelatedInformation: {
                organization: data?.organization,
                jobTitle: data?.jobTitle,
            },
            userPermission: {
                securityLevel: data?.securityLevel ?? "Super",
                userType: data?.userType ?? "Admin",
            },
            password: data?.password,
            rePassword: data?.rePassword,
            email: data?.email,
            organizationId: data?.organizationId,
            parentId: data?.parentId ?? 0
        },

        validate: {
            firstName: (value) =>
                value?.length < 2 || !value
                    ? "Name must have at least 2 letters"
                    : null,
            lastName: (value) =>
                value?.length < 2 || !value
                    ? "Name must have at least 2 letters"
                    : null,
            email: (value) => {
                if (method === "put") return null; // Skip validation for PUT method
                return /^\S+@\S+$/.test(value) ? null : "Invalid email";
            },
            rePassword: (value, values) => {
                if (method === "put") return null; // Skip validation for PUT method
                return value !== values.password ? "Passwords did not match" : null;
            },
        },
    });

    useEffect(() => {


        let levelId = 0;
        if (method !== "put") {
            if (user.level === 0) {
                levelId = 0;
            } else if (user.level === 1) {
                levelId = partenerTreeItems[0]?.id;
                form.setValues({
                    organizationId: String(levelId)
                });

            } else if (user.level === 2) {
                levelId = selectedGroupId;
                form.setValues({
                    organizationId: String(levelId)
                });

            } else if (user.level === 3) {
                levelId = reportSelection.clinicId;
                form.setValues({
                    organizationId: String(levelId)
                });
            }

        }


        if (orgGroupIds && Array.isArray(orgGroupIds)) {
            setOrgIds(orgGroupIds.map(obj => ({ value: String(obj.id), label: obj.name.trim() })));
            setLoading(false);
        }
    }, [orgGroupIds]);

    useEffect(() => {

        let securityLevel = "";
        let levelId = 0;
        if (method !== "put") {
            if (user.level === 0) {
                securityLevel = "Super";
                levelId = partenerTreeItems[0]?.id;
            } else if (user.level === 1) {
                securityLevel = "Partner";
                levelId = partenerTreeItems[0]?.id;
            } else if (user.level === 2) {
                securityLevel = "Group";
                levelId = selectedGroupId;
            } else if (user.level === 3) {
                securityLevel = "TIN";
                levelId = reportSelection.clinicId;
            }
            form.setValues({
                ...form.values,
                userPermission: {
                    ...form.values.userPermission,
                    securityLevel: securityLevel
                }
            });
        }
        else {

            securityLevel = form.values.userPermission.securityLevel;
        }

        const initialize = async () => {

            await handelLevelChange(securityLevel);
            setSelectedOrgData(form.values.organizationId??"");
            form.setFieldValue("groupRelatedInformation.organization", form.values.organizationId ?? "");
        };

        initialize();
    }, []);

    const handleBack = () => {
        navigate(RouteLink.Users_Uri);
        setLoading(false);
    };

    const handleSubmit = (formValues) => {

        //Parent
        let levelId = 0;
        if (user.level === 0) {
            levelId = 0;
        } else if (user.level === 1) {
            levelId = `${user.level}${partenerTreeItems[0]?.id}`;
        } else if (user.level === 2) {
            levelId = `${user.level}${selectedGroupId}`;
        } else if (user.level === 3) {
            levelId = `${user.level}${reportSelection.clinicId}`;

        }

        // Convert levelId to a number and create a new payload object
        const payload = {
            ...formValues,
            parentId: Number(levelId),
        };

        let stats = true;
        if (method !== "put") {
            stats = passwordchecker(formValues.password);
        }

        if (stats === true) {
            setLoading(true);
            ApiCall.axiosInstance[method](
                `${process.env.REACT_APP_API_URL}${ApiLink.User_Uri}`,
                payload
            )
                .then((response) => {
                    setLoading(false);
                    navigate(RouteLink.Users_Uri);
                })
                .catch((error) => {
                    console.error("Failed Request:", error);
                    setLoading(false);
                });
        }

    };

    const handelLevelChange = (value) => {


        setSelectedSecurityLevel(value.toString());

        if (value !== SecurityLevelType.Super) {
            setLoading(true);
            switch (value) {
                case SecurityLevelType.Group:

                    if (user.level === 1) {
                        dispatch(getGroups({ groupId: partenerTreeItems[0]?.id, userlevel: user.level, id: selectedGroupId }));
                    }
                    else if (user.level === 2) {
                        dispatch(getGroups({ groupId: partenerTreeItems[0]?.id, userlevel: user.level, id: selectedGroupId }));
                    }
                    else {
                        dispatch(getGroups({ groupId: 0 }));
                    }
                    // dispatch(getGroups());
                    break;
                case SecurityLevelType.Partner:

                    if (user.level === 1) {
                        dispatch(getPartners({ userlevel: user.level, id: partenerTreeItems[0]?.id }));
                    }
                    else { dispatch(getPartners()); }

                    break;

                case SecurityLevelType.TIN:

                    if (user.level === 1) {
                        dispatch(getTins({ userlevel: user.level, id: partenerTreeItems[0]?.id }));
                    }
                    if (user.level === 2) {
                        dispatch(getTins({ userlevel: user.level, id: selectedGroupId }));
                    }
                    if (user.level === 3) {
                        dispatch(getTins({ userlevel: user.level, id: reportSelection.clinicId }));
                    }
                    else { dispatch(getTins()); }

                    // dispatch(getTins());
                    break;
                default:
                    setLoading(false);
                    break;
            }
        }
        form.setFieldValue("userPermission.securityLevel", value);
    };

    const handleUserType = (value) => {
        switch (value) {
            case UserTypeType.Admin:
                break;
            case UserTypeType.Editor:
                break;
            case UserTypeType.ReadOnly:
                break;
            default:
                setLoading(false);
                break;
        }
        form.setFieldValue("userPermission.userType", value);
    };

    const passwordchecker = (password) => {
        const specialSymbols = /[!@#\$%\^&\*\(\)_\+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (!specialSymbols.test(password)) {
            alert("Add one Special Symbol");
            return false;
        }
        if (password[0] !== password[0].toUpperCase()) {
            alert("FirstLetter is Uppercase");
            return false;
        }
        return true;
    };

    const orgDataChangeHandler = (value) => {

        setSelectedOrgData(value);
        form.setFieldValue("groupRelatedInformation.organization", value);
    };

    //
    const getAllowedSecurityLevels = (userLevel) => {
        if (userLevel === 0) {
            // Show all options
            return SecurityLevel;
        } else if (userLevel === 1) {
            // Exclude "Super"
            return SecurityLevel.filter(option => option.value !== SecurityLevelType.Super);
        } else if (userLevel === 2) {
            // Only show Group and TIN (exclude Super and Partner)
            return SecurityLevel.filter(
                option =>
                    option.value === SecurityLevelType.Group ||
                    option.value === SecurityLevelType.TIN
            );
        } else if (userLevel === 3) {
            // Only show TIN
            return SecurityLevel.filter(option => option.value === SecurityLevelType.TIN);
        }
        return [];
    };


    return (
        <Box className={style.mt2}>
            {loading && <Loader size={48} />}
            <h5>User : Add New</h5>
            <Space />

            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Grid>
                    <Grid.Col span={1}>
                        <TextInput label="Prefix: " {...form.getInputProps("prefix")} />
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <TextInput label="First Name: " withAsterisk {...form.getInputProps("firstName")} />
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <TextInput label="Last Name: " withAsterisk {...form.getInputProps("lastName")} />
                    </Grid.Col>

                    <Grid.Col span={5}>
                        <TextInput label="Email: " withAsterisk {...form.getInputProps("email")} />
                    </Grid.Col>

                    {method !== "put" && (
                        <Grid.Col span={6}>
                            <PasswordInput
                                label="Password: "
                                placeholder="eg Test@123"
                                withAsterisk
                                {...form.getInputProps("password")}
                            />
                        </Grid.Col>
                    )}
                    {method !== "put" && (
                        <Grid.Col span={6}>
                            <PasswordInput label="Retype Password: " withAsterisk {...form.getInputProps("rePassword")} />
                        </Grid.Col>)}
                </Grid>

                <Sandivider text="Organization Details" />
                <Grid grow>
                    <Grid.Col span={4}>
                        <Text size="sm" fw={500}>
                            Security Level:
                        </Text>
                        <SegmentedControl
                            radius="xl"
                            size="sm"
                            color="lime"
                            data={getAllowedSecurityLevels(user.level)}
                            value={form.values.userPermission.securityLevel}
                            onChange={handelLevelChange}
                        />

                    </Grid.Col>
                    <Grid.Col span={3}>
                        <Text size="sm" fw={500}>
                            User Type:
                        </Text>
                        <SegmentedControl
                            radius="xl"
                            size="sm"
                            color="lime"
                            data={UserType}
                            {...form.getInputProps("userPermission.userType")}
                            onChange={handleUserType}
                        />
                    </Grid.Col>
                </Grid>
                <Sandivider text="Security Level Related Information" />
                <Grid>
                    <Grid.Col span={3}>
                        {selectedSecurityLevel !== SecurityLevelType.Super && (
                            <>
                                {/* <Select
                                    data={orgIds}
                                    label={`Select ${selectedSecurityLevel} Ids`}
                                    placeholder={`Select ${selectedSecurityLevel} Name`}
                                    value={form.values.organizationId}
                                    onChange={(value) => {
                                        orgDataChangeHandler(value);
                                        form.setFieldValue("organizationId", value);
                                    }}
                                    searchable
                                /> */}

                                <Autocomplete
                                    data={orgIds}
                                    //itemComponent={renderAutocompleteOption}
                                    maxDropdownHeight={300}
                                    label={`Select ${selectedSecurityLevel} Ids`}
                                    placeholder={`Search for ${selectedSecurityLevel} Name`}
                                    filter={(value, item) =>
                                        item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
                                        item.value.toLowerCase().includes(value.toLowerCase().trim())
                                    }
                                    limit={200}
                                    {...form.getInputProps("groupRelatedInformation.organization")}
                                    onChange={orgDataChangeHandler}
                                />
                                 {selectedOrgData &&
                                <Badge>{orgIds.find(item=>item.value==selectedOrgData)?.label||''}</Badge>
                                }

                            </>
                        )}
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <TextInput label="Job Title:" {...form.getInputProps("groupRelatedInformation.jobTitle")} />
                    </Grid.Col>
                </Grid>

                <div style={{ marginTop: "1rem", float: "right" }} />
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <div>
                        <Button type="primary" onClick={handleBack}>
                            Back
                        </Button>
                        <Button style={{ marginLeft: "10px" }} type="submit" mt="sm">
                            Submit
                        </Button>
                    </div>
                </div>
            </form>
        </Box>
    );
};

export default Registration;
