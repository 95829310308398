import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Group, Radio, Select, Text } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useSelector } from "react-redux";
import { ApiLink } from "../../../Constant/ApiLink";
import axios from "axios";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";

const QualityCalGroupWise = () => {
  const [rowData, setRowData] = useState();
  const [submissionType, setSubmissionType] = useState("Registry");
  const [weightage, setWeightage] = useState("");

  const [loading, setIsLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const selectedGroupId = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );
  const [uniqueMeasureIds, setUniqueMeasureId] = useState([]);

  const columnDef = useMemo(() => [
    // {
    //   header: "Measure Id",
    //   accessorKey: "measureId",
    //   filterVariant: "select",
    //   mantineFilterSelectProps: {
    //     data: uniqueMeasureIds,
    //   },
    // },
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Tin",
      accessorKey: "tin",
    },
    {
      header: "Target Score",
      accessorKey: "targetScore",
    },
    {
      header: "Quality Score",
      accessorKey: "weightageValue",
    },
    {
      header: "Overall Score",
      accessorKey: "mipsScore",
    },
  ]);

  useEffect(() => {
    if (
      submissionType != undefined &&
      reportSelection.selectedYear != undefined &&
      selectedGroupId != undefined
    ) {
      getQualityCalculationTinWise();
    }
  }, [reportSelection.selectedYear, selectedGroupId, submissionType]);

  const getQualityCalculationTinWise = () => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.QualityCalculationGroupWise}${selectedGroupId}/${reportSelection.selectedYear}/${submissionType}`
      )
      .then((response) => {
        setRowData(response.data);
        const uniqueMeasures = [
          ...new Set(response?.data?.map((item) => item.measureId)),
        ].sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        setUniqueMeasureId(uniqueMeasures);
        setWeightage(response?.data[1]?.weightageValue);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <Box style={{ marginTop: "15px" }}>
      <Grid>
        <Grid.Col span={6} ml={20}>
          <Radio.Group
            value={submissionType}
            onChange={setSubmissionType}
            name="favoriteFramework"
            label="Select Submission Method"
            withAsterisk
          >
            <Group mt="xs">
              <Radio value="Registry" label="Registry" />
              <Radio
                value="ElectronicHealthRecord"
                label="Electronic Health Record"
              />
            </Group>
          </Radio.Group>
        </Grid.Col>
      </Grid>
      <MantineReactTable
        columns={columnDef}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};
export default QualityCalGroupWise;
