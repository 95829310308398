import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import { TextInput, Button, Box, Grid, Select, Checkbox } from "@mantine/core";
import { ApiLink } from "../../Constant/ApiLink";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Loader } from "@mantine/core";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

function CcmForm() {
    const navigate = useNavigate();
    const { data, method } = useLocation().state;
    const [loading, setLoading] = useState(false);
    const [ccmDropDownData, setCcmDropDownData] = useState([]);

    useEffect(() => {
        setLoading(true);
        ApiCall.axiosInstance.get(
            `${process.env.REACT_APP_API_URL}${ApiLink.ccm}`
        )
            .then((response) => {
                setCcmDropDownData(response.data);
                setLoading(false);
                // navigate(RouteLink.CCM_Uri);
            })
            .catch((error) => {
                console.error("Failed Request:", error);
                setLoading(false);
            });
    }, [])


    const handleSubmit = (formValues) => {

        setLoading(true);
        ApiCall.axiosInstance[method](
            `${process.env.REACT_APP_API_URL}${ApiLink.CCMMaping}`,
            formValues
        )
            .then((response) => {
             setLoading(false);
            navigate(RouteLink.CCM_Uri);
            })
            .catch((error) => {
                console.error("Failed Request:", error);
                setLoading(false);
            });
    };

    const handleBack = () => {
        navigate(RouteLink.CCM_Uri);
        setLoading(false);
    };
    // "id": 8,
    // "firstCCM": 2,
    // "secondCCM": 1,
    // "status": true,
    // "ccmFirstDropdown": null,
    // "ccmSecondDropdown": {
    //   "id": 1,
    //   "name": null
    // }


    const form = useForm({
        initialValues: {
            id: data?.id,
            firstCCM: data?.firstCCM,
            secondCCM: data?.secondCCM,
            status: data?.status ?? true,
        },

        // functions will be used to validate values at corresponding key
        validate: {
            firstCCM: (value) =>
                value === "" || !value ?
                    "Name must have at least 2 letters"
                    : null,
            secondCCM: (value) =>
                value === "" || !value ? "financail Methot Type is required" : null,
        },
    });

    return (
      <Box>
        {loading && <Loader size={48} />}
        <h5></h5>
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Grid>
            <Grid.Col span={6}>
              <Select
                label="First: "
                placeholder="First Value"
                {...form.getInputProps("firstCCM")}
                data={ccmDropDownData?.map((ele) => ({
                  value: ele.id,
                  label: ele.name,
                }))}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                label="Second: "
                placeholder="Second Value"
                {...form.getInputProps("secondCCM")}
                data={ccmDropDownData?.map((ele) => ({
                  value: ele.id,
                  label: ele.name,
                }))}
              />
            </Grid.Col>
          </Grid>
          <Grid style={{ marginTop: "1rem" }}>
            <Grid.Col>
              <Checkbox
                mt="md"
                label="Is Active"
                {...form.getInputProps("status", { type: "checkbox" })}
              />
            </Grid.Col>
          </Grid>

          <div style={{ marginTop: "1rem", float: "right" }}></div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div>
              <Button type="primary" onClick={handleBack}>
                Back
              </Button>
              <Button style={{ marginLeft: "10px" }} type="submit" mt="sm">
                Save
              </Button>
            </div>
          </div>
        </form>
      </Box>
    );
}

export default CcmForm;
