import { useLocation, useNavigate } from "react-router";
import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Container, Divider, Select, TextInput, Text, Table, LoadingOverlay } from "@mantine/core";
import { Grid } from '@mantine/core';
import { RouteLink } from "../../Constant/RouterLink";
import { ApiLink } from "../../Constant/ApiLink";
import { ScalingFactor } from "../../Shared/DropDownOptions/Arrays";
import { getDynamicClass, nFormatter } from "../../Shared/NumberFunctions";
import { MantineReactTable } from "mantine-react-table";
import { ReportingEntitiesColumns } from "../Financial/Columns/ReportingEntitiesColumns";
import { useDispatch, useSelector } from "react-redux";
import { addClinicId, addScallingFactor, addClinicLevel,addSelectedClinicEntityDDD } from "../../utils/selectedClinicSlice";
import useAxios from "../../Shared/useApi";
import { addClinicForSeletedGroup, replacePartnerTreeItem } from "../../utils/patenerTreeSlice";
import { addPercentageDistribution } from "../../utils/calculationSlice";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";



const ReportingEntities = () => {

  var navigate = useNavigate();
  const SelectedClinicList = useSelector(store => store.partenerTree.selectedGroupClnics);
  const [loading, setloading] = useState(false);
  const [selectedClinic, setselectedClinic] = useState(); //table row
  const [enrollmentid, setEnrollmentid] = useState(); // set clinic enrollment
  const reportSelection = useSelector(store => store.selectedClinic.item);
  

  useEffect(() => {
      CallReport(reportSelection.clinicId, true);
      getScallingFactorForSelectedClinic();
  }, [reportSelection.clinicId, reportSelection.selectedYear]);

  const getScallingFactorForSelectedClinic = () => {
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.GetScalingFactorofClinic}/${reportSelection.clinicId}/${reportSelection.selectedYear}`)
      .then((response) => {
        localStorage.setItem('scallingFactor', response.data);
        dispatch(addScallingFactor(response.data));
      })
      .catch((error) => {
        console.error("Error EntityType fetching data:", error);
      });
  }




  const onScalingChange = (value) => {
    // setScallingFactor(value);
    localStorage.setItem('scallingFactor', value);
    dispatch(addScallingFactor(value));
    setselectedClinic([]);
    CallReport(value, false);
  }

  const dispatch = useDispatch()
  const onClinicSlelect = (value) => {
    setEnrollmentid(value);
    dispatch(addClinicId(value));
    dispatch(addClinicLevel(SelectedClinicList?.find(ele => ele.value === value)?.label));
    setselectedClinic([]);
    CallReport(value, true);
  }



  const CallReport = (value, isClinicChange) => {
    let customURL;
    if(isClinicChange){
      customURL = `${process.env.REACT_APP_API_URL}${ApiLink.Get_EntitiesReport}/${value}/${reportSelection.scallingFactor}/${reportSelection.selectedYear}/0`
    }else{
      customURL = `${process.env.REACT_APP_API_URL}${ApiLink.Get_EntitiesReport}/${reportSelection.clinicId}/${value}/${reportSelection.selectedYear}/0`
    }
    if (value) {
      setloading(true);
      ApiCall.axiosInstance.get(customURL)
        .then(response => {
          setselectedClinic(response.data);
          if(response?.data){
            if(isClinicChange){
              // createEntityTypeDropDown(response.data);
              // getPercentageDestribution(value);
            }
          }
          setloading(false);
        })
        .catch(error => {
          console.error('Error Clinic fetching data:', error);
          setloading(false);
        });
    }
  }


  const openAssumptionPage = () => {
    navigate(RouteLink.AssumptionsTarget, {
      state: { data: reportSelection.clinicId, method: "post" },
    });
  }

  const updateClinicAttestScalingFact = () => {
    ApiCall.axiosInstance
      .put(`${process.env.REACT_APP_API_URL}${ApiLink.UpdateScalingFactor}/${reportSelection.clinicId}/${reportSelection.selectedYear}/${reportSelection.scallingFactor}`)
      .then((response) => {
        window.alert('Scalling factor updated successfully for the selected Attestation Clinic.')
      })
      .catch((error) => {
        console.error("Error EntityType fetching data:", error);
      });
  }

  return (

    <Box style={{ marginTop: "15px" }}>
      {loading &&
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      }

      <Box className="mycontainer" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Text size="lg">TIN Financials {reportSelection.clinicEnrollLavel && <span>({reportSelection.clinicEnrollLavel})</span>}</Text>
        <Button variant="light" onClick={openAssumptionPage}>Assumptions For Target Results</Button>
      </Box><br />

      <Grid >
        <Grid.Col span={4}>
          <Select size="xs"
            label="Attestation Clinic"
            withAsterisk
            placeholder="Choose One"
            data={SelectedClinicList}
            value={reportSelection.clinicId}
            onChange={onClinicSlelect}
          />
        </Grid.Col>

        <Grid.Col span={2} offset={4}>
          <Select size="xs"
            label="Scaling Factor: Base"
            withAsterisk
            searchable
            value={reportSelection.scallingFactor}
            onChange={onScalingChange}
            data={ScalingFactor}
          />
        </Grid.Col>
        <Grid.Col span={2} mt={22}>
          <Button onClick={updateClinicAttestScalingFact}>Update Scaling Factor</Button>
        </Grid.Col>
      </Grid>
      <Divider my="sm" />


      {/* <MantineReactTable
        columns={columnDefs}
        data={selectedClinic ?? []}
        state={{ isLoading: loading }}
        positionActionsColumn='first'
        mantinePaperProps={{ shadow: '0', withBorder: false }}
      /> */}


      <Table striped highlightOnHover withBorder withColumnBorders className="greenhead">
        <thead>
          <tr>
            <th>Entity Type</th>
            <th>EC Count</th>
            <th>Basic Medicare</th>
            <th>Regulatory Low</th>
            <th>Current Result</th>
            <th>Target Result</th>
            <th>Regulatory High</th>
          </tr>
        </thead>

        <tbody>
          {loading && <Text fw={500}>Loading..</Text>}
          {selectedClinic?.length ? (
            selectedClinic.map((item, index) => (

              <tr key={index}>
                <td>{item.entityType}</td>
                <td>{item.ecCount}</td>
                <td>{nFormatter(item.basicMedicare, 2)}</td>
                <td className={getDynamicClass(item.regulatoryLow)}>{nFormatter(item.regulatoryLow, 2)}</td>
                <td>{nFormatter(item.currentResult, 2)}</td>
                <td>{nFormatter(item.targetResult, 2)}</td>
                <td>{nFormatter(item.regulatoryHigh, 2)}</td>
              </tr>
            ))
          ) : (
            !loading && <tr>
              <td colSpan="7">No data available</td>
            </tr>
          )}
        </tbody>
      </Table>

    </Box>
  )
};
export default ReportingEntities;