import React, { useState, useRef, useEffect, useMemo } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mantine/core";
import { RouteLink } from "../../Constant/RouterLink";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import { MantineReactTable } from "mantine-react-table";
import { RowAction } from "../../Shared/RowAction";
import { useDisclosure } from "@mantine/hooks";
import { GroupColumn } from "./GroupColumn";
import axios from "axios";
import { ApiLink } from "../../Constant/ApiLink";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";
import { useSelector } from "react-redux";

const GroupList = () => {
 
  const { user } = useSelector((store) => store.auth);
  const [rowData, setRowData] = useState();
  const partenerTreeItems = useSelector((state) => state.partenerTree.partenerTreeItem);
  const selectedGroupId = useSelector((store) => store.partenerTree.selectedGroupId);
  const [opened, { close, open }] = useDisclosure(false);
  const [loading, setLoading] = useState(false);

  var navigate = useNavigate();

  // const { response, loading, error } = useAxios({
    
  //   method: "get",
  //   url: `/Group/${(user && user.level === 1 ? parseInt(user.organizationId) : 0)}&userlevel=${user?.level}&id=${levelId}`,
  //   headers: null,
  //   body: null,
  // });



  useEffect(() => {

    let levelId = 0;
    if (user.level === 0) {
      levelId = 0
    }
    if (user.level === 1) {
      levelId = partenerTreeItems[0]?.id;
    }
    if (user.level === 2) {
      levelId = selectedGroupId;
    }
    
    setLoading(true)
    ApiCall.axiosInstance.get(
      `${process.env.REACT_APP_API_URL}${ApiLink.Group_Uri}/${(user && user.level === 1 ? parseInt(user.organizationId) : 0)}?userlevel=${user?.level}&id=${levelId}`
    )
      .then((response) => {
        setRowData(response.data);
        setLoading(false)
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        setLoading(false)
      });

   
  }, []);




  const onCreate = () => {
    navigate(RouteLink.Group_Create_Uri, {
      state: { data: null, method: "post" },
    });
  };

  const onUpdate = (value) => {

    navigate(RouteLink.Group_Update_Uri, {
      state: { data: value, method: "put" },
    });
  };

  const columnDefs = useMemo(() => GroupColumn);



  const [deletingRow, setDeletingRow] = useState({});

  const onDelete = (value) => {

    setDeletingRow(value);
    open();

  }

  

  function IsConfirmed(value) {
    if (value === true) {
      setLoading(true);
      ApiCall.axiosInstance["delete"](
        `${process.env.REACT_APP_API_URL}${ApiLink.Group_Uri}?id=${deletingRow.id}`
      )
        .then((response) => {

          setLoading(false);
          setRowData(oldValues => {
            return oldValues.filter(rowData => rowData.id !== deletingRow.id)
          })
        })
        .catch((error) => {
          console.error("Failed Request:", error);
          setLoading(false);
        });
    }

  }




  return (
    <Box style={{ marginTop: "15px" }}>
      <DeleteConfirmationModal IsConfirmed={IsConfirmed} opened={opened} open={open} close={close} />
      {user.level <= 1  && (
      <Button type="primary" onClick={() => onCreate()}>
        Create Group
      </Button>)}


      <MantineReactTable
        columns={columnDefs}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn='first'
        renderRowActions={(row) => (
          <RowAction onUpdate={() => onUpdate(row.row.original)} onDelete={() => onDelete(row.row.original)} />
        )}
        mantinePaperProps={{ shadow: '0', withBorder: false }}
      />

    </Box>
  );
};

export default GroupList;
