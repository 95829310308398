import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Grid,
  Select,
  Checkbox,
  LoadingOverlay,
  Divider,
  Tooltip,
  NumberInput,
  Radio,
  Group,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router";

import axios from "axios";

import { useMemo } from "react";
import { MantineReactTable } from "mantine-react-table";

import { ApiLink } from "../../../Constant/ApiLink";
import { IconRefresh, IconTrash } from "@tabler/icons-react";
import useConfirm from "../../../Shared/ConfirmModel";
import { label } from "../../../Shared/Constant";
import { useSelector } from "react-redux";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";

function QualityFormula() {
  const navigate = useNavigate();
  const { data, method } = useLocation().state;
  console.log(data);
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();
  const [tableData, setTableData] = useState([]);
  const [measureData, setMeasureData] = useState([]);
  const [formula, setFormula] = useState([]);
  const [childCounter, setChildCounter] = useState(1);
  // const [isMunliple, setIsMultiple] = useState(false);
  const [structFormulaData, setStructFormuladata] = useState([]);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [dateCompletnessValue, setDateCompleteness] = useState();
  const [performanceRateValue, setPerformanceRate] = useState();
  const [submissionType, setSubmissionType] = useState(data?.formulaType  || "Registry");

  const form = useForm({
    initialValues: {
      reqId: 0,
      childId: 0,
      formulaId: 0,
      isMulti: +data?.childId !== 0,
      finalCalType: "",
      dataCompleteness: 0,
      performanceRate: 0,
      year: reportSelection.selectedYear,
      formulaType: data?.formulaType || submissionType
    },
    validate: {
      reqId: (value) =>
        !value || value === "" ? "Measure selection is required!" : null,
      formulaId: (value) =>
        !value || value === "" ? "Formula Selection is required!" : null,
    },
  });

  const columns = useMemo(() => [
    {
      accessorKey: "reqId",
      header: "Selected Measure",
      accessorFn: (row) => {
        return measureData.find((ele) => +ele.id == +row.reqId)?.name;
      },
      enableEditing: false,
    },
    {
      accessorKey: "childId",
      header: "Child Ids",
      enableEditing: false,
    },
    {
      accessorKey: "formulaId",
      header: "Selected Formula",
      accessorFn: (row) => {
        return formula.find((ele) => ele.id == row.formulaId)?.name;
      },
      editVariant: "select",
      mantineEditSelectProps: {
        data: structFormulaData,
      },
    },
  ]);

  const Formulacolumns = useMemo(() => [
    {
      accessorKey: "name",
      header: "Formula Name",
    },
    {
      accessorKey: "performanceFormula",
      header: "Performancemet Formula",
    },
    {
      accessorKey: "dataCompletenessFormula",
      header: "Data Completeness Formula",
    },
  ]);

  useEffect(() => {
    getAllFormula();
    if (method == "put") {
      getFormulaDataForEdit();
      setChildCounter(+data?.childId + 1);
    }
    if (reportSelection.selectedYear && form.values.isMulti != undefined) {
      getMeasureData(form.values.isMulti);
    }
  }, [data, form.values.isMulti, reportSelection.selectedYear]);

  const getMeasureData = (isMulti) => {
    setLoading(true);
    ApiCall.axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}${ApiLink.MeasureListWithSigleorMulti}${isMulti}/${reportSelection.selectedYear}`
      )
      .then((response) => {
        setMeasureData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getAllFormula = () => {
    setLoading(true);
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.Formula}`)
      .then((response) => {
        setFormula(response.data);
        let StructFormuladata = response.data?.map((ele) => ({
          label: ele.name,
          value: ele.id,
        }));
        setStructFormuladata(StructFormuladata);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getFormulaDataForEdit = () => {
    setLoading(true);
    ApiCall.axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}${ApiLink.MFMappingGetSingleMeasureByMeasureName}${data?.reqId}/${data?.formulaType}`
      )
      .then((response) => {
        setTableData(response.data);
        setLoading(false);
        if (+data?.childId != 0) {
          form.values.setValues({
            isMulti: true,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleAddValues = (formValues) => {
    if (form.values.isMulti) {
      formValues.childId = childCounter;
      setChildCounter(+childCounter + 1);
    } else {
      formValues.childId = 0;
    }
    const newData = [...tableData, formValues];
    setTableData(newData);
   
    // updateDropDownData(newData);
    if (form.values.isMulti) {
      form.setValues({
        formulaId: "",
      });
    }
  };

  const handleSaveRow = async ({ table, row, values }) => {
    tableData[row.index].formulaId = values.formulaId;
    tableData[row.index].formula.id = values.formulaId;
    tableData[row.index].formula.name = formula.find(
      (ele) => ele.id == values.formulaId
    )?.name;
    setTableData([...tableData]);
    table.setEditingRow(null);
  };

  const handleBack = () => {
    navigate("/user/utilities/formula-list");
    setLoading(false);
  };

  const onDelete = async () => {
    const choise = await confirm({
      title: "Delete Record",
      description: "Are you sure to delete?",
    });
    if (choise) {
     
    }
  };

  const handleRefresh = () => {
    setTableData([]);
    setChildCounter(1);
  };

  const handleTypeChange = (value) => {
    setTableData([]);
    setChildCounter(1);
    form.setValues({
      finalCalType: value,
      dataCompleteness: 0,
      performanceRate: 0,
      formulaType :submissionType
    });
  };

  const handleSave = () => {
    if (
      form.values.finalCalType === "NU" &&
      (!performanceRateValue || !dateCompletnessValue)
    ) {
      alert(
        "Please fill & verify the value in PerformanceRate & DataCompleteness field!"
      );
      return;
    }
    form.values.formulaType = submissionType;

  // Set formulaType for each row in tableData
  const updatedTableData = tableData.map(row => ({
    ...row,
    formulaType: form.values.formulaType || submissionType, 
    performanceRate: performanceRateValue, 
    dataCompleteness: dateCompletnessValue
  }));

   console.log("table", updatedTableData)

    setLoading(true);
    ApiCall.axiosInstance[method](
      `${process.env.REACT_APP_API_URL}${ApiLink.MeasureFormulaMapping}`,
      updatedTableData
    )
      .then((response) => {
        setLoading(false);
        // navigate("/user/utilities/csvformate");
        form.setValues({
          dataCompleteness: 0,
          performanceRate: 0,
        });
        navigate("/user/utilities/formula-list");
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handlePerformanceRateChange = (value) => {
    setPerformanceRate(value);
    setTableData((prevTableData) =>
      prevTableData.map((ele) => ({
        ...ele,
        performanceRate : value,
      }))
    );
  };
  const handleDataCompletenessChange = (value) => {
    setDateCompleteness(value);
    setTableData((prevTableData) =>
      prevTableData.map((ele) => ({
        ...ele,
        dataCompleteness: value,
      }))
    );
  };
  const DividerStyle = {
    marginTop: "2rem",
    marginBottom: "2rem",
  };

  return (
    <Box>
      {loading && (
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      )}

      <form onSubmit={form.onSubmit((values) => handleAddValues(values))}>
        <Grid>
          <Grid.Col span={12} mt={10}>
            {formula.length ? (
              <MantineReactTable
                columns={Formulacolumns}
                data={formula}
                enablePagination={false}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                enableSorting={false}
                enableColumnActions={false}
              />
            ) : (
              ""
            )}
          </Grid.Col>
        </Grid>
        <Divider
          my="xs"
          size="sm"
          label="Formula Selection"
          labelPosition="center"
          style={DividerStyle}
          color="green"
        />
        <Grid my={10}>
          <Grid.Col span={3}>
            <Checkbox
              label="Is Multiple"
              disabled={method === "put"}
              // onChange={handleSingleChange}
              {...form.getInputProps("isMulti", { type: "checkbox" })}
            ></Checkbox>
          </Grid.Col>

          <Grid.Col span={3}>
          <Radio.Group
             value={submissionType}
             onChange={setSubmissionType}
             name="favoriteFramework"
           
          >
            <Group mt="xs">
              <Radio value="Registry" label="Registry" />
              <Radio
                value="ElectronicHealthRecord"
                label="Electronic Health Record"
              />
            </Group>
          </Radio.Group>
          </Grid.Col>

          <Grid.Col span={4}>
            {/* <Checkbox label="Mutliple"></Checkbox>
             */}
            <Tooltip label="Reset List">
              <Button onClick={handleRefresh}>
                <IconRefresh></IconRefresh>
              </Button>
            </Tooltip>
          </Grid.Col>
        </Grid>
        <Grid justify="space-between" mt={10}>
          {form.values.isMulti ? (
            <>
              <Grid.Col span={5}>
                <Select
                  label="Multi Type"
                  placeholder="Select Type"
                  {...form.getInputProps("finalCalType")}
                  onChange={handleTypeChange}
                  data={[
                    { label: "Simple Average", value: "SA" },
                    { label: "Weightage Average", value: "WA" },
                    { label: "Numeric", value: "NU" },
                  ]}
                />
              </Grid.Col>
              <Grid.Col span={7}></Grid.Col>
            </>
          ) : (
            ""
          )}
          <Grid.Col span={5}>
            <Select
              label="Measures"
              placeholder="Select Measure"
              disabled={tableData.length == 1 || tableData.length > 1}
              {...form.getInputProps("reqId")}
              data={measureData.map((ele) => ({
                label: ele.name,
                value: ele.id,
              }))}
            />
          </Grid.Col>
          <Grid.Col span={5}>
            <Select
              label="Select Formula"
              placeholder="Select Formula"
              disabled={
                (tableData.length == 1 && !form.values.isMulti) ||
                method == "put"
              }
              {...form.getInputProps("formulaId")}
              data={formula.map((ele) => ({
                label: ele.name,
                value: ele.id,
              }))}
            />
          </Grid.Col>
          <Grid.Col span={2} align="end" mt={25}>
            <Button
              type="submit"
              disabled={tableData.length == 1 && !form.values.isMulti}
            >
              Add Formula
            </Button>
          </Grid.Col>
          <Grid.Col span={12}>
            {tableData.length && measureData.length ? (
              <MantineReactTable
                columns={columns}
                data={tableData}
                editDisplayMode="row"
                //   enableEditing
                onEditingRowSave={handleSaveRow}
                enablePagination={false}
                enableRowActions={method == "put"}
                positionActionsColumn="first"
                enableTopToolbar={false}
                enableEditing={method == "put"}
                // renderRowActions={(row) => (
                //   <Delete
                //     onDelete={() => onDelete(row.row.original)}
                //     //  onDelete={()=>onDelete(row.row.original)}
                //   />
                // )}
              />
            ) : (
              ""
            )}
          </Grid.Col>
          {form.values.isMulti && form.values.finalCalType === "NU" ? (
            <>
              <Grid.Col span={6}>
                {/* <TextInput
                  label="Performance Rate: "
                  placeholder="Performance Rate:"
                  {...form.getInputProps("dataCompleteness")}
                /> */}
                <NumberInput
                  label="Performance Rate:"
                  description="This is mandatory if type is Numeric"
                  placeholder="Performance Rate:"
                  min={1}
                  max={childCounter - 1}
                  value={performanceRateValue}
                  onChange={handlePerformanceRateChange}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                {/* <TextInput
                  label="Data Completeness: "
                  placeholder="Data Completeness"
                  {...form.getInputProps("performanceRate")}
                /> */}
                <NumberInput
                  label="Data Completeness"
                  description="This is mandatory if type is Numeric"
                  placeholder="Data Completeness"
                  min={1}
                  max={childCounter - 1}
                  value={dateCompletnessValue}
                  onChange={handleDataCompletenessChange}
                />
              </Grid.Col>
            </>
          ) : (
            ""
          )}
        </Grid>
      </form>
      <Grid justify="end">
        <Grid.Col span={12} align="end">
          <Button type="primary" onClick={() => handleBack()}>
            Back
          </Button>
          <Button
            // disabled={method == "put"}
            type="primary"
            mt="sm"
            ml={5}
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </Grid.Col>
      </Grid>
    </Box>
  );
}

export default QualityFormula;

const Delete = (props) => {
  return (
    <Grid>
      <Button.Group>
        {
          <Button
            variant="outline"
            color="gray"
            size="xs"
            onClick={props.onDelete}
          >
            <IconTrash size="1.2rem"></IconTrash>
          </Button>
        }
      </Button.Group>
    </Grid>
  );
};
