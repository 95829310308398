import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Group, Radio, Select, Text } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useSelector } from "react-redux";
import { ApiLink } from "../../../Constant/ApiLink";
import axios from "axios";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";

const MeasureCqmDetails = () => {
  const [rowData, setRowData] = useState();
  const [qualityMeasure, setQualityMeasure] = useState([]);
  const [measureType, setMeasureType] = useState();
  const [submissionType, setSubmissionType] = useState("Registry");
  //   const [weightage, setWeightage] = useState("");

  const [loading, setIsLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const selectedGroupId = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );
  const [uniqueMeasureIds, setUniqueMeasureId] = useState([]);

  const columnDef = useMemo(() => [
    {
      header: "NPI",
      accessorKey: "npi",
      filterVariant: "select",
      mantineFilterSelectProps: {
        data: uniqueMeasureIds,
      },
    },
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Performance Met",
      accessorKey: "performanceMet",
    },
    {
      header: "Denominator",
      accessorKey: "denominator",
    },
    {
      header: "Performance Rate",
      accessorKey: "performanctRate",
    },
    {
      header: "Decile",
      accessorKey: "decile",
    },
    {
      header: "StartDate",
      accessorFn: (row) => {
        if (row?.startDate) {
          const [year, month, day] = row.startDate.split("-");
          const formattedDate = `${month}-${day}-${year}`;
          return formattedDate;
        }
        return row?.startDate;
      },
    },
    {
      header: "EndDate",
      accessorFn: (row) => {
        if (row?.endDate) {
          const [year, month, day] = row.endDate.split("-");
          const formattedDate = `${month}-${day}-${year}`;
          return formattedDate;
        }
        return row?.endDate;
      },
    },
  ]);


 

  // Runs only on first load and when submissionType changes
useEffect(() => {
  QualityPhysician();
}, [submissionType]);

// Runs when all dependencies are available
useEffect(() => {
  if (
    reportSelection.selectedYear !== undefined &&
    reportSelection.clinicId !== undefined &&
    measureType !== undefined &&
    submissionType !== undefined
  ) {
    getGroupCQMDetails();
  }
}, [reportSelection.selectedYear, reportSelection.clinicId, measureType, submissionType]);

  // Dropdown Measure
  const QualityPhysician = () => {
    setIsLoading(true);
    return ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.QualityMeasures}${reportSelection.clinicId}/${reportSelection.selectedYear}/${submissionType}`
      )
      .then((response) => {
        let measureData = response.data?.map((ele) => ({
          value: ele.id,
          label: ele.name,
        }));
        setQualityMeasure(measureData);
        setMeasureType(measureData[0]?.value);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  // List measure
  const getGroupCQMDetails = () => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.QualityCalculationMeasureWise}${reportSelection.clinicId}/${reportSelection.selectedYear}/${measureType}/${submissionType}`
      )
      .then((response) => {
        setRowData(response.data);
        const uniqueMeasures = [
          ...new Set(response?.data?.map((item) => item.npi)),
        ].sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        setUniqueMeasureId(uniqueMeasures);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  
  

  return (
    <Box style={{ marginTop: "15px" }}>
      <Grid>
        <Grid.Col span={8}>
          <Select
            maw="100%"
            label="QPP ID - eCQM ID - Measure Name "
            withAsterisk
            placeholder="Pick value"
            data={qualityMeasure}
            value={measureType}
            searchable
            onChange={setMeasureType}
          />
        </Grid.Col>
       <Grid.Col span={4}>
                 <Radio.Group
                   value={submissionType}
                   onChange={setSubmissionType}
                   name="favoriteFramework"
                   label="Select Submission Method"
                   withAsterisk
                 >
                   <Group mt="xs">
                     <Radio value="Registry" label="Registry" />
                     <Radio value="ElectronicHealthRecord" label="EHR" />
                   </Group>
                 </Radio.Group>
               </Grid.Col>
      </Grid>
      <MantineReactTable
        columns={columnDef}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};

export default MeasureCqmDetails;
