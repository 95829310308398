export const PartnerColumn = [
    {
        header: "Name",
        accessorKey: "name",
    },
    {
        header: "Description",
        accessorKey: "description",
    },
    {
        header: "Is Display",
        accessorKey: "isDisplay",
        Cell: ({ cell }) => {
            return <div>{cell.getValue() ? "Display" : "Hide"}</div>;
        },
    },
    {
        header: "Status",
        accessorKey: "isActive",
        Cell: ({ cell }) => {
            return <div>{cell.getValue() ? "Active" : "InActive"}</div>;
        },
    },
    {
        header: "Parent Group",
        accessorKey: "parentGroup",
    }
];