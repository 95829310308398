import React, { useState, useRef, useEffect, useMemo } from "react";

import { useNavigate } from "react-router-dom";
import {
  Anchor,
  Badge,
  Box,
  Button,
  Grid,
  Group,
  SegmentedControl,
  Select,
  Switch,
  Tooltip,
} from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useDisclosure } from "@mantine/hooks";
import { useDispatch, useSelector } from "react-redux";
import { RouteLink } from "../../Constant/RouterLink";
import { ApiLink } from "../../Constant/ApiLink";
import axios from "axios";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import { IconEdit, IconFile, IconTrash } from "@tabler/icons-react";
import { IconRefresh } from "@tabler/icons-react";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";
import { setSelectedGrupId } from "../../utils/patenerTreeSlice";
import { addChangeOnEdit, addClinicId } from "../../utils/selectedClinicSlice";

const SubmissionList = () => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  var navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [category, setCategory] = useState("Ia");
  const selectedGroupId = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [submissionType, setSubmissionType] = useState("Registry");
  const [programName, setProgramName] = useState("mips");

 

  const getCSVdataList = (selectedGroupId) => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}${ApiLink.SubmissionListReq}/${selectedGroupId}/${reportSelection.clinicId}/${user.level}/${reportSelection.selectedYear}/${submissionType}/${programName}`
      )
      .then((response) => {
        setRowData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setRowData([]);
      });
  };


  useEffect(() => {
    console.log("submissionType:", submissionType, "programName:", programName);
    getCSVdataList(selectedGroupId);
  }, [
    selectedGroupId,
    reportSelection.clinicId,
    reportSelection.selectedYear,
    category,submissionType,programName
  ]);


  // Define columns for the table
  const columnsDef = useMemo(
    () => [
      {
        header: "TIN",
        accessorKey: "tin",
      },
      {
        header: "Name",
        accessorKey: "clinicName",
      },
      {
        header: "Validation  Date",
        accessorFn: (row) => {
          const date = row.scoreUpdatedDate?.trim();
          return date === "01-01-0001" || date === "0001-01-01" ? "-" : date;
        },
      },
      {
        header: "Validation Status",
        accessorKey: "isValidated",
        Cell: ({ cell }) => {
          const isValidated = cell.getValue();
          return (
            <Badge
              color={
                isValidated === "success"
                  ? "green"
                  : isValidated === null
                    ? "gray"
                    : "red"
              }
            >
              {isValidated === "success"
                ? "Validated"
                : isValidated === null
                  ? "Not Validated"
                  : "Error"}
            </Badge>
          );
        },
      },
      {
        header: "Submission Date",
        accessorFn: (row) => {
          const date = row.submissionUpdatedDate?.trim();
          return date === "01-01-0001" || date === "0001-01-01" ? "-" : date;
        },
      },
      {
        header: "Submission Status",
        accessorKey: "isSubmitted",
        Cell: ({ cell }) => {
          const isSubmitted = cell.getValue();
          return (
            <Badge
              color={
                isSubmitted === "success"
                  ? "green"
                  : isSubmitted === "partial"
                    ? "red"
                    : "gray"
              }
            >
              {isSubmitted === "success"
                ? "Submitted"
                : isSubmitted === "partial"
                  ? "Partial"
                  : isSubmitted === "no"
                    ? "Not Submitted"
                    : "Verified"}
            </Badge>


          );
        },
      },
    ],
    []
  );

  const onCreate = () => {
    navigate(RouteLink.SubmissionForm_Uri, {
      state: { data: null, method: "post" },
    });
  };

  const onUpdate = (value) => {
    console.log(value);

    dispatch(setSelectedGrupId(value.groupId));
    dispatch(addClinicId(value.clinicId));
    dispatch(addChangeOnEdit(value.clinicId));

    navigate(RouteLink.SubmissionForm_Uri, {
      state: { data: value, method: "post" },
    });


  };

  return (
    <Box style={{ marginTop: "15px" }}>
      <Grid>
        {/* <Grid.Col span={2}>
          <Group position="apart">
            <Button type="primary" onClick={() => onCreate()}>
              Submission
            </Button>
          </Group>
        </Grid.Col> */}

<Grid.Col span={2}>
        <SegmentedControl
          label="Submitted Type:"
          fullWidth
          color="rgba(43, 138, 66, 1)"
          data={[
            { label: "Registry", value: "Registry" },
            { label: "EHR", value: "ElectronicHealthRecord" },
          ]}
          value={submissionType}
          onChange={(value) => setSubmissionType(value)}
        />
      </Grid.Col>
    
      <Grid.Col span={2}>
        <SegmentedControl
          label="Select Program:"
          fullWidth
          color="rgba(43, 138, 66, 1)"
          data={[
            { label: "MIPS", value: "mips" },
            { label: "MVP", value: "mvp" },
          ]}
          value={programName}
          onChange={(value) => setProgramName(value)}
        />
      </Grid.Col>


      </Grid>
      <MantineReactTable
        columns={columnsDef}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn="first"
        renderRowActions={(row) => (
          <RowAction onUpdate={() => onUpdate(row.row.original)} />
        )}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};

export default SubmissionList;

const RowAction = (props) => {
  return (
    <Grid>
      <Button.Group>
        {
          <Button
            variant="filled"
            color="green"
            size="xs"
            onClick={props.onUpdate}
          >
            <IconEdit size="1.2rem"></IconEdit>
          </Button>
        }
      </Button.Group>
    </Grid>
  );
};
