import { ApiLink } from "../../Constant/ApiLink";
import {
  TextInput,
  Button,
  Box,
  Grid,
  Select,
  Checkbox,
  Textarea,
  FileInput,
} from "@mantine/core";
import { Loader, Divider } from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { RouteLink } from "../../Constant/RouterLink";
import useAxios from "../../Shared/useApi";
import { StateList } from "../../Shared/DropDownOptions/StateList";
import { useDispatch, useSelector } from "react-redux";
import { refreshTreeData } from "../../utils/patenerTreeSlice";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const GroupForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, method } = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [groupLocation, setGroupLocation] = useState([]);
  const [preview, setPreview] = useState(null);
  //
  const { user } = useSelector((state) => state.auth);
  const partenerTreeItems = useSelector((state) => state.partenerTree.partenerTreeItem);
  

  //bind  parneter
  const [partner, setPartner] = useState([]);
  const { response, error } = useAxios({
    method: "get",
    url: "/Partner",
    headers: null,
    body: null,
  });

  const form = useForm({
    initialValues: {
      id: data?.id,
      name: data?.name,
      description: data?.description,
      legalBusinessName: data?.legalBusinessName,
      tin: data?.tin,
      ccn: data?.ccn,
      partnerId: data?.partnerId,
      isMIPS: data?.isMIPS,
      entityTypeId: data?.entityTypeId,
      calcPiScore: data?.calcPiScore,
      calcQualityScore: data?.calcQualityScore,
      calcCpiaScore: data?.calcCpiaScore,
      isActive: data?.isActive ?? true,
      groupLocationId: data?.groupLocationId,
      validationToeken: data?.validationToeken,
      submissionToken: data?.submissionToken,
      logo: data?.logo || "", // stores the uploaded file name
      file: null,
      address: {
        id: data?.addressId,
        line1: data?.address?.line1,
        line2: data?.address?.line2,
        line3: data?.address?.line3,
        city: data?.address?.city,
        state: data?.address?.state,
        telephone_number: data?.address?.telephone_number,
        fax_number: data?.address?.fax_number,
        address_type: data?.address?.address_type,
        address_purpose: data?.address?.address_purpose,
        country_code: data?.address?.country_code,
        country_name: data?.address?.country_name,
        zip: data?.address?.zip,
      },
    },

    validate: {
      name: (value) => (!value || value === "" ? "Name is required!" : null),
      legalBusinessName: (value) =>
        !value || value === "" ? "Legal Business Name is required!" : null,
      // tin: (value) => (!value || value === "" ? "Tin is required!" : null),

      partnerId: (value) =>
        !value || value === "" ? "Patner  is required!" : null,
      entityTypeId: (value) =>
        !value || value === "" ? "Entity Type  is required!" : null,

    },
  });


  useEffect(() => {
    if (response !== null) {
      setPartner(response);

      if(user.level ===1)
      {
        const paraterId = partenerTreeItems[0]?.id;
        form.setValues(
          {
            partnerId : paraterId
          }
        )
      }
     
    }
    if (error) {
     
    }
  }, [response, error]);
  //bind  parneter

  //bind  EntityType
  const [entitytype, setEntitytype] = useState([]);
  useEffect(() => {
    getEntityType();
    getGroupLocation();
  }, []);

  const getEntityType = () => {
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.Entity_Type}`)
      .then((response) => {
        setEntitytype(response.data);
       
      })
      .catch((error) => {
        console.error("Error EntityType fetching data:", error);
      });
  };

  const getGroupLocation = () => {
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.GroupLocation}`)
      .then((response) => {
        setGroupLocation(response.data);
      })
      .catch((error) => {
        console.error("Error Group Location fetching data:", error);
      });
  };
  //bind  EntityType

  const DividerStyle = {
    marginTop: "2rem",
    marginBottom: "1rem",
  };

  //Save Method
  const handleSubmit = (formValues) => {
    formValues.address.zip =
      formValues.address.zip === "" ? 0 : formValues.address.zip;

    setLoading(true);
    ApiCall.axiosInstance[method](
      `${process.env.REACT_APP_API_URL}${ApiLink.Group_Uri}`,
      formValues
    )
      .then((response) => {
      
        setLoading(false);
        dispatch(refreshTreeData());
        navigate(RouteLink.Group_Uri);
      })
      .catch((error) => {
       
        setLoading(false);
      });
  };
  //Save Method Closed

  // BackButton
  const handleBack = () => {
    navigate(RouteLink.Group_Uri);
    setLoading(false);
  };
  // BackButton Closed

  // When editing (PUT), fetch and display the existing image
  useEffect(() => {
    if (method === "put" && data?.logo) {
      axios
        .get(
          `https://localhost:7076/v1/FileUplaod/downloadLogo/${data.logo}`,
          { responseType: "blob" }
        )
        .then((response) => {
          const imageUrl = URL.createObjectURL(response.data);
          setPreview(imageUrl);
        })
        .catch((error) => {
          console.error("Error fetching logo image", error);
        });
    }
  }, [method, data]);

  
  const handleFileChange = async (file) => {
      form.setFieldValue("file", file);
    
      if (file) {
        const fileUrl = URL.createObjectURL(file);
        setPreview(fileUrl);
    
        const formData = new FormData();
        formData.append("file", file, file.name);
    
        try {
          const response = await ApiCall.axiosInstance.post(
            `${process.env.REACT_APP_API_URL}${ApiLink.Upload_Logo}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "text/plain",
              },
            }
          );
    
          const uploadedFileName = response.data;
          form.setFieldValue("logo", uploadedFileName);
        } catch (error) {
          console.error("File upload error:", error);
          // Optional: show error toast or fallback
        }
      } else {
        setPreview(null);
        form.setFieldValue("logo", ""); // Optional: reset logo if no file
      }
    };

    const handleRemoveImage = () => {
      setPreview(null);
      form.setFieldValue("logo", null);
      form.setFieldValue("file", null);
    };

  return (
    <Box>
      {loading ? <Loader size={48} /> : ""}
      <h5>Group: Add New</h5>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid>
        <Grid.Col span={8}>
            <Grid>
            <Grid.Col span={4}>
            <TextInput
              label="Name: "
              placeholder="Name"
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput
              label="Legal Business Name: "
              placeholder="Legal Business Name"
              {...form.getInputProps("legalBusinessName")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select
              label="Business Partner"
              withAsterisk
              {...form.getInputProps("partnerId")}
              data={partner.map((ele) => ({
                value: ele.id,
                label: ele.name,
              }))}
              disabled={user.level >= 1}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select
              label="Entity Type"
              withAsterisk
              {...form.getInputProps("entityTypeId")}
              data={entitytype.map((ele) => ({
                value: ele.id,
                label: ele.name,
              }))}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select
              label="MAC Location"
              withAsterisk
              {...form.getInputProps("groupLocationId")}
              data={groupLocation.map((ele) => ({
                value: ele.id,
                label: ele.name,
              }))}
            />
          </Grid.Col>
            </Grid>

        </Grid.Col>

          <Grid.Col span={4}>
                   <FileInput
                     label="Upload Logo"
                     placeholder="Select file"
                     onChange={handleFileChange}
                   />
                   {preview && (
                     <>
                       <img
                         src={preview}
                         alt="Preview"
                         style={{ maxWidth: "150px", maxHeight: "auto", marginTop: "1rem" }}
                       />
                       <br />
                       <Button
                         mt="sm"
                         variant="outline"
                         color="red"
                         onClick={handleRemoveImage}
                       >
                         Remove Image
                       </Button>
                     </>
                   )}
                 </Grid.Col>
          
        </Grid>

        
        <Grid>
          <Grid.Col span={12}>
            <Textarea
              label="Description"
              placeholder="Description"
              {...form.getInputProps("description")}
            />
          </Grid.Col>
        </Grid>

        <Divider
          my="xs"
          size="sm"
          label="Address Information"
          labelPosition="center"
          style={DividerStyle}
          color="green"
        />
        <Grid>
          <Grid.Col span={12}>
            <TextInput
              label="Address1: "
              placeholder="Address1"
              {...form.getInputProps("address.line1")}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label="Address 2: "
              placeholder="Address 2"
              {...form.getInputProps("address.line2")}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={12}>
            <TextInput
              label="Address 3: "
              placeholder="Address 3"
              {...form.getInputProps("address.line3")}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={4}>
            <Select
              label="State: "
              placeholder="State"
              {...form.getInputProps("address.state")}
              data={StateList}
              searchable
              maxDropdownHeight={250}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput
              label="City: "
              placeholder="City"
              {...form.getInputProps("address.city")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput
              label="Zip: "
              placeholder="Zip"
              {...form.getInputProps("address.zip")}
            />
          </Grid.Col>
        </Grid>

        <Divider
          my="xs"
          size="sm"
          label="status"
          labelPosition="center"
          style={DividerStyle}
          color="green"
        />
        <Grid>
          <Grid.Col span={3}>
            <Select
              label="Status: "
              placeholder="Status"
              {...form.getInputProps("isActive")}
              data={[
                { value: true, label: "Active" },
                { value: false, label: "InActive" },
              ]}
            />
          </Grid.Col>
        </Grid>

        <Divider
          my="xs"
          size="sm"
          label="Submission Token"
          labelPosition="center"
          style={DividerStyle}
          color="green"
        />

        <Grid>
          <Grid.Col span={12}>
          <TextInput
              label="Submission Token: "
              placeholder="Submission Token"
              {...form.getInputProps("submissionToken")}
            />
          </Grid.Col>
        </Grid>


        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <div>
            <Button type="primary" onClick={() => handleBack()}>
              Back
            </Button>
            <Button style={{ marginLeft: "10px" }} type="submit" mt="sm">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Box>
  );
};

export default GroupForm;
