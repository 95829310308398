import { useLocation, useNavigate } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { LoadingOverlay, Table } from "@mantine/core";
import {
  Box,
  Checkbox,
  Container,
  Divider,
  Select,
  Text,
  TextInput,
  Button,
} from "@mantine/core";
import { PIScoringType } from "../../Shared/DropDownOptions/PIScoringType";
import { YesNo } from "../../Shared/DropDownOptions/YesNo";
import { Grid } from "@mantine/core";
import { useSelector } from "react-redux";
import { ComponentDetialsColumns } from "./Columns/ComponentDetialsColumns";
import axios from "axios";
import { ApiLink } from "../../Constant/ApiLink";
import { MantineReactTable } from "mantine-react-table";
import { nFormatter } from "../../Shared/NumberFunctions";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const ComponentDetails = () => {
  var navigate = useNavigate();
  const reportSelection = useSelector((store) => store.selectedClinic.item); // coming from redux
  const [loading, setloading] = useState(false);
  const [componentdetails, setcomponentdetails] = useState(); //table row
  const [
    entityTypePercentageDestribution,
    setEntityTypePercentageDestribution,
  ] = useState([]);
  const [selectedIntityTypePercentage, setSelectedIntityTypePercentage] =
    useState(null);
  const [selectedEntity, setSelectedEntity] = useState(entityTypePercentageDestribution[0]?.id);

  const columnDefs = useMemo(() => ComponentDetialsColumns("EC Name"));
  const [mipsScoreToggle, setMipsScoreToggle] = useState(true);





  useEffect(() => {
    getEntityTypePercentageDestribution();
  }, [reportSelection]);



  const handleComponentDetailsOnEntity = (value) => {
    let selecteditem = entityTypePercentageDestribution?.find((ele) => ele.id == value);
    setSelectedEntity(value);
    setSelectedIntityTypePercentage(selecteditem)
    setloading(true);
    setcomponentdetails([]);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.Get_CompomnentDetails}/${reportSelection.clinicId}/${reportSelection.scallingFactor}?entityid=${value}&financialYear=${reportSelection.selectedYear}`
      )
      .then((response) => {
        
        setcomponentdetails(response.data.slice(0, -1));
        setloading(false);
      })
      .catch((error) => {
        console.error("Error Clinic fetching data:", error);
        setloading(false);
      });
  };

  const getEntityTypePercentageDestribution = () => {
    setloading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.EntityTypewithReqSetByTin}/${reportSelection.clinicId}/${reportSelection.selectedYear}`
      )
      .then((response) => {

        setEntityTypePercentageDestribution(response.data);
        handleComponentDetailsOnEntity(response.data[0]?.entityId);
        setSelectedIntityTypePercentage(response.data[0]);
      })
      .catch((error) => {
        console.error("Error EntityType fetching data:", error);
        setloading(false);
      });
  };

  const [calculationMethod, setCalculationMethod] = useState("target");
  const actualClicked = () => {
    setCalculationMethod("actual");
    setMipsScoreToggle(false);
  };
  const targetClicked = () => {
    setCalculationMethod("target");
    setMipsScoreToggle(true);
  };

  return (
    <Box style={{ marginTop: "15px" }}>
      {loading &&
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      }
      <div className="mycontainer">
      Physician Scores{" "}
        {reportSelection.clinicEnrollLavel && (
          <span>({reportSelection.clinicEnrollLavel})</span>
        )}
      </div>
      <br />
      <Grid>
        <Grid.Col span={3}>
          <Select
            size="xs"
            label="Entity Type"
            withAsterisk
            value={selectedEntity}
            data={entityTypePercentageDestribution?.map((ele) => ({
              value: +ele.id,
              label: ele.name,
            }))}
            onChange={(value) => handleComponentDetailsOnEntity(value)}
          />
        </Grid.Col>
        <Button.Group mt={30}>
          <Button
            variant={calculationMethod == "actual" ? "filled" : "outline"}
            onClick={actualClicked}
          >
            Actual
          </Button>
          <Button
            variant={calculationMethod == "target" ? "filled" : "outline"}
            onClick={targetClicked}
          >
            Target
          </Button>
        </Button.Group>
      </Grid>

      <Divider my="sm" />

      {/* <MantineReactTable
        columns={columnDefs}
        data={componentdetails ?? []}
        state={{ isLoading: loading }}
        positionActionsColumn='first'
        mantinePaperProps={{ shadow: '0', withBorder: false }}
      /> */}

      <Table
        striped
        highlightOnHover
        withBorder
        withColumnBorders
        className="greenhead"
      >
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Weights{"==>"}</th>
            <th>{selectedIntityTypePercentage?.mipsPI}%</th>
            <th>{selectedIntityTypePercentage?.mipsCqm}%</th>
            <th>{selectedIntityTypePercentage?.mipsRu}%</th>
            <th>{selectedIntityTypePercentage?.mipsCpia}%</th>
            <th></th>
          </tr>
          <tr>
            <th>EC Name</th>
            <th>NPI</th>
            <th>TIN</th>
            <th>Promoting Interoperability</th>
            <th>Quality</th>
            <th>Cost</th>
            <th>CPIA</th>

            <th>MIPS Score</th>
          </tr>
        </thead>
        <tbody>
          {loading && <Text fw={500}>Loading..</Text>}
          {componentdetails
            ? componentdetails?.map((e) => (
                <tr key={e.ecName}>
                  <td>{e.ecName}</td>
                  <td>{e.npi}</td>
                  <td>{e.tin}</td>
                  <td>{mipsScoreToggle?((selectedIntityTypePercentage?.mipsPI *e.targetScore)/100): e.pi==0?'-':e.pi }</td>
                  <td>{mipsScoreToggle?((selectedIntityTypePercentage?.mipsCqm *e.targetScore)/100):e.quality==0?'-':e.quality}</td>
                  <td>{mipsScoreToggle ?((selectedIntityTypePercentage?.mipsRu *e.targetScore)/100):e.resource==0?'-':e.resource}</td>
                  <td>{mipsScoreToggle?((selectedIntityTypePercentage?.mipsCpia *e.targetScore)/100):e.cpia==0?'-':e.cpia}</td>
                  <td>{mipsScoreToggle ? e.targetScore: '-' }</td>
                </tr>
              ))
            : !loading && (
                <tr>
                  <td>No data available</td>
                </tr>
              )}
        </tbody>
      </Table>
    </Box>
  );
};
export default ComponentDetails;
