import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  LoadingOverlay,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useSelector } from "react-redux";
import axios from "axios";
import { ApiLink } from "../../Constant/ApiLink";
import * as XLSX from "xlsx";
import { StateList } from "../../Shared/DropDownOptions/StateList";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const CcmGroupAnalysis = () => {
  const { id } = useParams();
  const [rowData, setRowData] = useState();
  const [loading, setIsLoading] = useState(false);
  const [groupWiseLocationData, setGroupWiseLocationData] = useState(null);
  const [ccGroupAnalysicsData, setCCMGroupAnalysicsData] = useState([]);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [status, setStatus] = useState(1);

  const selectedGroupId = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );
  const partenerTreeData = useSelector(
    (store) => store.partenerTree.partenerTreeItem
  );

  const [onboard, setOnboard] = useState(0.20);
  const [softwareFeeConst, setSoftwareFeeConst] = useState(4);
  const [nursingFeeConst, setNursingFeeConst] = useState(0.5);
  const [mothlyCostSaveConst, setMothlyCostSaveConst] = useState(74);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US").format(amount) + "$";
  };

  const defaultVisibleColumns = useMemo(
    () => ({
      clinicName: true,
      totProvider: true,
      totBen: true,
      potencialCcm: true,
      actualCcm: true,
      monthlyMedicareBillings: false,
      monthlySoftwareFees: false,
      monthlyNursingFees: false,
      monthlyNetToMD: false,
      monthlyCostSavingsAt74: false,
      annual99490Potential: true,
      annualSoftwareFees: true,
      annualNursingFees: true,
      annualCostSavingsAt888: true,
      state: true,
    }),
    []
  );

  const [columnVisibility, setColumnVisibility] = useState(
    defaultVisibleColumns
  );

  const columnDef = useMemo(
    () => [
      {
        header: "Clinic Name",
        accessorKey: "clinicName",
        Footer: () => <Stack>Total</Stack>,
      },
      {
        header: "State",
        accessorKey: "stateName",
        accessorFn: (row) => {
          return StateList.find((ele) => ele.value == row?.stateName)?.label;
        },
        Footer: () => <Stack></Stack>,
      },
      {
        header: "Total Provider",
        accessorKey: "totProvider",
        Footer: () => (
          <Stack>
            <Box color="orange">
              {
                rowData?.reduce((acc, curr) => acc + curr.totProvider, 0)
              }
            </Box>
          </Stack>
        ),
      },
      {
        header: "Total Beneficiaries",
        accessorKey: "totBen",
        Footer: () => (
          <Stack>
            <Box color="orange">
              {
                rowData?.reduce((acc, curr) => acc + curr.totBen, 0)
              }
            </Box>
          </Stack>
        ),
      },
      {
        header: "Highest Volume CCM",
        accessorKey: "potencialCcm",
        Footer: () => (
          <Stack>
            <Box color="orange">
              {
                rowData?.reduce((acc, curr) => acc + curr.potencialCcm, 0)
              }
            </Box>
          </Stack>
        ),
      },
      {
        header: "Actual CCM",
        accessorKey: "actualCcm",
        Footer: () => (
          <Stack>
            <Box color="orange">
              {
                rowData?.reduce((acc, curr) => acc + curr.actualCcm, 0)
              }
            </Box>
          </Stack>
        ),
      },
      {
        header: "Monthly Medicare Billings",
        accessorKey: "monthlyMedicareBillings",
        accessorFn: (row) => {
          return formatCurrency(row?.monthlyMedicareBillings);
        },
        Footer: () => (
          <Stack>
            <Box color="orange">
              {formatCurrency(
                rowData?.reduce(
                  (acc, curr) => acc + curr.monthlyMedicareBillings,
                  0
                )
              )}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Monthly Software Fees",
        accessorKey: "monthlySoftwareFees",
        accessorFn: (row) => {
          return formatCurrency(row?.monthlySoftwareFees);
        },
        Footer: () => (
          <Stack>
            <Box color="orange">
              {formatCurrency(
                rowData?.reduce(
                  (acc, curr) => acc + curr.monthlySoftwareFees,
                  0
                )
              )}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Monthly Nursing Fees",
        accessorKey: "monthlyNursingFees",
        accessorFn: (row) => {
          return formatCurrency(row?.monthlyNursingFees);
        },
        Footer: () => (
          <Stack>
            <Box color="orange">
              {formatCurrency(
                rowData?.reduce((acc, curr) => acc + curr.monthlyNursingFees, 0)
              )}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Monthly Net to MD",
        accessorKey: "monthlyNetToMD",
        accessorFn: (row) => {
          return formatCurrency(row?.monthlyNetToMD);
        },
        Footer: () => (
          <Stack>
            <Box color="orange">
              {formatCurrency(
                rowData?.reduce((acc, curr) => acc + curr.monthlyNetToMD, 0)
              )}
            </Box>
          </Stack>
        ),
      },
      {
        header: `Monthly Cost Savings @${mothlyCostSaveConst}`,
        accessorKey: "monthlyCostSavingsAt74",
        accessorFn: (row) => {
          return formatCurrency(row?.monthlyCostSavingsAt74);
        },
        Footer: () => (
          <Stack>
            <Box color="orange">
              {formatCurrency(
                rowData?.reduce(
                  (acc, curr) => acc + curr.monthlyCostSavingsAt74,
                  0
                )
              )}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Annual CCM Potential",
        accessorKey: "annual99490Potential",
        accessorFn: (row) => {
          return !isNaN(row.annual99490Potential)
            ? formatCurrency(row.annual99490Potential)
            : "Calculating..";
        },
        Footer: () => (
          <Stack>
            <Box color="orange">
              {formatCurrency(
                rowData?.reduce(
                  (acc, curr) => acc + curr.annual99490Potential,
                  0
                )
              )}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Annual Software Fees",
        accessorKey: "annualSoftwareFees",
        accessorFn: (row) => {
          return formatCurrency(row?.annualSoftwareFees);
        },
        Footer: () => (
          <Stack>
            <Box color="orange">
              {formatCurrency(
                rowData?.reduce((acc, curr) => acc + curr.annualSoftwareFees, 0)
              )}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Annual Nursing Fees",
        accessorKey: "annualNursingFees",
        accessorFn: (row) => {
          return formatCurrency(row?.annualNursingFees);
        },
        Footer: () => (
          <Stack>
            <Box color="orange">
              {formatCurrency(
                rowData?.reduce((acc, curr) => acc + curr.annualNursingFees, 0)
              )}
            </Box>
          </Stack>
        ),
      },
      {
        header: `Annual Cost Savings @${mothlyCostSaveConst * 12}`,
        accessorKey: "annualCostSavingsAt888",
        accessorFn: (row) => {
          return formatCurrency(row?.annualCostSavingsAt888);
        },
        Footer: () => (
          <Stack>
            <Box color="orange">
              {formatCurrency(
                rowData?.reduce(
                  (acc, curr) => acc + curr.annualCostSavingsAt888,
                  0
                )
              )}
            </Box>
          </Stack>
        ),
      }
      
    ],
    [mothlyCostSaveConst, rowData]
  );

  const handleColumnVisibilityChange = (updatedColumnVisibility) => {
    setColumnVisibility(updatedColumnVisibility);
  };

  const calculateData = (
    data,
    onboard,
    softwareFeeConst,
    nursingFeeConst,
    monthlyCostSaving
  ) => {
   
    return data.map((item) => {
      const monthlyMedicareBillings = Math.floor(
        onboard * item.potencialCcm * groupWiseLocationData?.amount
      );
      const monthlySoftwareFees = Math.floor(
        onboard * item.potencialCcm * softwareFeeConst
      );
      const monthlyNursingFees = monthlyMedicareBillings * nursingFeeConst;
      const monthlyNetToMD =
        monthlyMedicareBillings - monthlySoftwareFees - monthlyNursingFees;
      const monthlyCostSavingsAt74 =
        onboard * item.potencialCcm * monthlyCostSaving;
      const annual99490Potential = monthlyMedicareBillings * 12;
      const annualSoftwareFees = monthlySoftwareFees * 12;
      const annualNursingFees = monthlyNursingFees * 12;
      const annualCostSavingsAt888 = monthlyCostSavingsAt74 * 12;

      return {
        ...item,
        monthlyMedicareBillings,
        monthlySoftwareFees,
        monthlyNursingFees,
        monthlyNetToMD,
        monthlyCostSavingsAt74,
        annual99490Potential,
        annualSoftwareFees,
        annualNursingFees,
        annualCostSavingsAt888,
      };
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (partenerTreeData && selectedGroupId) {
        const groupLocationId = partenerTreeData[0]?.group?.find(
          (ele) => ele.id === selectedGroupId
        )?.groupLocationId;
        if (groupLocationId) {
          await GroupWiseLocation(groupLocationId);
        }
      }
    };
    fetchData();
  }, [reportSelection.selectedYear, selectedGroupId]);

  useEffect(() => {
    if (reportSelection.selectedYear && selectedGroupId) {
      getQualityHomeData();
    }
  }, [groupWiseLocationData]);

  const getQualityHomeData = async () => {
    setIsLoading(true);
    await ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.CCMGroupAnalysics}${selectedGroupId}/${reportSelection.selectedYear}/${status}`
      )
      .then((response) => {
        setCCMGroupAnalysicsData(response.data);
        const preparedData = calculateData(
          response.data,
          onboard,
          softwareFeeConst,
          nursingFeeConst,
          mothlyCostSaveConst
        );

        setRowData(preparedData);
        
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const GroupWiseLocation = async (groupLocationId) => {
    setIsLoading(true);
    await ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.GroupWiseLocation}${groupLocationId}`
      )
      .then((response) => {
        setGroupWiseLocationData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleFirstConstChange = (value) => {
    setOnboard(value);
    const preparedData = calculateData(
      ccGroupAnalysicsData,
      value,
      softwareFeeConst,
      nursingFeeConst,
      mothlyCostSaveConst
    );
    setRowData(preparedData);
  };
  const handleSecondConstChange = (value) => {
    setSoftwareFeeConst(value);
    const preparedData = calculateData(
      ccGroupAnalysicsData,
      onboard,
      value,
      nursingFeeConst,
      mothlyCostSaveConst
    );
    setRowData(preparedData);
  };
  const handleThirdConstChange = (value) => {
    setNursingFeeConst(value);
    const preparedData = calculateData(
      ccGroupAnalysicsData,
      onboard,
      softwareFeeConst,
      value,
      mothlyCostSaveConst
    );
    setRowData(preparedData);
  };

  const handleMonthCostSaving = (value) => {
    if (!value) {
      setMothlyCostSaveConst("");
    } else {
      setMothlyCostSaveConst(value);
    }
    const preparedData = calculateData(
      ccGroupAnalysicsData,
      onboard,
      softwareFeeConst,
      nursingFeeConst,
      value
    );
    setRowData(preparedData);
  };

  const exportToExcel = (data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(dataBlob);
    link.download = `${fileName}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const setStatusSpeciality = (value) => {
  
    setStatus(value);
  };


  useEffect(() => {
    if (status !== null) {
      getQualityHomeData();
    }
  }, [status]); 

  return (
    <Box style={{ marginTop: "15px" }}>
      <Grid justify="space-between" my={5}>
        <Grid.Col span={9}>
          <Text color="green" py={2} fw={500}>
            Number of Records:
            <span style={{ color: "black" }}>
              {ccGroupAnalysicsData?.length}
            </span>
            <Button
              ml={20}
              type="primary"
              id="export-button"
              onClick={() => exportToExcel(rowData, "CCMGroupAnalysicsData")}
            >
              Export to Excel
            </Button>
           
          </Text>

        </Grid.Col>

        <Grid.Col span={3} py={4} >
            <Select
              label="Speciality"
              placeholder="Select Status"
              value={status}
              data={[
                { value: 0, label: "All" },
                { value: 1, label: "Active" },
                { value: 2, label: "InActive" },
              ]}
              onChange={setStatusSpeciality}
            />
          </Grid.Col>


        <Grid.Col span={2}>
          <TextInput
            label="MAC Location "
            value={groupWiseLocationData?.name}
            readOnly
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <TextInput
            label="99490 "
            value={groupWiseLocationData?.amount}
            readOnly
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Select
            label="Onboard Percentage"
            placeholder="Select Type"
            value={onboard}
            // {...form.getInputProps("finalCalType")}
            onChange={handleFirstConstChange}
            data={[
              { label: "10%", value: 0.10 },
              { label: "20%", value: 0.20 },
              { label: "30%", value: 0.30 },
              { label: "40%", value: 0.40 },
              { label: "50%", value: 0.50 },
              { label: "60%", value: 0.60 },
              { label: "70%", value: 0.70 },
              { label: "80%", value: 0.80 },
              { label: "90%", value: 0.90 },
              { label: "100%", value: 1.00 },
            ]}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Select
            label="Software Fees"
            placeholder="Select Measure"
            onChange={handleSecondConstChange}
            value={softwareFeeConst}
            // {...form.getInputProps("reqId")}
            data={[
              { label: "$4.00", value: 4 },
              { label: "$5.00", value: 5 },
              { label: "$6.00", value: 6 },
            ]}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Select
            label="Nursing Fees"
            placeholder="Select Formula"
            onChange={handleThirdConstChange}
            value={nursingFeeConst}
            // {...form.getInputProps("formulaId")}
            data={[
              { label: "50%", value: 0.5 },
              { label: "60%", value: 0.6 },
              { label: "70%", value: 0.7 },
            ]}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <TextInput
            label="Monthly Saving "
            value={mothlyCostSaveConst}
            onChange={(e) => handleMonthCostSaving(e.target.value)}
          />
        </Grid.Col>
        {/* <Grid.Col span={2} align="end" mt={25}>
          <Button
            type="submit"
            disabled={tableData.length == 1 && !form.values.isMulti}
          >
            Add Formula
          </Button>
        </Grid.Col> */}
      </Grid>
      <MantineReactTable
        columns={columnDef}
        data={rowData ?? []}
        state={{ isLoading: loading, columnVisibility }}
        enableHiding={true}
        onColumnVisibilityChange={handleColumnVisibilityChange}
      />
    </Box>
  );
};

export default CcmGroupAnalysis;
