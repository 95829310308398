import { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Box, Button, LoadingOverlay, Tooltip } from "@mantine/core";
import { RouteLink } from "../../Constant/RouterLink";
import { useCallback } from "react";
import useAxios from "../../Shared/useApi";
import { ApiLink } from "../../Constant/ApiLink";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import CalendarDesptModel from "./CalendarDesptModel";

const localizer = momentLocalizer(moment);

const ActionCalenterView = () => {
  const navigate = useNavigate();
  const { data } = useLocation().state;
  const selectedGroupId = useSelector((store) => store.partenerTree.selectedGroupId);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [records, setRecords] = useState('');
  const {user}  = useSelector(store => store.auth);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [events, setEvents] = useState([
    // {
    //   start: moment().toDate(),
    //   end: moment().toDate(),
    //   title: "This is Target event 01 which is use to show only events",
    // },
  ]);


  const backtoActionItem = () => {
     navigate(`${RouteLink.ActionItemList}/${user.level === 3 ? reportSelection.clinicId : 'all'}`);
  };

  function generateRandomHexColor() {
    // Generate a random hex color code
    const letters = '0123456789ABCDEF';
    let color = '#';

    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // const { response, error } = useAxios({
  //   method: "get",
  //   url: ApiLink.ActionItem + '/' + selectedGroupId,
  //   headers: null,
  //   body: null,
  // });

  useEffect(() => {
    setLoading(true)
    if (data) {
      let eventArray = []
      data.forEach((element, i) => {
        let eventObj = {}
        eventObj['start'] = moment(element.targetDate).toDate();
        eventObj['end'] = moment(element.targetDate).toDate();
        if(element.clinic != null){
          eventObj['title'] = element.clinic.name;
        }else{
          eventObj['title'] = element.group.name;
        }
        eventObj['rowInfo'] = element;

        let currentT = new Date().getTime();
        let targetDate = new Date(element?.targetDate).getTime();


        if(element.assignstatus === "NotStarted" && currentT < targetDate){
          eventObj['hexColor'] = 'green'
        }
        if(element.assignstatus === "NotStarted" && currentT > targetDate){
          eventObj['hexColor'] = 'red'
        }

        if(element.assignstatus === "InProcess" && currentT < targetDate){
          eventObj['hexColor'] = 'green'
        }
        if(element.assignstatus === "InProcess" && currentT > targetDate)
        {
          eventObj['hexColor'] = 'red'
        }

        if(element.assignstatus === "OpenIssues" && currentT < targetDate){
          eventObj['hexColor'] = 'red'
        }
        if(element.assignstatus === "OpenIssues" && currentT > targetDate){
          eventObj['hexColor'] = 'green'
        }

        if(element.assignstatus === "Completed"){
          eventObj['hexColor'] = 'gray'
        }
       

          
         
        eventArray.push(eventObj)
      });
      setEvents(eventArray)
      setLoading(false)
    }
  }, [data]);




  const handleSelectEvent = useCallback(
    (event) => {
      // window.alert(event.title)
      setIsModalOpen(true);
     
      setRecords(event.rowInfo)
    },

    []
  )

  const showToolTip = (event) => {
    
  }

  return (
    <Box>
      {loading &&
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      }
      <Button my={20} type="primary" onClick={() => backtoActionItem()}>
        Back
      </Button>
      <Calendar
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={events}
        style={{ height: "90vh" }}
        onSelectEvent={handleSelectEvent}
        tooltipAccessor={null}
        eventPropGetter={(event, start, end, isSelected) => {
          var backgroundColor = event.hexColor;
          let newStyle = {
            backgroundColor: backgroundColor,
            color: "white",
            borderRadius: "5px",
            border: "none",
          };

          if (event.isMine) {
            newStyle.backgroundColor = "lightgreen";
          }

          if (isSelected) {
            newStyle.border = "2px solid green";
          }

          return {
            className: "",
            style: newStyle,
          };
        }}

        components={{
        event: ({ event }) => (
          <Tooltip
            multiline
            w={400} 
            label={event.rowInfo.descriptions} 
            position="top"
            withArrow
            transition="slide-up"
          >
            <div style={{ cursor: "pointer" }}>
              {event.rowInfo.clinic != null && event.rowInfo.clinic.name}
              {event.rowInfo.group != null && event.rowInfo.group.name}
            </div>
          </Tooltip>
        ),
      }}
      />

      {isModalOpen && records && (
        <CalendarDesptModel
          records={records}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  );
};

export default ActionCalenterView;
