import { useLocation, useNavigate } from "react-router";
import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Select,
  TextInput,
  Text,
  Table,
  LoadingOverlay,
  Stack,
} from "@mantine/core";
import { Grid } from "@mantine/core";
import { ApiLink } from "../../Constant/ApiLink";
import axios from "axios";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useDispatch, useSelector } from "react-redux";
import {
  addClinicId,
  addScallingFactor,
  addClinicLevel,
} from "../../utils/selectedClinicSlice";
import { CcmReportColumn } from "./Columns/CcmReportColumn";
import * as XLSX from "xlsx";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const CCM = () => {
  var navigate = useNavigate();
  const SelectedClinicList = useSelector(
    (store) => store.partenerTree.selectedGroupClnics
  );
  const [loading, setloading] = useState(false);
  const [ccmReportData, setCcmReportData] = useState([]); //table row
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [uniqueSpeciality, setUniqueSpeciality] = useState([]);
  const [status, setStatus] = useState(1);

  const columnDefs = useMemo(() => [
    {
      header: "Name",
      accessorKey: "phyName",
      Footer: () => <Stack>Total</Stack>,
    },
    {
      header: "Total",
      accessorKey: "totalBenes",
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce((acc, curr) => acc + curr.totalBenes, 0)}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Specialty",
      accessorKey: "speciality",

      filterVariant: "select",
      mantineFilterSelectProps: {
        data: uniqueSpeciality,
      },
    },
    {
      header: "Atrial Fibrillation",
      accessorKey: "atrialFibrillation",
      accessorFn: (row) => {
        return Math.floor((row.atrialFibrillation / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(
                  acc + (curr.atrialFibrillation / 100) * curr.totalBenes
                ),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Alzheimer",
      accessorKey: "alzheimer",
      accessorFn: (row) => {
        return Math.floor((row.alzheimer / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.alzheimer / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Asthma",
      accessorKey: "asthma",
      accessorFn: (row) => {
        return Math.floor((row.asthma / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.asthma / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Heart Failure",
      accessorKey: "heartFailure",
      accessorFn: (row) => {
        return Math.floor((row.heartFailure / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.heartFailure / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Kidney Disease",
      accessorKey: "kidneyDisease",
      accessorFn: (row) => {
        return Math.floor((row.kidneyDisease / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.kidneyDisease / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Chronic Obstructive Pulmonary Disease",
      accessorKey: "chronicObstructivPpulmonaryDisease",
      accessorFn: (row) => {
        return Math.floor(
          (row.chronicObstructivPpulmonaryDisease / 100) * row.totalBenes
        );
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(
                  acc +
                    (curr.chronicObstructivPpulmonaryDisease / 100) *
                      curr.totalBenes
                ),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Cancer",
      accessorKey: "cancer",
      accessorFn: (row) => {
        return Math.floor((row.cancer / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.cancer / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Diabetes",
      accessorKey: "diabetes",
      accessorFn: (row) => {
        return Math.floor((row.diabetes / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.diabetes / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Depression",
      accessorKey: "depression",
      accessorFn: (row) => {
        return Math.floor((row.depression / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.depression / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Hyperlipidemia",
      accessorKey: "hyperlipidemia",
      accessorFn: (row) => {
        return Math.floor((row.hyperlipidemia / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.hyperlipidemia / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Hypertension",
      accessorKey: "hypertension",
      accessorFn: (row) => {
        return Math.floor((row.hypertension / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.hypertension / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Ischemic Heart Disease",
      accessorKey: "ischemicHeartDisease",
      accessorFn: (row) => {
        return Math.floor((row.ischemicHeartDisease / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(
                  acc + (curr.ischemicHeartDisease / 100) * curr.totalBenes
                ),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Osteoporosis",
      accessorKey: "osteoporosis",
      accessorFn: (row) => {
        return Math.floor((row.osteoporosis / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.osteoporosis / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Retinal Artery Occlusion",
      accessorKey: "retinalArteryOcclusion",
      accessorFn: (row) => {
        return Math.floor((row.retinalArteryOcclusion / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(
                  acc + (curr.retinalArteryOcclusion / 100) * curr.totalBenes
                ),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Stroke",
      accessorKey: "stroke",
      accessorFn: (row) => {
        return Math.floor((row.stroke / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.stroke / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Schizoid",
      accessorKey: "schizoid",
      accessorFn: (row) => {
        return Math.floor((row.schizoid / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.schizoid / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Max Diab & /COPD",
      accessorKey: "maxdiabcopd",
      accessorFn: (row) => {
        return Math.floor((row.maxdiabcopd / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.maxdiabcopd / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Max Heart Failure & Ischemic",
      accessorKey: "maxheartfailureischemic",
      accessorFn: (row) => {
        return Math.floor((row.maxheartfailureischemic / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(
                  acc + (curr.maxheartfailureischemic / 100) * curr.totalBenes
                ),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Max Cancer & Dprssn",
      accessorKey: "maxcancerdprssn",
      accessorFn: (row) => {
        return Math.floor((row.maxcancerdprssn / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(
                  acc + (curr.maxcancerdprssn / 100) * curr.totalBenes
                ),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Max Osteo and Arthritis",
      accessorKey: "maxosteoandarthritis",
      accessorFn: (row) => {
        return Math.floor((row.maxosteoandarthritis / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(
                  acc + (curr.maxosteoandarthritis / 100) * curr.totalBenes
                ),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Max Diab and HyperT",
      accessorKey: "maxdiabandhyperT",
      accessorFn: (row) => {
        return Math.floor((row.maxdiabandhyperT / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(
                  acc + (curr.maxdiabandhyperT / 100) * curr.totalBenes
                ),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Max Kidney & Diab",
      accessorKey: "maxkidneydiab",
      accessorFn: (row) => {
        return Math.floor((row.maxkidneydiab / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.maxkidneydiab / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Max Hyperlipid & Hypertension",
      accessorKey: "maxhyperlipidhypertension",
      accessorFn: (row) => {
        return Math.floor(
          (row.maxhyperlipidhypertension / 100) * row.totalBenes
        );
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(
                  acc + (curr.maxhyperlipidhypertension / 100) * curr.totalBenes
                ),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "ADHD",
      accessorKey: "adhd",
      accessorFn: (row) => {
        return Math.floor((row.adhd / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.adhd / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Alcohol Drug",
      accessorKey: "alcohol_Drug",
      accessorFn: (row) => {
        return Math.floor((row.alcohol_Drug / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.alcohol_Drug / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Tobacco",
      accessorKey: "tobacco",
      accessorFn: (row) => {
        return Math.floor((row.tobacco / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.tobacco / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Anxiety",
      accessorKey: "anxiety",
      accessorFn: (row) => {
        return Math.floor((row.anxiety / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.anxiety / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Bipolar",
      accessorKey: "bipolar",
      accessorFn: (row) => {
        return Math.floor((row.bipolar / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.bipolar / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Mood",
      accessorKey: "mood",
      accessorFn: (row) => {
        return Math.floor((row.mood / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.mood / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "PD",
      accessorKey: "pd",
      accessorFn: (row) => {
        return Math.floor((row.pd / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.pd / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "PTSD",
      accessorKey: "ptsd",
      accessorFn: (row) => {
        return Math.floor((row.ptsd / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.ptsd / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Non IHD",
      accessorKey: "non_IHD",
      accessorFn: (row) => {
        return Math.floor((row.non_IHD / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.non_IHD / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Parkinson",
      accessorKey: "parkinson",
      accessorFn: (row) => {
        return Math.floor((row.parkinson / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.parkinson / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Arthritis",
      accessorKey: "arthritis",
      accessorFn: (row) => {
        return Math.floor((row.arthritis / 100) * row.totalBenes);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce(
              (acc, curr) =>
                Math.floor(acc + (curr.arthritis / 100) * curr.totalBenes),
              0
            )}
          </Box>
        </Stack>
      ),
    },
  ]);

  // const table = useMantineReactTable({
  //   columns: columnDefs,
  //   data: ccmReportData,
  //   // enableColumnResizing: true,
  //   // enableGrouping: true,
  //   // enableStickyHeader: true,
  //   enableStickyFooter: true,
  //   initialState: {
  //     density: 'xs',
  //     expanded: true,
  //     grouping: ['state'],
  //     pagination: { pageIndex: 0, pageSize: 20 },
  //     sorting: [{ id: 'state', desc: false }],
  //   },
  //   mantineToolbarAlertBannerBadgeProps: { color: 'blue', variant: 'outline' },
  //   mantineTableContainerProps: { sx: { maxHeight: 700 } },
  // });

  useEffect(() => {
    CallReport(reportSelection.clinicId, true, status);
  }, [reportSelection]);

  const dispatch = useDispatch();
  const onClinicSlelect = (value) => {
    dispatch(addClinicId(value));
    dispatch(
      addClinicLevel(
        SelectedClinicList?.find((ele) => ele.value === value)?.label
      )
    );
    setCcmReportData([]);
    CallReport(value, true);
  };

  const CallReport = (value, isClinicChange, sts) => {
    let customURL;
    if (isClinicChange) {
      customURL = `${process.env.REACT_APP_API_URL}${ApiLink.Get_CCMReports}/${value}/${reportSelection.selectedYear}/${sts}`;
    } else {
      customURL = `${process.env.REACT_APP_API_URL}${ApiLink.Get_CCMReports}/${reportSelection.clinicId}/${reportSelection.selectedYear}/${sts}`;
    }
    if (value) {
      setloading(true);
      ApiCall.axiosInstance
        .get(customURL)
        .then((response) => {
          setCcmReportData(response.data);
          const uniqueSpeciality = [
            ...new Set(response?.data.map((item) => item?.speciality)),
          ];
          setUniqueSpeciality(uniqueSpeciality);
          setloading(false);
        })
        .catch((error) => {
          console.error("Error Clinic fetching data:", error);
          setloading(false);
        });
    }
  };

  // const exportToExcel = () => {
  //   const table = document.querySelector(".mantine-Table-root");
  //   const ws = XLSX.utils.table_to_sheet(table);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   XLSX.writeFile(wb, "ccm.xlsx");
  // };

  const excludePropertiesFromArray = (array) => {
    const excludedProperties = [
      "id",
      "ccmName",
      "phyEnrollmentId",
      "phyNPI",
      "clinTin",
      "rndrng_Prvdr_Type",
      "basicMedicare",
      "ccCombination",
    ];
    return array.map((obj) => {
      const filteredObj = {};
      
      Object.keys(obj).forEach((key) => {
        if (!excludedProperties.includes(key)) {
          if (key === "atrialFibrillation") {
            filteredObj[key] = Math.floor(
              (obj.atrialFibrillation / 100) * obj.totalBenes
            );
          } else if (key === "alzheimer") {
            filteredObj[key] = Math.floor(
              (obj.alzheimer / 100) * obj.totalBenes
            );
          } else if (key === "asthma") {
            filteredObj[key] = Math.floor((obj.asthma / 100) * obj.totalBenes);
          } else if (key === "heartFailure") {
            filteredObj[key] = Math.floor(
              (obj.heartFailure / 100) * obj.totalBenes
            );
          } else if (key === "kidneyDisease") {
            filteredObj[key] = Math.floor(
              (obj.kidneyDisease / 100) * obj.totalBenes
            );
          } else if (key === "chronicObstructivPpulmonaryDisease") {
            filteredObj[key] = Math.floor(
              (obj.chronicObstructivPpulmonaryDisease / 100) * obj.totalBenes
            );
          } else if (key === "cancer") {
            filteredObj[key] = Math.floor((obj.cancer / 100) * obj.totalBenes);
          } else if (key === "diabetes") {
            filteredObj[key] = Math.floor(
              (obj.diabetes / 100) * obj.totalBenes
            );
          } else if (key === "depression") {
            filteredObj[key] = Math.floor(
              (obj.depression / 100) * obj.totalBenes
            );
          } else if (key === "hyperlipidemia") {
            filteredObj[key] = Math.floor(
              (obj.hyperlipidemia / 100) * obj.totalBenes
            );
          } else if (key === "hypertension") {
            filteredObj[key] = Math.floor(
              (obj.hypertension / 100) * obj.totalBenes
            );
          } else if (key === "ischemicHeartDisease") {
            filteredObj[key] = Math.floor(
              (obj.ischemicHeartDisease / 100) * obj.totalBenes
            );
          } else if (key === "osteoporosis") {
            filteredObj[key] = Math.floor(
              (obj.osteoporosis / 100) * obj.totalBenes
            );
          } else if (key === "retinalArteryOcclusion") {
            filteredObj[key] = Math.floor(
              (obj.retinalArteryOcclusion / 100) * obj.totalBenes
            );
          } else if (key === "stroke") {
            filteredObj[key] = Math.floor((obj.stroke / 100) * obj.totalBenes);
          } else if (key === "schizoid") {
            filteredObj[key] = Math.floor(
              (obj.schizoid / 100) * obj.totalBenes
            );
          } else if (key === "maxdiabcopd") {
            filteredObj[key] = Math.floor(
              (obj.maxdiabcopd / 100) * obj.totalBenes
            );
          } else if (key === "maxheartfailureischemic") {
            filteredObj[key] = Math.floor(
              (obj.maxheartfailureischemic / 100) * obj.totalBenes
            );
          } else if (key === "maxcancerdprssn") {
            filteredObj[key] = Math.floor(
              (obj.maxcancerdprssn / 100) * obj.totalBenes
            );
          } else if (key === "maxosteoandarthritis") {
            filteredObj[key] = Math.floor(
              (obj.maxosteoandarthritis / 100) * obj.totalBenes
            );
          } else if (key === "maxdiabandhyperT") {
            filteredObj[key] = Math.floor(
              (obj.maxdiabandhyperT / 100) * obj.totalBenes
            );
          } else if (key === "maxkidneydiab") {
            filteredObj[key] = Math.floor(
              (obj.maxkidneydiab / 100) * obj.totalBenes
            );
          } else if (key === "maxhyperlipidhypertension") {
            filteredObj[key] = Math.floor(
              (obj.maxhyperlipidhypertension / 100) * obj.totalBenes
            );
          } else if (key === "adhd") {
            filteredObj[key] = Math.floor((obj.adhd / 100) * obj.totalBenes);
          } else if (key === "alcohol_Drug") {
            filteredObj[key] = Math.floor(
              (obj.alcohol_Drug / 100) * obj.totalBenes
            );
          } else if (key === "tobacco") {
            filteredObj[key] = Math.floor((obj.tobacco / 100) * obj.totalBenes);
          } else if (key === "anxiety") {
            filteredObj[key] = Math.floor((obj.anxiety / 100) * obj.totalBenes);
          } else if (key === "bipolar") {
            filteredObj[key] = Math.floor((obj.bipolar / 100) * obj.totalBenes);
          } else if (key === "mood") {
            filteredObj[key] = Math.floor((obj.mood / 100) * obj.totalBenes);
          } else if (key === "pd") {
            filteredObj[key] = Math.floor((obj.pd / 100) * obj.totalBenes);
          } else if (key === "ptsd") {
            filteredObj[key] = Math.floor((obj.ptsd / 100) * obj.totalBenes);
          } else if (key === "non_IHD") {
            filteredObj[key] = Math.floor((obj.non_IHD / 100) * obj.totalBenes);
          } else if (key === "parkinson") {
            filteredObj[key] = Math.floor(
              (obj.parkinson / 100) * obj.totalBenes
            );
          } else if (key === "arthritis") {
            filteredObj[key] = Math.floor(
              (obj.arthritis / 100) * obj.totalBenes
            );
          } else {
            filteredObj[key] = obj[key];
          }
        }
      });
      return filteredObj;
    });
  };

  function mapColumnHeaders(columnDef) {
    return columnDef.map((column) => ({
      key: column.accessorKey,
      displayLabel: column.header,
    }));
  }

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: false,
    columnHeaders: mapColumnHeaders(columnDefs),
    filename: "CCM_Result",
  });

  const handleExportData = () => {
    const logData = excludePropertiesFromArray(ccmReportData);
    const csv = generateCsv(csvConfig)(logData);
    download(csvConfig)(csv);
  };


  const setStatusSpeciality = (value) => {
    
    setStatus(value);
  };


  useEffect(() => {
    if (status !== null) {
      CallReport(reportSelection.clinicId, true, status);
    }
  }, [status]); 

  return (
    <Box style={{ marginTop: "15px" }}>
      {loading && (
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      )}

      <Box
        className="mycontainer"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Text size="lg">
          CCM{" "}
          {reportSelection.clinicEnrollLavel && (
            <span>({reportSelection.clinicEnrollLavel})</span>
          )}
        </Text>
      </Box>
      <br />
      <Grid>
        <Grid.Col span={4}>
          <Text color="green" py={2} fw={500}>
            Number of Records:
            <span style={{ color: "black" }}>{ccmReportData?.length}</span>
            <Button
              ml={20}
              type="primary"
              id="export-button"
              onClick={handleExportData}
            >
              Export to Excel
            </Button>
          </Text>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={4}>
          <Select
            size="xs"
            label="Attestation Clinic"
            withAsterisk
            placeholder="Choose One"
            data={SelectedClinicList}
            value={reportSelection.clinicId}
            onChange={onClinicSlelect}
          />
        </Grid.Col>
        <Grid.Col span={3} py={4} >
            <Select
              label="Speciality"
              placeholder="Select Status"
              value={status}
              data={[
                { value: 0, label: "All" },
                { value: 1, label: "Active" },
                { value: 2, label: "InActive" },
              ]}
              onChange={setStatusSpeciality}
            />
          </Grid.Col>
      </Grid>
      <Divider my="sm" />

      <MantineReactTable
        id="ccm"
        columns={columnDefs}
        data={ccmReportData ?? []}
        state={{ isLoading: loading }}
        enableStickyHeader={true}
        // enableRowActions
        // positionActionsColumn='first'
        initialState={{
          columnVisibility: { firstName: false, lastName: false },
        }}
        mantinePaperProps={{ shadow: "0", withBorder: false, density: "xs" }}
      />
    </Box>
  );
};
export default CCM;
