import {
  ActionIcon,
  Avatar,
  Box,
  Divider,
  Grid,
  Header,
  NativeSelect,
  Select,
  Text,
  TextInput,
  createStyles,
} from "@mantine/core";
import PropTypes from "prop-types";
import { IconLogout, IconMan, IconRefresh, IconStar, IconUser } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { MultiLevelSelect } from "../components/MultiLevelSelect";
import { YearPicker, YearPickerInput } from "@mantine/dates";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  addClinicForSeletedGroup,
  replacePartnerTreeItem,
  setSelectedGrupId,
  addGroupDropDownList,
  addGuiData,
  GetClinicGroup,
} from "../utils/patenerTreeSlice";
import {
  addClinicId,
  addClinicLevel,
  addSelectedYear,
} from "../utils/selectedClinicSlice";

import axios from "axios";
import { ApiLink } from "../Constant/ApiLink";
import { useNavigate } from "react-router";
import { logout } from "../Pages/Login/AuthSlice";
import { UserMenu } from "./UserMenu";

const useStyles = createStyles((theme) => ({
  header: {
    padding: theme.spacing.md,
    background: "#f8f9fa",
    color: theme.black,
  },
  select: {
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "nowrap",
  },
}));

export const AdminHeader = ({ burger }) => {
  const { classes } = useStyles();
  var navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggleClinic, setToggleClinic] = useState(true);

  // sajid define
  const SelectedClinicList = useSelector(
    (store) => store.partenerTree.selectedGroupClnics
  );
  const groupDropDownItem = useSelector(
    (store) => store.partenerTree.groupDropDownList
  );
  const SelectedGroupID = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );

  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const {disableYear,clinicGroupDetails} = useSelector((store) => store.partenerTree);

  const partenerTreeItems = useSelector(
    (store) => store.partenerTree.partenerTreeItem
  );
  const editOnChnage = useSelector(
    (store) => store.selectedClinic.changeOnEdit
  );
  const { isLoggedIn, user } = useSelector(store => store.auth);

  useEffect(() => {
    onlySetClinicDropDown();
  }, [editOnChnage]);

  useEffect(() => {
    if (user && partenerTreeItems && user.organizationId && !clinicGroupDetails.groupName) {
      if (user.level == 2)
        setClinicOnGroupChange(user.organizationId);
      else if (user.level == 3)
      {
        dispatch(GetClinicGroup(parseInt(user.organizationId)));
      }
    }
    else if(user && partenerTreeItems && clinicGroupDetails && user.level == 3 )
    {
      setClinicOnGroupChange(clinicGroupDetails.groupId);
      onClinicSlelect(parseInt(clinicGroupDetails.clinicId));
    }
  }, [partenerTreeItems, user,clinicGroupDetails]);

  
  
  const onlySetClinicDropDown = () => {
    if (partenerTreeItems) {
      const selectedGroupClinicItem = partenerTreeItems[0]?.group?.filter(
        (ele) => ele.id == SelectedGroupID
      );
      if (selectedGroupClinicItem) {
        dispatch(addClinicForSeletedGroup(selectedGroupClinicItem[0]?.clinic));
      }
    }
  };

  const filterClinicsByGroupId = (groupId) => {
    const filteredClinics = partenerTreeItems.reduce((filtered, item) => {
      if (item.group && Array.isArray(item.group)) {
        const selectedGroup = item.group.find((group) => group.id == groupId);

        if (selectedGroup && selectedGroup.clinic) {
          filtered.push(...selectedGroup.clinic);
        }
      }
      return filtered;
    }, []);
    return filteredClinics;
  };

  const setClinicOnGroupChange = (id) => {
    const selectedGroupItem = filterClinicsByGroupId(id);
    if (selectedGroupItem) {
      dispatch(addClinicForSeletedGroup(selectedGroupItem));
      dispatch(setSelectedGrupId(id));
      dispatch(addClinicId(selectedGroupItem[0]?.id));
      localStorage.setItem("clinicId", selectedGroupItem[0]?.id);
      localStorage.setItem(
        "selectedClinicList",
        JSON.stringify(selectedGroupItem)
      );
    }
    localStorage.setItem("groupId", id);
  };

  // sajid define
  const onClinicSlelect = (value) => {
    dispatch(addClinicId(value));
    dispatch(
      addClinicLevel(
        SelectedClinicList?.find((ele) => ele.value === value)?.label
      )
    );
    localStorage.setItem("clinicId", value);
  };

  const handleYearSelection = (value) => {
    const year = value?.getFullYear();
    dispatch(addSelectedYear(year));
    localStorage.setItem("year", year);
  };

  const logoutUser = () => {
    localStorage.clear();
    navigate("/");
    dispatch(logout());
    dispatch(replacePartnerTreeItem([]));
  };

  useEffect(() => {
    setToggleClinic(false);
    setTimeout(() => {
      setToggleClinic(true);
    });
  }, [SelectedClinicList]);

  return (
    <Header height={60} withBorder={false} className={classes.header}>
      <Grid className={classes.grid}>
        {burger && burger}
        <Box sx={{ flex: 1 }} />

        {/* {partenerTreeItems && <MultiLevelSelect partenerTreeItems= {partenerTreeItems} />} */}
        <>
          <Text sx={{ lineHeight: '2' }} fw={700}> Group: </Text>
          {

            user && user.level < 2 ?
              <Select
                ml={10}
                size="sm"
                placeholder="Select Group"
                withinPortal
                value={SelectedGroupID}
                onChange={(v) => v && setClinicOnGroupChange(v)}
                data={groupDropDownItem}
                searchable
              /> :
              <Text sx={{ lineHeight: '2', paddingLeft: 5 }}>{partenerTreeItems[0]?.group.filter((ele) => ele.id == SelectedGroupID)[0]?.name}</Text>

          }
        </>
        <Divider orientation="vertical" sx={{ marginLeft: 10 }} />
        {toggleClinic && (
          <>
            <Text sx={{ paddingLeft: 5, lineHeight: '2' }} fw={700}> Clinic: </Text>
            {
              user && user.level < 3 ?
                <Select
                  size="sm"
                  withAsterisk
                  placeholder="Choose One"
                  data={SelectedClinicList}
                  value={reportSelection.clinicId}
                  onChange={onClinicSlelect}
                  style={{ width: "350px" }}
                  ml={10}
                  searchable
                  disabled={user.level > 2}
                /> :
                <Text sx={{ lineHeight: '2', paddingLeft: 5 }}>{clinicGroupDetails.clinicName}</Text>
            }
          </>
        )}
        <Divider orientation="vertical" sx={{ marginLeft: 10, marginRight: 10 }} />
        <YearPickerInput
          mx={2}
          disabled={disableYear}
          placeholder="Pick Year"
          value={new Date(reportSelection.selectedYear + "-12-31")}
          onChange={(v) => handleYearSelection(v)}
          withAsterisk
        />
        {/* <Select
          ml={10}
          value={value}
          size="sm"
          withinPortal
         
          data={[
            { value: '2021', label: '2021' },
            { value: '2022', label: '2022' },
            { value: '2023', label: '2023' },
          ]}
        /> */}

        {/* <ActionIcon ml={5} mt={3}>
          <IconLogout size="1.25rem" onClick={logoutUser} />
        </ActionIcon> */}
        {isLoggedIn === true ? <>
          <UserMenu user={user} />
        </>
          : "User Not Logged In"/* showing slice items lenght */}

      </Grid>
    </Header>
  );
};

AdminHeader.propTypes = {
  burger: PropTypes.node,
};
