import { createSlice } from "@reduxjs/toolkit";

// this is cart slice
const selectedClinicSlice = createSlice({
  name: "selectedClinic",
  initialState: {
    item: {
      clinicId: "",
      scallingFactor: 0.6,
      clinicEnrollLavel: "",
      selectedYear: "",
    },
    changeOnEdit: [],
    selectedClinicEntityDDD: [], //DD: drop down data
  },
  reducers: {
    add: (state, action) => {
      state.item = action.payload;
    },

    addClinicId: (state, action) => {
      state.item.clinicId = action.payload;
    },

    addScallingFactor: (state, action) => {
      state.item.scallingFactor = action.payload;
    },

    addClinicLevel: (state, action) => {
      state.item.clinicEnrollLavel = action.payload;
    },

    addSelectedYear: (state, action) => {
      state.item.selectedYear = action.payload;
    },

    addSelectedClinicEntityDDD: (state, action) => {
      state.selectedClinicEntityDDD = action.payload;
    },

    clear: (state) => {
      state.item = { clinicEnrollmentId: "", scallingFactor: 0.6 };
    },

    addChangeOnEdit : (state, action) => {
      state.changeOnEdit.push(action.payload);
      if(state.changeOnEdit.length > 2){
        state.changeOnEdit.shift();
      }
    }
  },
});

export const {
  add,
  addClinicId,
  addScallingFactor,
  clear,
  addClinicLevel,
  addSelectedYear,
  addSelectedClinicEntityDDD,
  addChangeOnEdit
} = selectedClinicSlice.actions;
export default selectedClinicSlice.reducer;
