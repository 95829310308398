import { Modal, Button, Group, Text } from "@mantine/core";
import { IconTrash, IconTrashOff } from "@tabler/icons-react";

export function DeleteConfirmationModal({ opened, close, open, IsConfirmed, deletemsg }) {
  function closing() {
    IsConfirmed(false);
    close();
  }

  function Confirmed(_is) {
    IsConfirmed(_is);
    close();
  }
  return (
    <>
      <Modal
        opened={opened}
        onClose={closing}
        size="auto"
        bg="red"
        title="Delete Confirmation"
      >
        {!deletemsg && <Text>Are You Want To Delete this Entry...</Text>}
        {deletemsg && <Text>{deletemsg}</Text>}
        

        <Group mt="xl">
          <Button variant="outline" color="red" onClick={() => Confirmed(true)}>
            <IconTrash /> Confirm
          </Button>
          <Button
            variant="outline"
            color="green"
            onClick={() => Confirmed(false)}
          >
            <IconTrashOff /> Cancel
          </Button>
        </Group>
      </Modal>
    </>
  );
}
