import { Button, Group, Modal, Text } from "@mantine/core";
import { IconTrash, IconTrashOff } from "@tabler/icons-react";
import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

const ConfirmDialog = createContext();

export function ConfirmDialogProvider({ children }) {
  const [state, setState] = useState({ isOpen: false });
  const fn = useRef();

  const confirm = useCallback(
    (data) => {
      return new Promise((resolve) => {
        setState({ ...data, isOpen: true });
        fn.current = (choice) => {
          resolve(choice);
          setState({ isOpen: false });
        };
      });
    },
    [setState]
  );
  return (
    <ConfirmDialog.Provider value={confirm}>
      {children}
      {/* <Alert
        {...state}
        onClose={() => fn.current(false)}
        onConfirm={() => fn.current(true)}
      /> */}

      <Modal
        opened={state.isOpen}
        // onClose={closing}
        size="auto"
        bg="red"
        title={state.title}
        withCloseButton={false}
        withOverlay={false}
      >
        {state && <Text>{state?.description}</Text>}
        <Group mt="xl">
          <Button
            variant="outline"
            color="red"
            onClick={() => fn.current(true)}
          >
            <IconTrash /> Confirm
          </Button>
          <Button
            variant="outline"
            color="green"
            onClick={() => fn.current(false)}
          >
            <IconTrashOff /> Cancel
          </Button>
        </Group>
      </Modal>
    </ConfirmDialog.Provider>
  );
}

export default function useConfirm() {
  return useContext(ConfirmDialog);
}
