import { isRejectedWithValue } from "@reduxjs/toolkit";
import { ApiLink } from "../../Constant/ApiLink";
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Use your base URL
});

// Request interceptor to add Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    // Get your token from storage (e.g., localStorage, cookies, etc.)
    const token = localStorage.getItem("authToken"); // Adjust according to your token storage method

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; // Set the token in the Authorization header
    }

    return config; // Return the modified config
  },
  (error) => {
    return Promise.reject(error); // Handle error
  }
);

// const GetGroups = async () => {
//   const response = await axiosInstance.get(
//     `${process.env.REACT_APP_API_URL}${ApiLink.Group_Uri}/0`
//   );
//   return response.data;
// };

// const getPartners = async (queryParams = '') => {
//   const response = await axiosInstance.get(
//     `${process.env.REACT_APP_API_URL}${ApiLink.Partner_Uri}`
//   );
//   return response.data;
// };

// const getTins = async () => {
//   const response = await axiosInstance.get(
//     `${process.env.REACT_APP_API_URL}${ApiLink.Get_ShortClinics}`
//   );
//   return response.data;
// };

// const getUsers = async () => {
//   const response = await axiosInstance.get(
//     `${process.env.REACT_APP_API_URL}${ApiLink.User_Uri}`
//   );
//   return response.data;
// };

const GetGroups = async (groupId = 0, queryParams = '') => {
  const url = `${process.env.REACT_APP_API_URL}${ApiLink.Group_Uri}/${groupId}${queryParams}`;
  const response = await axiosInstance.get(url);
  return response.data;
};


const getTins = async (queryParams = '') => {
  const url = `${process.env.REACT_APP_API_URL}${ApiLink.Get_ShortClinics}${queryParams}`;
  const response = await axiosInstance.get(url);
  return response.data;
};

const getPartners = async (queryParams = '') => {
  const url = `${process.env.REACT_APP_API_URL}${ApiLink.Partner_Uri}${queryParams}`;
  const response = await axiosInstance.get(url);
  return response.data;
};

const getUsers = async (userlevel, id) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}${ApiLink.User_Uri}`,
    {
      params: {userlevel,id}
    }
  );
  return response.data;
};



const userLogin = async (credentials) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}${ApiLink.User_login}`,
      credentials
    );
    return response; // Assuming the response has the token and user information
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    throw err;
  }
};

const GetClinicGroup = async (clinicId) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}${ApiLink.Get_ClinicGroup}/${clinicId}`
  );
  return response;
};

export const ApiCall = {
  GetGroups,
  userLogin,
  getPartners,
  getTins,
  getUsers,
  axiosInstance,
  GetClinicGroup,
};
