import { Box, Group, Navbar as MantineNavbar, ScrollArea, createStyles, rem } from '@mantine/core';
import PropTypes from 'prop-types';

import { NavLinksGroup } from '../layout/NavLinksGroup';
import defaultLogo  from '../assets/logo.png';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { ApiCall } from '../Shared/ApiCall/ApiCall';
import { ApiLink } from '../Constant/ApiLink';

const useStyles = createStyles((theme) => ({
  navbar: {
    position: 'fixed',
    backgroundColor: theme.white,
    paddingBottom: 0,
    boxShadow: 'rgba(113, 122, 131, 0.11) 0px 7px 30px 0px',
  },

  links: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },
}));

export function Navbar({ data, hidden, burger }) {
  const { classes } = useStyles();
  const links = data.map((item) => <NavLinksGroup key={item.label} {...item} />);

  //
  const reportclinic = useSelector((store) => store.selectedClinic.item);
  const reportSelection = useSelector((store) => store.partenerTree);
  const selectedGroupId = useSelector((store) => store.partenerTree.selectedGroupId);
  const { user } = useSelector((state) => state.auth);
  const [logo, setLogo] = useState('');
  const lastFetchedLogoRef = useRef(null); // 🔁 to track last fetched file
  // main object with all data
  const partenerTreeItems = useSelector((state) => state.partenerTree.partenerTreeItem);

  // useEffect(() => {
  //   if (
  //     !user ||
  //     !reportSelection?.groupDropDownList?.length ||
  //     !partenerTreeItems?.length
  //   ) return;

  //   const fetchLogo = async (fileNameOrKeyword) => {
  //     if (!fileNameOrKeyword || !(
  //       fileNameOrKeyword.endsWith('.jpg') ||
  //       fileNameOrKeyword.endsWith('.jpeg') ||
  //       fileNameOrKeyword.endsWith('.png')
  //     ) ) {
  //       setLogo(defaultLogo);
  //       return;
  //     }

  //     // Avoid re-fetching if the same logo is already set
  //     if (lastFetchedLogoRef.current === fileNameOrKeyword) return;

  //     try {
  //       const response = await ApiCall.axiosInstance.get(
  //         `${process.env.REACT_APP_API_URL}${ApiLink.Download_Logo}/${fileNameOrKeyword}`,
  //         { responseType: 'blob' }
  //       );

  //       const imageUrl = URL.createObjectURL(response.data);
  //       setLogo(imageUrl);
  //       lastFetchedLogoRef.current = fileNameOrKeyword; // ✅ save last
  //     } catch (error) {
  //       console.error('Error fetching logo:', error);
  //       setLogo(defaultLogo);
  //     }
  //   };

  //   // Determine logo based on level
  //   let fileName = null;
   
  //   switch (user.level) {
  //     case 0:
  //       fileName = ''; // Admin → will fallback
  //       break;

  //     case 1:
  //       fileName = partenerTreeItems[0]?.logo;
  //       break;

  //     case 2: {
  //       const group = partenerTreeItems[0]?.group?.find(
  //         g => g?.id === Number(selectedGroupId)
  //       );
  //       fileName = group?.logo;
  //       break;
  //     }

  //     case 3: {
       
  //       const group = partenerTreeItems[0]?.group?.find(
  //         g => g.id === Number(selectedGroupId)
  //       );
  //       const clinic = group?.clinic?.find(c => c.id === reportclinic.clinicId);
  //       fileName = clinic?.logo;
  //       break;
  //     }

  //     default:
  //       fileName = null;
  //       break;
  //   }

  //   fetchLogo(fileName);
  // }, [user?.level, selectedGroupId, reportSelection?.groupDropDownList?.length, partenerTreeItems?.length]);

  //
   
 

    useEffect(() => {
    if (
      !user ||
      !reportSelection?.groupDropDownList?.length ||
      !partenerTreeItems?.length
    ) return;

    const fetchLogo = async (fileNameOrKeyword) => {
      if (!fileNameOrKeyword || !(
        fileNameOrKeyword.endsWith('.jpg') ||
        fileNameOrKeyword.endsWith('.jpeg') ||
        fileNameOrKeyword.endsWith('.png')
      ) ) {
        setLogo(defaultLogo);
        return;
      }

      // Avoid re-fetching if the same logo is already set
      if (lastFetchedLogoRef.current === fileNameOrKeyword) return;

      try {
        const response = await ApiCall.axiosInstance.get(
          `${process.env.REACT_APP_API_URL}${ApiLink.Download_Logo}/${fileNameOrKeyword}`,
          { responseType: 'blob' }
        );

        const imageUrl = URL.createObjectURL(response.data);
        setLogo(imageUrl);
        lastFetchedLogoRef.current = fileNameOrKeyword; // ✅ save last
      } catch (error) {
        console.error('Error fetching logo:', error);
        setLogo(defaultLogo);
      }
    };

    // Determine logo based on level
    let fileName = null;
   
    switch (user.level) {
      case 0:
        fileName = ''; // Admin → will fallback
        break;

      case 1:
        fileName = partenerTreeItems[0]?.logo;
        break;

      case 2: {
        const group = partenerTreeItems[0]?.group?.find(
          g => g?.id === Number(selectedGroupId)
        );
        fileName = group?.logo;
        break;
      }

      case 3: {
       
        const group = partenerTreeItems[0]?.group?.find(
          g => g.id === Number(selectedGroupId)
        );
        const clinic = group?.clinic?.find(c => c.id === reportclinic.clinicId);
        fileName = clinic?.logo;
        break;
      }

      default:
        fileName = null;
        break;
    }

    fetchLogo(fileName);
  }, [user?.level, selectedGroupId, reportSelection?.groupDropDownList?.length, partenerTreeItems?.length]);

  
   


  return (
    <MantineNavbar
      hidden={hidden}
      hiddenBreakpoint="sm"
      height="100vh"
      width={{ sm: 260 }}
      p="md"
      withBorder={false}
      className={classes.navbar}
    >
      <Box sx={{ position: 'absolute', right: 0, top: 24 }}>{burger && burger}</Box>
      <MantineNavbar.Section>
        <Group position="apart" h={rem(40)}>
        <img 
    src={logo || defaultLogo} 
    alt="logo" 
    style={{
      maxHeight: '60px',
      width: 'auto',
      maxWidth :'200px',
      objectFit: 'contain',
      borderRadius: '4px'
    }} 
  />

        </Group>
      </MantineNavbar.Section>
      <MantineNavbar.Section grow className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>{links}</div>
      </MantineNavbar.Section>
    </MantineNavbar>
  );
}

Navbar.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.shape,
      link: PropTypes.string,
      initiallyOpened: PropTypes.bool,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          link: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  hidden: PropTypes.bool,
  burger: PropTypes.node,
};
