import { useLocation, useNavigate } from "react-router";
import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Select,
  TextInput,
  Text,
  Table,
  LoadingOverlay,
  Stack,
} from "@mantine/core";
import { Grid } from "@mantine/core";
import { ApiLink } from "../../Constant/ApiLink";
import axios from "axios";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useDispatch, useSelector } from "react-redux";
import {
  addClinicId,
  addScallingFactor,
  addClinicLevel,
} from "../../utils/selectedClinicSlice";
import { CcmReportColumn } from "./Columns/CcmReportColumn";
import { Tooltip } from "chart.js";
import * as XLSX from "xlsx";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const CCMGapAnalysis = () => {
  var navigate = useNavigate();
  const SelectedClinicList = useSelector(
    (store) => store.partenerTree.selectedGroupClnics
  );
  const [loading, setloading] = useState(false);
  const [ccmReportData, setCcmReportData] = useState([]); //table row
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [uniqueSpeciality, setUniqueSpeciality] = useState([]);
  const [status, setStatus] = useState(1);

  const columnDefs = useMemo(() => [
    {
      header: "Name",
      accessorKey: "firstName",
      Footer: () => <Stack>Total</Stack>,
    },
    {
      header: "NPI",
      accessorKey: "npi",
    },
    {
      header: "Speciality",
      accessorKey: "speciality",

      filterVariant: "select",
      mantineFilterSelectProps: {
        data: uniqueSpeciality,
      },

      filterFn: (row, id, filterValue) => {
        const clinics = row?.original?.speciality;
        const found = clinics
          ?.toLowerCase()
          .includes(filterValue?.toLowerCase());
        return found;
      },
    },
    {
      header: "Total Beneficiaries",
      accessorKey: "totBen",
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce((acc, curr) => acc + curr.totBen, 0)}
          </Box>
        </Stack>
      ),
    },
    {
      header: "Potential CCM",
      accessorKey: "potencialCcm",
      accessorFn: (row) => {
        return Math.floor((row.potencialCcm / 100) * row.totBen);
        //return Math.floor(row.potencialCcm);
      },
      Footer: () => (
        <Stack>
          <Box color="orange">
            {Math.floor(
              ccmReportData?.reduce(
                (acc, curr) => acc + (curr.potencialCcm / 100) * curr.totBen,
                0
              )
            )}
          </Box>
        </Stack>
      ),
    },
    {
      header: " Actual CCM",
      accessorKey: "actualCcm",
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce((acc, curr) => acc + curr.actualCcm, 0)}
          </Box>
        </Stack>
      ),
    },
    {
      header: "CCM 20-minutes provided by clinical staff",
      accessorKey: "_99490",
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce((acc, curr) => acc + curr._99490, 0)}
          </Box>
        </Stack>
      ),
    },
    {
      header: "CCM 30-minutes provided by Physician",
      accessorKey: "_99491",
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce((acc, curr) => acc + curr._99491, 0)}
          </Box>
        </Stack>
      ),
    },
    {
      header: "PCM 30-minutes provided by clinical staff",
      accessorKey: "_99426",
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce((acc, curr) => acc + curr._99426, 0)}
          </Box>
        </Stack>
      ),
    },
    {
      header: "PCM 30-minutes provided by Physician",
      accessorKey: "_99424",
      Footer: () => (
        <Stack>
          <Box color="orange">
            {ccmReportData?.reduce((acc, curr) => acc + curr._99424, 0)}
          </Box>
        </Stack>
      ),
    },
  ]);

  useEffect(() => {
    CallReport(reportSelection.clinicId, true, status);
  }, [reportSelection]);

  const dispatch = useDispatch();
  const onClinicSlelect = (value) => {
    dispatch(addClinicId(value));
    dispatch(
      addClinicLevel(
        SelectedClinicList?.find((ele) => ele.value === value)?.label
      )
    );
    setCcmReportData([]);
    CallReport(value, true, status);
  };

  const CallReport = (value, isClinicChange, sts) => {
    let customURL;
    if (isClinicChange) {
      customURL = `${process.env.REACT_APP_API_URL}${ApiLink.Get_CCMBills}/${reportSelection.selectedYear}/${value}/${sts}`;
    } else {
      customURL = `${process.env.REACT_APP_API_URL}${ApiLink.Get_CCMBills}/${reportSelection.selectedYear}${value}${sts}`;
    }
    if (value) {
      setloading(true);
      ApiCall.axiosInstance
        .get(customURL)
        .then((response) => {
          getTotalSum(response.data);
          setCcmReportData(response.data);
          const uniqueSpeciality = [
            ...new Set(response?.data.map((item) => item?.speciality)),
          ];
          setUniqueSpeciality(uniqueSpeciality);
          setloading(false);
        })
        .catch((error) => {
          console.error("Error Clinic fetching data:", error);
          setloading(false);
        });
    }
  };

  const getTotalSum = (ccmList) => {
    ccmList?.forEach((ele) => {
      ele.actualCcm = ele._99424 + ele._99426 + ele._99490 + ele._99491;
    });
  };

  const getCurrentRow = () => {
    let rawTableData = Object.values(table?.getRowModel().rowsById);
    let currentRowData = rawTableData.map((ele) => ele.original);
    setCcmReportData(currentRowData);
  };

  const table = useMantineReactTable({
    columns: columnDefs,
    data: ccmReportData ?? [],
    state: { isLoading: loading },
    mantinePaperProps: { shadow: "0", withBorder: false },
    enableStickyHeader: true,
    density: "xs",
  });

  // const exportToExcel = () => {
  //   const table = document.querySelector(".mantine-Table-root");
  //   const ws = XLSX.utils.table_to_sheet(table);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   XLSX.writeFile(wb, "ccm.xlsx");
  // };

  const excludePropertiesFromArray = (array) => {
    const excludedProperties = ["lastName"];
    return array.map((obj) => {
      const filteredObj = {};
      Object.keys(obj).forEach((key) => {
        if (!excludedProperties.includes(key)) {
          if (key == "potencialCcm") {
            filteredObj[key] = Math.floor(
              (obj.potencialCcm / 100) * obj.totBen
            );
          } else {
            filteredObj[key] = obj[key];
          }
        }
      });
      return filteredObj;
    });
  };

  function mapColumnHeaders(columnDef) {
    return columnDef.map((column) => ({
      key: column.accessorKey,
      displayLabel: column.header,
    }));
  }

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: false,
    columnHeaders: mapColumnHeaders(columnDefs),
    filename: "CCM_Result",
  });

  const handleExportData = () => {
    const logData = excludePropertiesFromArray(ccmReportData);
    const csv = generateCsv(csvConfig)(logData);
    download(csvConfig)(csv);
  };

  const setStatusSpeciality = (value) => {
    
    setStatus(value);
  };


  useEffect(() => {
    if (status !== null) {
      CallReport(reportSelection.clinicId, true, status);
    }
  }, [status]); 

  return (
    <Box style={{ marginTop: "15px" }}>
      {loading && (
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      )}

      <Box
        className="mycontainer"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Text size="lg">
          CCM Gap Analysis{" "}
          {reportSelection.clinicEnrollLavel && (
            <span>({reportSelection.clinicEnrollLavel})</span>
          )}
        </Text>
      </Box>
      <br />
      <Grid>
        <Grid.Col span={4}>
          <Text color="green" py={2} fw={500}>
            Number of Records:
            <span style={{ color: "black" }}>{ccmReportData?.length}</span>
            <Button
              ml={20}
              type="primary"
              id="export-button"
              onClick={handleExportData}
            >
              Export to Excel
            </Button>
          </Text>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={4}>
          <Select
            size="xs"
            label="Attestation Clinic"
            withAsterisk
            placeholder="Choose One"
            data={SelectedClinicList}
            value={reportSelection.clinicId}
            onChange={onClinicSlelect}
          />
        </Grid.Col>
        <Grid.Col span={3} py={4} >
            <Select
              label="Speciality"
              placeholder="Select Status"
              value={status}
              data={[
                { value: 0, label: "All" },
                { value: 1, label: "Active" },
                { value: 2, label: "InActive" },
              ]}
              onChange={setStatusSpeciality}
            />
          </Grid.Col>
        {/* <Button mt={30} onClick={() => getCurrentRow()}>Update Sum</Button> */}
      </Grid>

      <Divider my="sm" />

      <MantineReactTable
        table={table}
        // columns={columnDefs}
        // data={ccmReportData ?? []}
        // // manualFiltering = {getCurrentRow()}
        // state={{ isLoading: loading }}
        // mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};
export default CCMGapAnalysis;
