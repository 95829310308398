import React, { useState, useRef, useEffect, useMemo } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Anchor, Box, Button, Grid, Switch } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useDisclosure } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { RouteLink } from "../../../Constant/RouterLink";
import { ApiLink } from "../../../Constant/ApiLink";
import axios from "axios";
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";

const MeasureLog = () => {
  const { id } = useParams();
  const [rowData, setRowData] = useState();
  var navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);

  const columnDef = useMemo(() => [
    {
      header: "Error Title",
      accessorKey: "errortitle",
    },
    {
      header: "Error Category",
      accessorKey: "errorCategory",
    },
    {
      header: "Error Message",
      accessorKey: "errorMessage",
    },
    {
      header: "Created Date",
      accessorKey: "createdDate",
    },
    {
      header: "Year",
      accessorKey: "year",
    },
  ]);

  useEffect(() => {
    getMeasureLogDataList(id);
  }, [id]);

  const getMeasureLogDataList = (id) => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.GetLogDataList}${id}`)
      .then((response) => {
        setRowData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const  excludePropertiesFromArray = (array) => {
    let ErrorDataList = [];
    array.forEach(obj => {
        Object.keys(obj).forEach(key => {
            if (key == 'errorData' && obj[key] != "") {
                let errorData = JSON.parse(obj[key]);
                ErrorDataList.push(errorData);
            }
        });
    });
    return ErrorDataList;
  }

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: id
  });

  const handleExportData = () => {
    const logData = excludePropertiesFromArray(rowData);
    if(!logData.length){
      alert('Opps! There is no log available to export.');
      return;
    }else{
      const csv = generateCsv(csvConfig)(logData);
      download(csvConfig)(csv);
    }
  };

  return (
    <Box style={{ marginTop: "15px" }}>
      <Grid>
        <Grid.Col span={3}>
          <Button type="primary" onClick={handleExportData}>
            Export Logfile
          </Button>
        </Grid.Col>
      </Grid>
      <MantineReactTable
        columns={columnDef}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};

export default MeasureLog;
