import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import {
  TextInput,
  Button,
  Box,
  Grid,
  Select,
  Space,
  FileInput,
  rem,
} from "@mantine/core";
import { ApiLink } from "../../Constant/ApiLink";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Loader } from "@mantine/core";
import style from "../../Shared/Theme/CommanStyle.module.css";
import { ActiveStatus } from "../../Shared/DropDownOptions/ActiveStatus";
import { IconUpload } from "@tabler/icons-react";
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import { v4 as uuidv4 } from 'uuid';
import { getFileUri } from "../../Shared/Constant";
import { useSelector } from "react-redux";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

function PublicFilesForm() {

  //Define variable and state
  const navigate = useNavigate();
  const { data, method } = useLocation().state;
  const [loading, setLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);


  const form = useForm({
    initialValues: {
      id: data?.id,
      name: data?.name,
      description: data?.description,
      status: data?.status,
      filepath: data?.filepath,
      year: data?.year ? data?.year : reportSelection.selectedYear
    },
  });

  const handleSubmit = async (formValues) => {
    setLoading(true);
    try {
      let filepath = form.values.filepath;
      if (filepath !== null) {
        const fileUri = await getFileUri(form.values.filepath);
        formValues.filepath = fileUri;
        setLoading(false);
        if (fileUri) {
          savePublicFile(formValues);
        }
      } else {
        savePublicFile(formValues);
      }
    } catch (error) {
      console.error("Failed Request:", error);
      setLoading(false);
    }

  };

  const savePublicFile = (formValues) => {
    ApiCall.axiosInstance[method](
      `${process.env.REACT_APP_API_URL}${ApiLink.PublicFiles}`,
      formValues
    )
      .then((response) => {
        
        setLoading(false);
        navigate(RouteLink.PublicFile_Uri);
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        setLoading(false);
      });
  }

  const handleBack = () => {
    navigate(RouteLink.PublicFile_Uri);
    setLoading(false);
  };
  // back handle button




  // code to upload azure file
  // const [file, setFile] = useState(null);
  // const handleFileChange = event => {
  //   setFile(event.target.files[0]);
  //   //file come

  //   //form.setValues({ file: file.name });

  // };


  //Create an instance of the Blob client using the connection string for your storage account.
  // const blobServiceClient = new BlobServiceClient(
  //   "DefaultEndpointsProtocol=https;AccountName=macrafilestorage;AccountKey=9kcYFiylDqKALDjEx54q72zAQgrvBipsIj9UnpC1KH7KvSJVzdGQHVe5akYwnX73a8AOd2/L4nvL+AStWWXhIA==;EndpointSuffix=core.windows.net"
  // );

  // upload image function
  async function uploadImage(containerName, file) {
    

    const blobServiceClient = new BlobServiceClient(
      // "connection-string-to-your-storage-account + sas tokken"
      "https://macrafilestorage.blob.core.windows.net/macrafiles?sp=racwdl&st=2023-08-25T16:32:39Z&se=2023-12-31T00:32:39Z&sv=2022-11-02&sr=c&sig=d%2FOsbbexy37cCqPnbkQGp3zaRhtn%2BAZ0%2BwKSVvE8mk4%3D"
    );
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(file.name);
    const blockBlobClient = blobClient.getBlockBlobClient();
    const result = await blockBlobClient.uploadBrowserData(file, {
      blockSize: 4 * 1024 * 1024,
      concurrency: 20,
      onProgress: ev => console.log(ev)
    });
    
  }

  //code to upload azure file end

  return (
    <Box className={style.mt2}>
      {loading && <Loader size={48} />}
      <h5>Public Files : Add New</h5>
      <Space></Space>

      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid>
          <Grid.Col span={4}>
            <TextInput
              label="Name"
              withAsterisk
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput
              label="Descriptions"
              withAsterisk
              {...form.getInputProps("description")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select
              label="Status:"
              defaultValue={data?.account}
              {...form.getInputProps("status")}
              data={ActiveStatus}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <FileInput
              placeholder="Pick file"
              label="Your resume"
              withAsterisk
              {...form.getInputProps("filepath")}
            />

          </Grid.Col>
        </Grid>

        <div style={{ marginTop: "1rem", float: "right" }}></div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div>
            <Button type="primary" onClick={() => handleBack()}>
              Back
            </Button>
            <Button style={{ marginLeft: "10px" }} type="submit" mt="sm">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Box>
  );
}

export default PublicFilesForm;
