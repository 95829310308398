import React, { useState, useRef, useEffect, useMemo } from "react";

import { useNavigate } from "react-router-dom";
import { Box, Button, Grid } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useDisclosure } from "@mantine/hooks";
import { useSelector } from "react-redux";
import axios from "axios";
import { RowAction } from "../../../Shared/RowAction";
import { DeleteConfirmationModal } from "../../../Shared/DeleteConfirmationModal";
import { ApiLink } from "../../../Constant/ApiLink";
import { RouteLink } from "../../../Constant/RouterLink";
import { csvdata } from "../CsvFormate/FormData";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";

const FormulaMappingList = () => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  var navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [formLoading, setLoading] = useState(false);

  const reqColumnDefs = useMemo(() => [
    // {
    //   header: "Request Id",
    //   accessorKey: "reqId",
    // },
    {
      header: "Measure Name",
      // accessorKey: "measureName",
      accessorFn: (row) => {
        return row?.measureName?.name;
      },
    },
    {
      header: "No Of Child",
      accessorKey: "childId",
    },
    {
      header: "Formula Type",
      accessorKey: "formulaType",
    },
    {
      header: "MeasureType",
      accessorKey: "finalCalType",
    },
  ]);

  useEffect(() => {
    getCSVdataList();
  }, [reportSelection.selectedYear]);

  const getCSVdataList = (year) => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}${ApiLink.MeasureFormulaMappingGetList}?year=${reportSelection.selectedYear}`
      )
      .then((response) => {
        setRowData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const onCreate = () => {
    navigate(RouteLink.Formula_Uri, {
      state: { data: null, method: "post" },
    });
  };

  const onUpdate = (value) => {
    navigate(RouteLink.Formula_Uri, {
      state: { data: value, method: "put" },
    });
  };

  const [deletingRow, setDeletingRow] = useState({});
  const onDelete = (value) => {
    setDeletingRow(value);
    open();
  };

  const [opened, { close, open }] = useDisclosure(false);

  function IsConfirmed(value) {
    if (value === true) {
      setLoading(true);
      ApiCall.axiosInstance["delete"](
        `${process.env.REACT_APP_API_URL}${ApiLink.FormulaDelete}/${deletingRow.reqId}/${deletingRow.formulaType}`
      )
        .then((response) => {
          setLoading(false);
          getCSVdataList();
          
        })
        .catch((error) => {
          console.error("Failed Request:", error);
          setLoading(false);
        });
    }
  }

  return (
    <Box style={{ marginTop: "15px" }}>
      <DeleteConfirmationModal
        IsConfirmed={IsConfirmed}
        opened={opened}
        open={open}
        close={close}
      />
      <Grid>
        <Grid.Col span={3}>
          <Button type="primary" onClick={() => onCreate()}>
            Create Formula Map
          </Button>
        </Grid.Col>
      </Grid>
      <MantineReactTable
        columns={reqColumnDefs}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn="first"
        renderRowActions={(row) => (
          <RowAction
            onUpdate={() => onUpdate(row.row.original)}
            onDelete={() => onDelete(row.row.original)}
          />
        )}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};

export default FormulaMappingList;
