// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiCall } from '../../Shared/ApiCall/ApiCall';


// Define an initial state
const initialState = {
    orgGroupIds: [],
    isLoading: false,
    error: null,
};

// Create an async thunk for login
//export const getGroups = createAsyncThunk(`data/getGroup`, async () => await ApiCall.GetGroups());
//export const getPartners = createAsyncThunk(`data/getPartner`, async () => await ApiCall.getPartners());
// export const getTins = createAsyncThunk(`data/getTin`, async () => await ApiCall.getTins());

export const getPartners = createAsyncThunk(
    'data/getPartner',
    async (params = {}) => {
      const queryParams = Object.keys(params).length 
        ? `?${new URLSearchParams(params).toString()}`
        : '';
      console.log('Query string:', queryParams); // Debug output
      return await ApiCall.getPartners(queryParams);
    }
  );
  
  
  export const getGroups = createAsyncThunk(
    'data/getGroup',
    async ({ groupId = 0, ...params } = {}) => {
      // Build query string only if additional parameters exist
      const queryParams = Object.keys(params).length 
        ? `?${new URLSearchParams(params).toString()}`
        : '';
      console.log('Query string:', queryParams);
      // Call the API function with the groupId and queryParams
      return await ApiCall.GetGroups(groupId, queryParams);
    }
  );

  export const getTins = createAsyncThunk(
    'data/getTin',
    async (params = {}) => {
      const queryParams = Object.keys(params).length 
        ? `?${new URLSearchParams(params).toString()}`
        : '';
      console.log('Query string:', queryParams); // Debug output
      return await ApiCall.getTins(queryParams);
    }
  );
  

// Create a slice
const authSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            // get groups
            .addCase(getGroups.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getGroups.fulfilled, (state, action) => {
                state.isLoading = false;
                state.orgGroupIds = action.payload; // Adjust based on your API response
               
            })
            .addCase(getGroups.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })

            // get partners
            .addCase(getPartners.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getPartners.fulfilled, (state, action) => {
                state.isLoading = false;
                state.orgGroupIds = action.payload; // Adjust based on your API response
               
            })
            .addCase(getPartners.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })

            // get tins
            .addCase(getTins.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getTins.fulfilled, (state, action) => {
                state.isLoading = false;
                state.orgGroupIds = action.payload; // Adjust based on your API response
               
            })
            .addCase(getTins.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })
    },
});

// Export actions and reducer
export const { logout } = authSlice.actions;
export default authSlice.reducer;