export const ClinicColumn =
    [
        {
            header: "Name",
            accessorKey: "name",

        },
        {
            header: "Contact No",
            accessorKey: 'additionalContact'
        },
        {
            header: "TIN",
            accessorKey: "tin",
        },
        {
            header: "ORG-NPI",
            accessorKey: "npi",
        },
        {
            header: "Clinic Type",
            accessorKey: "clinicType",
            filterVariant: 'select',
            mantineFilterSelectProps: {
                data: ['Client', 'Prospect'],
            },
        },
        {
            header: "Site Practice Manager",
            accessorKey: 'sitePracticeManager'
        },
        {
            header: "Pac ID",
            accessorKey: "pacId",

        },
        
    ]