import { Button, Table, Text } from "@mantine/core";
import React, { useState } from "react";
import { ClinicAttestationRow } from "./ClinicAttestationRow";
import { useEffect } from "react";
import axios from "axios";
import { ApiLink } from "../../Constant/ApiLink";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";
import { useSelector } from "react-redux";

export default function ClinicAttestationHistory({
  values,
  defaultsubmission,
  defaultGroupId,
  year,
  entityId,
  updateDataForRevalidation
}) {
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [showAttestation, setShowAttestation] = useState(true);
  const reportSelection = useSelector((store) => store.selectedClinic.item);

  const updateAddNew = (newValue) => {
    if (newValue) {
      GetAttestationHistory(values);
    }
  };

  const discard = () => {
    GetAttestationHistory(values);
  };

  const toggelHistoryView = () => {
    setShowAttestation(false);
    setTimeout(() => {
      setShowAttestation(true);
    });
  };

  useEffect(() => {
    GetAttestationHistory(values);
  }, []);

  useEffect(() => {
      updateDataForRevalidation(data[0]);
  }, [data]);

  const GetAttestationHistory = (param) => {
    setLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.GetClinicAttestationHistory}${param}`
      )
      .then((response) => {
        
        setLoading(true);
        setdata(response.data);
        toggelHistoryView();
      })
      .catch((error) => {
        console.error("get pecos clinic records Fails:", error);
        setLoading(false);
      });
  };

  const [entitytype, setEntitytype] = useState([]);
  useEffect(() => {
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.Entity_Type}`)
      .then((response) => {
        const extractedData = response.data.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        setEntitytype(extractedData);
      })
      .catch((error) => {
        console.error("Error EntityType fetching data:", error);
      });
  }, []);

  const handleDeleteItem = (itemId) => {
    if (!window.confirm("Are you sure to delete the Attestation History?")) {
      return;
    }
    ApiCall.axiosInstance["delete"](
      `${process.env.REACT_APP_API_URL}${ApiLink.ClinicAttestationHistory}?id=${itemId.id}`
    )
      .then((response) => {
        GetAttestationHistory(values);
        alert("Record Deleted SuccesFully");
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        setLoading(false);
      });
  };

  const [meetingStatus, setMeetingStatus] = useState([]);
  useEffect(() => {
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.MeetingStatus}/${defaultGroupId}`
      )
      .then((response) => {
        setMeetingStatus(response.data);
        modifiyRecordonFormGroupChange(defaultGroupId, response.data);
      })
      .catch((error) => {
        console.error("Error EntityType fetching data:", error);
      });
  }, [defaultGroupId]);


  const [mvp, setMvp] = useState([]);
  useEffect(() => {
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.mvplistClinicAttestation}/${reportSelection.selectedYear}`)
      .then((response) => {
        const extractedData = response.data.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        setMvp(extractedData);
      })
      .catch((error) => {
        console.error("Error EntityType fetching data:", error);
      });
  }, []);


  const modifiyRecordonFormGroupChange = (defaultGroupId, meetingStatus) => {
    if (data?.length) {
      data?.forEach((ele) => {
        if (ele.isNewRow || ele.fiscalYear == year) {
          ele.groupId = defaultGroupId;
          ele.groupMeetingStatus = meetingStatus;
        }
      });
      toggelHistoryView();
    }
  };

  
  const addnewRow = (e) => {
    const newAttesHistryRow = {
      // id: "",
      groupId: defaultGroupId,
      clinicId: values,
      fiscalYear: year?.toString(),
      entityID: parseInt(entityId),
      chert: "-",
      concent: null,
      cpiaDoc: null,
      meetingStatus: "",
      overridSubMethod: defaultsubmission,
      pi: false,
      cqm: false,
      ru: false,
      cpia: false,
      cost:0,
      groupMeetingStatus: meetingStatus,
      isNewRow: true,
      smallGroupPractitioner: false,
      phyNPI: '',
      ismvp :false,
      mvpId : "",
      groupMvp: mvp,
    };
    const newData = [newAttesHistryRow, ...data];
    setdata(newData);
    toggelHistoryView();

  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mycontainer "
      >
        <span>Attestation History </span>{" "}
        <div>
          <Button onClick={discard}>Remove Unsaved</Button>
          <Button onClick={addnewRow}>Add</Button>{" "}
        </div>
      </div>
      <div>
        <Table
          verticalSpacing="xs"
          fontSize="xs"
          striped
          highlightOnHover
          withBorder
          withColumnBorders
        >
          <thead></thead>
          <tbody>

            {showAttestation &&
              data.map((form, index) => (
                <ClinicAttestationRow
                  index={index}
                  entityList={entitytype}
                  attestions={form}
                  onDelete={handleDeleteItem}
                  updateAddNew={updateAddNew}
                  defaultGroupId={defaultGroupId}
                  groupId={form?.groupId}
                  groupMeetingStatus={form.groupMeetingStatus}
                  groupMvp={mvp}
                />
              ))}
            {!showAttestation && <Text>Loading...</Text>}
          </tbody>
        </Table>
      </div>
    </>
  );
}
