import { useLocation, useNavigate } from "react-router";
import { useState, useEffect } from "react";
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Loader,
  LoadingOverlay,
  Select,
  Text,
} from "@mantine/core";
import { Table } from "@mantine/core";
import { PIScoringType } from "../../Shared/DropDownOptions/PIScoringType";
import { ConfirmedStatus, YesNo } from "../../Shared/DropDownOptions/YesNo";
import { Grid } from "@mantine/core";
import { useSelector } from "react-redux";
import { ApiLink } from "../../Constant/ApiLink";
import axios from "axios";
import { ScalingFactor } from "../../Shared/DropDownOptions/Arrays";
import { nFormatter } from "../../Shared/NumberFunctions";
import { IconActivity, IconRadarOff, IconRowRemove } from "@tabler/icons-react";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import { useDisclosure } from "@mantine/hooks";
import { RouteLink } from "../../Constant/RouterLink";
import * as XLSX from "xlsx";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const QPPDataConfirmation = () => {
  var navigate = useNavigate();
  const reportSelection = useSelector((store) => store.selectedClinic.item);

  const [qppDataConfirmation, setQppDataConfirmation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState("all");
  const [entitytype, setEntitytype] = useState([]);
  const [qppCmsGov, setQppCmsGov] = useState(null);
  const [
    entityTypePercentageDestribution,
    setEntityTypePercentageDestribution,
  ] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(
    entityTypePercentageDestribution[0]?.id
  );
  const [copyQppDataConfirmation, setCopyQppDataConfirmation] = useState([]);
  const [qppCmsLoader, setQppCmsLoader] = useState(false);

  const [rows, setRowData] = useState(null);

  useEffect(() => {
    getEntityTypePercentageDestribution();
  }, [reportSelection]);

  useEffect(() => {
    setRowData(createRows());
  }, [qppDataConfirmation]);

  useEffect(() => {
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.Entity_Type}`)
      .then((response) => {
        setEntitytype(response.data);
        
      })
      .catch((error) => {
        console.error("Error EntityType fetching data:", error);
      });
  }, []);

  const openNewWindow = (rowData, year) => {
    // Calculate the center position for the popup
    const url = `https://qpp.cms.gov/participation-lookup?npi=${rowData.npi}&py=${reportSelection.selectedYear}`;
    const width = 1000;
    const height = 700;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const windowName = "qppWindow";
    // Open the popup window
    window.open(
      url,
      windowName,
      `width=${width},height=${height},left=${left},top=${top}`
    );
  };

  const handleQppDataConfirmation = (value) => {
    setChecked("all");
    setSelectedEntity(value);
    setLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.QppMismatch}/${reportSelection.clinicId}/${reportSelection.scallingFactor}?entityid=${value}&financialYear=${reportSelection.selectedYear}`
      )
      .then((response) => {
        getCustomMedicareAmount(response.data);
        setQppDataConfirmation(response.data.slice(0, -1));
   
        setCopyQppDataConfirmation(response.data.slice(0, -1));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error Clinic fetching data:", error);
        setLoading(false);
      });
  };

  const getEntityTypePercentageDestribution = () => {
    setLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.EntityTypewithReqSetByTin}/${reportSelection.clinicId}/${reportSelection.selectedYear}`
      )
      .then((response) => {
        setEntityTypePercentageDestribution(response.data);
        handleQppDataConfirmation(response.data[0]?.entityId);
      })
      .catch((error) => {
        console.error("Error EntityType fetching data:", error);
        setLoading(false);
      });
  };

  const getCustomMedicareAmount = (qppdata) => {
    qppdata.forEach((ele) => {
      if (ele.phyCounter != 0) {
        ele["customAmount"] = ele.basicMedicare / ele.phyCounter;
      } else {
        ele["customAmount"] = ele.basicMedicare;
      }
    });
  };

  const handleCheckboxChange = (event, enrollmentiD, property) => {
    const { checked } = event.target;

    setQppDataConfirmation((prevData) =>
      prevData.map((item) =>
        item.enrollmentiD === enrollmentiD
          ? { ...item, [property]: checked }
          : item
      )
    );

    //recheck....here..
    if (property == "isConfirmed") {
      let checkedRow = qppDataConfirmation.find(
        (item) => item.enrollmentiD === enrollmentiD
      );
      checkedRow.isConfirmed = checked;
      if (checkedRow.id != null && checked) {
        let customPayload = {
          id: checkedRow.id,
          clinicTin: checkedRow.tin,
          clinicEnrollmentID: reportSelection.clinicId,
          phyNPI: checkedRow.npi,
          phyEnrollmentiD: checkedRow.enrollmentiD,
          year: reportSelection.selectedYear,
          isLV: checkedRow.isLV,
          isConfirmed: checkedRow.isConfirmed,
          atesstationId: checkedRow.atesstationId,
          phyCounter:
            checkedRow.phyCounter == 0 || checkedRow.phyCounter == null
              ? checkedRow.pccount
              : checkedRow.phyCounter,
          isDelete: checkedRow.isDelete,
          entityId: checkedRow.entityId,
        };
        // handleSubmit([customPayload], "put");
      }
    }
  };

  // const [opened, { close, open }] = useDisclosure(false);
  // function IsConfirmed(value) {
  //   return value;
  // }

  const handleCheckboxChangeForDelete = (event, enrollmentiD, property) => {
    const { checked } = event.target;
    if (checked && window.confirm("Are you sure to inactive record?")) {
      setQppDataConfirmation((prevData) =>
        prevData.map((item) =>
          item.enrollmentiD === enrollmentiD
            ? { ...item, [property]: checked }
            : item
        )
      );
    } else if (!checked && window.confirm("Are you sure to active record?")) {
      setQppDataConfirmation((prevData) =>
        prevData.map((item) =>
          item.enrollmentiD === enrollmentiD
            ? { ...item, [property]: checked }
            : item
        )
      );
    }
  };

  const handleInputChange = (event, enrollmentiD, property1, property2) => {
    let { value } = event.target;
    const targetRow = qppDataConfirmation.find(
      (ele) => ele.enrollmentiD == enrollmentiD
    );
    debugger;
    if (+value > +targetRow.totalClinic) {
      alert(
        `Phycisian counter ${value} should be less then total clinic ${targetRow.totalClinic}`
      );
      value = targetRow.phyCounter;
    }

    setQppDataConfirmation((prevData) =>
      prevData.map((item) =>
        item.enrollmentiD === enrollmentiD
          ? {
              ...item,
              [property1]: value,
              [property2]:
                value > 0 && value != ""
                  ? item.basicMedicare / value
                  : item.basicMedicare,
            }
          : item
      )
    );
  };

  const handleDropDownChange = (event, enrollmentiD, property) => {
    const { value } = event.target;
    setQppDataConfirmation((prevData) =>
      prevData.map((item) =>
        item.enrollmentiD === enrollmentiD
          ? { ...item, [property]: value }
          : item
      )
    );
  };

  const handleSave = () => {
    let QppCustomPayload = [];
    qppDataConfirmation.forEach((ele) => {
      if (ele.isConfirmed) {
        let rowData = {
          id: ele.id == null ? 0 : ele.id,
          isLV: ele.isLV,
          isConfirmed: ele.isConfirmed,
          phyCounter: ele.phyCounter,
          isDelete: ele.isDelete,
          entityId: ele.entityId,
          atesstationId: ele.ateestationId == null ? 0 : ele.ateestationId,
          ClinicId: reportSelection.clinicId,
          PhysicianId: ele.enrollmentiD,
          year: reportSelection.selectedYear,
          medicareAmt: ele.basicMedicare,
        };
        QppCustomPayload.push(rowData);
      }
    });
    handleSubmit(QppCustomPayload, "put");
  };

  const handleSubmit = (customPayload, method) => {
    setLoading(true);
    ApiCall.axiosInstance["put"](
      `${process.env.REACT_APP_API_URL}/${ApiLink.FinancialReports}`,
      customPayload
    )
      .then((response) => {
        setLoading(false);
        alert("Records saved successfully");
        getEntityTypePercentageDestribution();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleConfirmedFiltered = (value) => {
    setQppDataConfirmation([]);

    if (value == "all") {
      setQppDataConfirmation(copyQppDataConfirmation);
      return;
    }

    if (copyQppDataConfirmation.length) {
      const filteredData = copyQppDataConfirmation.filter(
        (ele) => ele.isConfirmed == value
      );

      setQppDataConfirmation(filteredData);
    }
  };

  const handleLowVolumeFilter = (value) => {
    // setChecked(value.target.checked)
    setChecked(value);
    setQppDataConfirmation([]);
    if (value == "all") {
      setQppDataConfirmation(copyQppDataConfirmation);
      return;
    } else if (value == false) {
      const filteredData = copyQppDataConfirmation.filter(
        (ele) => ele.isLV == false
      );
      setQppDataConfirmation(filteredData);
      return;
    } else if (value == true && copyQppDataConfirmation.length) {
      const filteredData = copyQppDataConfirmation.filter(
        (ele) => ele.isLV == true
      );
      setQppDataConfirmation(filteredData);
    }
  };

  const handleClickGoToClinic = (enrollmentId) => {
    setLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.getPhysicianByEnrollmentId}/${enrollmentId}/${reportSelection.selectedYear}`
      )
      .then((response) => {
        const physicianData = { data: response?.data[0], method: "put" };
        localStorage.setItem("physicianData", JSON.stringify(physicianData));
        if (physicianData) {
          setTimeout(() => {
            window.open(
              `${window.location.origin}${RouteLink.Physician_Update_Uri}`
            );
            //  navigate(RouteLink.Physician_Update_Uri);
            setLoading(false);
          }, 200);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error Clinic fetching data:", error);
        setLoading(false);
      });
  };

  const createRows = () => {
    return qppDataConfirmation?.map((item) => (
      <tr key={item.enrollmentiD}>
        <td>
          <Anchor onClick={() => handleClickGoToClinic(item.enrollmentiD)}>
            {item.firstName}
          </Anchor>
        </td>
        <td>
          <a href="##" onClick={() => openNewWindow(item, "2023")}>
            CMS
          </a>
        </td>
        <td>
          <input
            type="checkbox"
            disabled={item.isConfirmed}
            checked={item.isLV}
            onChange={(e) => handleCheckboxChange(e, item.enrollmentiD, "isLV")}
            className="chkbox"
          />
        </td>
        <td style={{ minWidth: "10rem" }}>
          <select
            disabled={item.isConfirmed}
            value={item.entityId}
            onChange={(e) =>
              handleDropDownChange(e, item.enrollmentiD, "entityId")
            }
            className="ddldropdown"
          >
            {entitytype.map((ele) => (
              <option key={ele.id} value={ele.id}>
                {ele.name}
              </option>
            ))}
          </select>
        </td>
        <td>
          <input
            type="checkbox"
            checked={item.isConfirmed}
            onChange={(e) =>
              handleCheckboxChange(e, item.enrollmentiD, "isConfirmed")
            }
            className="chkbox"
          />
        </td>
        <td>{item.tin}</td>
        <td>{item.npi}</td>
        <td>{item.qualityScroe}</td>
        <td>{nFormatter(item.customAmount, 2)}</td>
        <td>{nFormatter(item.basicMedicare, 2)}</td>
        <td>{nFormatter(item.allowAmt, 2)}</td>
        <td>
          <input
            disabled={item.isConfirmed}
            type="text"
            value={item.phyCounter}
            onChange={(e) =>
              handleInputChange(
                e,
                item.enrollmentiD,
                "phyCounter",
                "customAmount"
              )
            }
            className="txtbox"
          />
        </td>
        <td style={{ display: "flex", justifyContent: "space-between" }}>
          <input
            disabled={item.isConfirmed}
            type="checkbox"
            checked={item.isDelete}
            onChange={(e) =>
              handleCheckboxChangeForDelete(e, item.enrollmentiD, "isDelete")
            }
            className="chkbox"
          />
        </td>
      </tr>
    ));
  };

  //
  const exportToExcel = () => {
    const tbody = document
      .getElementById("tinsummary")
      .getElementsByTagName("tbody")[0];
    const specialIndices = [2, 3, 4, 8, 9, 10, 11];

    const existingData = Array.from(tbody.rows).map((row) =>
      Array.from(row.cells).map((cell, index) => {
        if (specialIndices.includes(index)) {
          if (index === 2) {
            const selectElement = cell.querySelector('input[type="checkbox"]');
            return selectElement ? selectElement.checked : false;
          } else if (index === 3) {
            const selectElement = cell.querySelector("select");
            return selectElement
              ? selectElement.selectedOptions[0].textContent
              : "";
          } else if (index === 4) {
            const selectElement = cell.querySelector('input[type="checkbox"]');
            return selectElement ? selectElement.checked : false;
          } else if (index === 8) {
            const selectElement = row.cells[8];
            const value = selectElement ? selectElement.textContent.trim() : "";
            const numericalValue = value
              ? parseFloat(value.replace(/[^\d.]/g, "")) * 1000
              : 0; // Replace NaN with 0 if value is empty
            return numericalValue.toFixed(2);
          } else if (index === 9) {
            const selectElement = row.cells[9];
            const value = selectElement ? selectElement.textContent.trim() : "";
            const numericalValue = value
              ? parseFloat(value.replace(/[^\d.]/g, "")) * 1000
              : 0; // Replace NaN with 0 if value is empty
            return numericalValue.toFixed(2);
          } else if (index === 10) {
            const selectElement = row.cells[10];
            const value = selectElement ? selectElement.textContent.trim() : "";
            const numericalValue = value
              ? parseFloat(value.replace(/[^\d.]/g, "")) * 1000
              : 0; // Replace NaN with 0 if value is empty
            return numericalValue.toFixed(2);
          } else if (index === 11) {
            const selectElement = cell.querySelector('input[type="text"]');
            return selectElement ? selectElement.value : "";
          } else if (index === 12) {
            const selectElement = cell.querySelector('input[type="checkbox"]');
            return selectElement ? selectElement.checked : false;
          }
        } else {
          // Default handling for other indices
          return cell.textContent;
        }
        if (index === 3) {
        } else {
          return cell.textContent;
        }
      })
    );
    // Custom headers for the first row
    const customHeaders = [
      "EC Name",
      "Lookup",
      "LV Toggle",
      "Reporting Category",
      "Confirmed",
      "TIN",
      "NPI",
      "Current Quality Score",
      "Allocated Medicare Payment",
      "Total Medicare Amount",
      "Total Allowed Amount",
      "Tin Counter",
      "Mark For Delete",
    ];
    // Combine custom headers with existing data
    const data = [customHeaders, ...existingData];
   
    // Create a new worksheet from the extracted data
    const ws = XLSX.utils.aoa_to_sheet(data);
    // Create a new Excel workbook
    const wb = XLSX.utils.book_new();
    // Append the worksheet to the workbook with the name 'Sheet1'
    // Set column widths
    const columnWidths = [30, 15, 15, 30, 16, 16, 16, 20, 20, 15, 20];

    // Apply style to set column width
    const wscols = columnWidths.map((width, index) => ({ wch: width }));

    ws["!cols"] = wscols;

    // Append the worksheet to the workbook with the name 'Sheet1'
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "QPPDataConfirmation.xlsx");
  };

  const getDataForTransfer = () => {
    if (
      !window.confirm(
        `Are you sure to transfer QPP individual from year ${
          +reportSelection.selectedYear - 1
        } to ${+reportSelection.selectedYear} ? `
      )
    ) {
      return;
    }
    setLoading(true);
    const customPayload = {
      clinicId: reportSelection.clinicId,
      fromyear: +reportSelection.selectedYear - 1,
      toYear: +reportSelection.selectedYear,
    };
    ApiCall.axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}${ApiLink.TransferQppDataFromToYear}`,
        customPayload
      )
      .then((response) => {
        window.alert("Record transfered successfully");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error Clinic fetching data:", error);
        setLoading(false);
      });
  };

  return (
    <Box style={{ marginTop: "15px" }}>
      {/* <DeleteConfirmationModal IsConfirmed={IsConfirmed} opened={opened} open={open} close={close} deletemsg={'Are you sure to inctive record.'} /> */}
      {loading && (
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      )}
      <div className="mycontainer">
        QPP Data Confirmation{" "}
        {reportSelection.clinicEnrollLavel && (
          <span>({reportSelection.clinicEnrollLavel})</span>
        )}
      </div>
      <br />
      <Grid>
        <Grid.Col span={8}>
          {/* <Checkbox
            label="Low Volume Only"
            fw={500}
            checked={checked}
            onChange={(event) => handleLowVolumeFilter(event)}
          /> */}
          <Text color="green" py={2} fw={500}>
            Number of Records:
            <span style={{ color: "black" }}>
              {qppDataConfirmation?.length}
            </span>
            <Button
              ml={20}
              type="primary"
              id="export-button"
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
            <Button
              ml={20}
              type="primary"
              id="export-button"
              onClick={getDataForTransfer}
            >
              QPP Individual Transfer From year{" "}
              {+reportSelection.selectedYear - 1} to{" "}
              {reportSelection.selectedYear}
            </Button>
          </Text>
          {/* <Divider my="sm" /> */}
        </Grid.Col>
        <Grid.Col span={2}></Grid.Col>

        <Grid.Col span={2}></Grid.Col>
      </Grid>
      <Divider my="sm" />
      {loading && <Text fw={500}>Loading..</Text>}
      <Table
        striped
        highlightOnHover
        withBorder
        withColumnBorders
        sx={{ overflowX: "auto" }}
        id="tinsummary"
      >
        <thead>
          <tr>
            <th>EC Name</th>
            <th>Lookup</th>
            <th>
              <Text>LV Toggle</Text>
              <Select
                size="xs"
                value={checked}
                data={[
                  { value: "all", label: "All" },
                  { value: false, label: "No" },
                  { value: true, label: "Yes" },
                ]}
                onChange={(v) => handleLowVolumeFilter(v)}
              />
            </th>
            <th style={{ minWidth: "10rem" }}>
              <Text>Reporting Category</Text>
              <Select
                size="xs"
                value={selectedEntity}
                data={entityTypePercentageDestribution?.map((ele) => ({
                  value: +ele.id,
                  label: ele.name,
                }))}
                onChange={(value) => handleQppDataConfirmation(value)}
              />
            </th>
            <th>
              <Text>Confirmed</Text>
              <Select
                size="xs"
                withAsterisk
                data={[
                  { value: "all", label: "All" },
                  { value: false, label: "No" },
                  { value: true, label: "Yes" },
                ]}
                onChange={(v) => handleConfirmedFiltered(v)}
              />
            </th>

            <th>TIN</th>
            <th>NPI</th>
            <th>Current Quality Score</th>
            <th>Allocated Medicare Payment</th>
            <th>Total Medicare Amount</th>
            <th>Total Allowed Amount</th>
            <th>Tin Counter</th>
            {/* <th>Target Results</th>
            <th>Regulatory High</th>
            */}
            <th>Mark For Delete</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
        {!qppDataConfirmation.length && <Text>No data available</Text>}
      </Table>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <div>
          <Button type="primary" onClick={() => handleSave()}>
            Save QPP Data
          </Button>
        </div>
      </div>
    </Box>
  );
};
export default QPPDataConfirmation;
