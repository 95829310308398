import { Divider } from "@mantine/core"

export const Sandivider=(prop)=>{
    return <Divider
    my="xs"
    size="sm"
    label={prop.text??"Status"}
    labelPosition="center"
    style={{marginTop: '2rem', marginBottom: '2rem'}}
    color="green"
  />
}