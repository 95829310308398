import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiCall } from "../Shared/ApiCall/ApiCall";

export const GetClinicGroup = createAsyncThunk(`clinic/GetClinicGroup`, async (clinicId) => {

  var response = await ApiCall.GetClinicGroup(parseInt(clinicId))
  if (response.status > 199 && response.status < 300) {
    return response.data;
  }
  else {
    throw new Error(response.data.operationResults[0].message);
  }

});

const partenerTreeSlice = createSlice({
  name: "partenerTree",
  initialState: {
    //set this data just after login;
    partenerTreeItem: [],
    selectedGroupClnics: [],
    selectedGroupId: "",
    groupDropDownList: [],
    treeRefreshCount: 0,
    disableYear: false,
    guiData: null,
    isLoading: false,
    error: null,
    clinicGroupDetails: {
      clinicId: 0,
      groupId: null,
      clinicName: "",
      groupName: null
    }
  },
  reducers: {
    addClinicForSeletedGroup: (state, action) => {
      let dropDownList = [];
      action?.payload?.forEach((element) => {
        let dropDownItem = {};
        dropDownItem["value"] = element.id;
        dropDownItem["label"] = element.name;
        dropDownItem["tinID"] = element.tin;
        dropDownList.push(dropDownItem);
      });
      state.selectedGroupClnics = dropDownList;
    },
    addGroupDropDownList: (state, action) => {
      state.groupDropDownList = action.payload;
    },
    setSelectedGrupId: (state, action) => {
      state.selectedGroupId = action.payload;
    },
    replacePartnerTreeItem: (state, action) => {
      state.partenerTreeItem = action.payload;
    },
    refreshTreeData(state) {
      state.treeRefreshCount += 1;
    },
    addGuiData(state, action) {
      state.guiData = action.payload;
    },
    setDisableYear(state, action) {
      state.disableYear = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetClinicGroup.pending, (state) => {
       
        state.isLoading = true;
        state.error = null;
      })
      .addCase(GetClinicGroup.fulfilled, (state, action) => {
       
        state.clinicGroupDetails = {
          clinicId: action.payload.clinicId,
          groupId: action.payload.groupId,
          clinicName: action.payload.clinicName,
          groupName: action.payload.groupName
        }
      })
      .addCase(GetClinicGroup.rejected, (state, action) => {
      
        state.isLoading = false;
        state.error = action.error.message;
      });
  }
});

export const {
  addClinicForSeletedGroup,
  setSelectedGrupId,
  replacePartnerTreeItem,
  addGroupDropDownList,
  refreshTreeData,
  addGuiData,
  setDisableYear,
} = partenerTreeSlice.actions;

export default partenerTreeSlice.reducer;
