import { useForm } from "@mantine/form";
import React, { useState } from "react";
import {
  Button,
  Box,
  Grid,
  Space,
  FileInput,
  LoadingOverlay,
} from "@mantine/core";

import axios from "axios";
import { ApiLink } from "../../../Constant/ApiLink";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RouteLink } from "../../../Constant/RouterLink";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";

export const BenchmarkForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);

  const form = useForm({
    initialValues: {
      file: null,
    },
    validate: {},
  });

  const handleSubmit = () => {
    setLoading(true);
    try {
      ApiCall.axiosInstance.post(
        `${process.env.REACT_APP_API_URL}${ApiLink.Benchmark}?year=${reportSelection.selectedYear}`,
        form.values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoading(false);
      navigate(RouteLink.Benchmark_List_Uri);
    } catch (error) {
      console.error("Error Group fetching data:", error);
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(RouteLink.Benchmark_List_Uri);
    setLoading(false);
  };

  return (
    <Box>
      {loading && (
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      )}
      <Space></Space>

      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid>
          <Grid.Col span={6}>
            <FileInput
              mt={0}
              label="Upload Excel or CSV"
              accept=".csv,.xlsx"
              {...form.getInputProps("file")}
              placeholder="Upload file"
              rightSectionPointerEvents="none"
            />
          </Grid.Col>
        </Grid>
        <Grid justify="end">
          <Grid.Col span={12} align="end">
            <Button type="primary" onClick={() => handleBack()}>
              Back
            </Button>
            <Button
              // disabled={method == "put"}
              type="primary"
              mt="sm"
              ml={5}
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Box>
  );
};

export default BenchmarkForm;
