import { nFormatter } from "../../../Shared/NumberFunctions";

export const FinancialSummaryColumns = [
  {
    header: "EC Name",
    accessorKey: "ecName",
  },
  {
    header: "Submission Method",
    accessorKey: "submissionMethod",
  },
  {
    header: "TIN",
    accessorKey: "tin",
  },

  {
    header: "NPI",
    accessorKey: "npi",
  },
  {
    header: "Projected MIPS Score",
    accessorKey: "projectedMIPSScore",
  },
  {
    header: "CMS MIPS Score",
    accessorKey: "CMSMIPSScore",
  },
  {
    header: "2020 Medicare",
    accessorKey: "basicMedicare",
    accessorFn: (row) => {
      return nFormatter(row.basicMedicare, 2);
    },
    Cell: ({ cell }) => cell.getValue(),
  },
  {
    header: "Regulatory Low",
    accessorKey: "regulatoryLow",
    accessorFn: (row) => {
      return nFormatter(row.regulatoryLow, 2);
    },
    Cell: ({ cell }) => cell.getValue(),
  },
  {
    header: "Projected Results",
    accessorKey: "projectedResults",
  },
  {
    header: "CMS Results",
    accessorKey: "CMSResults",
  },
  {
    header: "Target Results",
    accessorKey: "targetResult",
    accessorFn: (row) => {
      return nFormatter(row.targetResult, 2);
    },
    Cell: ({ cell }) => cell.getValue(),
  },
  {
    header: "Regulatory High",
    accessorKey: "regulatoryHigh",
    accessorFn: (row) => {
      return nFormatter(row.regulatoryHigh, 2);
    },
    Cell: ({ cell }) => cell.getValue(),
  }

];
