import React, { useState, useRef, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Modal, Button } from "@mantine/core";
import { StateList } from "../../Shared/DropDownOptions/StateList";
const ClinicOrgModel = ({ records, onClose, onSelect }) => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  const [columnDefs] = useState([
    {
      headerName: "Name",
      field: "groupLegalBusinessName",
      minWidth: 500
    },
    {
      headerName: "Enrollment ID",
      field: "groupEnrollmentID",
    },
    {
      headerName: "Pac ID",
      field: "groupPACID",
    },
    {
      headerName: "State",
      field: "groupStateCode",
      valueGetter: (params) => {
        return StateList.find(ele => ele.value == params?.data.groupStateCode)?.label;
      }
    },
    {
      headerName: "Action",
      cellRenderer: (props) => {
        return (
          <>
            <Button onClick={() => handleSelect(props.data)}>Select</Button>
          </>
        );
      },
    },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    flex: 1,
    wrapHeaderText: true,
  }));

  useEffect(() => {
    setRowData(records);
  }, []);

  const handleSelect = (selectedRecord) => {
    onSelect(selectedRecord);
    onClose();
  };

  return (
    <Modal onClose={onClose} title="Select Orgaization" size="calc(100vw - 15rem)" opened>
      <div className="ag-theme-alpine" style={{ height: 500, marginTop: 10 }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          pagination={true}
          rowSelection={"single"}
          rowMultiSelectWithClick={true}
          animateRows={true}
        />
      </div>
    </Modal>
  );
};

export default ClinicOrgModel;
