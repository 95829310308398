import { Editor } from "@tiptap/react";

export const SecurityLevelType={
    Super:"Super",
    Partner:"Partner",
    Group:"Group",
    TIN:"TIN"
}

export const SecurityLevel = [
    { value: SecurityLevelType.Super, label: SecurityLevelType.Super },
    { value: SecurityLevelType.Partner, label: SecurityLevelType.Partner },
    { value: SecurityLevelType.Group, label: SecurityLevelType.Group },
    { value:SecurityLevelType.TIN, label: SecurityLevelType.TIN },
];

export const UserTypeType={
    Admin:"Admin",
    Editor:"Editor",
    ReadOnly:"ReadOnly",
    
}
export const UserType = [
    { value: UserTypeType.Admin, label: UserTypeType.Admin},
    { value: UserTypeType.Editor, label: UserTypeType.Editor },
    { value: UserTypeType.ReadOnly, label: UserTypeType.ReadOnly },
];

