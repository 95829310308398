import React, { useState, useRef, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate } from "react-router-dom";
import useAxios from "../../Shared/useApi";
import { Loader, Box, Button } from "@mantine/core";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import { MantineReactTable } from "mantine-react-table";
import { useDisclosure } from "@mantine/hooks";
import { RowAction } from "../../Shared/RowAction";
import { SubCategoryColumn } from "./SubCategoryColumn";
import { ApiLink } from "../../Constant/ApiLink";
import axios from "axios";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";



const SubCategoryList = () => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  var navigate = useNavigate();

  const defaultColDef = useMemo(() => ({
    sortable: true,
    flex: 1,
  }));

  const { response, loading, error } = useAxios({
    method: "get",
    url: "/SubCategory",
    headers: null,
    body: null,
  });

  useEffect(() => {
    if (response !== null) {
     
      setRowData(response);
    }
    if (error) {
     
    }
  }, [response, error]);


  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    
  };

  const onCreate = () => {
    navigate(RouteLink.SubCatagory_Create_Uri, {
      state: {
        data: null,
        method: "post",
      },
    });
  };

  const onUpdate = (value) => {
   
    navigate(RouteLink.SubCatagory_Update_Uri, {
      state: { data: value, method: "put" },
    });
  };

  const [deletingRow, setDeletingRow] = useState({});
  const onDelete = (value) => {
   
    setDeletingRow(value);
    open();
  };

  const [opened, { close, open }] = useDisclosure(false);

  const columnDefs = useMemo(() => SubCategoryColumn);

  const [formLoading, setLoading] = useState(false);

  function IsConfirmed(value) {

    if (value === true) {

      setLoading(true);
      ApiCall.axiosInstance["delete"](
        `${process.env.REACT_APP_API_URL}${ApiLink.SubCategory_Uri}?id=${deletingRow.subCategoryId}`
      )
        .then((response) => {
         
          setLoading(false);
          setRowData(oldValues => {
            return oldValues.filter(rowData => rowData.subCategoryId !== deletingRow.subCategoryId)
          })

        })
        .catch((error) => {
          console.error("Failed Request:", error);
          setLoading(false);
        });
    }
  }


  return (
    <Box style={{ marginTop: "15px" }}>
      <DeleteConfirmationModal IsConfirmed={IsConfirmed} opened={opened} open={open} close={close} />
      <Button type="primary" onClick={() => onCreate()}>
        Create Sub Category
      </Button>

     
      <MantineReactTable
        columns={columnDefs}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn='first'
        renderRowActions={(row) => (
          <RowAction onUpdate={() => onUpdate(row.row.original)} onDelete={() => onDelete(row.row.original)} />
        )}
        mantinePaperProps={{ shadow: '0', withBorder: false }}
      />

    </Box>
  );
};

export default SubCategoryList;