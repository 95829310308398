import React, { useState, useRef, useEffect, useMemo } from "react";

import { useNavigate } from "react-router-dom";
import { Anchor, Badge, Box, Button, Grid, Group, Select, Switch, Tooltip } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useDisclosure } from "@mantine/hooks";
import { useDispatch, useSelector } from "react-redux";
import { RouteLink } from "../../Constant/RouterLink";
import { ApiLink } from "../../Constant/ApiLink";
import axios from "axios";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import { IconEdit, IconFile, IconTrash } from "@tabler/icons-react";
import { IconRefresh } from "@tabler/icons-react";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";
import { setSelectedGrupId } from "../../utils/patenerTreeSlice";
import { addChangeOnEdit, addClinicId } from "../../utils/selectedClinicSlice";



const CPIAList = () => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  var navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [category, setCategory] = useState("Ia");
  const selectedGroupId = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );
  
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  

  useEffect(() => {
      getCSVdataList(selectedGroupId)
  }, [selectedGroupId,reportSelection.clinicId,reportSelection.selectedYear,category]);


  

  const getCSVdataList = (selectedGroupId) => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}${ApiLink.CPIAMeasureListTinorGroup}/${selectedGroupId}/${reportSelection.clinicId}/${user.level}/${reportSelection.selectedYear}`
      )
      .then((response) => {
        setRowData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setRowData([]);
      });
  };
   // Define columns for the table
   const columnsDef = useMemo(() => [
    {
      header: "Guid",
      accessorKey: "randomFileId",
      id: "randomFileId", // Ensure this matches the key in initialState
    },
    {
      header: "TIN",
      accessorKey: "tin",
    },
    {
      header: "Name",
      accessorKey: "name",
    },
    {
        header: "Created Date",
        accessorFn: (row) => {
          const date = new Date(row.createdDate);
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
          
          // If the year is 1 (e.g., DateTime.MinValue in .NET), return "-"
          if (year === 1) {
            return "-";
          }
          
          return `${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}-${year}`;
        },
      },
    // {
    //     header: "Is Submitted",
    //     accessorKey: "isSubmitted",
    //     Cell: ({ cell }) => {
    //       const isSubmitted = cell.getValue();
    //       return (
    //         <Badge color={isSubmitted ? "green" : "red"}>
    //           {isSubmitted ? "Submitted" : "Not Submitted"}
    //         </Badge>
    //       );
    //     },
    //   },
    {
      header: "Measure Count",
      accessorKey: "measureCount",
    },
  ], []);


  const onCreate = () => {
    navigate(RouteLink.IA_Uri, {
      state: { data: null, method: "post" },
    });
  };

  const onUpdate = (value) => {
   
    console.log(value);
     dispatch(setSelectedGrupId(value.groupId));
     dispatch(addClinicId(value.clinicId));
     dispatch(addChangeOnEdit(value.clinicId));

    navigate(RouteLink.IA_Uri, {
      state: { data: value, method: "put" },
    });


  };

  
 

 

  return (
    <Box style={{ marginTop: "15px" }}>
      
      <Grid>
        {/* <Grid.Col span={2}>
        <Group position="apart">
          <Button type="primary" onClick={() => onCreate()}>
            Sumission Measuse IA
          </Button>
         
        </Group>
         
        </Grid.Col> */}
       
        <Grid.Col span={9}></Grid.Col>
        <Grid.Col span={3}>
         
        </Grid.Col>
      </Grid>
      <MantineReactTable
        columns={columnsDef}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn="first"
        renderRowActions={(row) => (
          <RowAction onUpdate={() => onUpdate(row.row.original)} />
        )}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
        initialState={{ columnVisibility: { randomFileId: false } }}
      />
    </Box>
  );
};

export default CPIAList;

const RowAction = (props) => {
  return (
    <Grid>
      <Button.Group>
        {
          <Button
            variant="filled"
            color="green"
            size="xs"
            onClick={props.onUpdate}
          >
            <IconEdit size="1.2rem"></IconEdit>
          </Button>
        }
      </Button.Group>
    </Grid>
  );
};
