import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from './AuthSlice';

const InactivityHandler = ({ timeout = 3600000, warningTime = 60000, redirectPath = '/' }) => {  
    // Default timeout: 60 minutes, warning 1 minute before logout
    const dispatch = useDispatch();
    useEffect(() => {
        let timer;
        let warningTimer;

        const resetTimer = () => {
            clearTimeout(timer);
            clearTimeout(warningTimer);

            // Show a warning exactly 1 minute before logout
            warningTimer = setTimeout(() => {
                alert('You will be logged out in 1 minute due to inactivity.');
            }, timeout - warningTime);

            // Logout after the full timeout
            timer = setTimeout(() => {
                dispatch(logout());
                window.location.href = redirectPath;
            }, timeout);
        };

        const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart'];
        events.forEach((event) => window.addEventListener(event, resetTimer));
        resetTimer();

        return () => {
            clearTimeout(timer);
            clearTimeout(warningTimer);
            events.forEach((event) => window.removeEventListener(event, resetTimer));
        };
    }, [dispatch, timeout, warningTime, redirectPath]);

    return null;
};

export default InactivityHandler;
