import { Box, Button } from '@mantine/core';
import { MantineReactTable } from 'mantine-react-table';
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { RouteLink } from '../../Constant/RouterLink';
import { PartnerColumn } from '../Partner/PartnerColumn';
import useAxios from '../../Shared/useApi';
import { CcmColumnDef } from './CcmColumn';
import { ApiLink } from '../../Constant/ApiLink';
import { DeleteConfirmationModal } from '../../Shared/DeleteConfirmationModal';
import { useDisclosure } from '@mantine/hooks';
import { RowAction } from '../../Shared/RowAction';
import axios from 'axios';
import { ApiCall } from '../../Shared/ApiCall/ApiCall';

const CcmList = () => {
    const navigate = useNavigate();
    const columnDefs = useMemo(() => CcmColumnDef);
    const [rowData, setRowData] = useState();
    const [loading, setLoading] = useState(false);
    const [deletingRow, setDeletingRow] = useState({});

    useEffect(() => {
        getCcmMapListData()
    }, [])

    const getCcmMapListData = () => {
        setLoading(true);
        ApiCall.axiosInstance.get(
            `${process.env.REACT_APP_API_URL}${ApiLink.CCMMaping}`
        )
            .then((response) => {
                setRowData(response.data);
                setLoading(false);
                // navigate(RouteLink.CCM_Uri);
            })
            .catch((error) => {
                console.error("Failed Request:", error);
                setLoading(false);
            });
    }
    const onFirstCCM = () => {
        navigate(RouteLink.CCM_Create_Uri, { state: { data: '', method: "post" } });
    }

    const onUpdate = (value) => {;
        navigate(RouteLink.CCM_Update_Uri, {
            state: { data: value, method: "put" },
        });
    };

    const [opened, { close, open }] = useDisclosure(false);

    function IsConfirmed(value) {
        if (value === true) {
            setLoading(true);
            ApiCall.axiosInstance["delete"](
              `${process.env.REACT_APP_API_URL}/${ApiLink.CCMMaping}?id=${deletingRow.id}`
            )
              .then((response) => {
               
                setLoading(false);
                getCcmMapListData();
              })
              .catch((error) => {
                console.error("Failed Request:", error);
                setLoading(false);
              });
          }
    }
    const onDelete = (value) => {
        setDeletingRow(value);
       
        open();

    }

    return (
        <Box style={{ marginTop: "15px" }}>
          <DeleteConfirmationModal IsConfirmed={IsConfirmed} opened={opened} open={open} close={close} />
            <Button mx={4} type="primary" onClick={() => onFirstCCM()}>
             CCM Combination
            </Button>

            <MantineReactTable
                columns={columnDefs}
                data={rowData ?? []}
                state={{ isLoading: loading }}
                enableRowActions
                positionActionsColumn='first'
                mantinePaperProps={{ shadow: '0', withBorder: false }}
                renderRowActions={(row) => (
                    <RowAction onUpdate={() => onUpdate(row.row.original)} onDelete={() => onDelete(row.row.original)} />
                )}
            />

        </Box>
    )
}

export default CcmList;