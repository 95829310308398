
export const GroupColumn =
  [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Description",
      accessorKey: "description",
    },
    {
      header: "Legal Business Name",
      accessorKey: "legalBusinessName",
      minWidth: 140
    },
    {
      header: "Partner Name",
      accessorKey: "partnerName",
    },
    {
      header: "Group Status",
      accessorKey: "isActive",
      Cell: ({ cell }) => {
        return <div>{cell.getValue() ? "Active" : "InActive"}</div>;
      },
    }
  ]
