export const AppUrl = {
  baseUrl: "https://macamonitorapi.azurewebsites.net/v1",
};

export const ApiLink = {
  User_Uri: "/User",
  User_login: "/User/Login",
  Partner_Uri: "/Partner",
  Category_Uri: "/Category",
  SubCategory_Uri: "/SubCategory",
  Requirement: "/Requirement/GetRequirements",
  PublicFiles: "/PublicFiles/GetAll",
  Group_Uri: "/Group",
  Physician_Uri: "/Physicians",
  Physician_GetPecosClinic: "/Physicians/getpecosclinic",
  Clinic_Uri: "/Clinic",
  Clinic_Uri_Physicians: "/Clinic/GetPhyBaseOnClinicEnrollment",
  Get_ClinicGroup: "/Clinic/GetClinicGroup",
  Clinic_Uri_Attestation:
    "/ClinicAttestationHistory/GetClinicAttestationEntityYear",
  Clinic_Uri_ClinicDetailsByEnrollmentId: "/Clinic/ClinicDetailsByEnrollmentId",
  Clinic_Uri_GetNPI_OF_TIN_EnrollmentID: "/DataCmsApiControllers/GetTinNPI",
  Clinic_Uri_GetSingleClinicByEnrollmentId:
    "/Clinic/GetSingleClinicByEnrollmentId",
  Clinic_Uri_ViewPhysiciansCount: "/Clinic/GetPhyBaseOnClinicEnrollment",
  Entity_Type: "/EntityType",
  AttestationHistory: "/AttestationHistory",
  GetAttestationHistory: "/AttestationHistory/GetPhysicianByEnrollID/",
  ClinicAttestationHistory: "/ClinicAttestationHistory",
  GetClinicAttestationHistory: "/ClinicAttestationHistory/GetClinicByEnrollID/",
  ReqSet_Uri: "/ReqSet",
  Get_ShortClinics: "/Clinic/ClinicNames",
  Get_EntitiesReport: "/financial/EntitiesReport",
  Get_CCMReports: "/financial/CCMReports",
  UpdateAssumtionTarget: "/Financial/UpdateAssumtionTarget",
  GetAssumtionTarget: "/Financial/GetAssumtionTarget?clinicEnrollmentId=",
  Get_FinancialSummary: "/financial/FinancialSummary",
  Get_FinancialDetails: "/financial/FinancialDetails",
  Get_CompomnentDetails: "/financial/ComponentDetails",
  Get_TinSummary: "/Financial/TinSummary",
  QppMismatch: "/Financial/QppMismatch",
  GetPartnersDetails: "/Partner/GetPartnerTree/1",
  GetPartnersTreeById: "/Partner/GetPartnerTree",

  ActionItem: "ActionItem",
  FileUpload:"ActionItem/FileUpload",
  GetActionItemByEnrollment: "/ActionItem/GetActionItemByEnrollmentId",

  EntityTypewithReqSetByTin: "/Financial/EntityTypewithReqSetByTin",
  FinancialReports: "FinancialReports",
  GetPhyBaseOnClinicEnrollment: "/Clinic/GetPhyBaseOnClinicEnrollment",
  createCcmColllection: "createCcmColllection",
  ccm: "/CCM",
  CCMMaping: "/CCMMaping",
  ClinicAttestationHistoryUpdate:
    "ClinicAttestationHistory/UpdateMeetingStatus",
  Get_CCMBills: "/CCMBills",
  getPhysicianByEnrollmentId: "/Physicians/GetPhysicians",
  getPhysiciansQpp: "/Physicians/GetPhysiciansQpp",
  Meeting: "/Meeting",
  GetMannualpecosclinic: "/Physicians/getMannualpecosclinic",
  StatusGroup: "/StatusGroup",
  AttestationClinicType: "/Clinic/AttestationClinicType",
  MeetingStatus: "/Clinic/MeestingStatus",
  GetNpiByName: "/Physicians/getnppes/byname",
  GetPhysicianDetialsNPI: "/Physicians/GetPhysicianDetialsNPI",
  GetPhysicianByName: "/Physicians/getnpibyname",
  GetClinicDetailsByNPI: "/Clinic/ClinicDetailsByNPI",
  ActivateDeletedTin: "/Clinic/ActivateDeletedTin",
  DeletePermanentTin: "/Clinic/DeletePermanentTin/",
  GetQppDataFromQppApiBasedOnNPIandYear:
    "/Physicians/GetQppDataFromQppApiBasedOnNPIandYear",
  GUIDYear: "/GUIDYear",
  UpdateClinicNewPhysicians: "/Clinic/UpdateClinicNewPhysicians",
  DataCmsApiControllers:
    "/DataCmsApiControllers/GetTinNameFromReValidationApi/",
  ReValidatePhysiciansYearWiseClinic:
    "/Clinic/ReValidatePhysiciansYearWiseClinic",

  FileUpload: "FileUplaod/FileUpload",
  MeasureCSV: "/FileUplaod/MeasureCSV",
  TransferQppDataFromToYear: "/Financial/TransferQppDataFromToYear",
  CSVFormat: "/CSVFormat",
  CSVFormatDownloadCSVFormat: "/CSVFormat/DownloadCSVFormat",
  MeasureCSVUpload: "/MeasureCSVUpload",
  GetScalingFactorofClinic:
    "/ClinicAttestationHistory/GetScalingFactorofClinic",
  UpdateScalingFactor: "/ClinicAttestationHistory/UpdateScalingFactor",
  GetLogDataList: "/MeasureCSVUpload/GetLogDataList/",

  //quality
  QualityHome: "/Quality/QualityHome/",
  CQMSelector: "/Quality/CQMSelector/",
  ECComparisionTool: "/Quality/ECComparisionTool/",
  GroupCQMDetails: "/Quality/GroupCQMDetails/",
  GroupCQMSummary: "/Quality/GroupCQMSummary/",
  ECSubmissionStatus: "/Quality/ECSubmissionStatus/",
  QualityCalculationPhysicianWise: "/Quality/QualityCalculationPhysicianWise/",
  QualityPhysician: "/Quality/QualityPhysician/",
  QualityPhysicianScore: "/Quality/PhysicianScore/",
  QualityCalculationTinWise: "/Quality/QualityCalculationTinWise/",
  QualityMeasures: "/Quality/QualityMeasures/",
  QualityCalculationMeasureWise: "/Quality/QualityCalculationMeasureWise/",
  QualityCalculationGroupWise: "/Quality/QualityCalculationGroupWise/",
  QualityDownload: "/Quality/Download",

  MeasureListWithSigleorMulti: "/Requirement/MeasureListWithSigleorMulti/",
  Formula: "/Formula",
  FormulaDelete: "/Formula/FormulaDelete",
  MeasureFormulaMapping: "/Requirement/MeasureFormulaMapping",
  MeasureFormulaMappingGetList: "/Requirement/MeasureFormulaMappingGetList",
  MFMappingGetSingleMeasureByMeasureName:
    "/Requirement/MFMappingGetSingleMeasureByMeasureName/",

  Benchmark: "/Benchmark",
  GetSingleBenchmarkBasedonmYear: "/Benchmark/GetSingleBenchmarkBasedonmYear/",
  CCMEnabled: "/CCMEnabled",
  GroupLocation: "/GroupLocation",
  CCMGroupAnalysics: "/CCMBills/CCMGroupAnalysics/",
  GroupWiseLocation: "/GroupLocation/GroupWiseLocation/",

  //
  ResetPasswordwithOtp: "/User/ResetPasswordwithOtp",
  CreateNewPassword: "/User/CrerateNewPassword",
  RequirementCreate: "/Requirement",
  CPIAMeasure: "/MeasureCSVUpload/MeasureIAList",
  CPIAMeasureListTinorGroup: "/MeasureCSVUpload/MeasureIAListGroupTinWise",
  CPIAMeasureSave: "/MeasureCSVUpload/CreateMeasureIA",
  CPIAMeasureFileSave: "/FileUplaod/IAFile",
  CPIAMeasureIARequirement: "/MeasureCSVUpload/MeasureIARequirememt",

  //Submission
  SubmissionReq: "/Submission/GetSubmissionReq",
  SubmissionListReq: "/Submission/GetSubmissionList",
  SubmissionPreparjson: "/Submission/PrepareJson",
  FinalSubmissionSave: "/Submission/FinalSubmission",
  FinalViewSubmissionSave: "/Submission/FinalSubmissionView",
  FinalSubmissionVerifiedSave: "/Submission/FinalSubmissionVerified",
  SubmissionVerify: "/Submission/VerifySubmission",

  //PI
  PIMeasureSave: "/MeasureCSVUpload/CreateMeasurePI",
  PIMeasure: "/MeasureCSVUpload/MeasurePIList",
  PIMeasureListTinorGroup: "/MeasureCSVUpload/MeasurePIListGroupTinWise",

  //
  mvplistEntity: "/Mvp/MVPListForEntityType",
  mvplistClinicAttestation: "/Mvp/MVPListForClinicAttestation",

  //
  Get_MeasureAtlas: "/Measureatlas/MeasureatlasList",
  Get_MeasureAtlasSubmission: "/Measureatlas/MeasureatlasSubmissionList",
  Post_MeasureAtlasSubmission: "/Measureatlas/MeasureatlasSubmission",

  //
  Upload_Logo: "/FileUplaod/LogoUpload",
  Download_Logo: "/FileUplaod/DownloadLogo",
};
