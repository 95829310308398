import { configureStore } from '@reduxjs/toolkit'
import cartSlice from './cartSlice'
import counterSlice from './counterSlice'
import patenerTreeSlice from './patenerTreeSlice'
import selectedClinicSlice from './selectedClinicSlice'
import calculationSlice from './calculationSlice'
import AuthSlice from '../Pages/Login/AuthSlice'
import RegistrationSlice from '../Pages/Users/RegistrationSlice'

export const store = configureStore({
  reducer: {
    cart: cartSlice,  // registering slices
    counter: counterSlice,
    partenerTree: patenerTreeSlice,
    selectedClinic: selectedClinicSlice,
    calculatoin: calculationSlice,
    auth:AuthSlice,
    registration:RegistrationSlice
  },
})