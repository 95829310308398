import { useMemo } from "react";

const PublicFilesColumn =
    [
        {
            header: "Name",
            accessorKey: "name",
        },
        {
            header: "Description",
            accessorKey: "description",
        },


        {
            header: "Download",
            accessorKey: "filepath",
            Cell: ({ cell }) => {
                return <a href={cell.getValue()} target="_blank" >View</a>;
            },
        },
        {
            header: "Account Status",
            accessorKey: "status",
            Cell: ({ cell }) => {
                return <div>{cell.getValue() ? "Active" : "InActive"}</div>;
            },
        },

    ];
export default PublicFilesColumn;