import { ApiLink } from "../../Constant/ApiLink";
import { TextInput, Button, Box, Grid, Select, LoadingOverlay, Textarea, FileInput } from "@mantine/core";
import { Loader, Divider } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { RouteLink } from "../../Constant/RouterLink";
import ClinicAttestationHistory from "./ClinicAttestationHistory";
import ClinicOrgModel from "./ClinicOrgModel";
import { StateList } from "../../Shared/DropDownOptions/StateList";
import ClnicPhysicianModel from "./ClinicPhysicianModel";
import { useDispatch, useSelector } from "react-redux";
import { refreshTreeData, setDisableYear, setSelectedGrupId } from "../../utils/patenerTreeSlice";
import { addChangeOnEdit, addClinicId } from "../../utils/selectedClinicSlice";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

function PhysicianForm() {
  const navigate = useNavigate();
  const [data]  = useState(JSON.parse(localStorage.getItem('clinicData'))?.data);
  const [method] = useState(JSON.parse(localStorage.getItem('clinicData'))?.method);
  const [loading, setLoading] = useState(false);
  const [entitytype, setentitytype] = useState([]);
  //for Group State
  const [Group, setGroup] = useState([]);
  const dispatch = useDispatch();
  const [IsShowEntityType, setIsShowEntityType] = useState(true);
  const guiData = useSelector((store) => store.partenerTree.guiData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPhysicianModelOpen, setIsPhysicianModelOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedRecordPhysician, setSelectedRecordPhysician] = useState(null);
  const [records, setRecords] = useState([]);
  const [physicianRecord, setPhysicianRecord] = useState([]);
  const [orgName, setOrgName] = useState(data?.name);
  const [attestationClinicDD, setAttestationClinicDD] = useState([]);
  const [attesttationHistoryData, setAttestationHisoryData] = useState(null);
  // const [lasTName, setLastName] = useState('');
  //popup-----------------------------

  //attestationClinic
  const [isAttestation, setisAttestation] = useState(false);
  const [reqSet, setReqSet] = useState([]);
  const selectElement = document.getElementById('select');
    //
    const { user } = useSelector((state) => state.auth);
    const partenerTreeItems = useSelector((state) => state.partenerTree.partenerTreeItem);
    const selectedGroupId = useSelector((store) => store.partenerTree.selectedGroupId);
    const reportSelection = useSelector((store) => store.selectedClinic.item);
    const [preview, setPreview] = useState(null);


    const form = useForm({
      initialValues: {
        id: data?.id,
        name: data?.name,
        npi: data?.npi,
        pacId: data?.pacId,
        tin: data?.tin,
        director: data?.director,
        sitePracticeManager: data?.sitePracticeManager,
        additionalContact: data?.additionalContact,
        status: data?.status ?? true,
        lastValidationDate: new Date(data?.lastValidationDate ?? new Date()),  
        enrollmentId: data?.enrollmentId,
        email: data?.email,
        attestationStatus: data?.attestationStatus,
        submissionMetohd: data?.submissionMetohd,
        attestationClinicType: data?.attestationClinicType,
        classifyTin: data?.classifyTin,
        entityId: data?.entityId?? null,
        logo: data?.logo || "", // stores the uploaded file name
        file: null,
        group: {
          id: data?.group?.id ? data?.group.id : null,
          name: data?.group?.name,
        },
        address: {
          id: data?.address?.id,
          line1: data?.address?.line1,
          line2: data?.address?.line2,
          line3: data?.address?.line3,
          city: data?.address?.city,
          state: data?.address?.state,
          zip: data?.address?.zip ?? 0,
        },
      },
  
      validate: {
        name: (value) => (!value || value === "" ? "Name is required!" : null),
        npi: (value) => (!value || value === "" ? "ORG-NPI is required!" : null), 
        entityId: (value) => ((!value || value === "") && method != 'put' ? "Entity Type is required!" : null),
        enrollmentId: (value) =>
          !value || value === ""
            ? "Pecos Enrollment Id is required!"
            : null,
        status: (value) =>
          (typeof value !== 'boolean') ? "Clinic Status is required!" : null,
        attestationStatus: (value) =>
          (typeof value !== 'boolean') ? "Attestation Status is required!" : null,
        attestationClinicType: (value) =>
          (!value || value === '') ? "Attetation Clinic type is required!" : null,
        
        lastValidationDate: (value) =>
          !value || value === "" ? "Comment is required!" : null,
        group: {
          id: (value) =>
            !value || value === "" ? "Group is required!" : null,
        },
  
        address: {
          state: (value) =>
            !value || value === "" ? "State is required!" : null,
          
        },
      },
    });

  //bind  Group
  useEffect(() => {

    let levelId = 0;
    if(user.level === 0 )
    {  
      levelId=0
    }
    if(user.level === 1 )
      {  
        levelId=partenerTreeItems[0]?.id ;
      }
      if(user.level === 2 )
        {  
           //levelId=selectedGroupId ;
           levelId=partenerTreeItems[0]?.id ;
        }
        if(user.level === 3 )
          {  

            //levelId=reportSelection.clinicId ;
            levelId=partenerTreeItems[0]?.id ;
          }

    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.Group_Uri}/${levelId}?userlevel=${user?.level}&id=${levelId}`)
      .then((response) => {
        setGroup(response.data);
        // form.setFieldValue('group.id', Number(selectedGroupId));
        
        if(user?.level >= 1)
          {
            form.setValues({group : {id:  Number(3),} });
          }
        getEntityTypeData();
       
      })
      .catch((error) => {
        console.error("Error Group fetching data:", error);
      });
  }, []);
  //bind  Group

  //
  //popup-----------------------------
  

  //show attestation div base on conditions it run only page load + tin
  useEffect(() => {
    if (data !== null) {
      setisAttestation(true);
      if(method == 'put'){
        getPhysicianCount(data?.id)
        dispatch(setSelectedGrupId(data?.group?.id));
        dispatch(addClinicId(data?.id));
        dispatch(addChangeOnEdit(data?.id))
      }
    }
    dispatch(setDisableYear(true))
    return () => {
      dispatch(setDisableYear(false))
      localStorage.removeItem('clinicData');
    }
  }, [data]);

  //ReqSet
  useEffect(() => {
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.ReqSet_Uri}`)
      .then((response) => {
        // Extract "reqId" and "title" fields from the JSON data and store them in the state.
        const extractedData = response.data.map((item) => ({
          reqId: item.reqId,
          title: item.title,
        }));

        setReqSet(extractedData);
      })
      .catch((error) => {
        console.error("Error Group fetching data:", error);
      });
  }, []);

  //ReqSet
  //show ateestation div base on conditions
  //

  const handleSubmit = (formValues) => {
    formValues['year'] = reportSelection.selectedYear;
    setLoading(true);
    ApiCall.axiosInstance[method](`${ApiLink.Clinic_Uri}`, formValues)
      .then((response) => {
        setLoading(false);
        dispatch(refreshTreeData());
        navigate(RouteLink.Clinic_Uri);
      
      })
      .catch((error) => {
        let errorData = error?.response?.data?.operationResults[0];
        if(errorData.code === 400){
          alert(errorData?.message + '!');
          console.error("Failed Request:", error);
        }
        setLoading(false);
      });
  };

  const handleBack = () => {
    navigate(RouteLink.Clinic_Uri);
    setLoading(false);
  };

 

  const DividerStyle = {
    marginTop: "2rem",
    marginBottom: "2rem",
  };


  useEffect(() => {
    if (selectedRecord) {
      setOrgName(selectedRecord?.groupLegalBusinessName);
      form.setValues({
        name: selectedRecord?.groupLegalBusinessName?.toUpperCase(),
        pacId: selectedRecord?.groupPACID,
        enrollmentId: selectedRecord?.groupEnrollmentID,
        address: {
          state:  selectedRecord?.groupStateCode
        }, 
      });
      if(selectedRecord?.groupPACID){
        getPhysicianCount(selectedRecord?.groupPACID)
      }
      getMoreInformationaboutOrg(selectedRecord?.groupEnrollmentID);
      getTinNpI(selectedRecord?.groupEnrollmentID);
    }
  }, [selectedRecord]);

  
  useEffect(() => {
    if (selectedRecordPhysician) {
      setOrgName(selectedRecord?.orgName);

      form.setValues({
        name: 'Anand',
        npi: selectedRecord?.npi,
        enrollmentId: selectedRecord?.enrollmentId,
        address: {
          state:  selectedRecord?.state
        }, 
      });

      getMoreInformationaboutOrg(selectedRecord?.enrollmentId);  
      getTinNpI(selectedRecord?.enrollmentId);  
    }
  }, [selectedRecordPhysician]);
  //----------------------------

  useEffect(()=> {
    if(form?.values?.group?.id){
      getAttestationClinicType();
    }
  },[form?.values?.group?.id])

  const getAttestationClinicType = () => {
    setLoading(true)
    ApiCall.axiosInstance.get(
      `${process.env.REACT_APP_API_URL}${ApiLink.AttestationClinicType}/${form?.values?.group?.id}`
    )
      .then((response) => {
        const data = response.data.map(ele => {
          return {
            value: ele.id,
            label: ele.name
          }
        })
        setAttestationClinicDD(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        setLoading(false)
      });
  }

  const handleOpenModal = (e) => {
    setLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.DataCmsApiControllers}${guiData[0]?.uuid}/${orgName}`
      )
      .then((response) => {
        setLoading(false);
        setRecords(response?.data);
        setIsModalOpen(true);

      })
      .catch((error) => {
        console.error("get NPI records Fails:", error);
        setLoading(false);
      });
  };

  const getPhysicianCount = (clinicId) => {
   
    setLoading(true);
    ApiCall.axiosInstance
    .get(
       // `https://macamonitorapi.azurewebsites.net/v1/Clinic/GetPhyBaseOnClinicEnrollment/${enrollmentid}/${0}`
      `${process.env.REACT_APP_API_URL}${ApiLink.Clinic_Uri_ViewPhysiciansCount}/${clinicId}/0`
    )
    .then((response) => {
      setLoading(false);
      setPhysicianRecord(response?.data);
    })
    .catch((error) => {
      console.error("get NPI records Fails:", error);
      setLoading(false);
    });
  }

  const handleOpenModalPhysician = () => {
    setIsPhysicianModelOpen(true);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClosePhysicin = () => {
    setIsPhysicianModelOpen(false)
  }
  const handleSelectRecord = (record) => {
    setSelectedRecord(record);
  };

  const handleSelectRecordPhysician = (record) => {
    setSelectedRecordPhysician(record);
  }

  //callback function after Click on Get Button for more information of clinic
  const getMoreInformationaboutOrg = (enrollmentid) => {

    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.Clinic_Uri_ClinicDetailsByEnrollmentId}/${enrollmentid}`
      )
      .then((response) => {
        const records = response.data;



        form.setValues({
          sitePracticeManager: `${records.results[0].basic.authorized_official_last_name || ''},${records.results[0].basic.authorized_official_first_name || ''}`,
          additionalContact: String(records.results[0].basic.authorized_official_telephone_number),
          
          address: {
            line1: records.results[0].addresses[0].address_1,
            city: records.results[0].addresses[0].city,
            zip: records.results[0].addresses[0].postal_code.slice(0, 5),
            state: records.results[0].addresses[0].state,
          }

        })

      })
      .catch((error) => {
        console.error("get NPI records Fails:", error);

      });

  }

  //Get Tin NPI
  const getTinNpI = (enrollmentid) => {

    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.Clinic_Uri_GetNPI_OF_TIN_EnrollmentID}/${enrollmentid}`
      )
      .then((response) => {
        const records = response.data;

        form.setValues({
          npi: records[0].npi,
          // additionalContact: String(records.results[0].basic.authorized_official_telephone_number),
        })

      })
      .catch((error) => {
        console.error("get NPI records Fails:", error);

      });

  }

  const setOrgNameOnchange = (e) => {
    setOrgName(e.currentTarget.value.toUpperCase())
    form.setValues({
      name: e.currentTarget.value.toUpperCase()
    });
  }

  const generateRandomIds = () => {
    const min = Math.pow(10, 13);
    const max = Math.pow(10, 14) - 1;
    const id = 'R' + Math.floor(Math.random() * (max - min + 1)) + min

    form.setValues({
      pacId : id.toString().slice(0,15)
    });
   
  };

  function setEntityTypeByGroupSlect(groupId) {
    const filterByGroup = Group.filter(row => row.id == groupId)[0];
    if (filterByGroup) {

      const entityTypeId = filterByGroup.entityTypeId;
      if (entityTypeId != undefined){
        form.setValues({
          entityId: entityTypeId
        })
      }
        // form.getInputProps("entityId").onChange(entityTypeId);
    }
  }

  const handleGroupChange = (e) => {
    // if(method !== 'put'){
    //   return;
    // }
    const pevSelectedGroup = form?.values?.group?.id;
    if(method == 'put' && !window.confirm('If you change the Group, the Meeting Status will reset to Empty!')){
      form.setValues({
        group: {
          id: pevSelectedGroup
        }
      })
    }else{
      form.setValues({
        group: {
          id: e
        }
      })
    }
    setEntityTypeByGroupSlect(e)
  }

  const getEntityTypeData = () => {
    ApiCall.axiosInstance.get(`${process.env.REACT_APP_API_URL}${ApiLink.Entity_Type}`)
      .then(response => {
        setentitytype(response?.data);
      })
      .catch(error => {
        console.error('Error Entity fetching data:', error);
      });
  }

  const updateDataForRevalidation = (attestationHistoryData) => {
    setAttestationHisoryData(attestationHistoryData)
  }

  const handleValidateTin = () => {
    if(!window.confirm("Are you sure to revalidate Tin?")){
      return;
    }
    const payload = {
      ...data,
      year: reportSelection.selectedYear,
      entityId: attesttationHistoryData.entityID,
    };

    ApiCall.axiosInstance.post(`${process.env.REACT_APP_API_URL}${ApiLink.ReValidatePhysiciansYearWiseClinic}`, payload)
      .then(response => {
      })
      .catch(error => {
        console.error('Error Entity fetching data:', error);
      });
  }

  const handleCreateAction= () => {
    navigate(RouteLink.CreateActionItem, {
      state: {
        data: {
          clinicId: form.values.id
        },
        method: "post",
      },
    });
  }

  // When editing (PUT), fetch and display the existing image
  useEffect(() => {
    if (method === "put" && data?.logo) {
      axios
        .get(
          `https://localhost:7076/v1/FileUplaod/downloadLogo/${data.logo}`,
          { responseType: "blob" }
        )
        .then((response) => {
          const imageUrl = URL.createObjectURL(response.data);
          setPreview(imageUrl);
        })
        .catch((error) => {
          console.error("Error fetching logo image", error);
        });
    }
  }, [method, data]);

  
  const handleFileChange = async (file) => {
      form.setFieldValue("file", file);
    
      if (file) {
        const fileUrl = URL.createObjectURL(file);
        setPreview(fileUrl);
    
        const formData = new FormData();
        formData.append("file", file, file.name);
    
        try {
          const response = await ApiCall.axiosInstance.post(
            `${process.env.REACT_APP_API_URL}${ApiLink.Upload_Logo}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "text/plain",
              },
            }
          );
    
          const uploadedFileName = response.data;
          form.setFieldValue("logo", uploadedFileName);
        } catch (error) {
          console.error("File upload error:", error);
          // Optional: show error toast or fallback
        }
      } else {
        setPreview(null);
        form.setFieldValue("logo", ""); // Optional: reset logo if no file
      }
    };

    const handleRemoveImage = () => {
      setPreview(null);
      form.setFieldValue("logo", null);
      form.setFieldValue("file", null);
    };

  return (
    <Box>
     {loading &&
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      }
      <h5>Clinic: Add New</h5>

      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid>
          <Grid.Col span={4}>
            <TextInput
              label="Name: "
              placeholder="Name"
              {...form.getInputProps("name")}
              value={orgName}
              onChange={(event) => setOrgNameOnchange(event)}
            />
          </Grid.Col>
          <Grid.Col span={2}>
          <TextInput
              label="PECOS Enrollment IDs: "
              placeholder="Enrollment ID"
              {...form.getInputProps("enrollmentId")}
              readOnly
            />
            
          </Grid.Col>
          <Grid.Col span={1}>
            <Button
              style={{ marginTop: "25px", float: "right" }}
              onClick={handleOpenModal}
            >
              Get
            </Button>
            {isModalOpen && (
              <ClinicOrgModel
                records={records}
                onClose={handleCloseModal}
                onSelect={handleSelectRecord}
              />
            )}
          </Grid.Col>
         <Grid.Col span={2}>
            <Button
              disabled={!form?.values?.enrollmentId}
              style={{ marginTop: "25px"}}
              onClick={() => handleOpenModalPhysician(true)}
            >
             View ({physicianRecord?.length}) Physician
            </Button>
            {isPhysicianModelOpen && (
              <ClnicPhysicianModel
                enrollmentId={form?.values?.id}
                year={reportSelection.selectedYear}
                onClose={handleClosePhysicin}
                onSelect={handleSelectRecordPhysician}
              />
            )}
          </Grid.Col>
          <Grid.Col span={1}>
            <Button
              style={{ marginTop: "25px", float: "right" }}
              onClick={handleCreateAction}
            >
              Action
            </Button>
          </Grid.Col>
          <Grid.Col span={2}>
            <Button
              style={{ marginTop: "25px", float: "right" }}
              onClick={handleValidateTin}
            >
              Revalidate
            </Button>
          </Grid.Col>
        </Grid>
        {/* {JSON.stringify(form)} */}
        <h5 style={{ color: "green" }}>Attestation Clinic</h5>
        <Grid>
         
           <Grid.Col span={9}>
             <Grid>
             <Grid.Col span={4}>
            <Select
              label="Group"
              withAsterisk
              searchable
              // id="select"
              // disabled={method == 'put'}
              // nothingFound="No options"
              // clearable
              {...form.getInputProps("group.id")}
              data={Group.map((ele) => ({
                value: ele.id,
                label: ele.name,
              }))}
              onChange={(e) => handleGroupChange(e)}
              disabled={user?.level >= 2}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput
              label="ORG-NPI: "
              placeholder="NPI"
              {...form.getInputProps("npi")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput
              label="TIN: "
              placeholder="TIN"
              {...form.getInputProps("tin")}
            />
          </Grid.Col>
        
        
          <Grid.Col span={3}>
            <TextInput
              label="Director: "
              placeholder="Director"
              {...form.getInputProps("director")}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <TextInput
              label="Site Practice Manager: "
              placeholder="Site Practice Manager"
              {...form.getInputProps("sitePracticeManager")}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <TextInput
              label="Phone"
              placeholder="Phone"
              {...form.getInputProps("additionalContact")}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <TextInput
              label="Email"
              placeholder="Email"
              {...form.getInputProps("email")}
            />
          </Grid.Col>

             </Grid>
           </Grid.Col>

           <Grid.Col span={3}>
                             <FileInput
                               label="Upload Logo"
                               placeholder="Select file"
                               onChange={handleFileChange}
                             />
                             {preview && (
                               <>
                                 <img
                                   src={preview}
                                   alt="Preview"
                                   style={{ maxWidth: "150px", maxHeight: "auto", marginTop: "1rem" }}
                                 />
                                 <br />
                                 <Button
                                   mt="sm"
                                   variant="outline"
                                   color="red"
                                   onClick={handleRemoveImage}
                                 >
                                   Remove Image
                                 </Button>
                               </>
                             )}
                           </Grid.Col>

        </Grid>

        <Divider
          my="xs"
          size="sm"
          label="Action on Pecos Clinic"
          labelPosition="center"
          style={DividerStyle}
          color="green"
        />
        <Grid>
          <Grid.Col span={4}>
            <Select
              label="Status"
              placeholder="Clinic Status"
              {...form.getInputProps("status")}
              data={[
                { value: true, label: "Active" },
                { value: false, label: "Deactive" },
              ]}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <DateInput
              dateParser={(input) => {
                if (input === "WW2") {
                  return new Date(1939, 8, 1);
                }
                return new Date(input);
              }}
              valueFormat="MM/DD/YYYY"
              label="Last Validation Date"
              placeholder="Date input"
              {...form.getInputProps("lastValidationDate")}
            />
          </Grid.Col>
          <Grid.Col span={2}>
          <TextInput
              label="Pac Id: "
              placeholder="Pac Id"
              disabled={method == 'put'}
              {...form.getInputProps("pacId")}
            />
          </Grid.Col>
          {method == 'post' && <Grid.Col span={2}>
              <Button onClick={generateRandomIds} mt={25}>Gen PacId</Button>
          </Grid.Col>}
        </Grid>

        <Grid>
          <Grid.Col span={4}>
            <Select
              label="Attestation Status: "
              placeholder="Attestation Status"
              {...form.getInputProps("attestationStatus")}
              data={[
                { value: true, label: "Client" },
                { value: false, label: "Prospect" },
              ]}
            />
          </Grid.Col>
          <Grid.Col span={4}>
          {/* <Select
              label="Submission Method: "
              placeholder="Submission Method"
              {...form.getInputProps("submissionMetohd")}
              data={[
                { value: "Claims", label: "Claims" },
                { value: "CSV", label: "CSV" },
                { value: "CMS", label: "CMS" },
                { value: "EHR", label: "EHR" },
                { value: "Registry", label: "Registry" },
              ]}
            /> */}
            <Select
              label="Clinic Type: "
              placeholder="Clinic Attestation Type"
              {...form.getInputProps("attestationClinicType")}
              data={attestationClinicDD}
            />
          </Grid.Col>
          <Grid.Col span={4}>
             {method != 'put' && <Select
              // value={item.entityId}
              // onChange={(e) =>
              //   handleDropDownChange(e, item.enrollmentId, "entityId")
              // }
              // className="ddldropdown"

              label="Entity Type: "
              placeholder="Entity type"
              {...form.getInputProps("entityId")}
              data={entitytype.map(ele => ({
                value: ele.id,
                label: ele.name,
              }))}
            >
            </Select>}
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={12}>
              <Textarea
              label="Notes"
              placeholder="Note"
              {...form.getInputProps("classifyTin")}
            />
          </Grid.Col>
        </Grid>
        <Divider
          my="xs"
          size="sm"
          label="Address Information"
          labelPosition="center"
          style={DividerStyle}
          color="green"
        />
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="Address1: "
              placeholder="Address1"
              {...form.getInputProps("address.line1")}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Address 2: "
              placeholder="Address 2"
              {...form.getInputProps("address.line2")}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={12}>
            <TextInput
              label="Address 3: "
              placeholder="Address 3"
              {...form.getInputProps("address.line3")}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={4}>
            <TextInput
              label="City: "
              placeholder="City"
              {...form.getInputProps("address.city")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select
              label="State: "
              placeholder="State"
              {...form.getInputProps("address.state")}
              data={StateList}
              searchable
             
              maxDropdownHeight={250}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput
              label="Zip: "
              placeholder="Zip"
              {...form.getInputProps("address.zip")}
            />
          </Grid.Col>
        </Grid>

        <br></br>

        {/* //Attestation */}
        {isAttestation && data ? (
          <ClinicAttestationHistory
            values={data?.id}
            entityId={data?.entityId}
            defaultsubmission={data?.submissionMetohd}
            defaultGroupId={form?.values?.group?.id}
            year={reportSelection?.selectedYear}
            updateDataForRevalidation={updateDataForRevalidation}
          />
        ) : null}
        {/* //Attestation */}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div>
            <Button onClick={() => handleBack()}>Back</Button>
            <Button style={{ marginLeft: "10px" }} type="submit" mt="sm">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Box>
  );
}

export default PhysicianForm;
