import { useEffect, useMemo, useState } from "react";
import { MantineReactTable } from "mantine-react-table";
import {
  Box,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { ApiLink } from "../../Constant/ApiLink";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RouteLink } from "../../Constant/RouterLink";
import Swal from "sweetalert2";

const CPIAForm = () => {
  // Local state for table data and loading indicator
  const [tableData, setTableData] = useState([]);
  const [loading, setIsLoading] = useState(false);

  // Get values from Redux store
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const selectedGroupId = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  // Payload state (currently not used in the table, but preserved for future use)
  const [payload, setPayload] = useState({
    id: 0,
    name: "string",
    csvFormatId: 0,
    year: 0,
    createdDate: new Date().toISOString(),
    filePath: "string",
    status: "string",
    rCount: "string",
    rFailCount: "string",
    execptions: "string",
    randomFileId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    groupId: 0,
    clinicId: 0,
    entityType: "string",
    category: "string",
    fileType: "string",
    errorData: "string",
    errorMessage: "string",
    submissionType: "string",
    measureCSVBulkInsertDataIA: [],
  });
  
  const [weightage, setWeightage] = useState({});
  const [iaRequirementData, setIARequirementData] = useState(null);

  useEffect(() => {
    console.log("Updated weightage:", weightage);
  }, [weightage]);

  // Function to fetch table data based on selectedGroupId and reportSelection
  const getCCMEnableData = async () => {
    setIsLoading(true);
    try {
      // First API call remains unchanged
      const ccmResponse = ApiCall.axiosInstance.post(
        `${process.env.REACT_APP_API_URL}${ApiLink.CPIAMeasure}/${selectedGroupId}/${reportSelection.clinicId}/${reportSelection.selectedYear}`
      );
      // Second API call
      const iaRequirementResponse = ApiCall.axiosInstance.post(
        `${process.env.REACT_APP_API_URL}${ApiLink.CPIAMeasureIARequirement}/${selectedGroupId}/${reportSelection.clinicId}/${reportSelection.selectedYear}`
      );

      // Execute both API calls simultaneously
      const [ccmData, iaRequirementData] = await Promise.all([
        ccmResponse,
        iaRequirementResponse,
      ]);

      // Extract first object from array (assuming it always returns a single-item list)
      const iaRequirementObject = iaRequirementData.data.length > 0 ? iaRequirementData.data[0] : null;

      // Handle both responses
      setTableData(ccmData.data);
      setIARequirementData(iaRequirementObject);
    
      // Extract high & low values
      const actualHigh = iaRequirementObject?.high || 0;
      const actualLow = iaRequirementObject?.low || 0;
      // Extract reqSetMIPS data
      const reqSetMIPS = iaRequirementObject?.reqSetMIPS?.[0] || {};
      const mipsCpia = reqSetMIPS.mipsCpia || 0;
      const cpiaScore = reqSetMIPS.cpiaScore || 0;
      // Filter the first API response for `true` values
      const filteredData = ccmData.data.filter((item) => item.values === true);
      // Count occurrences of HIGH and MEDIUM in `measureWght`
      let highCount = 0;
      let mediumCount = 0;
      filteredData.forEach((item) => {
        const weight = item.measureWght?.toLowerCase();
        if (weight === "high") {
          highCount++;
        } else if (weight === "medium") {
          mediumCount++;
        }
      });
      // Calculate computed high & low values
      const computedHigh = highCount * actualHigh;
      const computedMedium = mediumCount * actualLow;
      // Compute total sum
const totalComputedValue = computedHigh + computedMedium;

// Get cqmScore from reqSetMIPS
const cqmScore = reqSetMIPS?.cpiaScore || 0;

// Ensure total does not exceed cqmScore
const numerator = Math.min(totalComputedValue, cqmScore);
      // Update the weightage state
      const newWeightage = {
        actualHigh,
        actualLow,
        computedHigh,
        computedMedium,
        numerator,
        mipsCpia,
        cpiaScore,
        totalComputedValue
      };
      setWeightage(newWeightage);
      console.log(weightage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch data whenever selectedGroupId or reportSelection changes or year
  useEffect(() => {
    if (selectedGroupId && reportSelection) {
      getCCMEnableData();
    }
  }, [selectedGroupId, reportSelection]);

  // Handle save button click (currently logs the tableData to the console)
  const handleSave = () => {
    const filteredRecords = tableData.filter((row) => row.values === true);
    // Construct the final payload
    const finalPayload = {
      ...payload,
      clinicId: reportSelection.clinicId,
      groupId: selectedGroupId,
      year: reportSelection.selectedYear,
      measureCSVBulkInsertDataIA: filteredRecords,
    };

    setIsLoading(true);
    ApiCall.axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}${ApiLink.CPIAMeasureSave}`,
        finalPayload
      )
      .then((response) => {
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Records updated successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate(RouteLink.CPIAList_Uri);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(JSON.stringify(error));
      });
  };

  // Function to open the file in a new tab/window

  const downloadFile = async (filepath) => {
    try {
      const fileName = filepath.split("/").pop();
      const apiUrl = `${process.env.REACT_APP_API_URL}${ApiLink.QualityDownload}/${fileName}?IA=IA`;
      const response = await ApiCall.axiosInstance.get(apiUrl, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("An error occurred during the download process:", error);
    }
  };

  const fileUpload = async (id) => {
    // Create a hidden file input element
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "application/json"; // Accept JSON files only

    fileInput.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        const formData = new FormData();
        // Append the file. The third parameter sets the filename.
        formData.append("file", file, file.name);
        try {
          const response = await ApiCall.axiosInstance.post(
            `${process.env.REACT_APP_API_URL}${ApiLink.CPIAMeasureFileSave}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                accept: "text/plain",
              },
            }
          );

          // Assume the API returns the filename as plain text
          const returnedFilename = response.data;

          // Update the corresponding row in tableData:
          const updatedData = tableData.map((row) => {
            if (row.id === id) {
              return {
                ...row,
                filePath: returnedFilename,
                isValidated: true,
              };
            }
            return row;
          });

          setTableData(updatedData);
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      }
    };

    // Programmatically click the file input to open the file picker
    fileInput.click();
  };

  // Define columns for the MantineReactTable
  const columns = useMemo(
    () => [
      {
        header: "Measure",
        accessorKey: "measure",
        filterVariant: "text", // Enables text filtering
      },
      {
        header: "Measure Title",
        accessorKey: "measureTitle",
        size: 500, // Sets the column width
        Cell: ({ cell }) => (
          <Tooltip label={cell.getValue()} withArrow>
            <div
              style={{
                width: "400px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {cell.getValue()}
            </div>
          </Tooltip>
        ),
      },
      {
        header: "Weightage",
        accessorKey: "measureWght",
      },
      {
        header: "Value",
        accessorKey: "values",
        Cell: ({ cell, row }) => (
          <input
            type="checkbox"
            checked={cell.getValue()}
            onChange={(e) => {
              const newData = [...tableData];
              newData[row.index].values = e.target.checked;
              setTableData(newData);
            }}
          />
        ),
      },
      {
        header: "Actions",
        Cell: ({ row }) => (
          <Group spacing="xs">
            {row.original.filePath && row.original.filePath.trim() !== "" && (
              <Button
                size="xs"
                onClick={() => downloadFile(row.original.filePath)}
              >
                Download
              </Button>
            )}
            <Button size="xs" onClick={() => fileUpload(row.original.id)}>
              Upload
            </Button>
          </Group>
        ),
      },
    ],
    [tableData]
  );

  return (
    <Box style={{ marginTop: "15px" }}>
      {loading && (
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      )}

    <Grid align="center">
      {/* Left Section: Entity, MIPS Weightage, and CPIA Score */}
      <Grid.Col span={6}>
        {iaRequirementData?.reqSetMIPS?.length > 0 && (
          <Stack spacing={12}>
            <Text fw={700} size="sm" c="gray">
              Entity:{" "}
              <Text span fw={500} size="md" c="black">
                {iaRequirementData.reqSetMIPS[0].idNamePair?.name || "N/A"}
              </Text>
            </Text>

            <Group spacing={50} align="center">
              <Text fw={700} size="sm" c="gray">
                MIPS Weightage:{" "}
                <Text span fw={500} size="md" c="black">
                  {iaRequirementData.reqSetMIPS[0].mipsCpia || "N/A"}
                </Text>
              </Text>

              <Text fw={700} size="sm" c="gray">
                CPIA Score Den.:{" "}
                <Text span fw={500} size="md" c="black">
                  {iaRequirementData.reqSetMIPS[0].cpiaScore || "N/A"}
                </Text>
              </Text>
            </Group>
          </Stack>
        )}
      </Grid.Col>

      {/* Right Section: Save Button, IA Contributions, Standalone IA */}
      <Grid.Col span={6} style={{ textAlign: "right" }}>
        
        <Group position="right" spacing="lg">
        <Button
            type="primary"
            mt="sm"
            loading={loading}
            loaderPosition="right"
            loaderProps={{ size: "sm", color: "white" }}
            onClick={handleSave}
          >
            Save
          </Button>
          {weightage && (
            <Stack spacing={8} align="flex-end">
              <Tooltip
                label={
                  weightage.cpiaScore
                    ? (
                        (((weightage.numerator * weightage.mipsCpia) / weightage.cpiaScore) /
                          weightage.mipsCpia) *
                        100
                      ).toFixed(2) + "%"
                    : "N/A"
                }
                position="top"
                withArrow
              >
                <Text>
                  <span style={{ fontWeight: "bold" }}>IA Contributions:</span>{" "}
                  {((weightage.numerator * weightage.mipsCpia) / weightage.cpiaScore).toFixed(2) +
                    "/" +
                    weightage.mipsCpia}
                </Text>
              </Tooltip>

              <Tooltip
                label={
                  weightage.cpiaScore
                    ? ((weightage.numerator / weightage.cpiaScore) * 100).toFixed(2) + "%"
                    : "N/A"
                }
                position="top"
                withArrow
              >
                <Text>
                  <span style={{ fontWeight: "bold" }}>Standalone IA:</span>{" "}
                  {weightage.numerator + "/" + weightage.cpiaScore}
                </Text>
              </Tooltip>
            </Stack>
          )}

         
        </Group>
      </Grid.Col>
    </Grid>

      <MantineReactTable
        columns={columns}
        data={tableData}
        enableColumnFilters
        enableGlobalFilter
        mantinePaperProps={{ shadow: "0", withBorder: false }}
        enablePagination={false}
        tableProps={{
          style: {
            tableLayout: "fixed", // Forces fixed layout
          },
        }}
      />

      <Grid justify="end">
        <Grid.Col span={12} align="end">
          <Button
            type="primary"
            mt="sm"
            ml={5}
            onClick={handleSave}
            loading={loading}
            loaderPosition="right"
            loaderProps={{ size: "sm", color: "white" }}
          >
            Save
          </Button>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default CPIAForm;
