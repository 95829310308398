import React, { useState, useRef, useEffect, useMemo } from "react";

import { useNavigate } from "react-router-dom";
import { Box, Button, Grid } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useDisclosure } from "@mantine/hooks";
import { useSelector } from "react-redux";
import axios from "axios";
import { DeleteConfirmationModal } from "../../../Shared/DeleteConfirmationModal";
import { ApiLink } from "../../../Constant/ApiLink";
import { RouteLink } from "../../../Constant/RouterLink";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";

const BenchmarkList = () => {
  const [rowData, setRowData] = useState();
  var navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);

  const reqColumnDefs = useMemo(() => [
    {
      header: "Benchmark Count",
      accessorKey: "count",
    },
    {
      header: "Year",
      accessorKey: "year",
    },
  ]);

  useEffect(() => {
    getBenchmarkList();
  }, [reportSelection.selectedYear]);

  const getBenchmarkList = (year) => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.Benchmark}/${reportSelection.selectedYear}`
      )
      .then((response) => {
        // setRowData(response.data);
        setRowData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const onCreate = () => {
    navigate(RouteLink.Benchmark_Uri);
  };

  const handleview = (value) => {
    
    navigate(RouteLink.Benchmark_view_Single, {
      state: { data: value },
    });
  };

  return (
    <Box style={{ marginTop: "15px" }}>
      <Grid>
        <Grid.Col span={3}>
          <Button type="primary" onClick={() => onCreate()}>
            Upload New Benchmark
          </Button>
        </Grid.Col>
      </Grid>
      <MantineReactTable
        columns={reqColumnDefs}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn="first"
        renderRowActions={(row) => (
          <Button onClick={() => handleview(row.row.original)}>View</Button>
        )}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};

export default BenchmarkList;
