import { PartnerColumn } from "./PartnerColumn";
import useAxios from "../../Shared/useApi";
import { useNavigate } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { MantineReactTable } from "mantine-react-table";
import { RouteLink } from "../../Constant/RouterLink";
import { RowAction } from "../../Shared/RowAction";
import { useDisclosure } from "@mantine/hooks";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import { ApiLink } from "../../Constant/ApiLink";
import axios from "axios";
import { Box, Button } from "@mantine/core";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";
import { useSelector } from "react-redux";



const PartnerList = () => {
  var navigate = useNavigate();
  const columnDefs = useMemo(() => PartnerColumn);
  const [rowData, setRowData] = useState();
   const { user } = useSelector((store) => store.auth);
   const partenerTreeItems = useSelector((state) => state.partenerTree.partenerTreeItem);
   const [opened, { close, open }] = useDisclosure(false);
   const [loading, setLoading] = useState(false);
  
 useEffect(() => {

    let levelId = 0;
    if (user.level === 1) {
      levelId = partenerTreeItems[0]?.id;
    }

    setLoading(true)
    ApiCall.axiosInstance.get(
      `${process.env.REACT_APP_API_URL}${ApiLink.Partner_Uri}/?userlevel=${user?.level}&id=${levelId}`
    )
      .then((response) => {
        setRowData(response.data);
        setLoading(false)
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        setLoading(false)
      });

   
  }, []);

  // when create button will click then 
  function onCreate() {
    navigate(RouteLink.CreatePartner_Uri, {
      state: {
        data: null, method: "post"
      }
    });
  }


  const onUpdate = (value) => {
   
    navigate(RouteLink.UpdatePartner_Uri, {
      state: { data: value, method: "put" },
    });
  };


  const [deletingRow, setDeletingRow] = useState({});

  const onDelete = (value) => {
    
    setDeletingRow(value);
    open();

  }
 

  function IsConfirmed(value) {
    if (value === true) {
      setLoading(true);
      ApiCall.axiosInstance["delete"](
        `${process.env.REACT_APP_API_URL}${ApiLink.Partner_Uri}?id=${deletingRow.id}`
      )
        .then((response) => {
     ;
          setLoading(false);
          setRowData(oldValues => {
            return oldValues.filter(rowData => rowData.id !== deletingRow.id)
          })
        })
        .catch((error) => {
          console.error("Failed Request:", error);
          setLoading(false);
        });
    }

  }



  return (

    <Box style={{ marginTop: "15px" }}>
      <DeleteConfirmationModal IsConfirmed={IsConfirmed} opened={opened} open={open} close={close} />
      {user.level <= 1  && (<Button onClick={onCreate}>Create Partner</Button>)}
      <MantineReactTable
        columns={columnDefs}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn='first'
        renderRowActions={(row) => (
          <RowAction onUpdate={() => onUpdate(row.row.original)} onDelete={() => onDelete(row.row.original)} />
        )}
        mantinePaperProps={{ shadow: '0', withBorder: false }}
      />

    </Box>
  )
};
export default PartnerList;