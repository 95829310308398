export const UserColumn = [
    {
        accessorKey: 'email',
        header: 'Email',
    },
   
    {
        accessorKey: 'firstName',
        header: 'First Name',
    },
    {
        accessorKey: 'lastName',
        header: 'Last Name',
    },
    {
        accessorKey: 'securityLevel',
        header: 'Security Level',
    },
    {
        accessorKey: 'role',
        header: 'Role',
    },
    {
        accessorKey: 'isLocked',
        header: 'User Status',
        Cell:(cell=>(<span>{cell?"Display":"Hide"}</span>))
    },
    {
        accessorKey: 'isAccountStatus',
        header: 'Account Status',
        Cell:(cell=>(<span>{cell?"Active":"InActive"}</span>))
    },
    
];