import React, { useState, useRef, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate } from "react-router-dom";
import useAxios from "../../Shared/useApi";
import { Loader, Box, Button } from "@mantine/core";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import { MantineReactTable } from "mantine-react-table";
import { useDisclosure } from "@mantine/hooks";
import { RowAction } from "../../Shared/RowAction";
import { EntityColumn } from "./EntityColumn";


const EntityList = () => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  var navigate = useNavigate();


  const defaultColDef = useMemo(() => ({
    sortable: true,
    flex: 1,
  }));

  const { response, loading, error } = useAxios({
    method: "get",
    url: "/EntityType",
    headers: null,
    body: null,
  });

  useEffect(() => {
    if (response !== null) {
      setRowData(response);
    }
    if (error) {
      console.error(error);
    }
  }, [response, error]);

  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
  };

  const onCreate = () => {
    navigate(RouteLink.Entity_Create_Uri, {
      state: {
        data: null,
        method: "post",
      },
    });
  };

  const onUpdate = (value) => {
    navigate(RouteLink.Entity_Upadte_Uri, {
      state: { data: value, method: "put" },
    });
  };
  const [opened, { close, open }] = useDisclosure(false);
  const columnDefs = useMemo(() => EntityColumn);
  function IsConfirmed(value) {

  }
  const onDelete = (value) => {
    open();

  }

  return (
    <Box style={{ marginTop: "15px" }}>
      <DeleteConfirmationModal IsConfirmed={IsConfirmed} opened={opened} open={open} close={close} />
      <Button type="primary" onClick={() => onCreate()}>
        Create Entity
      </Button>

      <MantineReactTable
        columns={columnDefs}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn='first'
        renderRowActions={(row) => (
          <RowAction onUpdate={() => onUpdate(row.row.original)} onDelete={() => onDelete(row.row.original)} />
        )}
        mantinePaperProps={{ shadow: '0', withBorder: false }}
      />

    </Box>
  );
};

export default EntityList;
