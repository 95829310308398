import { useMemo } from "react";

const CategoryColumn =
    [
        {
            header: "Category Name",
            accessorKey: "catName",
        },
        {
            header: "Description",
            accessorKey: "description",
        },
        {
            header: "Abbrevation",
            accessorKey: "abbrevation",
        },
        {
            header: "Sort Order1",
            accessorKey: "sortOrder_1",
        },
        {
            header: "Sort Order2",
            accessorKey: "sortOrder_2",
        },
        {
            header: "Account Status",
            accessorKey: "account",
            Cell: ((cell) => <span>{cell ? "Display" : "Hide"}</span>)
        },
        {
            header: "Criterion Status",
            accessorKey: "catstatus",
            Cell: ((cell) => <span>{cell ? "Active" : "InActive"}</span>)
        }
    ];
export default CategoryColumn;