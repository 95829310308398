import { createSlice } from "@reduxjs/toolkit";

const calculationSlice = createSlice({
    name: 'calculatoin',
    initialState: {   
        percentageDistribution : [],
    },
    reducers: {
        addPercentageDistribution: (state, action) => {
            state.percentageDistribution = action.payload;
        },
    }

})

export const {addPercentageDistribution} = calculationSlice.actions;
export default calculationSlice.reducer;