import { useState, useEffect } from 'react';
import axios from 'axios';
import { ApiCall } from './ApiCall/ApiCall';

// axios.defaults.baseURL = `https://macamonitorapi.azurewebsites.net/v1`;
ApiCall.axiosInstance.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

const useAxios = ({ url, method, body = null, headers = null}) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState('');
    const [loading, setloading] = useState(true);

    const fetchData = (secondBody=null) => {
        const finalBody = secondBody ?? body;
        ApiCall.axiosInstance[method](url, JSON.parse(headers), JSON.parse(finalBody))
            .then((res) => {
                setResponse(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setloading(false);
            });
    };

    useEffect(() => {
       
            fetchData();
    }, [method, url, body, headers]);

    return { response, error, loading };
};

export default useAxios;