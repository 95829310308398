import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import { TextInput, Button, Box, Grid, Select } from "@mantine/core";
import { ApiLink } from "../../Constant/ApiLink";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Loader } from "@mantine/core";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";
import { useSelector } from "react-redux";

function EntityForm() {
  const navigate = useNavigate();
  const { data, method } = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [mvplist, setMvplist] = useState([]);
  const displayStyle = method === "post" || "put" ? "none" : "inline";

   // Redux state
    const reportSelection = useSelector((store) => store.selectedClinic.item);
   

   // On load, call the MVP API and update mvplist state
   useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.mvplistEntity}/${reportSelection.selectedYear}`)
      .then((response) => {
        const namePairList = response.data.map((mvp) => ({
          id: mvp.id,
          name: mvp.name,
        }));
        setMvplist(namePairList);
      })
      .catch((error) => {
        console.error("Error fetching MVP list:", error);
      });
  }, [reportSelection.selectedYear]);


  const handleSubmit = (formValues) => {
    if (!formValues.defaultInvidualEntityType) {
      formValues.defaultInvidualEntityType = "";
    }
    setLoading(true);
    ApiCall.axiosInstance[method](
      `${process.env.REACT_APP_API_URL}${ApiLink.Entity_Type}`,
      formValues
    )
      .then((response) => {
        setLoading(false);
        navigate(RouteLink.Entity_Uri);
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        setLoading(false);
      });
  };

  const handleBack = () => {
    navigate(RouteLink.Entity_Uri);
    setLoading(false);
  };

  const form = useForm({
    initialValues: {
      id: data?.id,
      name: data?.name,
      financailMethodType: data?.financailMethodType,
      type: data?.type,
      defaultInvidualEntityType: 'MPISMethod',
      lowVolumneIncluded: 'Yes',
      whatIfOption: 'Yes',
      mvpId: data?.mvpId,
    },

    // functions will be used to validate values at corresponding key
    validate: {
      name: (value) =>
        value?.length < 2 || !value
          ? "Name must have at least 2 letters"
          : null,
      financailMethodType: (value) =>
        value === "" || !value ? "financail Methot Type is required" : null,
    },
  });

  return (
    <Box>
      {loading && <Loader size={48} />}
      <h5>Entity Type: Add New</h5>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid>
          <Grid.Col span={4}>
            <TextInput
              label="Name: "
              placeholder="Name"
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Select
              label="Financial Method Type: "
              placeholder="Financial Method Type"
              {...form.getInputProps("financailMethodType")}
              data={[
                { value: "", label: "Select" },
                { value: "MIPSMethod", label: "MIPS Method" },
                { value: "APMFlatMethod", label: "APM Flat Method" },
                { value: "NonParticipating", label: "Non-Participating" },
              ]}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Select
              label="Type: "
              placeholder="Type"
              {...form.getInputProps("type")}
              data={[
                { value: "", label: "Select" },
                { value: "Individual", label: "Individual" },
                { value: "Group", label: "Group" },
                { value: "ACO", label: "ACO" },
              ]}
            />
          </Grid.Col>
          <Grid.Col span={4}>
                       <Select
                         label="MVP"
                         placeholder="Select MVP"
                         clearable
                         searchable
                         {...form.getInputProps("mvpId")}
                         data={mvplist.map((ele) => ({
                           value: ele.id,
                           label: ele.name,
                         }))}
                       />
          </Grid.Col>
        </Grid>
        <Grid style={{ marginTop: "1rem" }}>

          <Grid.Col span={3} style={{ display: displayStyle }}>
            <Select
              label="Default individual Entity Type: "
              placeholder="defaultInvidualEntityType"
              {...form.getInputProps("defaultInvidualEntityType")}
              data={[
                { value: "", label: "Select" },
                { value: "MPISMethod", label: "None" },
                {
                  value: "FacilityBasedIndividual",
                  label: "Facility Based Individual",
                },
                {
                  value: "HostpitalBasedIndividual",
                  label: "Hospital Based Individual",
                },
                { value: "Individual", label: "Individual" },
                {
                  value: "IndividualFinalThreeMonth",
                  label: "Individual - Final 3 Month",
                },
                {
                  value: "LowVolumeIndividual",
                  label: "Low Volume Individual",
                },
                { value: "LowVolumeOptIn", label: "Low Valume Opt-In" },
                { value: "NonEligible", label: "Non-Eligible" },
                {
                  value: "NonPatientFacingIndividual",
                  label: "Non-pPatient Facing Individual",
                },
                { value: "NoneQPHostpital", label: "Non-QP - Hospital" },
                { value: "PQPNotFilling", label: "PQP - Non Filing" },
                { value: "PQPHostpital", label: "PQP - Hospital" },
              ]}
            />
          </Grid.Col>

          <Grid.Col span={3} style={{ display: displayStyle }}>
            <Select
              label="Low Vaolume Included: "
              placeholder="Low Volume Included"
              {...form.getInputProps("lowVolumneIncluded")}
              data={[
                { value: "", label: "Select" },
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
              ]}
            />
          </Grid.Col>
          <Grid.Col span={3} style={{ display: displayStyle }}>
            <Select
              label="What If Open: "
              placeholder="What if Option"
              {...form.getInputProps("whatIfOption")}
              data={[
                { value: "", label: "Select" },
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
              ]}
            />
          </Grid.Col>
        </Grid>

        <div style={{ marginTop: "1rem", float: "right" }}></div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div>
            <Button type="primary" onClick={() => handleBack()}>
              Back
            </Button>
            <Button style={{ marginLeft: "10px" }} type="submit" mt="sm">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Box>
  );
}

export default EntityForm;
