import { useMemo } from "react";

const RequirementColumn =
    [


        {
            header: " E-measure Id",
            accessorKey: "eMeasureId",
        },
        {
            header: "Measure Id",
            accessorKey: "measureId",
        },
        {
            header: "Tittle",
            accessorKey: "title",
        },
        {
            header: "Category",
            accessorKey: "category",
        },
        {
            header: "Metric Type",
            accessorKey: "metricType",
        },





    ];
export default RequirementColumn;