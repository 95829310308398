import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useSelector } from "react-redux";
import { ApiLink } from "../../../Constant/ApiLink";
import axios from "axios";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";

const ECSubmissionStatus = () => {
  const { id } = useParams();
  const [rowData, setRowData] = useState();
  const [loading, setIsLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const selectedGroupId = useSelector((store) => store.partenerTree.selectedGroupId);

  const columnDef = useMemo(() => [
    {
      header: "EC Name",
      accessorKey: "ecName",
    },
    {
      header: "NPI",
      accessorKey: "npi",
    },
    {
      header: "Attestation Clinic",
      accessorKey: "attestationClinic",
    },
    {
      header: "E-CQM Type",
      accessorKey: "eCQMType",
    },
    {
      header: "Submitter",
      accessorKey: "submitter",
    },
    {
      header: "Submission Status",
      accessorKey: "submissionStatus",
    },
    {
      header: "Submission Date",
      accessorKey: "submissionDate",
    },
    {
      header: "Template",
      accessorKey: "template",
    },
  ]);

  useEffect(() => {
    getECSubmissionStatus();
  }, [reportSelection.selectedYear, selectedGroupId]);

  const getECSubmissionStatus = () => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.ECSubmissionStatus}${selectedGroupId}/${reportSelection.clinicId}/${reportSelection.selectedYear}`)
      .then((response) => {
        setRowData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };


  return (
    <Box style={{ marginTop: "15px" }}>
      <MantineReactTable
        columns={columnDef}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};

export default ECSubmissionStatus;