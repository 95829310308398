import React, { useEffect, useMemo, useState } from "react";
import { ApiLink } from "../../../Constant/ApiLink";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Box, Button, Grid } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { RouteLink } from "../../../Constant/RouterLink";
import axios from "axios";
import { RowAction } from "../../../Shared/RowAction";
import useConfirm from "../../../Shared/ConfirmModel";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";

const GroupLocationList = () => {
  const [rowData, setRowData] = useState();
  var navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const confirm = useConfirm();

  const reqColumnDefs = useMemo(() => [
    {
      header: "Mac Location",
      accessorKey: "name",
      //   accessorFn: (row) => {
      //     return row?.measureName?.name;
      //   },
    },
    {
      header: "Amount",
      accessorKey: "amount",
    },
  ]);

  useEffect(() => {
    groupLocationData();
  }, []);

  const groupLocationData = (year) => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.GroupLocation}`)
      .then((response) => {
        setRowData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const onCreate = () => {
    navigate(RouteLink.Group_Location, {
      state: { data: null, method: "post" },
    });
  };

  const onUpdate = (value) => {
   
    navigate(RouteLink.Group_Location, {
      state: { data: value, method: "put" },
    });
  };

  const onDelete = async (value) => {
    
    const choise = await confirm({
      title: "Delete Record",
      description: "Are you sure to delete?",
    });
    if (choise) {
      setIsLoading(true);
      ApiCall.axiosInstance
        .delete(
          `${process.env.REACT_APP_API_URL}${ApiLink.GroupLocation}?id=${value.id}`
        )
        .then((response) => {
          setRowData(response.data);
          setIsLoading(false);
          groupLocationData();
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Box style={{ marginTop: "15px" }}>
      <Grid>
        <Grid.Col span={3}>
          <Button type="primary" onClick={() => onCreate()}>
            Add New
          </Button>
        </Grid.Col>
      </Grid>
      <MantineReactTable
        columns={reqColumnDefs}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn="first"
        renderRowActions={(row) => (
          <RowAction
            onUpdate={() => onUpdate(row.row.original)}
            onDelete={() => onDelete(row.row.original)}
          />
        )}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};

export default GroupLocationList;
