export const DefaultSubmissionMethod = [
  { value: "None", label: "None" },
  { value: "AdministrativeClaims", label: "Administrative Claims" },
  { value: "CSV", label: "CSV" },
  { value: "CMSWebInterface", label: "CMS Web Interface" },
  { value: "EHR", label: "EHR" },
  { value: "Registry", label: "Registry" },
];

export const DefaultCqmTemplates = [{ value: "None", label: "None" }];

export const FiscalYear = [
  { value: "2015", label: "2015" },
  { value: "2016", label: "2016" },
  { value: "2017", label: "2017" },
  { value: "2018", label: "2018" },
  { value: "2019", label: "2019" },
  { value: "2020", label: "2020" },
  { value: "2021", label: "2021" },
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
];

export const EHRinterfaceMap = [
  { value: "CMSGenricMeasureLoad", label: "CMS Genric Measure Load" },
  { value: "MeditechLifepoint", label: "Meditech Lifepoint" },
];


// export const MeetingStatus = [
//   { value: null, label: "No Status" },
//   { value: "Initiale-Mail", label: "Initial e-Mail" },
//   { value: "DialogueEstablished", label: "Dialogue established" },
//   { value: "DataReceived", label: "Data Received" },
//   { value: "MeetingScheduled", label: "Meeting Scheduled" },
//   { value: "LeftMessage", label: "Left Message" },
//   { value: "Feedback1", label: "Feedback1" },
//   { value: "Feedback2", label: "Feedback2" },
//   { value: "Feedback+", label: "Feedback+" },
//   { value: "TIN-QPP", label: "TIN QPP" },
//   { value: "EC-QPP", label: "EC QPP" },
//   { value: "Reviewed-SV", label: "Reviewed - SV" },
//   { value: "Reviewed-JF", label: "Reviewed - JF" },
//   { value: "Submit", label: "Submit" },
//   { value: "Attested", label: "Attested" },
//   { value: "NoSubmit", label: "No Submit" },
//   { value: "Deferred", label: "Deferred" },
//   { value: "MeetingHeld", label: "Meeting Held" },
//   { value: "Q4Done", label: "Q4 Done" },
//   { value: "MIPSExempt", label: "MIPS Exempt" },
//   { value: "SetCallwithConsultant", label: "Set Call with Consultant" },
//   { value: "EUCAccepted", label: "EUC Accepted" },
// ];


export const OverrideSubmissionMethodClinic = [
  { value: "No", label: "No" },
  { value: "AdministrativeClaims", label: "AdministrativeClaims" },
  { value: "Claims", label: "Claims" },
  { value: "CSV", label: "CSV" },
  { value: "CMSWebInterface", label: "CMS Web Interface" },
  { value: "EHR", label: "EHR" },
  { value: "Registry", label: "Registry" },
];

export const ReqMeasureType = [
  { value: "EHRonlypatients", label: "EHR only patients" },
  { value: "Allpatients", label: "All patients" },
  { value: "YesandNo", label: "Yes and No" },
  { value: "Numeric", label: "Numeric" },
  { value: "Turnaround", label: "Turnaround" },
];

export const ReqCategory = [
  { value: "Pi", label: "PI" },
  { value: "Ia", label: "IA" },
  { value: "Quality", label: "Quality" },
  { value: "Cost", label: "Cost" },
];

export const MetricType = [
  { value: "Boolean", label: "Boolean" },
  { value: "Cahps", label: "Cahps" },
  { value: "CostScore", label: "Cost Score" },
  { value: "MultiPerformanceRate", label: "Multi PerformanceRate" },
  { value: "NonProportion", label: "Non Proportion" },
  { value: "Proportion", label: "Proportion" },
  {
    value: "RegistryMultiPerformanceRate",
    label: "Registry Multi PerformanceRate",
  },
  { value: "SinglePerformanceRate", label: "Single  PerformanceRate" },
];

export const CategoryDropDown = [
  { value: "3374", label: "2021 Feedback" },
  { value: "1363", label: "2021 Other" },
  { value: "3541", label: "2022 Audit Documentation" },
  { value: "3409", label: "2022 Feedback" },
  { value: "3542", label: "2023 Feedback" },
  { value: "1366", label: "Archive" },
  { value: "3162", label: "Provider Reconciliation" },
];

export const AssingDropDown = [
  { value: "0", label: "Not Assigned" },
  { value: "1110", label: "Ryan Arendas - C3 Partn..." },
  { value: "2", label: "Jay Fisher - C3 Partn..." },
  { value: "645", label: "Bob Molthen - C3 Partn..." },
  { value: "1106", label: "srujay Setty - C3 Partn..." },
  { value: "818", label: "Srujay Setty - C3 Partn..." },
  { value: "668", label: "Joanne Baldwin - Deborah ..." },
  { value: "1042", label: "Janet Bennett - Deborah ..." },
  { value: "1034", label: "Whitney Birney - Deborah ..." },
  { value: "669", label: "Joyce Brown - Deborah ..." },
  { value: "951", label: "Kane Chang - Deborah ..." },
  { value: "1043", label: "Joseph Chirichella - Deborah ..." },
  { value: "1040", label: "Jill DeVito - Deborah ..." },
  { value: "1039", label: "Adam Flick - Deborah ..." },
  { value: "1037", label: "Geraldine Franklin - Deborah ..." },
  { value: "950", label: "Tara Hankins - Deborah ..." },
  { value: "667", label: "Rick Hendrickson - Deborah ..." },
  { value: "949", label: "John Hill - Deborah ..." },
  { value: "814", label: "May Kane - Deborah ..." },
  { value: "670", label: "Grant Leidy - Deborah ..." },
  { value: "948", label: "Carolyn Magnotta - Deborah ..." },
  { value: "952", label: "Andrew Martin - Deborah ..." },
  { value: "671", label: "Claudette McIntosh - Deborah ..." },
  { value: "672", label: "Krystyna Monticello - Deborah ..." },
  { value: "953", label: "Vincent Pompili - Deborah ..." },
  { value: "1038", label: "Nicole Ragonese - Deborah ..." },
  { value: "678", label: "Bituin Rebillon - Deborah ..." },
  { value: "813", label: "Rich Rifenburg - Deborah ..." },
  { value: "954", label: "Betsy Schloo - Deborah ..." },
  { value: "1041", label: "Marion Stamopoulos - Deborah ..." },
  { value: "1035", label: "Justin Szawlewicz - Deborah ..." },
  { value: "666", label: "Richard Temple - Deborah ..." },
  { value: "1036", label: "Cynthia Walker - Deborah ..." },
  { value: "812", label: "Jean Wessendorf - Deborah ..." },
];

export const StatusDropDown = [
  { value: "Completed", label: "Completed" },
  { value: "InProcess", label: "In Process" },
  { value: "NotStarted", label: "Not Started" },
  { value: "OpenIssues", label: "Open Issues" },
];
