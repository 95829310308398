import { Box, Button } from '@mantine/core';
import { MantineReactTable } from 'mantine-react-table';
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { RouteLink } from '../../Constant/RouterLink';
import { PartnerColumn } from '../Partner/PartnerColumn';
import useAxios from '../../Shared/useApi';
import { ApiLink } from '../../Constant/ApiLink';
import { DeleteConfirmationModal } from '../../Shared/DeleteConfirmationModal';
import { useDisclosure } from '@mantine/hooks';
import { RowAction } from '../../Shared/RowAction';
import axios from 'axios';
import { CcmColumnDef } from '../CCM/CcmColumn';
import { useSelector } from 'react-redux';
import { ApiCall } from '../../Shared/ApiCall/ApiCall';

const MeetingList = () => {
    const navigate = useNavigate();
    let  groupDropDownList = useSelector((store) => store.partenerTree.groupDropDownList)
    const [groupStatusDropDown, setGroupStatusDropDown] = useState([]);
    // const [sortedGroupDropDownList, setGroupDropDownList] = useState([]);
    
    const columnDefs = useMemo(() => [
        {
            header: "UDF Value",
            accessorKey: "meetingStatusName",
        },
        {
            header: "User Define Fields",
            accessorKey: "statusGroup",
            Cell: ({ cell }) => {
                return cell?.row?.original?.statusGroup?.name;
            },
            filterVariant: 'select',
            mantineFilterSelectProps: {
              data: groupStatusDropDown
            },
            filterFn: (row, id, filterValue) => {
                const tempStatusGroup = row?.original?.statusGroup?.name;
                const found = tempStatusGroup?.toLowerCase().includes(filterValue?.toLowerCase())
                return found;
              }
        },
        {
            header: "Group",
            accessorKey: "gid",
            Cell: ({ cell }) => {
                return cell?.row?.original?.group.map(item => item?.name).join(", ");
            },
            filterVariant: 'select',
            mantineFilterSelectProps: {
              data: groupDropDownList
            },

            filterFn: (row, id, filterValue) => {
                return row?.original?.group.find(item => item.id == filterValue)? true : false;
            }
        }
        
    ]);

    const [rowData, setRowData] = useState();
    const [loading, setLoading] = useState(false);
    const [deletingRow, setDeletingRow] = useState({});

    useEffect(() => {
        getMeetingData()
    }, [])

    // useEffect(() =>{
    //     if(groupDropDownList?.length){
    //         let sortedGroupDDL =  groupDropDownList?.
    //         setGroupDropDownList(sortedGroupDDL)
    //     }
      
    // }, [groupDropDownList])

    const getMeetingData = () => {
        setLoading(true);
        ApiCall.axiosInstance.get(
            `${process.env.REACT_APP_API_URL}${ApiLink.Meeting}`
        )
            .then((response) => {
                setRowData(response.data);
                setLoading(false);
                // navigate(RouteLink.CCM_Uri);
                const meetingData = [...new Set(response?.data?.map(item => item?.statusGroup?.name))]?.sort(function (a, b) {
                    return a?.toLowerCase().localeCompare(b?.toLowerCase());
                  });
                setGroupStatusDropDown(meetingData);
            })
            .catch((error) => {
                console.error("Failed Request:", error);
                setLoading(false);
            });
    }
    const createMeetingName = () => {
        navigate(RouteLink.Meeting_Create_Uri, { state: { data: '', method: "post" } });
    }

    const onUpdate = (value) => {;
        navigate(RouteLink.Meeting_Update_Uri, {
            state: { data: value, method: "put" },
        });
    };

    const [opened, { close, open }] = useDisclosure(false);

    function IsConfirmed(value) {
        if (value === true) {
            setLoading(true);
            ApiCall.axiosInstance["delete"](
              `${process.env.REACT_APP_API_URL}/${ApiLink.Meeting}?id=${deletingRow.id}`
            )
              .then((response) => {
                setLoading(false);
                getMeetingData();
              })
              .catch((error) => {
                console.error("Failed Request:", error);
                setLoading(false);
              });
          }
    }
    const onDelete = (value) => {
        setDeletingRow(value);
        open();

    }

    return (
        <Box style={{ marginTop: "15px" }}>
          <DeleteConfirmationModal IsConfirmed={IsConfirmed} opened={opened} open={open} close={close} />
            <Button mx={4} type="primary" onClick={() => createMeetingName()}>
                Create UDF
            </Button>

            <MantineReactTable
                columns={columnDefs}
                data={rowData ?? []}
                state={{ isLoading: loading }}
                enableRowActions
                positionActionsColumn='first'
                mantinePaperProps={{ shadow: '0', withBorder: false }}
                renderRowActions={(row) => (
                    <RowAction onUpdate={() => onUpdate(row.row.original)} onDelete={() => onDelete(row.row.original)} />
                )}
            />

        </Box>
    )
}

export default MeetingList;