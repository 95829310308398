import React, { useState, useRef, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Box, Button, Grid, Select,Switch } from "@mantine/core";
import { ApiLink } from "../../Constant/ApiLink";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate } from "react-router-dom";
import useAxios from "../../Shared/useApi";
import LoaderOverlay from "../../Shared/LoaderOverlay";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import { useDisclosure } from "@mantine/hooks";
import { MantineReactTable } from "mantine-react-table";
import { ClinicColumn } from "./ClinicColumn";
import { RowAction } from "../../Shared/RowAction";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { refreshTreeData, setDisableYear } from "../../utils/patenerTreeSlice";
import { label } from "../../Shared/Constant";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const ClinicList = () => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  const [rowDataCopy, setRowDataCopy] = useState([]);
  var navigate = useNavigate();
  const [clinicType, setClinicType] = useState(true);
  const selectedGroupId = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );
  const [checked, setChecked] = useState(true);
  const [isSoft, setIsSoft] = useState(false);
  let [loading, setLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const dispatch = useDispatch();
   const { user } = useSelector((state) => state.auth);
      const partenerTreeItems = useSelector((state) => state.partenerTree.partenerTreeItem);


  useEffect(() => {
    if (checked) {
      getClinicListData(selectedGroupId)
    } else {
      getClinicListData('0')
    }
    dispatch(setDisableYear(true))
    return () => {
      dispatch(setDisableYear(false))
    }
  }, [checked, isSoft,selectedGroupId,reportSelection]);

  const getClinicListData = (selectedGroupId) => {

    let levelId = 0;
    if(user.level === 0 )
    {  
      levelId=0
    }
    if(user.level === 1 )
      {  
        levelId=partenerTreeItems[0]?.id ;
      }
      if(user.level === 2 )
        {  
           levelId=selectedGroupId ;
        }
        if(user.level === 3 )
          {  
            levelId=reportSelection.clinicId ;
          }

    setLoading(true)
    ApiCall.axiosInstance.get(
      `${process.env.REACT_APP_API_URL}${ApiLink.Clinic_Uri}?groupid=${selectedGroupId}&year=${reportSelection.selectedYear}&status=${isSoft}&userlevel=${user?.level}&id=${levelId}`
    )
      .then((response) => {
        setRowData(response.data);
        setLoading(false)
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        setLoading(false)
      });
  }



  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
  };

  const onCreate = () => {
    const clinicData = { data: null, method: "post" }
    localStorage.setItem('clinicData', JSON.stringify(clinicData));
    setTimeout(() => {
      navigate(RouteLink.Clinic_Create_Uri);
    }, 200);
  };

  const onUpdate = (value) => {
    console.log("values",value);
    const clinicData = { data: value, method: "put" }
    localStorage.setItem('clinicData', JSON.stringify(clinicData));
    setTimeout(() => {
      navigate(RouteLink.Clinic_Upadte_Uri);
    }, 200);
  };

  const onActive = (value) => {
    debugger;
    if(!window.confirm('Are you sure to activated this record?')){
      return;
    }
    setLoading(true);
    ApiCall.axiosInstance.get(
      `${process.env.REACT_APP_API_URL}${ApiLink.ActivateDeletedTin}/${value.pacId}`
    )
      .then((response) => {
        setLoading(false);
        setRowData(oldValues => {
          return oldValues.filter(rowData => rowData.id !== value.id)
        })
        dispatch(refreshTreeData());
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        setLoading(false);
      });
  };


  const [opened, { close, open }] = useDisclosure(false);
  const columnDefs = useMemo(() => ClinicColumn);
 
  const [deletingRow, setDeletingRow] = useState({});
  const onDelete = (value) => {
    setDeletingRow(value);
    open();
  };


  function IsConfirmed(value) {
    if (value === true) {
      setLoading(true);
      let url = '';
      let requestType = '';
      if(!isSoft){
        requestType = 'delete';
        url = `${process.env.REACT_APP_API_URL}${ApiLink.Clinic_Uri}?id=${deletingRow.id}`
      }else{
        requestType = 'get';
        url = `${process.env.REACT_APP_API_URL}${ApiLink.DeletePermanentTin}/${deletingRow.enrollmentId}`
      }
      ApiCall.axiosInstance?.[requestType](url)
        .then((response) => {
          setLoading(false);
          setRowData(oldValues => {
            return oldValues.filter(rowData => rowData.id !== deletingRow.id)
          })
          dispatch(refreshTreeData());
        })
        .catch((error) => {
          console.error("Failed Request:", error);
          setLoading(false);
        });
    }
  }

  

  const onReset = ()=> {
    setRowData(rowDataCopy);
    setClinicType('all');
    return ;
  }

  return (

    <Box style={{ marginTop: "15px" }}>
      <DeleteConfirmationModal IsConfirmed={IsConfirmed} opened={opened} open={open} close={close} />
      <Grid>
        <Grid.Col span={3}>
        {user.level <= 2  && (
          <Button type="primary" onClick={() => onCreate()}>
            Create TIN
          </Button>)}
        </Grid.Col>
        <Grid.Col span={3}>
        </Grid.Col>
        <Grid.Col span={3}>
        {user.level === 0 && (
          <Switch style={{ float: 'right' }}
            label={label('Show soft deleted TIN')}
            checked={isSoft}
            onChange={(event) => setIsSoft(event.currentTarget.checked)}
          />)}
        </Grid.Col>
        <Grid.Col span={3}>
        {user.level === 0 && (
          <Switch style={{ float: 'right' }}
            label='Show Group wise TIN'
            checked={checked}
            onChange={(event) => setChecked(event.currentTarget.checked)}
          />)}
        </Grid.Col>
      </Grid>

     
      <MantineReactTable
        columns={columnDefs}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn='first'
        renderRowActions={(row) => (
           <RowAction  onUpdate={() => onUpdate(row.row.original)} onActive={() => onActive(row.row.original)} onDelete={() => onDelete(row.row.original)} isSoft={isSoft}/>
        )}
        mantinePaperProps={{ shadow: '0', withBorder: false }}
      />

    </Box>

  );
};

export default ClinicList;