import { useEffect, useMemo, useState } from "react";
import { MantineReactTable } from "mantine-react-table";
import {
  Alert,
  Box,
  Button,
  Grid,
  LoadingOverlay,
  Select,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { ApiLink } from "../../Constant/ApiLink";
import axios from "axios";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const CCMEnable = () => {
  const ststusDropDown = [
    { label: "Active", value: true },
    { label: "In Active", value: false },
  ];
  const [tableData, setTableData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [copyTableData, setCopyTableData] = useState([]);
  const [statusValue, setStatusValue] = useState("all");
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    getCCMEnableData();
  }, []);

  useEffect(() => {
    setCopyTableData(tableData);
  }, [tableData]);

  const getCCMEnableData = (year) => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.CCMEnabled}`)
      .then((response) => {
        setTableData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleDropDownChange = (event, id, property) => {
    const { value } = event.target;
    setTableData((prevData) =>
      prevData.map((item) =>
        item.id === id
          ? { ...item, [property]: value === "true" ? true : false }
          : item
      )
    );
  };

  const rows = copyTableData?.map((item) => (
    <tr key={item.id}>
      <td>
        <Text>{item.specialityName}</Text>
      </td>

      <td>
        <select
          value={item.status}
          onChange={(e) => handleDropDownChange(e, item.id, "status")}
          className="ddldropdown"
        >
          {ststusDropDown.map((ele) => (
            <option key={ele.id} value={ele.value}>
              {ele.label}
            </option>
          ))}
        </select>
      </td>
    </tr>
  ));
  const handleSave = () => {
    
    setIsLoading(true);
    ApiCall.axiosInstance
      .put(`${process.env.REACT_APP_API_URL}${ApiLink.CCMEnabled}`, tableData)
      .then((response) => {
        setIsLoading(false);
        alert("Records updated successfully!");
      })
      .catch((error) => {
        setIsLoading(false);
        alert(JSON.stringify(error));
      });
  };

  const filterOnStatusChnage = (value) => {
    setStatusValue(value);
    setCopyTableData([]);
    if (value == "all") {
      setCopyTableData(tableData);
      return;
    } else if (value == false) {
      const filteredData = tableData?.filter((ele) => ele.status == false);
      setCopyTableData(filteredData);
      return;
    } else if (value == true) {
      const filteredData = tableData?.filter((ele) => ele.status == true);
      setCopyTableData(filteredData);
    }
  };

  const filterOnSpeciality = (text) => {
    setFilterText(text);
    setCopyTableData(
      tableData.filter((speciality) =>
        speciality.specialityName.toLowerCase().includes(text.toLowerCase())
      )
    );
  };

  return (
    <Box style={{ marginTop: "15px" }}>
      {loading && (
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      )}

      <Grid mb={5}>
        <Grid.Col span={6}>
          <TextInput
            label="Speciality"
            value={filterText}
            onChange={(event) => filterOnSpeciality(event.target.value)}
            placeholder="Filter specialities"
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            label="Status"
            value={statusValue}
            data={[
              { value: "all", label: "All" },
              { value: false, label: "InActive" },
              { value: true, label: "Active" },
            ]}
            onChange={(v) => filterOnStatusChnage(v)}
          />
        </Grid.Col>
      </Grid>
      <Table
        striped
        highlightOnHover
        withBorder
        withColumnBorders
        sx={{ overflowX: "auto", overflowY: "scroll", maxHeight: "50%" }}
        id="tinsummary"
      >
        <thead>
          <tr>
            <th>Speciality</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>{rows ?? "No Data"}</tbody>
      </Table>
      <Grid justify="end">
        <Grid.Col span={12} align="end">
          <Button type="primary" mt="sm" ml={5} onClick={() => handleSave()}>
            Save
          </Button>
          {loading && <Text> Saving...</Text>}
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default CCMEnable;
