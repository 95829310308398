import React, { useState, useEffect, useMemo } from "react";
import { Anchor, Button, Divider, Grid, Modal, Text, Title } from "@mantine/core";
import { formateDate } from "../../Shared/NumberFunctions";
import { ApiLink } from "../../Constant/ApiLink";
import axios from "axios";
import { IconDownload } from "@tabler/icons-react";
import { useNavigate } from "react-router";
import { RouteLink } from "../../Constant/RouterLink";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const CalendarDesptModel = ({ records, onClose }) => {
  const [rowData, setRowData] = useState(records);
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  
  useEffect(() => {
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.User_Uri}`)
      .then((response) => {
        const data = response.data;
        const targetData = data.find((ele) => ele.id == records.assign);
        const currentUserName = targetData
          ? targetData?.firstName + " " + targetData?.lastName
          : "";
        setUserName(currentUserName);
      })
      .catch((error) => {
        console.error("Get User Details:", error);
      });
  }, []);

  const onEdit = (value) => {
   
    navigate(RouteLink.updateActionItem, {
      state: { data: records, method: "put" },
    });
  };

  return (
    <Modal centered onClose={onClose} title="Action Details" size="xl" opened>
        <Divider></Divider>
      <div className="ag-theme-alpine" style={{ height: 270 }}>
      <Text fw={600} fz="md" mt={10} style={{color: 'green'}}>{records?.descriptions}</Text>
        <Grid mt={10}>
          {records?.group && (
            <>
              <Grid.Col span={2}>
                <Text fw={600} fz="md">
                  Group:{" "}
                </Text>
              </Grid.Col>
              <Grid.Col span={10}>
                <Text fz="md">{records?.group?.name} </Text>
              </Grid.Col>
            </>
          )}

          {records?.clinic && (
            <>
              <Grid.Col span={2}>
                <Text fw={600} fz="md">
                  Clinic:{" "}
                </Text>
              </Grid.Col>
              <Grid.Col span={10}>
                <Text fz="md">{records?.clinic?.name} </Text>
              </Grid.Col>
            </>
          )}

          {records?.categories && (
            <>
              <Grid.Col span={2}>
                <Text fw={600} fz="md">
                  Categorie:{" "}
                </Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text fz="md">{records?.categories?.name} </Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Text fw={600} fz="md">
                  Assign Status:{" "}
                </Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text fz="md">{records?.assignstatus} </Text>
              </Grid.Col>
            </>
          )}

          {records?.categories && (
            <>
              <Grid.Col span={2}>
                <Text fw={600} fz="md">
                  Created Date:{" "}
                </Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text fz="md">{formateDate(records?.date)} </Text>
              </Grid.Col>

              <Grid.Col span={2}>
                <Text fw={600} fz="md">
                  Target Date:{" "}
                </Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text fz="md">{formateDate(records?.targetDate)} </Text>
              </Grid.Col>
            </>
          )}

          {records?.assign && (
            <>
              <Grid.Col span={2}>
                <Text fw={600} fz="md">
                  Assign To:{" "}
                </Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text fz="md">{userName}</Text>
              </Grid.Col>
            </>
          )}

          {records?.process && (
            <>
              <Grid.Col span={2}>
                <Text fw={600} fz="md">
                  Process:{" "}
                </Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text fz="md">{records?.process} </Text>
              </Grid.Col>
            </>
          )}

          {records?.filepath && (
            <>
              <Grid.Col span={2}>
                <Text fw={600} fz="md">
                  Download:{" "}
                </Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Anchor target="_blank" href={records?.filepath}>
                  <IconDownload/>
                </Anchor>
              </Grid.Col>
            </>
          )}
        </Grid>
        <Button mt={20} type="primary" onClick={() => onEdit()}>
           Edit
          </Button>
      </div>
    </Modal>
  );
};

export default CalendarDesptModel;
