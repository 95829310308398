import React, { useState, useRef, useEffect, useMemo } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { RouteLink } from "../../Constant/RouterLink";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAxios from "../../Shared/useApi";
import { Box, Button, Anchor, Select, Grid, Tooltip, LoadingOverlay, Switch } from "@mantine/core";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useDisclosure } from "@mantine/hooks";
import { RowAction } from "../../Shared/RowAction";
import { ApiLink } from "../../Constant/ApiLink";
import axios from "axios";
import { useSelector } from "react-redux";
import { AssingDropDown, CategoryDropDown, StatusDropDown } from "../../Shared/DropDownOptions/DropDownList";
import { IconFile } from "@tabler/icons-react";

// ActionItem StyleSheet 
import "../../../src/ActiomItems.css";  // Adjust the path accordingl
import { label } from "../../Shared/Constant";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const QualityNotes = () => {
  const gridRef = useRef();
  const { id } = useParams();
  const [rowData, setRowData] = useState();
  const navigate = useNavigate();
  const [opened, { close, open }] = useDisclosure(false);
  const [loading, setLoading] = useState(false);
  // const columnDefs = useMemo(() => ActionItemColumn);
  const SelectedClinicList = useSelector(store => store.partenerTree.selectedGroupClnics);
  const selectedGroupId = useSelector((store) => store.partenerTree.selectedGroupId);
  const [selectedClinicId, setSelectedClinicId] = useState('all');
  const [copyActionItem, setCopyActionItem] = useState([]);
  const [userData, setUserData] = useState([]);
  const [clinicArray, setClinicArray] = useState([]);
  const [uniqueYear, setUniqueYear] = useState([]);
  const [uniqueNames, setUniqueNames] = useState([]);
  const [uniqueCatNames, setUniqueCategory] = useState([]);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [checked,setChecked] = useState(false);
  const {user}  = useSelector(store => store.auth);
  

  const ActionItemColumn = [
{
      header: "Category",
      accessorKey: "category",
      accessorFn: (row) => {
        return row.categories?.name;
      },
      filterVariant: 'select',
      mantineFilterSelectProps: {
        data: uniqueCatNames,
      },
    },
    {
      header: "Quality Description",
      accessorFn: (row) => {
        return <Tooltip multiline
        w={400} label={row.descriptions}><span className="text-ellipsis">{row.descriptions}</span></Tooltip>
      },
      size: 10,

    },
    {
      header: "Attachment",
      accessorKey: "filepath",
      accessorFn: (row) => {
        return <Anchor target="_blank" href={row?.filepath}>
          {row?.filepath && <IconFile />}
        </Anchor>
      },
    },

    {
      header: "Created Date",
      accessorFn: (row) => {
        let sDay = new Date(row.date);
        var day = sDay.getDate();
        var month = sDay.getMonth() + 1; // Month is zero-based, so we add 1
        var year = sDay.getFullYear();
        sDay = (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day + '-' + year;
        return sDay;
      },
      // id: 'date',
      // Cell: ({ cell }) => cell.getValue(), //render Date as a string
    },
    {
      header: "Target Date",
      accessorFn: (row) => {
        let sDay = new Date(row.targetDate);
        let day = sDay.getDate();
        let month = sDay.getMonth() + 1; // Month is zero-based, so we add 1
        let year = sDay.getFullYear();
        sDay = (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day + '-' + year;
        return sDay;
      },
      // id: 'date',
      // Cell: ({ cell }) => cell.getValue(), //render Date as a string
    },

    {
      header: "Year",
      accessorKey: "year",
      filterVariant: 'select',
      mantineFilterSelectProps: {
        data: uniqueYear,
      },

      filterFn: (row, id, filterValue) => {
        const clinics = row?.original?.year;
        const found = clinics?.toLowerCase().includes(filterValue?.toLowerCase())
        return found;
      }
      // accessorFn: (row) => {
      //   return StatusDropDown.find(ele => ele.value == row.assignstatus)?.label;
      // },
    },
  ]

  

  useEffect(() => {
    
    // alert(user.level);
   getActionItem(selectedGroupId)
  }, [id, selectedGroupId, reportSelection.selectedYear]);

  const getActionItem = (selectedId) => {
    setSelectedClinicId(id);
    setLoading(true)
    ApiCall.axiosInstance
    .get(
      `${process.env.REACT_APP_API_URL}/${ApiLink.ActionItem}/${selectedId}?year=${reportSelection.selectedYear}`
    )
    .then((response) => {
      let data = response.data;
      if (data !== null) {
        
        setCopyActionItem(data);
        const clinicNames = [...new Set(data.map(item => item?.clinic?.name))].sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        const uniqueYears = [...new Set(data.map(item => item.year))].sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        setClinicArray(clinicNames)
        setUniqueYear(uniqueYears)
        if (id != 'all' && selectedId != 0) {
          setSelectedClinicId(+id);
          let selectedActionItem = data.filter((ele) => +ele.clinicId == +id);
          setRowData(selectedActionItem);
        } else {
          setRowData(data);
        }
      }
      setLoading(false)
    })
    .catch((error) => {
      console.error("Get User Details:", error);
      setLoading(false)
    });
  }

  useEffect(() => {
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.User_Uri}`
      )
      .then((response) => {
        const records = response.data;
        setUserData(records);
        const uniqueNames = [...new Set(records.map(item => `${item.firstName} ${item.lastName}`))].sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        setUniqueNames(uniqueNames)
      })
      .catch((error) => {
        console.error("Get User Details:", error);
      });
  }, []);


  useEffect(() => {
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.Category_Uri}`
      )
      .then((response) => {
        const category = response.data;
        const uniqueCatNames = [
          ...new Set(category.map((item) => item.catName)),
        ].sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        setUniqueCategory(uniqueCatNames);
      })
      .catch((error) => {
        console.error("Get category Details:", error);
      });
  }, []);


  const getAssignedUserName = (assignedUserId) => {

    let item = userData.find((ele) => ele.id == assignedUserId);
    return item?.firstName + ' ' + item?.lastName;
  }

  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    
  };

  const onCreate = () => {
    navigate(RouteLink.Create_QualityNotes, {
      state: {
        data: null,
        method: "post",
      },
    });
  };

  const onUpdate = (value) => {
    
    navigate(RouteLink.updateActionItem, {
      state: { data: value, method: "put" },
    });
  };

  const [deletingRow, setDeletingRow] = useState({});
  const onDelete = (value) => {
    
    setDeletingRow(value);
    open();
  };

  function IsConfirmed(value) {

    if (value === true) {

      setLoading(true);
       ApiCall.axiosInstance["delete"](
        `${process.env.REACT_APP_API_URL}/${ApiLink.ActionItem}?id=${deletingRow.id}`
      )
        .then((response) => {
          
          setLoading(false);
          setRowData(oldValues => {
            return oldValues.filter(rowData => rowData.id !== deletingRow.id)
          })
        })
        .catch((error) => {
          console.error("Failed Request:", error);
          setLoading(false);
        });
    }
  }

  const handleClickAttestation = (clinicId) => {
    navigate(`${RouteLink.ActionItemList}/${clinicId}`);
  }

  const onReset = () => {
    navigate(`${RouteLink.ActionItemList}/all`);
  }

  const openCalender = () => {
    let rawTableData = Object.values(table.getRowModel().rowsById);
    let currentRowData = rawTableData.map(ele => ele.original);
    navigate(RouteLink.calendar, {
      state: { data: currentRowData },
    });
  }

  //   const getCurrentRow = () => {
  //      debugger;
  //   }

  const table = useMantineReactTable({
    columns: ActionItemColumn,
    data: rowData ?? [],
    enableRowActions: true,
    positionActionsColumn: 'first',
    // onColumnFiltersChange: getCurrentRow(),
    renderRowActions: (row) => (
      <RowAction onUpdate={() => onUpdate(row.row.original)} onDelete={() => onDelete(row.row.original)} />
    ),
    mantinePaperProps: { shadow: '0', withBorder: false }
  });

  const getAllGroup = (value) => {
    if(value){
      getActionItem(0);
      setSelectedClinicId('');
      setChecked(value);
    }else{
      getActionItem(selectedGroupId)
      setChecked(value);
      setSelectedClinicId('');
    }
  }


  return (

    <Box style={{ marginTop: "15px" }}>
     {loading && (
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      )}
      <DeleteConfirmationModal IsConfirmed={IsConfirmed} opened={opened} open={open} close={close} />
      <Grid>
        <Grid.Col span={2}>
          <Button mt={20} type="primary" onClick={() => onCreate()}>
            Create Action Item
          </Button>
        </Grid.Col>
        <Grid.Col span={3}>
        {/* {user.level <= 1 && (<Select
            label="Attestation Clinic"
            value={selectedClinicId}
            data={SelectedClinicList}
            onChange={(v) => handleClickAttestation(v)}
            searchable
          />)} */}
        </Grid.Col>
        <Grid.Col span={2}>
        {/* {user.level <= 0 && (
          <Button mt={20} type="primary" onClick={() => onReset()}>
            Reset All
          </Button>
        )} */}
        </Grid.Col>
        <Grid.Col span={2}>
        {/* {user.level <= 0 && (

          <Switch mt={20} style={{ float: 'right' }}
            label={label('Show All Group')}
            checked={checked}
            onChange={(event) => getAllGroup(event.currentTarget.checked)}
          />
        )} */}

        </Grid.Col>
        <Grid.Col span={1}>
        </Grid.Col>
        {/* <Grid.Col span={2} style={{ textAlign: 'right' }}>
          <Button mt={20} type="primary" onClick={() => openCalender()}>
            View Calendar
          </Button>
        </Grid.Col> */}
      </Grid>

      <MantineReactTable table={table} />
    </Box>
  );
};

export default QualityNotes;

