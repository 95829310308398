export const YesNo = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
]


export const ConfirmedStatus = [
    { value: 'all', label: "All" },
    { value: 'true', label: "Yes" },
    { value: 'false', label: "No" },
]