import { FormDrawer } from "./FormDrawer";
import { UsersTable } from "./UsersTable";
import { Paper, Space, Title } from "@mantine/core";
import { Tabs } from "@mantine/core";
import {
  Link,
  Outlet,
  Router,
  useLocation,
  useNavigate,
} from "react-router-dom";
import React from "react";
// import CommonLayout from "../Shared/CommonLayout";
import { RouteLink } from "../Constant/RouterLink";
import { useSelector } from "react-redux";

export const AdminTabs = () => {
  const linkStyle = {
    textDecoration: "none",
    color: "#000",
    fontSize: "15px",
    marginBottom: "0px",
    paddingBottom: "0px",
  };
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  if (location.pathname === RouteLink.Admin_Uri) {
    navigate(RouteLink.Partners_Uri);
  }

  
  const adminRouteWithIndex = [
    {text: "Partner", routeTo: RouteLink.Partners_Uri, index: "1", level: 1 },
    {text: "User", routeTo: RouteLink.Users_Uri, index: "2", level: 3 },
    {text: "Group", routeTo: RouteLink.Group_Uri, index: "3", level: 2 },
    {text: "TIN", routeTo: RouteLink.Clinic_Uri, index: "4", level: 3 },
    {text: "Physician",routeTo: RouteLink.Physician_Uri,index: "5",level: 3,},
    {text: "EntityType", routeTo: RouteLink.Entity_Uri, index: "6", level: 0 },
    {text: "Category", routeTo: RouteLink.Category_Uri, index: "7", level: 0 },
    {text: "Sub Category", routeTo: RouteLink.SubCategory_Uri, index: "8", level: 0},
    {text: "Requirement", routeTo: RouteLink.Requirement_Uri, index: "9", level: 0},
    {text: "Requirement Set", routeTo: RouteLink.RequirementSet_Uri, index: "10", level: 0},
    {text: "Public File", routeTo: RouteLink.PublicFile_Uri, index: "11", level: 0},
    {text: "CCM", routeTo: RouteLink.CCM_Uri, index: "12", level: 0},
    {text: "UDF", routeTo: RouteLink.Meeting_Uri, index: "13", level: 0},
    // { text: "GUI Year", routeTo: RouteLink.Gui_Uri, index: '14', level: 4 },
  ];

  const getActiveTabValue = () => {
    const path = location.pathname;
    if (path === RouteLink.Partners_Uri) return "1";
    else if (path === RouteLink.Group_Uri) return "3";
    else if (path === RouteLink.Physician_Uri) return "4";
    else if (path === RouteLink.Clinic_Uri) return "5";
    else if (path === RouteLink.Entity_Uri) return "6";
    else if (path === RouteLink.Category_Uri) return "7";
  };

  const activeTabValue = getActiveTabValue();

  return (
    <Paper withBorder radius="md" p="md">
      <Tabs defaultValue={activeTabValue}>
        <Tabs.List>
          {adminRouteWithIndex.map((row) => {
            if (row.level >= user.level)
              return (
                <Link to={row.routeTo} style={linkStyle} key={row.index}>
                  <Tabs.Tab value={row.index} key={row.index}>
                    {row.text}
                  </Tabs.Tab>
                </Link>
              );
          })}

          {/* <Tabs.Tab value="13">Third tab</Tabs.Tab> */}
        </Tabs.List>

        <Outlet></Outlet>
      </Tabs>
    </Paper>
  );
};
