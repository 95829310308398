import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import { Box, Container, Divider, Select, TextInput } from "@mantine/core";
import { Table } from '@mantine/core';
import { PIScoringType } from "../../Shared/DropDownOptions/PIScoringType";
import { YesNo } from "../../Shared/DropDownOptions/YesNo";
import { Grid } from '@mantine/core';


const QPPMismatch = () => {

  var navigate = useNavigate();


  const elements = [
    { et: 'APM QP- Individudal', ec: 1, bm: '$56.0k', rl: '#2.5k', cr: '$2.8k', tr: '$2.8k', rh: '$2.8k' },
    { et: 'APM QP- Individudal', ec: 1, bm: '$56.0k', rl: '#2.5k', cr: '$2.8k', tr: '$2.8k', rh: '$2.8k' },
    { et: 'APM QP- Individudal', ec: 1, bm: '$56.0k', rl: '#2.5k', cr: '$2.8k', tr: '$2.8k', rh: '$2.8k' },
    { et: 'APM QP- Individudal', ec: 1, bm: '$56.0k', rl: '#2.5k', cr: '$2.8k', tr: '$2.8k', rh: '$2.8k' },

  ];


  const rows = elements.map((e) => (
    <tr key={e.et}>
      <td>{e.et}</td>
      <td>{e.ec}</td>
      <td>{e.bm}</td>
      <td>{e.rl}</td>
      <td>{e.cr}</td>
      <td>{e.tr}</td>
      <td>{e.rh}</td>


    </tr>
  ));

  return (

    <Box style={{ marginTop: "15px" }}>
      <div className="mycontainer">QPP Mismatch</div><br />

      <Grid >
        <Grid.Col span={4}>
          <Select size="xs"
            label=" Attestation Clinic Type(UDF)"
            withAsterisk
            data={YesNo}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Select size="xs"
            label="Scaling Factor: Base"
            withAsterisk
            data={YesNo}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Select size="xs"
            label="Bonus"
            withAsterisk
            data={YesNo}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Select size="xs"
            label="Attestation Clinic"
            withAsterisk
            data={YesNo}
          />
        </Grid.Col>


      </Grid>
      <Divider my="sm" />

      <Table striped highlightOnHover withBorder withColumnBorders>
        <thead>
          <tr>
            <th>Entity Type</th>
            <th>EC Count</th>
            <th>Basic Medicare</th>
            <th>Regulatory Low</th>
            <th>Current Result</th>
            <th>Target High</th>
            <th>Regulatory High</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

    </Box>
  )
};
export default QPPMismatch;