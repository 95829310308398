export const CcmColumnDef = [
    {
        header: "First CCM Conditions",
        accessorKey: "ccmFirstDropdown",
        Cell: ({ cell }) => {
            return cell?.row?.original?.ccmFirstDropdown?.name;
        },
    },
   
    {
        header: "Second CCM Conditions",
        accessorKey: "ccmSecondDropdown",
        Cell: ({ cell }) => {
            return cell?.row?.original?.ccmSecondDropdown?.name;;
        },
    },
    {
        header: "Status",
        accessorKey: "status",
        Cell: ({ cell }) => {
            return <div>{cell.getValue() ? "Active" : "InActive"}</div>;
        },
    },
    
];