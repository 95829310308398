import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import {
  Anchor,
  Box,
  Button,
  Container,
  Divider,
  Group,
  LoadingOverlay,
  Radio,
  SegmentedControl,
  Select,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { Table } from "@mantine/core";
import { PIScoringType } from "../../Shared/DropDownOptions/PIScoringType";
import { YesNo } from "../../Shared/DropDownOptions/YesNo";
import { Grid } from "@mantine/core";
import { useSelector } from "react-redux";
import { ApiLink } from "../../Constant/ApiLink";
import axios from "axios";
import { MeetingStatus } from "../../Shared/DropDownOptions/DropDownList";
import { nFormatter } from "../../Shared/NumberFunctions";
import { IconBook, IconFile, IconTextPlus } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { RouteLink } from "../../Constant/RouterLink";
import { IconTextCaption } from "@tabler/icons-react";
import * as XLSX from "xlsx";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const TINSummary = () => {
  var navigate = useNavigate();
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const SelectedClinicList = useSelector(
    (store) => store.partenerTree.selectedGroupClnics
  );
  const [tinSummary, setTinSummary] = useState([]);
  const [tinSummaryCopy, setTinSummaryCopy] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedGroupId = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );

  const [selectedSegment, setSelectedSegment] = useState("all");
  const [selectedTinName, setSelectedTinName] = useState();
  const [selectedMeetingStatus, setSelectedMeetingStatus] = useState();
  const [selectedReportEntity, setSelectedReportEntity] = useState();
  const [modifiedRowEnrollId, setModifiedRowEnrollId] = useState(null);
  const [attestationClinicDD, setAttestationClinicDD] = useState([]);
  const [selectedClinicType, setSelectedClinicType] = useState("");
  const [reportingEntityDD, setReportingEntityDD] = useState([]);
   const [sysgen, setSysgen] = useState("false");

  //bind  MeetingStatus
  const [meetingStatus, setMeetingStatus] = useState([]);
  useEffect(() => {
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.MeetingStatus}/${selectedGroupId}`
      )
      .then((response) => {
        setMeetingStatus(response.data);
      })
      .catch((error) => {
        console.error("Error EntityType fetching data:", error);
      });
  }, [selectedGroupId]);
  //bind  MeetingStatus

  useEffect(() => {
    getTinSummeryData();
  }, [selectedGroupId, reportSelection.selectedYear,sysgen]);

  const getTinSummeryData = () => {
    if (selectedGroupId) {
      setLoading(true);
      ApiCall.axiosInstance
        .get(
          `${process.env.REACT_APP_API_URL}${ApiLink.Get_TinSummary}/${selectedGroupId}/${reportSelection.selectedYear}/${sysgen}`
        )
        .then((response) => {
          setTinSummaryCopy(response.data);
          setTinSummary(response.data);
          getUniqueTinEntityDropDown(response.data);
        })
        .catch((error) => {
          console.error("Error fetching Clinic data:", error);
        })
        .finally(() => {
          // Ensure setLoading(false) is called after all operations are completed
          setLoading(false);
        });
    }
  };
  

  const getUniqueTinEntityDropDown = (data) => {
    const uniqueEntities = [
      ...new Set(data.map((element) => element.entityId)),
    ];
    const entityDropDownList = uniqueEntities.map((entityId) => ({
      value: entityId,
      label:
        data.find((element) => element.entityId === entityId)?.entityType || "",
    }));
    entityDropDownList.unshift({ value: "all", label: "All" });
    setReportingEntityDD(entityDropDownList);
  };


  const handleSegmentChange = (value) => {
    setSelectedSegment(value);
    if (value == "all") {
      setTinSummary(tinSummaryCopy);
      return;
    }
    let filterData = tinSummaryCopy.filter(
      (ele) => ele.attestationStatus == value
    );
    setTinSummary(filterData);
  };

  const handleTinNameChnage = (value) => {
    setSelectedTinName(value);
    setTinSummary(tinSummaryCopy);
    let filterData = tinSummaryCopy.filter((ele) => ele.enrollmentId == value);
    setTinSummary(filterData);
  };

  const handleMeetingStatusChange = (value) => {
    // setTinSummaryCopy(tinSummary)
    if (value == "no_status") {
      setTinSummary(tinSummaryCopy);
      return;
    }
    setSelectedMeetingStatus(value);
    let filterData = tinSummaryCopy.filter((ele) => ele.meetingStatus == value);
    setTinSummary(filterData);
  };

  const handleClinicTypeChange = (value) => {
    // setTinSummaryCopy(tinSummary)
    setSelectedClinicType(value);
    let filterData = tinSummaryCopy.filter(
      (ele) => ele.attesClinicType == value
    );
    setTinSummary(filterData);
  };

  const onReportingSelectionChnage = (value) => {
    // setTinSummaryCopy(tinSummary)
    setSelectedReportEntity(value);
    if (value != "all") {
      let filterData = tinSummaryCopy.filter((ele) => ele.entityId == value);
      setTinSummary(filterData);
    } else {
      setTinSummary(tinSummaryCopy);
    }
  };

  const handleRest = () => {
    getTinSummeryData();
    setSelectedTinName("");
    setSelectedMeetingStatus("");
    setSelectedReportEntity("");
  };

  const handleDropDownChange = (value, enrollmentId, property) => {
    setModifiedRowEnrollId(enrollmentId);
    setTinSummary((prevData) =>
      prevData.map((item) =>
        item.enrollmentId === enrollmentId
          ? { ...item, [property]: value }
          : item
      )
    );
  };

  const handleClinicTypeDropDownChange = (value, enrollmentId, property) => {
    setModifiedRowEnrollId(enrollmentId);
    setTinSummary((prevData) =>
      prevData.map((item) =>
        item.enrollmentId === enrollmentId
          ? { ...item, [property]: value }
          : item
      )
    );
  };

  useEffect(() => {
    if (modifiedRowEnrollId != null)
      handleSave(tinSummary, modifiedRowEnrollId);
  }, [tinSummary]);

  const handleSave = (tinSummary, enrollmentId) => {
    const modifiedRow = tinSummary.find((e) => e.enrollmentId == enrollmentId);
    let customPayload = {
      clinicId: modifiedRow.clinicId,
      meetingStatus: modifiedRow.meetingStatus,
      year: reportSelection.selectedYear,
    };
    handleSubmit(customPayload, "put");
  };

  const handleSubmit = (customPayload) => {
    setLoading(true);
    ApiCall.axiosInstance["put"](
      `${process.env.REACT_APP_API_URL}/${ApiLink.ClinicAttestationHistoryUpdate}/${customPayload.clinicId}/${customPayload.year}/${customPayload.meetingStatus}`
    )
      .then((response) => {
        setLoading(false);
        setModifiedRowEnrollId(null);
        alert("Records saved successfully");
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAttestationClinicType();
  }, [selectedGroupId]);

  const getAttestationClinicType = () => {
    setLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.AttestationClinicType}/${selectedGroupId}`
      )
      .then((response) => {
        const data = response.data.map((ele) => {
          return {
            value: ele.id,
            label: ele.name,
          };
        });
        setAttestationClinicDD(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        setLoading(false);
      });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A"; 
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Invalid Date";
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };
  

  const rows = tinSummary?.map((item) => (
    <tr key={item.name}>
      <td>
        <Anchor onClick={() => handleClickGoToClinic(item.enrollmentId)}>
          {item.name}
        </Anchor>
        {/* <Link to={RouteLink.Clinic_Create_Uri, state: {}}>{item.name}</Link> */}
        {/* <Link to={{ pathname:'/user/admin/Test', query: { foo: "bar" } }} target="_blank">
      {item.name}
      </Link> */}
      </td>
      <td>
        <Tooltip multiline w={400} 
         label={
          <div>
            <div><strong>AssignDate:</strong> {formatDate(item.assigndate) || "N/A"}</div>
            <div><strong>TargetDate:</strong> {formatDate(item.targetDate) || "N/A"}</div>
            <div><strong>Description:</strong> {item.acitemdesc || "N/A"}</div>
            <div><strong>Status:</strong> {item.assignsts || "N/A"}</div>
          </div>
        }>
          <Anchor onClick={() => handleClickGoToActionItem(item.clinicId)}>
            {item.filePath && <IconFile />}
          </Anchor>
        </Tooltip>
      </td>
      <td>{item.attestationStatus == true ? "Client" : "Prospect"}</td>

      <td style={{ minWidth: "12rem" }}>
        {
          attestationClinicDD?.find((ele) => ele.value == item.attesClinicType)
            ?.label
        }
      </td>

      <td
        className={
          item.attestationStatus === true && item.concent === null
            ? "blockclass"
            : ""
        }
      >
        <Anchor onClick={() => handleClickGoToClinic(item?.enrollmentId)}>
          {item.concent && <IconFile />}
        </Anchor>
      </td>

      <td>
        <Tooltip multiline w={400} label={item.clinicNotes}>
          <Anchor onClick={() => handleClickGoToClinic(item?.enrollmentId)}>
            {item.clinicNotes && <IconBook />}
          </Anchor>
        </Tooltip>
      </td>

      <td style={{ minWidth: "12rem" }}>
        <Select
          size="xs"
          withAsterisk
          value={item.meetingStatus}
          data={meetingStatus.map((ele) => ({
            value: ele.id,
            label: ele.name,
          }))}
          onChange={(e) =>
            handleDropDownChange(e, item.enrollmentId, "meetingStatus")
          }
        />
      </td>
      <td>{item.physicianCount}</td>
      <td>{item.ahCount}</td>
      <td>{item.ecCount}</td>
      {/* <td>{item.mipsPI}</td> */}
      <td>
      <Tooltip  label={`0/Na`}>
         <span>0</span>
         </Tooltip>
      </td>
      <td>
         <Tooltip  label={`${item.cqm} / ${item.mipsCqm}`}>
         <span>{item.calculateCQM}</span>
         </Tooltip>
      </td>
      <td>
      <Tooltip  label={`${item.iaNumerator}/${item.mipsCpia}`}>
         <span>
         {(item.iaNumerator && item.mipsCpia && item.mipsCpia !== 0) 
  ? ((item.iaNumerator / item.mipsCpia) * 100).toFixed(0) 
  : ""}

         </span>
         </Tooltip>
      </td>
      {/* <td>{(item.mipsRu * item.cost) / 100}</td> */}
      <td>
      <Tooltip  label={`${(item.cost *item.mipsRu)/100 } / ${item.mipsRu}`}>
         <span>{item.cost}</span>
         </Tooltip>
      </td>
      <td>
        {
          (
            parseFloat(item.cqm || 0) + 
            (parseFloat(item.mipsRu || 0) * parseFloat(item.cost || 0)) / 100 +
            parseFloat(item.iaNumerator || 0)
          ).toFixed(2)
          
        }
      </td>
      <td>{item.entityType}</td>
    </tr>
  ));

  // click Event for Action Item
  const handleClickGoToActionItem = (enrollmentId) => {
    navigate(`${RouteLink.ActionItemList}/${enrollmentId}`);
  };

  const handleClickGoToMaintenance = () => {
    navigate(`${RouteLink.Clinic_Create_Uri}`);
  };

  const handleClickGoToClinic = (enrollmentId) => {
    setLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.Clinic_Uri_GetSingleClinicByEnrollmentId}/${enrollmentId}`
      )
      .then((response) => {
        

        const clinicData = { data: response.data, method: "put" };
        localStorage.setItem("clinicData", JSON.stringify(clinicData));
       
        if (clinicData) {
          setTimeout(() => {
            window.open(
              `${window.location.origin}${RouteLink.Clinic_Upadte_Uri}`
            );
            // navigate(`${RouteLink.Clinic_Upadte_Uri}`);
            setLoading(false);
          }, 200);
        }
      })
      .catch((error) => {
        console.error("Error Clinic fetching data:", error);
        setLoading(false);
      });
  };

  const exportToExcel = () => {
    // Get the tbody element within the table
    const tbody = document
      .getElementById("tinsummary")
      .getElementsByTagName("tbody")[0];
    // Extract the data from tbody and create an array of arrays
    const existingData = Array.from(tbody.rows).map((row) =>
      Array.from(row.cells).map((cell, index) => {
        if (index === 6) {
          // Assuming the sixth cell, change the index accordingly
          // If the cell has a nested div with an input element
          const inputElement = cell.querySelector('div input[type="search"]');
          return inputElement ? inputElement.value : ""; // Get the value or an empty string if not found
        } else {
          return cell.textContent;
        }
      })
    );
    // Custom headers for the first row
    const customHeaders = [
      "Name",
      "Action Item",
      "Attestation Status",
      "Clinic Type",
      "Consent",
      "Clinic Notes",
      "Meeting Status",
      "TIN ECs",
      "AH ECs",
      "ECs Other R/E",
      "PI",
      "CQM",
      "CPIA",
      "COST",
      "Total",
      "Tin Reporting Category",
    ];
    // Combine custom headers with existing data
    const data = [customHeaders, ...existingData];
    
    // Create a new worksheet from the extracted data
    const ws = XLSX.utils.aoa_to_sheet(data);
    // Create a new Excel workbook
    const wb = XLSX.utils.book_new();
    // Append the worksheet to the workbook with the name 'Sheet1'
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    // Write the workbook to an Excel file and trigger download
    XLSX.writeFile(wb, "TinSummary.xlsx");
  };

  return (
    <Box style={{ marginTop: "12px" }}>
      <div className="mycontainer">TIN Summary </div>
      <br />
      {loading && (
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      )}

<Grid align="left">

<Grid.Col span={3}>
    <Text color="green" py={2} fw={500}>
      Number of Records:{" "}
      <span style={{ color: "black" }}>{tinSummary?.length}</span>
      <Button
        ml={20}
        type="primary"
        id="export-button"
        onClick={exportToExcel}
      >
        Export to Excel
      </Button>
    </Text>
  </Grid.Col>

  <Grid.Col span={6}>
    <Radio.Group
      value={sysgen}
      onChange={setSysgen}
      name="favoriteFrameworks"
      withAsterisk
    >
      <Group mt="xs">
        <Radio value="false" label="System Generated" />
        <Radio value="true" label="Client Generated" />
      </Group>
    </Radio.Group>
  </Grid.Col>

  
</Grid>


      <Divider my="sm" />
      <div style={{ overflowX: "scroll" }}>
        <Table
          striped
          highlightOnHover
          withBorder
          withColumnBorders
          id="tinsummary"
        >
          <thead>
            <tr>
              <th>
                <Text>
                  TIN Name &nbsp; <Anchor onClick={handleRest}>(Reset)</Anchor>
                </Text>
                <Select
                  style={{ minWidth: "180px" }}
                  size="xs"
                  value={selectedTinName}
                  data={SelectedClinicList}
                  onChange={(value) => handleTinNameChnage(value)}
                  searchable
                />
              </th>
              <th>Action Item</th>
              <th>
                <Text>Attestation Status</Text>
                <Select
                  size="xs"
                  value={selectedSegment}
                  data={[
                    { value: "all", label: "All" },
                    { value: false, label: "Prospect" },
                    { value: true, label: "Client" },
                  ]}
                  onChange={handleSegmentChange}
                />
              </th>
              {/* <th>IA Doc</th> */}
              <th>
                <Text>Clinic Type</Text>
                <Select
                  size="xs"
                  withAsterisk
                  value={selectedClinicType}
                  data={attestationClinicDD}
                  onChange={handleClinicTypeChange}
                />
              </th>

              <th>Consent</th>
              <th>Clinic Notes</th>
              <th style={{ minWidth: "12rem" }}>
                <Text>Meeting Status</Text>
                <Select
                  size="xs"
                  withAsterisk
                  value={selectedMeetingStatus}
                  data={meetingStatus.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  }))}
                  onChange={handleMeetingStatusChange}
                />
              </th>
              <th>TIN ECs</th>
              <th>AH ECs</th>
              <th>ECs Other R/E</th>
              <th>PI</th>
              <th>CQM</th>
              <th>CPIA</th>
              <th>Cost</th>
              <th>Total</th>
              <th>
                <Text>Tin Reporting Category</Text>
                <Select
                  style={{ minWidth: "150px" }}
                  size="xs"
                  withAsterisk
                  value={selectedReportEntity}
                  data={reportingEntityDD}
                  onChange={(v) => onReportingSelectionChnage(v)}
                />
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </div>
    </Box>
  );
};
export default TINSummary;
