import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import {
  TextInput,
  Button,
  Box,
  Grid,
  Select,
  Space,
  Loader,
  Textarea,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router";
import { ApiLink } from "../../../Constant/ApiLink";
import axios from "axios";

import { useMemo } from "react";
import { MantineReactTable } from "mantine-react-table";
import { csvdata } from "./FormData";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";

function CsvFormate() {
  const navigate = useNavigate();
  const { data, method } = useLocation().state;
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      id: data?.id,
      formatName: data?.formatName,
      formatType: data?.formatType,
    },
    validate: {
      formatName: (value) =>
        !value || value === "" ? "formatName is required!" : null,
      formatType: (value) =>
        !value || value === "" ? "formatType is required!" : null,
    },
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "index",
        header: "Index",
        mantineEditTextInputProps: {
          type: "number",
        },
      },
      {
        accessorKey: "name",
        header: "Name",
      },

      {
        accessorKey: "defaultName",
        header: "Default Name",
        enableEditing: false,
      },
    ],
    []
  );

  const [tableData, setTableData] = useState(() => data.jsonFormats);

  const handleSaveRow = async ({ table, row, values }) => {
    tableData[row.index].name = values.name;
    tableData[row.index].index = +values.index;
    setTableData([...tableData]);
    table.setEditingRow(null);
  };

  function containsEmptyValue(array) {
    return array.some((obj) => obj.index === "" || obj.name === "");
  }

  const handleSubmit = (formValues) => {
    if (containsEmptyValue(tableData)) {
      window.alert("Please fill all require data and try again!");
      return;
    }
    setLoading(true);
    ApiCall.axiosInstance[method](
      `${process.env.REACT_APP_API_URL}${ApiLink.CSVFormat}`,
      {
        ...form.values,
        jsonFormats: tableData,
      }
    )
      .then((response) => {
        setLoading(false);
        navigate("/user/utilities/csvformate");
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleBack = () => {
    navigate("/user/utilities/csvformate");
    setLoading(false);
  };

  return (
    <Box>
      {loading && <Loader size={48} />}
      <Space></Space>

      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="Format Name: "
              placeholder="Fomat Name"
              withAsterisk
              {...form.getInputProps("formatName")}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              label="Format Type: "
              placeholder="Format Type"
              {...form.getInputProps("formatType")}
              data={[
                {
                  label: "CSV",
                  value: "csv",
                },
                {
                  label: "XML",
                  value: "xml",
                },
              ]}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            {/* <Textarea
              minRows={15}
              label="Json Object"
              value={JSON.stringify(jsonObj)}
              placeholder="Enter your json object here.."
              {...form.getInputProps("classifyTin")}
            /> */}
            {tableData.length && (
              <MantineReactTable
                columns={columns}
                data={tableData}
                editDisplayMode="row"
                enableEditing
                onEditingRowSave={handleSaveRow}
                enablePagination={false}
              />
            )}
          </Grid.Col>
        </Grid>

        <div style={{ marginTop: "1rem", float: "right" }}></div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div>
            <Button type="primary" onClick={() => handleBack()}>
              Back
            </Button>
            <Button style={{ marginLeft: "10px" }} type="submit" mt="sm">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Box>
  );
}

export default CsvFormate;
