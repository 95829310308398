import React, { useState, useRef, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Modal, Button, Select, LoadingOverlay, Anchor } from "@mantine/core";
import axios from "axios";
import { ApiLink } from "../../Constant/ApiLink";
import { RouteLink } from "../../Constant/RouterLink";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const ClnicPhysicianModel = ({ onClose, onSelect, enrollmentId, year }) => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState('0');
  const [columnDefs] = useState([
    
    {
      headerName: "Name",
       cellRenderer: (props) => {
        return (
          <>
            <Anchor key={props.data.npi} onClick={() => handleSelect(props.data)}>{props.data.name}</Anchor>
          </>
        );
      },
      minWidth: 500

    },
    {
      headerName: "NPI",
      field: "npi",
    },
    {
      headerName: "Attested",
      field: "isAttested",
      valueGetter: (params) => {
        return params?.data.isAttested === false? 'False': 'True'
      }
    },
    {
      headerName: "Specialty",
      field: "specialty",
    },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    flex: 1,
    wrapHeaderText: true,
  }));

  useEffect(() => {
    handlePhysicianOnYearChange('0');
  }, []);

  const handlePhysicianOnYearChange = (year) => {
    setLoading(true)
    setSelectedYear(year);
    ApiCall.axiosInstance
    .get(
      `${process.env.REACT_APP_API_URL}${ApiLink.Clinic_Uri_ViewPhysiciansCount}/${enrollmentId}/${year}`
    )
    .then((response) => {
      setLoading(false);
      setRowData(response?.data);
    })
    .catch((error) => {
      setLoading(false);
      console.error("get NPI records Fails:", error);
    });
  }

  const handleSelect = (selectedRecord) => {
    
    handleClickGoToPhysician(selectedRecord?.physicianId)
  };

  const handleClickGoToPhysician = (enrollmentId) => {
    setLoading(true)
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.getPhysicianByEnrollmentId}/${enrollmentId}/${year}`
      )
      .then((response) => {

        const physicianData = { data: response?.data[0], method: "put" }
        localStorage.setItem('physicianData', JSON.stringify(physicianData));
        if(physicianData){
          setTimeout(() => {
            window.open(`${window.location.origin}${RouteLink.Physician_Update_Uri}`)
            //  navigate(RouteLink.Physician_Update_Uri);
            setLoading(false);
          }, 200);
        }
        setLoading(false);
        // onClose();

      })
      .catch((error) => {
        console.error("Error Clinic fetching data:", error);
        setLoading(false);
      });
  }

  return (
      <Modal onClose={onClose} title="View Physician" size="calc(100vw - 15rem)" opened>
       {loading &&
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      }
      <Select
        label="Select Year"
        value={selectedYear}
        style={{maxWidth: '20rem'}}
        onChange={(year) => handlePhysicianOnYearChange(year)}
        data={[
          { label: 'ALL', value: '0' },
          { label: '2023', value: '2023' },
          { label: '2024', value: '2024' },
          { label: '2025', value: '2025' }
        ]}
      />
        <div className="ag-theme-alpine" style={{ height: 500, marginTop: 10 }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            rowSelection={"single"}
            rowMultiSelectWithClick={true}
            animateRows={true}
          />
        </div>
      </Modal>
  );
};

export default ClnicPhysicianModel;
