import { createSlice } from "@reduxjs/toolkit";

// this is cart slice
const cartSlice = createSlice({
    name: 'cart',
    initialState: {   
        items : [],
    },
    reducers: {
        addItem: (state, action) => {
            state.items.push(action.payload);
        },
        removeItem: (state) => {
            state.items.pop();
        },
        clearCart: (state) => {
            state.items = [];
        }
    }

})

export const {addItem, removeItem, clearCart} = cartSlice.actions;
export default cartSlice.reducer;