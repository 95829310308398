export function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return Math.abs(num) >= item.value;
    });

  return (
    "$" +
    (item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0")
  );
}

export function formateDate(date) {
  let sDay = new Date(date);
  let day = sDay.getDate();
  let month = sDay.getMonth() + 1; // Month is zero-based, so we add 1
  let year = sDay.getFullYear();
  sDay =
    (month < 10 ? "0" : "") +
    month +
    "-" +
    (day < 10 ? "0" : "") +
    day +
    "-" +
    year;
  return sDay;
}


export const getDynamicClass = (value) => {
  return value < 0 ? 'red-text' : 'black-text';
};