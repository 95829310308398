import { Paper } from "@mantine/core";
import { Tabs } from "@mantine/core";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { RouteLink } from "../Constant/RouterLink";
import { useSelector } from "react-redux";

export const UtilitiTabs = () => {
  const linkStyle = {
    textDecoration: "none",
    color: "#000",
    fontSize: "15px",
    marginBottom: "0px",
    paddingBottom: "0px",
  };
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  //   if (location.pathname === RouteLink.Copy_Tin) {
  //     navigate(RouteLink.Copy_Tin);
  //   }

  const utilityRouteWithIndex = [
    // { text: "Add New Physician", routeTo: RouteLink.Copy_Tin, index: '1', level: 5 },
    // { text: "Physician Income", routeTo: RouteLink.Physician_Income, index: "1", level: 1 },
    { text: "UUID", routeTo: RouteLink.Gui_Uri, index: "2", level: 0 }, // UUID
    { text: "CSV Format", routeTo: RouteLink.CSV_Uri, index: "3", level: 0 }, // CSV Format
    { text: "Formula", routeTo: RouteLink.Formula_List_Uri, index: "4", level: 0 }, // Formula
    { text: "Benchmark", routeTo: RouteLink.Benchmark_List_Uri, index: "5", level: 0 }, // Benchmark
    { text: "Group Location", routeTo: RouteLink.Group_Location_List, index: "7", level: 0 }, // Group Location
    { text: "CCM Enable", routeTo: RouteLink.CCM_Enable, index: "6", level: 0 }, // CCM Enable
  ];

  const getActiveTabValue = () => {
    const path = location.pathname;
    // if (path === RouteLink.Copy_Tin) return "1";
    if (path === RouteLink.Gui_Uri) return "2";
    else if (path === RouteLink.CSV_Create_Uri) return "3";
    else if (path === RouteLink.Formula_List_Uri) return "4";
    else if (path === RouteLink.Benchmark_Uri) return "5";
    else if (path === RouteLink.CCM_Enable) return "6";
  };

  const activeTabValue = getActiveTabValue();

  return (
    <Paper withBorder radius="md" p="md">
      <Tabs defaultValue={activeTabValue}>
        <Tabs.List>
          {utilityRouteWithIndex.map((row) => {
            if (row.level >= user.level)
              return (
                <Link to={row.routeTo} style={linkStyle} key={row.index}>
                  <Tabs.Tab value={row.index} key={row.index}>
                    {row.text}
                  </Tabs.Tab>
                </Link>
              );
          })}
        </Tabs.List>

        <Outlet></Outlet>
      </Tabs>
    </Paper>
  );
};

export default UtilitiTabs;
