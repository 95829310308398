export const EntityColumn = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Financial Method Type",
    accessorKey: "financailMethodType",
  },
  {
    header: "Type",
    accessorKey: "type",
  },
  // {
  //   header: "Default Entity Type",
  //   accessorKey: "defaultInvidualEntityType",
  // },
  // {
  //   header: "Low Volume Included",
  //   accessorKey: "lowVolumneIncluded",
  // },
  // {
  //   header: "What If Open",
  //   accessorKey: "whatIfOption",
  // },

]