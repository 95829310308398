import { AppShell, Box, Burger, Container, Footer, MediaQuery, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { navLinks, useNavLinks } from '../navlinks';
import { Navbar } from './Navbar';
import { AdminHeader } from './AdminHeader';
import { Outlet, useNavigate, useRoutes } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RouteLink } from '../Constant/RouterLink';


export function UserLayout() {
    const navigate = useNavigate();
    const [opened, setOpened] = useState(true);
    const { user, isLoggedIn } = useSelector(state => state.auth);
    useEffect(() => {
        if (!isLoggedIn) {
            navigate(RouteLink.Login_Uri);
        }
    }, [isLoggedIn]);

    const navLinks = useNavLinks();

  // Filter navLinks based on user level
  const filteredNavLinks = navLinks.filter((item) => item.level >= user.level);
    return (
        <AppShell
            layout="alt"
            md={(theme) => ({
                main: {
                    backgroundColor: theme.colors.gray[0],
                },
            })}
            navbar={
                opened ? (
                    <Navbar
                       data={filteredNavLinks} // Pass the filtered navLinks here
                        hidden={!opened}
                        burger={
                            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                                <Burger opened={false} onClick={() => setOpened((o) => !o)} size="sm" mr="xl" />
                            </MediaQuery>
                        }
                    />
                ) : (
                    <></>
                )
            }
            navbarOffsetBreakpoint="sm"
            header={
                <AdminHeader
                    // {isLoggedIn}?<div>User loggedIn</div>:<div>User not loggedIn</div>}
                    burger={<Burger opened={false} onClick={() => setOpened((o) => !o)} size="sm" mr="xl" />}
                />
            }
            footer={
                <Footer height={50} p="md">
                    <Text w="full" size="sm" align="center" color="gray">
                        © 2023
                    </Text>
                </Footer>
            }
        >
            <Box ><Outlet /></Box>
        </AppShell>
    )
}