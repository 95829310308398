import React from 'react'
import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Anchor, Box } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useSelector } from "react-redux";
import { ApiLink } from "../../../Constant/ApiLink";
import axios from "axios";
import { ApiCall } from '../../../Shared/ApiCall/ApiCall';

const CqmSelector = () => {
  const { id } = useParams();
  const [rowData, setRowData] = useState();
  const [loading, setIsLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const selectedGroupId = useSelector((store) => store.partenerTree.selectedGroupId);

  const columnDef = useMemo(() => [
    {
      header: "CQM Name",
      accessorKey: "cqmName",
      accessorFn: (row) => {
        return <Link to={"/user/quality/EC-comparision/"+ row?.cqmid}>
          {row?.cqmName}
        </Link>
      },
    },
    {
      header: "CQM Category",
      accessorKey: "cqmCategory",
    },
    {
      header: "CQM Type",
      accessorKey: "cqmType",
    },
    {
      header: "High Low Good",
      accessorKey: "highLowGood",
    },
    {
      header: "Numer",
      accessorKey: "numer",
    },
    {
      header: "Denom",
      accessorKey: "denom",
    },
    {
      header: "CQM Result",
      accessorKey: "cqmResult",
    },
    {
      header: "Measure Possible Point",
      accessorKey: "measurePossiblePoint",
    },
    {
      header: "Decile Point",
      accessorKey: "decilePoint",
    },
    {
      header: "Total Point",
      accessorKey: "totalPoint",
    },
    {
      header: "Selected So Far",
      accessorKey: "selectedSoFar",
    },
  ]);

  useEffect(() => {
    getCQMSelectorData();
  }, [reportSelection.selectedYear, selectedGroupId, reportSelection.clinicId]);

  const getCQMSelectorData = (id) => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.CQMSelector}${selectedGroupId}/${reportSelection.clinicId}/${reportSelection.selectedYear}`)
      .then((response) => {
        setRowData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };


  return (
    <Box style={{ marginTop: "15px" }}>
      <MantineReactTable
        columns={columnDef}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};

export default CqmSelector;