import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Modal, Button, Tooltip, Grid } from "@mantine/core";
import { download, generateCsv, mkConfig } from "export-to-csv";

const MeasureDetailsModel = ({ records, header, onClose }) => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  const [columnDefs] = useState([
    {
      headerName: "NPI",
      field: "npi",
      resizable: true,
    },
    {
      headerName: "TIN",
      field: "tin",
      resizable: true,
    },
    {
      headerName: "Code",
      field: "code",
      resizable: true,
    },
    {
      headerName: "Accession Number",
      field: "accession_number",
      resizable: true,
    },
    {
      headerName: "Birthdate",
      field: "birthdate",
      minWidth: 250,
    },
    {
      headerName: "Encounter Date",
      field: "encounter_date",
      resizable: true,
      minWidth: 250,
    },
    {
      headerName: "Age",
      cellRenderer: (props) => {
        if (props.data) {
          const encounterDate = new Date(props.data.encounter_date);
          const birthDate = new Date(props.data.birthdate);

          // Calculate age
          const age = encounterDate.getFullYear() - birthDate.getFullYear();
          const monthDifference =
            encounterDate.getMonth() - birthDate.getMonth();

          // Adjust age if the encounter date is before the birthday in the current year
          if (
            monthDifference < 0 ||
            (monthDifference === 0 &&
              encounterDate.getDate() < birthDate.getDate())
          ) {
            return age - 1;
          }

          return age;
        }
        return null;
      },
    },

    {
      headerName: "CPT Code Denominator",
      field: "cpt_code_denominator",
      resizable: true,
    },
    {
      headerName: "ICD-10 Codes",
      field: "icd_10_codes",
      resizable: true,
    },
    {
      headerName: "Modifier 1",
      field: "modifier_1",
      resizable: true,
    },
    {
      headerName: "Numerator CPT Code",
      field: "numerator_cpt_code",
      resizable: true,
    },
    {
      headerName: "Numerator Modifier",
      field: "numerator_modifier",
      resizable: true,
    },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    // flex: 1,
    wrapHeaderText: true,
    filter: "agTextColumnFilter",
    filterParams: {
      buttons: [], // Hide the buttons (Clear, Apply)
      debounceMs: 200, // Delay in ms before filter is applied
      suppressAndOrCondition: true, // Hide additional filter options
    },
  }));

  useEffect(() => {
    setRowData(records);
  }, []);

  function mapColumnHeaders(columnDef) {
    return columnDef.map((column) => ({
      key: column.field,
      displayLabel: column.headerName,
    }));
  }

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    columnHeaders: mapColumnHeaders(columnDefs),
    filename: "Excel_Report",
  });

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  return (
    <Modal onClose={() => onClose()} title={header} size="100%" opened>
      <Button
        onClick={handleExportData}
        style={{ marginBottom: "5px", fontWeight: "bold" }}
      >
        Export to Excel
      </Button>
      <div className="ag-theme-alpine" style={{ height: 600, marginTop: 10 }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          pagination={true}
          rowSelection={"single"}
          rowMultiSelectWithClick={true}
          animateRows={true}
        />
      </div>
    </Modal>
  );
};

export default MeasureDetailsModel;
