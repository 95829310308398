import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Box, Button, Grid } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useDispatch, useSelector } from "react-redux";
import { RouteLink } from "../../Constant/RouterLink";
import { ApiLink } from "../../Constant/ApiLink";
import axios from "axios";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";
import { setSelectedGrupId } from "../../utils/patenerTreeSlice";
import { addChangeOnEdit, addClinicId } from "../../utils/selectedClinicSlice";
import Swal from "sweetalert2";

const PIList = () => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [category, setCategory] = useState("PI");
  const selectedGroupId = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    getCSVdataList(selectedGroupId);
  }, [selectedGroupId, reportSelection.clinicId, reportSelection.selectedYear, category]);

  const getCSVdataList = (selectedGroupId) => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}${ApiLink.PIMeasureListTinorGroup}/${selectedGroupId}/${reportSelection.clinicId}/${user.level}/${reportSelection.selectedYear}`
      )
      .then((response) => {
        setRowData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setRowData([]);
      });
  };

  // Define columns for the table
  const columnsDef = useMemo(() => [
    {
      header: "Guid",
      accessorKey: "randomFileId",
      id: "randomFileId",
    },
    {
      header: "TIN",
      accessorKey: "tin",
    },
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Created Date",
      accessorFn: (row) => {
        const date = new Date(row.createdDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        // If the year is 1 (e.g., DateTime.MinValue), return "-"
        if (year === 1) {
          return "-";
        }
        return `${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}-${year}`;
      },
    },
    {
      header: "Is Submitted",
      accessorKey: "isSubmitted",
      Cell: ({ cell }) => {
        const isSubmitted = cell.getValue();
        return (
          <Badge color={isSubmitted ? "green" : "red"}>
            {isSubmitted ? "Submitted" : "Not Submitted"}
          </Badge>
        );
      },
    },
  ], []);

  const onCreate = () => {
    navigate(RouteLink.Piform_Uri, {
      state: { data: null, method: "post" },
    });
  };

  const onUpdate = (value) => {
    dispatch(setSelectedGrupId(value.groupId));
    dispatch(addClinicId(value.clinicId));
    dispatch(addChangeOnEdit(value.clinicId));
    navigate(RouteLink.Piform_Uri, {
      state: { data: value, method: "put" },
    });
  };

  // Updated onDelete function: update the row values rather than removing it
  const onDelete = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        ApiCall.axiosInstance["delete"](
          `${process.env.REACT_APP_API_URL}${ApiLink.MeasureCSVUpload}?uuidfile=${row.randomFileId}&category=PI`
        )
          .then((response) => {
            // Update the corresponding row in the state instead of removing it.
            setRowData((prevRows) =>
              prevRows.map((item) =>
                item.randomFileId === row.randomFileId
                  ? {
                      ...item,
                      randomFileId: "00000000-0000-0000-0000-000000000000",
                      createdDate : "0001-01-01T00:00:00",
                      isSubmitted: false,
                    }
                  : item
              )
            );
          })
          .catch((error) => {
            console.error("Error deleting record:", error);
          });
      }
    });
  };

  return (
    <Box style={{ marginTop: "15px" }}>
      <Grid>
        <Grid.Col span={9}></Grid.Col>
        <Grid.Col span={3}></Grid.Col>
      </Grid>
      <MantineReactTable
        columns={columnsDef}
        data={rowData}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn="first"
        renderRowActions={(row) => (
          <RowAction
            onUpdate={() => onUpdate(row.row.original)}
            onDelete={() => onDelete(row.row.original)}
          />
        )}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
        initialState={{ columnVisibility: { randomFileId: false } }}
      />
    </Box>
  );
};

export default PIList;

const RowAction = (props) => {
  return (
    <Grid>
      <Button.Group>
        <Button
          variant="filled"
          color="green"
          size="xs"
          onClick={props.onUpdate}
        >
          <IconEdit size="1.2rem" />
        </Button>
        <Button
          variant="filled"
          color="red"
          size="xs"
          onClick={props.onDelete}
        >
          <IconTrash size="1.2rem" />
        </Button>
      </Button.Group>
    </Grid>
  );
};
