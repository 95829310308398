import React, { useEffect } from 'react';
import img1 from '../../assets/login-bg.svg';
import { Anchor, Button, Grid, TextInput, Title, createStyles } from '@mantine/core';
import { useNavigate } from 'react-router';
import { RouteLink } from "../../Constant/RouterLink";
import useAxios from '../../Shared/useApi';
import { useForm } from '@mantine/form';
import axios from 'axios';
import { refreshTreeData } from '../../utils/patenerTreeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { login } from './AuthSlice';
import { Link } from 'react-router-dom';


const useStyle = createStyles((theme) => ({
  input: {
    input: {
      height: 50,
    },
  },
  button: {
    minHeight: 50,
    marginTop: 10,
  },
  title: {
    color: theme.colors.blue[7],
  },
  form: {
    flexDirection: 'column',
    gap: 20,
    width: '50%',
    padding: 50,
    '@media (max-width: 900px)': {
      padding: 30,
      width: '100%',
      maxWidth: 500,
    },
  },
  image: {
    width: '50%',
    maxWidth: '812px',
    '@media (max-width: 900px)': {
      width: '90%',
      maxWidth: 500,
    },
  },
  container: {
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  link: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue[6],
    marginTop:'80px',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const Login = () => {
  const { classes } = useStyle();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {user,isLoading,isLoggedIn,error}=useSelector(state=>state.auth);
  // const { response, loading, error } = useAxios({
  //   method: "post",
  //   url: "/User/Login",
  //   isDefaultCall: false
  // });
  function handleSubmit(values) {
    
    if(values.password && values.userId){
      dispatch(login({userId:values.userId,password:values.password}));
    }
  }

  useEffect(() => {
    if (user && isLoggedIn) {
      dispatch(refreshTreeData());
      navigate(RouteLink.Dashboard_Uri);
    }
  }, [user,isLoggedIn]);

  useEffect(() => {
    if (error) {
      form.setErrors({ password: `Error:${error}` });
    }
  }, [error]);

  const form = useForm({
    initialValues: {
      userId: "",
      password: ""
    },

    // functions will be used to validate values at corresponding key
    validate: {
      userId: (value) =>
        value?.length < 3 || !value
          ? "Name must have at least 3 letters"
          : null,
      password: (value) =>
        value?.length < 6 || !value
          ? "Name must have at least 6 letters"
          : null,
    },
  });

  return (
    <Grid
      container
      spacing={0}
      className={classes.container}
      sx={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}
    >
      <img src={img1} alt="bg" className={classes.image} />
      <Grid className={classes.form}>
        <Title order={2} className={classes.title}>
          Welcome to MACRAmonitor
        </Title>
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <TextInput
            placeholder="Your login"
            label="Login"
            withAsterisk
            type="email"
            className={classes.input}
            {...form.getInputProps("userId")}
          />

          <TextInput
            placeholder="Your password"
            label="Password"
            withAsterisk
            type="password"
            className={classes.input}
            {...form.getInputProps("password")}
          />
          <Button type='submit' className={classes.button} fullWidth>
            Sign In
          </Button>
          <br />
          <Anchor component={Link} to="/forgetpassword">
              Forgot Password?
            </Anchor>
        </form>
      </Grid>
    </Grid>
  );
};

export default Login;
