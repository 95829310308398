import { nFormatter } from "../../../Shared/NumberFunctions";

export const ComponentDetialsColumns = (abc) => {
  return [
    {
      header: abc,
      accessorKey: "ecName",
    },
    {
      header: "NPI",
      accessorKey: "npi",
    },
    {
      header: "TIN waitage",
      accessorKey: "tin",
    },

    {
      header: "Promoting Interoperability (25%)",
      accessorKey: "pi",
      accessorFn: (row) => {
        return nFormatter(0, 2);
      },
    },
    {
      header: "Quality (45%)",
      accessorKey: "quality",
    },
    {
      header: "Cost (15%)",
      accessorKey: "resource",
    },
    {
      header: "CPIA (15%)",
      accessorKey: "cpia",
    },
    {
      header: "Composite Score",
      accessorKey: "compositeScore",
    },

    {
      header: "Target Composite Score",
      accessorKey: "targetCompositeScore",
    },
  ];
};
