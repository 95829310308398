import React, { useState } from "react";
import img1 from "../../assets/forgetpassword.jpg";
import {
  Anchor,
  Box,
  Button,
  Grid,
  LoadingOverlay,
  TextInput,
  Title,
  createStyles,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router";
import { useForm } from "@mantine/form";
import axios from "axios";
import { ApiLink } from "../../Constant/ApiLink";
import Swal from "sweetalert2"; // Import SweetAlert2

const useStyle = createStyles((theme) => ({
  input: {
    input: {
      height: 50,
    },
  },
  button: {
    minHeight: 50,
    marginTop: 10,
  },
  title: {
    color: theme.colors.blue[7],
  },
  form: {
    flexDirection: "column",
    gap: 20,
    width: "50%",
    padding: 50,
    "@media (max-width: 900px)": {
      padding: 30,
      width: "100%",
      maxWidth: 500,
    },
  },
  image: {
    width: "50%",
    maxWidth: "612px",
    margin: "100px",
    "@media (max-width: 900px)": {
      width: "90%",
      maxWidth: 500,
    },
  },
  container: {
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
}));

const ForgetPassword = () => {
  const location = useLocation();
  const caller = location.state?.caller || "default";
  const { classes } = useStyle();
  const navigate = useNavigate();
  const [step, setStep] = useState("email"); // Steps: "email", "otp", "reset"
  const [storedOtp, setStoredOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");

  const form = useForm({
    initialValues: {
      email: "",
      otp: "",
      newPassword: "",
      confirmPassword: "",
    },
    validate: {
      confirmPassword: (value, values) =>
        value !== values.newPassword ? "Passwords do not match" : null,
    },
  });

  const handleSendOtp = async (values) => {
    setLoading(true);
    const generatedOtp = Math.floor(100000 + Math.random() * 900000).toString();
    setStoredOtp(generatedOtp);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${ApiLink.ResetPasswordwithOtp}`,
        {
          id: "string",
          email: values.email,
          otp: generatedOtp,
          password: "",
        }
      );
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Six digit code sent to your registered email address.",
      });
      setToken(response.data.data); // Save the token from the response
      form.setFieldValue("token", response.data.data); // Update the form token field
      setStep("otp");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Email does not exist.",
      });
      console.error(error);
    }
  };

  const handleVerifyOtp = (values) => {
    if (values.otp === storedOtp) {
      setStep("reset");
    } else {
      Swal.fire({
        icon: "error",
        title: "Invalid OTP",
        text: "The code you entered is invalid.",
      });
    }
  };

  const handleResetPassword = async (values) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    if (!passwordRegex.test(values.newPassword)) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Password",
        text: "Password must be at least 8 characters, include one uppercase letter, one number, and one special character",
      });
      return;
    }
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}${ApiLink.CreateNewPassword}`,
        {
          id: "string",
          email: form.values.email,
          otp: form.values.otp,
          password: values.newPassword,
          token: form.values.token,
        }
      );
      setLoading(false);
      Swal.fire({
        icon: "success",
        title: "Password Reset",
        text: "Password reset successful",
      }).then(() => {
        navigate("/");
      });
    } catch (error) {
      setLoading(false);
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Reset Failed",
        text: "Failed to reset password. Please try again.",
      });
    }
  };

  return (
    <Box>
      <br />
      {loading && (
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      )}
      <Grid
        container
        spacing={0}
        className={classes.container}
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        {caller !== "user" && (
          <img src={img1} alt="bg" className={classes.image} />
        )}
        <Grid className={classes.form}>
          {step === "email" && (
            <>
              <Title order={2} className={classes.title}>
                Enter Your Registered Email
              </Title>
              <form onSubmit={form.onSubmit((values) => handleSendOtp(values))}>
                <TextInput
                  placeholder="Your email"
                  label="Email"
                  withAsterisk
                  type="email"
                  className={classes.input}
                  required
                  {...form.getInputProps("email")}
                />
                <Button type="submit" className={classes.button} fullWidth>
                  Send Email
                </Button>
                <br />
                <Anchor href="/">Back </Anchor>
              </form>
            </>
          )}

          {step === "otp" && (
            <>
              <Title order={2} className={classes.title}>
                Enter OTP
              </Title>
              <form onSubmit={form.onSubmit((values) => handleVerifyOtp(values))}>
                <TextInput
                  placeholder="OTP"
                  label="OTP"
                  withAsterisk
                  type="text"
                  className={classes.input}
                  required
                  {...form.getInputProps("otp")}
                />
                <Button type="submit" className={classes.button} fullWidth>
                  Verify OTP
                </Button>
                <Button
                  className={classes.button}
                  variant="outline"
                  fullWidth
                  onClick={() => handleSendOtp({ email: form.values.email })}
                >
                  Resend OTP
                </Button>
                <Button
                  className={classes.button}
                  variant="subtle"
                  fullWidth
                  onClick={() => setStep("email")}
                >
                  Back
                </Button>
              </form>
            </>
          )}

          {step === "reset" && (
            <>
              <Title order={2} className={classes.title}>
                Reset Password
              </Title>
              <form
                onSubmit={form.onSubmit((values) =>
                  handleResetPassword(values)
                )}
              >
                <TextInput
                  placeholder="New Password"
                  label="New Password"
                  withAsterisk
                  type="password"
                  className={classes.input}
                  required
                  {...form.getInputProps("newPassword")}
                />
                <TextInput
                  placeholder="Confirm Password"
                  label="Confirm Password"
                  withAsterisk
                  type="password"
                  className={classes.input}
                  required
                  {...form.getInputProps("confirmPassword")}
                />
                <Button type="submit" className={classes.button} fullWidth>
                  Reset Password
                </Button>
              </form>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgetPassword;
