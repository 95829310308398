import { Paper } from "@mantine/core";
import { Tabs } from "@mantine/core";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { RouteLink } from "../Constant/RouterLink";
import { useSelector } from "react-redux";

export const QualityTab = () => {
  const linkStyle = {
    textDecoration: "none",
    color: "#000",
    fontSize: "15px",
    marginBottom: "0px",
    paddingBottom: "0px",
  };
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();

  const utilityRouteWithIndex = [
    { text: "Data Upload", routeTo: RouteLink.CSV_Upload_List, index: "1", level: 3 },
    // { text: "Quality Home", routeTo: RouteLink.Quality_Home_Uri, index: "2", level: 2 }, // tin & quality score
    // { text: "CQM Selector", routeTo: RouteLink.CQM_Selector_Uri, index: "3", level: 3 }, // tin & measures
    // { text: "EC Comparision Tool", routeTo: RouteLink.EC_Comparision_Uri, index: "4", level: 4 }, // each physician in a tin
    { text: "Group CQM Details ", routeTo: RouteLink.Group_CQM_Details, index: "10", level: 2 },
    { text: "TIN CQM Details", routeTo: RouteLink.Tin_CQM_Details_Uri, index: "8", level: 3 },
    { text: "Measure CQM Details", routeTo: RouteLink.Measure_CQM_Details_Uri, index: "9", level: 3 },
    { text: "Physician CQM Details ", routeTo: RouteLink.Group_CQM_Details_Uri, index: "5", level: 3 },
    { text: "Physician Score ", routeTo: RouteLink.Quaity_PhysicianScore, index: "2", level: 3 },
    { text: "Quality Notes ", routeTo: RouteLink.Quaity_QualityNotes, index: "3", level: 3 },

    // physician & measures
    // { text: "Group CQM Summary", routeTo: RouteLink.Group_CQM_Summary, index: "6", level: 6 }, // each physician in a tin
    // { text: "EC Submission Status", routeTo: RouteLink.EC_Submission_status_Uri, index: "7", level: 6 }, // physician &
  ];

  const getActiveTabValue = () => {
    const path = location.pathname;
    if (path === RouteLink.CSV_Upload_uri) return "1";
    else if (path === RouteLink.Group_CQM_Details_Uri) return "5";
    else if (path == RouteLink.Tin_CQM_Details_Uri) return "8";
    else if (path == RouteLink.Measure_CQM_Details_Uri) return "9";
    else if (path == RouteLink.Group_CQM_Details) return "10";
    else if (path == RouteLink.Quaity_PhysicianScore) return "2";
    else if (path == RouteLink.Quaity_QualityNotes) return "3";
  };

  const activeTabValue = getActiveTabValue();
  return (
    <Paper withBorder radius="md" p="md">
      <Tabs defaultValue={activeTabValue}>
        <Tabs.List>
          {utilityRouteWithIndex.map((row) => {
            if (row.level >= user.level)
              return (
                <Link to={row.routeTo} style={linkStyle} key={row.index}>
                  <Tabs.Tab value={row.index} key={row.index}>
                    {row.text}
                  </Tabs.Tab>
                </Link>
              );
          })}
        </Tabs.List>

        <Outlet></Outlet>
      </Tabs>
    </Paper>
  );
};

export default QualityTab;
