import React, { useEffect, useState } from "react";
import { Anchor, Box, Button, Checkbox, Divider, Grid, LoadingOverlay, SegmentedControl, Select, Text, TextInput, } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ApiLink } from "../../Constant/ApiLink";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { IconDownload, IconFileText } from "@tabler/icons-react";
import { formatDate } from "./SubmissionUtilis";
import { useLocation } from "react-router";

const SubmissionForm = () => {
  const [loading, setIsLoading] = useState(false);
  const [physicians, setPhysicians] = useState([]);
  const { data, method } = useLocation().state;
  console.log("hello", data);

  // Redux state
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const selectedGroupId = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );
  const { user } = useSelector((state) => state.auth);


  // Mantine form initialization
  const thisForm = useForm({
    initialValues: {
      id: data?.id ?? 0,
      submissionfor: data?.submissionfor,
      submissiontype: data?.submissiontype,
      systemGenrated: String(data?.systemGenrated ?? false),
      programName: data?.programName,
      npi: "0",
      qa: false,
      ia: false,
      pi: false,
      tin: "",
      clinicname: "",
      group: selectedGroupId,
      clinicId: reportSelection.clinicId,
      year: reportSelection.selectedYear,
      meetingStatus: "",
      reportingCategory: "",
      totalPhysician: "",
      chert: "",
      consent: "",
      cpiaDoc: "",
      clinicType: "",
      overallScore: 0,
      quaContNum: 0,
      quaContDen: 0,
      quaStanNum: 0,
      quaStanDen: 0,
      iaContNum: 0,
      iaContDen: 0,
      iaStanNum: 0,
      iaStanDen: 0,
      //
      piContNum: 0,
      piContDen: 0,
      piStanNum: 0,
      piStanDen: 0,
      //
      isScoreStatus: false,
      scoreResult: "",
      scoreSubmissionjson: "",
      scoreResponsejson: "",
      createdDate: null,
      updatedDate: null,
      submissionVerified: false,
      groupName: "",
      submissionToken: "",
      submissionId: "",
      //Quality Submission
      qualityId: "",
      qualityResult: "",
      qualityJson: "",
      qualitySubmissionResponseJson: "",
      qualityCreatedDate: "",
      qualityUpdatedDate: "",
      qualityMeasuremenrSetId: "",
      // IA
      iaId: "",
      iaResult: "",
      iaJson: "",
      iaSubmissionResponseJson: "",
      iaCreatedDate: "",
      iaUpdatedDate: "",
      iaMeasuremenrSetId: "",
      // PI
      piId: "",
      piResult: "",
      piJson: "",
      piSubmissionResponseJson: "",
      piMeasuremenrSetId: "",
      piCreatedDate: "",
      piUpdatedDate: ""

    },
  });

  // Fetch data from API and set form values
  const getCCMEnableData = async () => {
    setIsLoading(true);
    try {
      const systemGeneratedBool = thisForm.values.systemGenrated === "true";
      const response = await ApiCall.axiosInstance.post(
        `${process.env.REACT_APP_API_URL}${ApiLink.SubmissionReq}/${reportSelection.selectedYear
        }/${selectedGroupId}/${reportSelection.clinicId}/${thisForm.values.submissionfor === "Group" ? "0" : thisForm.values.npi
        }/${user.level}/${thisForm.values.submissionfor}/${thisForm.values.submissiontype}/${systemGeneratedBool}/${thisForm.values.programName}/${thisForm.values.id}`
      );


      const respdata = response.data[0];
      thisForm.setValues({
        id: respdata?.id,
        group: respdata?.group,
        tin: respdata?.tin,
        clinicId: respdata?.clinicId,
        clinicName: respdata?.clinicName,
        submissionfor: thisForm.values.submissionfor,
        submissiontype: thisForm.values.submissiontype,
        systemGenrated: thisForm.values.systemGenrated,
        programName: thisForm.values.programName,
        npi: data.npi === "0" ? "0" : respdata.npi,
        qa: respdata?.qa,
        ia: respdata?.ia,
        pi: respdata?.pi,
        fileName: respdata?.fileName,
        submissionjson: respdata?.submissionjson,
        result: respdata?.result,
        resultFile: respdata?.resultFile,
        isStatus: respdata?.isStatus,
        year: respdata?.year,
        meetingStatus: respdata?.meetingStatus,
        reportingCategory: respdata?.reportingCategory,
        totalPhysician: respdata?.totalPhysician,
        chert: respdata?.chert,
        consent: respdata?.consent,
        cpiaDoc: respdata?.cpiaDoc,
        clinicType: respdata?.clinicType,
        overallScore: (respdata?.overallScore ?? 0) + (respdata?.iaContNum ?? 0) + (respdata?.piContNum ?? 0),
        quaContNum: respdata?.quaContNum,
        quaContDen: respdata?.quaContDen,
        quaStanNum: respdata?.quaStanNum,
        quaStanDen: respdata?.quaStanDen,
        iaContNum: respdata?.iaContNum,
        iaContDen: respdata?.iaContDen,
        iaStanNum: respdata?.iaStanNum,
        iaStanDen: respdata?.iaStanDen,
        piContNum: respdata?.piContNum,
        piContDen: respdata?.piContDen,
        piStanNum: respdata?.piStanNum,
        piStanDen: respdata?.piStanDen,
        //
        isScoreStatus: respdata?.isScoreStatus,
        scoreResult: respdata?.scoreResult,
        scoreSubmissionjson: respdata?.scoreSubmissionjson,
        scoreResponsejson: respdata?.scoreResponsejson,
        createdDate: respdata?.createdDate,
        updatedDate: formatDate(respdata?.updatedDate) || null,
        submissionUpdatedDate: formatDate(respdata?.submissionUpdatedDate) || null,
        submissionVerified: respdata?.submissionVerified,
        //
        groupName: respdata?.groupName,
        submissionToken: respdata?.submissionToken,
        submissionId: respdata?.submissionId,
        //Quality Submission
        qualityId: respdata?.qualityId,
        qualityResult: respdata?.qualityResult,
        qualityJson: respdata?.qualityJson,
        qualitySubmissionResponseJson: respdata?.qualitySubmissionResponseJson,
        qualityCreatedDate: respdata?.qualityCreatedDate,
        qualityUpdatedDate: respdata?.qualityUpdatedDate,
        qualityMeasuremenrSetId: respdata?.qualityMeasuremenrSetId,
        // IA
        iaId: respdata?.iaId,
        iaResult: respdata?.iaResult,
        iaJson: respdata?.iaJson,
        iaSubmissionResponseJson: respdata?.iaSubmissionResponseJson,
        iaCreatedDate: respdata?.iaCreatedDate,
        iaUpdatedDate: respdata?.iaUpdatedDate,
        iaMeasuremenrSetId: respdata?.iaMeasuremenrSetId,
        // PI
        piId: respdata?.piId,
        piResult: respdata?.piResult,
        piJson: respdata?.piJson,
        piSubmissionResponseJson: respdata?.piSubmissionResponseJson,
        piMeasuremenrSetId: respdata?.piMeasuremenrSetId,
        piCreatedDate: respdata?.piCreatedDate,
        piUpdatedDate: respdata?.piUpdatedDate,
        //



      });

      setPhysicians(
        data.physicians.map((phy) => ({
          value: phy.id,
          label: phy.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle switch between Group and Individual
  useEffect(() => {
    if (thisForm.values.submissionfor === "Individual") {
      thisForm.setFieldValue("qa", false);
      thisForm.setFieldValue("ia", false);
      thisForm.setFieldValue("pi", false);
      if (thisForm.values.npi !== "0") getCCMEnableData();
    } else {
      thisForm.setFieldValue("npi", "0");
      getCCMEnableData();
    }
  }, [thisForm.values.submissionfor, thisForm.values.submissiontype, thisForm.values.systemGenrated, thisForm.values.programName]);

  // Trigger API when a physician is selected
  useEffect(() => {
    if (
      thisForm.values.submissionfor === "Individual" &&
      thisForm.values.npi !== "0"
    ) {
      getCCMEnableData();
    }
  }, [thisForm.values.npi]);

  // Initial API call for Group on component load
  useEffect(() => {
    if (selectedGroupId && reportSelection) {
      if (thisForm.values.submissionfor === "Group") {
        getCCMEnableData();
      }
    }
  }, [selectedGroupId, reportSelection]);

  //


  // Handle Prepare JSON (Process) Button
  const handlePrepareJson = () => {

    if (thisForm.values.pi === true) {
      if (
        !thisForm.values.chert.trim() ||
        thisForm.values.chert.trim() === "" ||
        thisForm.values.chert.trim() === "-"
      ) {
        Swal.fire({
          icon: "error",
          title: "Missing CHERT",
          text: "Since PI is true, the CHERT cannot be empty. Please provide a value.",
        });
        return; // Stop execution if consent is empty
      }
    }


    const payload = {
      id: thisForm.values.id,
      group: selectedGroupId,
      tin: thisForm.values.tin,
      clinicId: reportSelection.clinicId,
      clinicName: thisForm.values.clinicName,
      npi: thisForm.values.npi,
      qa: thisForm.values.qa,
      ia: thisForm.values.ia,
      pi: thisForm.values.pi,
      submissiontype: thisForm.values.submissiontype,
      submissionfor: thisForm.values.submissionfor,
      year: reportSelection.selectedYear,
      isScoreStatus: thisForm.values.isScoreStatus,
      scoreViewResult: thisForm.values.scoreFileName,
      scoreSubmissionjson: thisForm.values.scoreSubmissionjson,
      isResult: thisForm.values.isResult,
      result: thisForm.values.result,
      resultSubmissionJson: thisForm.values.resultSubmissionJson,
      resultFile: thisForm.values.resultFile,
      CHERT: thisForm.values.chert,
      SystemGenrated: thisForm.values.systemGenrated,
      ProgramName: thisForm.values.programName,
    };

    console.log("Payload on Save:", payload);
    setIsLoading(true);

    ApiCall.axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}${ApiLink.SubmissionPreparjson}`,
        payload
      )
      .then((response) => {
        setIsLoading(false);
        const data = response?.data;
        // Set response values in form
        thisForm.setValues({
          isScoreStatus: data?.isScoreStatus || false,
          scoreResult: data?.scoreResult || "",
          scoreSubmissionjson: data?.scoreSubmissionjson || "",
          scoreResponsejson: data?.scoreResponsejson || "",
          updatedDate: formatDate(data?.updatedDate) || "",
          createdDate: formatDate(data?.createdDate) || "",
          qa: data?.qa,
          ia: data?.ia,
          pi: data?.pi,
          submissionVerified: data?.submissionVerified,
          id: data?.id,
        });

        Swal.fire({
          icon: "success",
          title: "Json Prepared successfully!",
          showConfirmButton: false,
          timer: 1500,
        });

        //navigate(RouteLink.CPIAList_Uri);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Save error:", error);
      });
  };

  /// Final SubmissionSave
  const handleSubmit = (values, event) => {

    // Retrieve the clicked button using event.nativeEvent.submitter
    const submitter = event.nativeEvent.submitter;
    const clickedCategory = submitter.getAttribute('data-category');


    if (clickedCategory === "QualityView") {
      GenrateViewSubmissionJson(values, clickedCategory);
      return;
    }
    if (clickedCategory === "IaView") {
      GenrateViewSubmissionJson(values, clickedCategory);
      return;
    }

    if (clickedCategory === "PiView") {
      GenrateViewSubmissionJson(values, clickedCategory);
      return;
    }


    if (thisForm.values.submissiontype === "ElectronicHealthRecord") {
      if (
        !thisForm.values.chert.trim() ||
        thisForm.values.chert.trim() === ""
      ) {
        Swal.fire({
          icon: "error",
          title: "Missing CHERT",
          text: "CHERT cannot be empty. Please provide a value.",
        });
        return; // Stop execution if consent is empty
      }
    }

    if (!thisForm.values.consent || thisForm.values.consent.trim() === "") {
      Swal.fire({
        icon: "error",
        title: "Missing Consent",
        text: "Consent cannot be empty. Please provide a value.",
      });
      return; // Stop execution if consent is empty
    }

    if (thisForm.values.ia === true) {
      if (!thisForm.values.cpiaDoc || thisForm.values.cpiaDoc.trim() === "") {
        Swal.fire({
          icon: "error",
          title: "Missing CpiaDoc",
          text: "CpiaDoc cannot be empty. Please provide a value.",
        });
        return; // Stop execution if consent is empty
      }
    }


    if (thisForm.values.pi === true) {
      if (
        !thisForm.values.chert.trim() ||
        thisForm.values.chert.trim() === "" ||
        thisForm.values.chert.trim() === "-"
      ) {
        Swal.fire({
          icon: "error",
          title: "Missing CHERT",
          text: "Since PI is true, the CHERT cannot be empty. Please provide a value.",
        });
        return; // Stop execution if consent is empty
      }
    }

    //
    let measurementid = "";
    if (clickedCategory.toLowerCase() === "quality") {
      measurementid = thisForm.values.qualityMeasuremenrSetId;
    } else if (clickedCategory.toLowerCase() === "ia") {
      measurementid = thisForm.values.iaMeasuremenrSetId;
    } else if (clickedCategory.toLowerCase() === "pi") {
      measurementid = thisForm.values.piMeasuremenrSetId;
    }

    // Determine the key name based on the clickedCategory
    const measurementKey =
      clickedCategory.toLowerCase() === "quality"
        ? "qualityMeasuremenrSetId"
        : clickedCategory.toLowerCase() === "ia"
          ? "iaMeasuremenrSetId"
          : clickedCategory.toLowerCase() === "pi"
            ? "piMeasuremenrSetId"
            : null;

    const payload = {
      id: thisForm.values.id,
      group: selectedGroupId,
      tin: thisForm.values.tin,
      clinicId: reportSelection.clinicId,
      npi: thisForm.values.npi,
      submissiontype: values.submissiontype,
      submissionfor: values.submissionfor,
      year: reportSelection.selectedYear,
      isResult: thisForm.values.isResult,
      result: thisForm.values.result,
      resultFile: thisForm.values.resultFile,
      submissionVerified: thisForm.values.submissionVerified === true ? false : true,
      CHERT: thisForm.values.chert,
      Category: clickedCategory,
      submissionId: thisForm.values.submissionId,
      SystemGenrated: thisForm.values.systemGenrated,
      ProgramName: thisForm.values.programName,
      ...(measurementKey ? { [measurementKey]: measurementid } : {}),
    };


    console.log("Payload on Save:", payload);
    setIsLoading(true);

    ApiCall.axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}${ApiLink.FinalSubmissionSave}`,
        payload
      )
      .then((response) => {
        setIsLoading(false);
        const data = response?.data;
        // Set response values in form
        thisForm.setValues({
          id: data?.id,
          submissionResult: data?.submissionResult || "",
          submissionResponse: data?.submissionResponse || "",
          submissionUpdatedDate: formatDate(data?.submissionUpdatedDate) || "",
          scoreUpdatedDate: formatDate(data?.scoreUpdatedDate) || "",
          qa: data?.qa,
          ia: data?.ia,
          pi: data?.pi,
          submissionVerified: data?.submissionVerified,
          submissionId: data?.submissionId,

          //Quality Submission
          qualityId: data?.qualityId,
          qualityResult: data?.qualityResult,
          qualityJson: data?.qualityJson,
          qualitySubmissionResponseJson: data?.qualitySubmissionResponseJson,
          qualityCreatedDate: data?.qualityCreatedDate,
          qualityUpdatedDate: data?.qualityUpdatedDate,
          qualityMeasuremenrSetId: data?.qualityMeasuremenrSetId,
          // IA
          iaId: data?.iaId,
          iaResult: data?.iaResult,
          iaJson: data?.iaJson,
          iaSubmissionResponseJson: data?.iaSubmissionResponseJson,
          iaCreatedDate: data?.iaCreatedDate,
          iaUpdatedDate: data?.iaUpdatedDate,
          iaMeasuremenrSetId: data?.iaMeasuremenrSetId,
          // PI
          piId: data?.piId,
          piResult: data?.piResult,
          piJson: data?.piJson,
          piSubmissionResponseJson: data?.piSubmissionResponseJson,
          piMeasuremenrSetId: data?.piMeasuremenrSetId,
          piCreatedDate: data?.piCreatedDate,
          piUpdatedDate: data?.piUpdatedDate

        });

        Swal.fire({
          icon: "success",
          title: "Records Saved successfully!",
          showConfirmButton: false,
          timer: 1500,
        });

        //navigate(RouteLink.CPIAList_Uri);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Save error:", error);
      });
  };
  // Final Views SubmissionSave
  // Final Views SubmissionSave
  const GenrateViewSubmissionJson = (values, event) => {


    let clickedCategory = event

    if (clickedCategory === "QualityView") {
      clickedCategory = "Quality"
    }
    if (clickedCategory === "IaView") {
      clickedCategory = "IA"
    }
    if (clickedCategory === "PiView") {
      clickedCategory = "PI"
    }

    //
    let measurementid = "";
    if (clickedCategory.toLowerCase() === "quality") {
      measurementid = thisForm.values.qualityMeasuremenrSetId;
    } else if (clickedCategory.toLowerCase() === "ia") {
      measurementid = thisForm.values.iaMeasuremenrSetId;
    } else if (clickedCategory.toLowerCase() === "pi") {
      measurementid = thisForm.values.piMeasuremenrSetId;
    }

    // Determine the key name based on the clickedCategory
    const measurementKey =
      clickedCategory.toLowerCase() === "quality"
        ? "qualityMeasuremenrSetId"
        : clickedCategory.toLowerCase() === "ia"
          ? "iaMeasuremenrSetId"
          : clickedCategory.toLowerCase() === "pi"
            ? "piMeasuremenrSetId"
            : null;

    const payload = {
      id: thisForm.values.id,
      group: selectedGroupId,
      tin: thisForm.values.tin,
      clinicId: reportSelection.clinicId,
      npi: thisForm.values.npi,
      submissiontype: values.submissiontype,
      submissionfor: values.submissionfor,
      year: reportSelection.selectedYear,
      isResult: thisForm.values.isResult,
      result: thisForm.values.result,
      resultFile: thisForm.values.resultFile,
      submissionVerified: thisForm.values.submissionVerified === true ? false : true,
      CHERT: thisForm.values.chert,
      Category: clickedCategory,
      submissionId: thisForm.values.submissionId,
      SystemGenrated: thisForm.values.systemGenrated,
      ProgramName: thisForm.values.programName,
      ...(measurementKey ? { [measurementKey]: measurementid } : {}),
    };

    console.log("Payload on Save:", payload);
    setIsLoading(true);

    ApiCall.axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}${ApiLink.FinalViewSubmissionSave}`,
        payload
      )
      .then((response) => {
        setIsLoading(false);
        const data = response?.data;
        var rst = JSON.stringify(data);
        downloadJsonFromString(rst, thisForm.values.tin + "_" + clickedCategory + ".json");

      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Save error:", error);
      });
  }


  //Verified
  const handleChangeSubmit = (checked) => {
    const payload = {
      group: selectedGroupId,
      tin: thisForm.values.tin,
      clinicId: reportSelection.clinicId,
      npi: thisForm.values.npi,
      submissiontype: thisForm.values.submissiontype,
      submissionfor: thisForm.values.submissionfor,
      year: reportSelection.selectedYear,
      submissionVerified: checked,
    };

    console.log("Payload on Save:", payload);
    setIsLoading(true);

    ApiCall.axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}${ApiLink.FinalSubmissionVerifiedSave}`,
        payload
      )
      .then((response) => {
        setIsLoading(false);
        const data = response?.data;
        // Set response values in form
        thisForm.setValues({
          submissionVerified: data?.submissionVerified,
        });

        Swal.fire({
          icon: "success",
          title: "Records Verified Status Updated!",
          showConfirmButton: false,
          timer: 1500,
        });

        //navigate(RouteLink.CPIAList_Uri);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Save error:", error);
      });
  };
  //
  const downloadJsonFromString = (jsonString, fileName = "data.json") => {
    try {
      const jsonObject = JSON.parse(jsonString); // Parse the JSON string
      const jsonStr = JSON.stringify(jsonObject, null, 2); // Pretty print JSON
      const blob = new Blob([jsonStr], { type: "application/json" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Invalid JSON string:", error);
    }
  };
  //
  const handleShowToken = (token) => {
    Swal.fire({
      title: "Submission Token",
      text: token || "No token available",
      icon: "info",
      confirmButtonText: "OK",
    });
  };

  //
  // Function that calls your API with the given submission id
  const getSubmission = async (submissionid) => {
    setIsLoading(true);
    try {
      const response = await ApiCall.axiosInstance.post(
        `${process.env.REACT_APP_API_URL}${ApiLink.SubmissionVerify}/${selectedGroupId}/${submissionid}`
      );
      const data = response.data;
      var rst = JSON.stringify(data);
      downloadJsonFromString(rst, "SubmissionData.json");
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={thisForm.onSubmit(handleSubmit)}>
      <Box style={{ marginTop: "15px", position: "relative" }}>
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        {/* First Row: Controls */}
        <Grid align="center" mt={20} mb={10}>
          <Grid.Col span={2}>
            <SegmentedControl
              label="Submitted For:"
              fullWidth
              color="rgba(43, 138, 66, 1)"
              data={[
                { label: "Group", value: "Group" },
                { label: "Individual", value: "Individual" },
              ]}
              {...thisForm.getInputProps("submissionfor")}
            />
          </Grid.Col>

          <Grid.Col span={2}>
            <SegmentedControl
              label="Submitted Type:"
              fullWidth
              color="rgba(43, 138, 66, 1)"
              data={[
                { label: "Registry", value: "Registry" },
                { label: "EHR", value: "ElectronicHealthRecord" },
              ]}
              value={thisForm.values.submissiontype}
              onChange={(value) =>
                thisForm.setFieldValue("submissiontype", value)
              }
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <SegmentedControl
              label="Selected Method:"
              fullWidth
              color="rgba(43, 138, 66, 1)"
              data={[
                { label: "System Generated", value: "false" },
                { label: "Client Generated", value: "true" },
              ]}
              value={String(thisForm.values.systemGenrated)}
              onChange={(value) =>
                thisForm.setFieldValue("systemGenrated", value)
              }


            />
          </Grid.Col>
          <Grid.Col span={2}>
            <SegmentedControl
              label="Select Program:"
              fullWidth
              color="rgba(43, 138, 66, 1)"
              data={[
                { label: "MIPS", value: "mips" },
                { label: "MVP", value: "mvp" },
              ]}
              value={thisForm.values.programName}
              onChange={(value) =>
                thisForm.setFieldValue("programName", value)
              }
            />
          </Grid.Col>

          {thisForm.values.submissionfor === "Individual" && (
            <Grid.Col
              span={2}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Select
                label="Select Physician:"
                searchable
                placeholder="Select Physician..."
                data={[
                  { value: "0", label: "Select Physician..." },
                  ...physicians,
                ]}
                value={thisForm.values.npi}
                onChange={(value) => thisForm.setFieldValue("npi", value)}
                style={{ width: "100%", marginTop: "-26px" }}
              />
            </Grid.Col>
          )}
        </Grid>

        <Divider my="md" label="Details Section" labelPosition="center" />

        <Grid align="center" gutter="md" mt={10}>
          <Grid.Col span={3}>
            <TextInput
              label={`TIN (${thisForm.values.tin})`}
              readOnly
              value={thisForm.values.clinicName}
              fz="xs"
            />

            <TextInput
              label="Reporting Category"
              readOnly
              value={thisForm.values.reportingCategory}
              mt="md"
              fz="xs"
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <TextInput
              label="CHERT"
              readOnly
              value={thisForm.values.chert}
              fz="xs"
            />
            <TextInput
              label="Clinic Type"
              readOnly
              value={thisForm.values.clinicType}
              mt="md"
              fz="xs"
            />
          </Grid.Col>

          <Grid.Col span={3}>
            <TextInput
              label="Meeting Status"
              readOnly
              value={thisForm.values.meetingStatus}
              fz="xs"
            />

            <Grid align="center" gutter="md">
              <Grid.Col span={6}>
                <TextInput
                  label="Total Physicians"
                  readOnly
                  value={thisForm.values.totalPhysician}
                  mt="md"
                  fz="xs"
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="Overall Score"
                  readOnly
                  value={(thisForm.values.overallScore).toFixed(2)}
                  mt="md"
                  fz="xs"
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col span={3}>
            <fieldset
              style={{
                border: "1px solid #ced4da",
                borderRadius: "8px",
                padding: "20px",
                backgroundColor: "#f1f3f5",
                position: "relative",
                top: "-25px",
              }}
            >
              <legend
                style={{
                  fontWeight: 600,
                  padding: "5px 10px",
                  backgroundColor: "#3d893d",
                  color: "white",
                  borderRadius: "5px",
                }}
              >
                Document
              </legend>
              <Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <IconFileText size={20} color="#3d893d" />
                    <Text ml="sm" fw={500}>
                      Consent:
                    </Text>
                  </Box>
                  {thisForm.values.consent &&
                    thisForm.values.consent.trim() !== "" ? (
                    <Anchor
                      href={thisForm.values.consent} // Dynamic file link
                      download
                      target="_blank"
                      ml="sm"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        textDecoration: "none",
                        color: "#3d893d",
                        fontWeight: 500,
                        position: "relative",
                        top: "5px",
                      }}
                    >
                      <IconDownload size={18} />
                      Download
                    </Anchor>
                  ) : (
                    <Text
                      c="dimmed"
                      fz="sm"
                      style={{
                        position: "relative",
                        top: "5px",
                      }}
                    >
                      No file available
                    </Text>
                  )}
                </Box>

                {/* CPIA Doc Download */}
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <IconFileText size={20} color="#3d893d" />
                    <Text ml="sm" fw={500}>
                      CPIA Doc:
                    </Text>
                  </Box>
                  {thisForm.values.cpiaDoc &&
                    thisForm.values.cpiaDoc.trim() !== "" ? (
                    <Anchor
                      href={thisForm.values.cpiaDoc} // Dynamic file link
                      download
                      target="_blank"
                      ml="sm"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        textDecoration: "none",
                        color: "#3d893d",
                        fontWeight: 500,
                        position: "relative",
                        top: "5px",
                      }}
                    >
                      <IconDownload size={20} fw={500} />
                      Download
                    </Anchor>
                  ) : (
                    <Text
                      c="dimmed"
                      fz="sm"
                      style={{
                        position: "relative",
                        top: "5px",
                      }}
                    >
                      No file available
                    </Text>
                  )}
                </Box>
              </Box>
            </fieldset>
          </Grid.Col>
        </Grid>

        <Grid align="center" gutter="md">
          <Grid.Col span={3}>
            <fieldset
              style={{
                border: '1px solid #ced4da',
                borderRadius: '8px',
                padding: '20px',
                backgroundColor: '#f1f3f5',
                position: 'relative',
                height: '280px'

              }}
            >
              <legend
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '10px',
                  fontWeight: 600,
                  padding: '5px 10px',
                  backgroundColor: '#3d893d',
                  color: 'white',
                  borderRadius: '5px',

                }}
              >
                <span>Quality</span>

                <Button
                  type="submit"
                  data-category="QualityView"
                  style={{ backgroundColor: 'white', color: '#3d893d' }}
                  size="xs"
                >
                  View
                </Button>

                {thisForm.values.scoreResult === 'success' &&
                  (thisForm.values.quaContNum !== 0.0 ||
                    thisForm.values.quaContDen !== 0.0 ||
                    thisForm.values.quaStanNum !== 0.0 ||
                    thisForm.values.quaStanDen !== 0.0) && (
                    <Button
                      type="submit"
                      data-category="Quality"
                      style={{ backgroundColor: 'white', color: '#3d893d' }}
                      size="xs"
                    >
                      Submit
                    </Button>
                  )}

              </legend>

              <Box>
                {(thisForm.values.quaContNum !== 0.0 ||
                  thisForm.values.quaContDen !== 0.0 ||
                  thisForm.values.quaStanNum !== 0.0 ||
                  thisForm.values.quaStanDen !== 0.0) && (
                    <>
                      <Text fw={700} fz="sm">
                        Quality Contributions:{' '}
                        <Text component="span" fw={500} color="black">
                          {thisForm.values.quaContNum}/{thisForm.values.quaContDen}
                        </Text>
                      </Text>

                      <Text fw={700} fz="sm">
                        Standalone Quality:{' '}
                        <Text component="span" fw={500} color="black">
                          {thisForm.values.quaStanNum}/{thisForm.values.quaStanDen}
                        </Text>
                      </Text>
                      <hr />
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Text fw={700} fz="sm" mt={thisForm.values.qualityResult && thisForm.values.qualityResult.trim() !== "" ? 10 : 0}>
                          Status:{' '}
                          {thisForm.values.qualityResult && thisForm.values.qualityResult.trim() !== "" && (
                            <Text component="span" fw={500} color="black">
                              {thisForm.values.qualityResult}
                            </Text>
                          )}

                        </Text>
                        <Text fw={700} fz="sm">
                          Json:{' '}
                          {thisForm.values.qualityJson &&
                            thisForm.values.qualityJson.trim() !== "" ? (
                            <IconFileText
                              style={{
                                color: "#3d8a3d",
                                cursor: "pointer",
                                position: "relative",
                                top: "5px",
                              }}
                              size={24}
                              onClick={() =>
                                downloadJsonFromString(
                                  thisForm.values.qualityJson,
                                  "Quality.json"
                                )
                              }
                            />
                          ) : (
                            ""
                          )}
                        </Text>
                        <Text fw={700} fz="sm">
                          Response:{' '}
                          {thisForm.values.qualitySubmissionResponseJson &&
                            thisForm.values.qualitySubmissionResponseJson.trim() !== "" ? (
                            <IconFileText
                              style={{
                                color: "#3d8a3d",
                                cursor: "pointer",
                                position: "relative",
                                top: "5px",
                              }}
                              size={24}
                              onClick={() =>
                                downloadJsonFromString(
                                  thisForm.values.qualitySubmissionResponseJson,
                                  "QualitySubmission.json"
                                )
                              }
                            />
                          ) : (
                            ""
                          )}
                        </Text>
                      </div>
                      <hr />

                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Text fw={700} fz="xs">
                          Created Date:{' '}
                          <Text component="span" fw={500} color="black">
                            {thisForm.values.qualityCreatedDate &&
                              thisForm.values.qualityCreatedDate !== "-"
                              ? formatDate(thisForm.values.qualityCreatedDate)
                              : ""}
                          </Text>
                        </Text>
                        <Text fw={700} fz="xs">
                          Updated Date:{' '}
                          <Text component="span" fw={500} color="black">
                            {thisForm.values.qualityUpdatedDate &&
                              thisForm.values.qualityUpdatedDate !== "-"
                              ? formatDate(thisForm.values.qualityUpdatedDate)
                              : ""}
                          </Text>
                        </Text>
                      </div>
                      <hr />
                      <Text fw={700} fz="xs">
                        MeasurementId:{' '}
                        <Text component="span" fw={500} color="black">
                          {thisForm.values.qualityMeasuremenrSetId &&
                            thisForm.values.qualityMeasuremenrSetId !== "-"
                            ? thisForm.values.qualityMeasuremenrSetId
                            : ""}
                        </Text>
                      </Text>
                    </>
                  )}
              </Box>
            </fieldset>
          </Grid.Col>

          <Grid.Col span={3}>
            <fieldset
              style={{
                border: "1px solid #ced4da",
                borderRadius: "8px",
                padding: "20px",
                backgroundColor: "#f1f3f5",
                position: "relative",
                height: '280px'

              }}
            >
              <legend
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '10px',
                  fontWeight: 600,
                  padding: '5px 10px',
                  backgroundColor: '#3d893d',
                  color: 'white',
                  borderRadius: '5px',
                }}
              >
                <span>IA</span>

                <Button
                  type="submit"
                  data-category="IaView"
                  style={{ backgroundColor: 'white', color: '#3d893d' }}
                  size="xs" >
                  View
                </Button>




                {thisForm.values.scoreResult === 'success' &&
                  (thisForm.values.iaContNum !== 0.0 ||
                    thisForm.values.iaContDen !== 0.0 ||
                    thisForm.values.iaStanNum !== 0.0 ||
                    thisForm.values.iaStanDen !== 0.0) && (
                    <Button
                      type="submit"
                      data-category="IA"
                      style={{ backgroundColor: 'white', color: '#3d893d' }}
                      size="xs"
                    >
                      Submit
                    </Button>
                  )}

              </legend>
              <Box>
                {(thisForm.values.iaContNum !== 0.0 ||
                  thisForm.values.iaContDen !== 0.0 ||
                  thisForm.values.iaStanNum !== 0.0 ||
                  thisForm.values.iaStanDen !== 0.0) && (
                    <>
                      <Text fw={700} fz="sm">
                        IA Contributions:{" "}
                        <Text component="span" fw={500} color="black">
                          {thisForm.values.iaContNum}/{thisForm.values.iaContDen}
                        </Text>
                      </Text>

                      <Text fw={700} fz="sm">
                        IA Standalone:{" "}
                        <Text component="span" fw={500} color="black">
                          {thisForm.values.iaStanNum}/{thisForm.values.iaStanDen}
                        </Text>
                      </Text>
                      <hr />
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Text fw={700} fz="sm" mt={thisForm.values.iaResult && thisForm.values.iaResult.trim() !== "" ? 10 : 0}>
                          Status:{' '}
                          {thisForm.values.iaResult && thisForm.values.iaResult.trim() !== "" && (
                            <Text component="span" fw={500} color="black">
                              {thisForm.values.iaResult}
                            </Text>
                          )}

                        </Text>
                        <Text fw={700} fz="sm">
                          Json:{' '}
                          {thisForm.values.iaJson &&
                            thisForm.values.iaJson.trim() !== "" ? (
                            <IconFileText
                              style={{
                                color: "#3d8a3d",
                                cursor: "pointer",
                                position: "relative",
                                top: "5px",
                              }}
                              size={24}
                              onClick={() =>
                                downloadJsonFromString(
                                  thisForm.values.iaJson,
                                  "IaJson.json"
                                )
                              }
                            />
                          ) : (
                            ""
                          )}
                        </Text>
                        <Text fw={700} fz="sm">
                          Response:{' '}
                          {thisForm.values.iaSubmissionResponseJson &&
                            thisForm.values.iaSubmissionResponseJson.trim() !== "" ? (
                            <IconFileText
                              style={{
                                color: "#3d8a3d",
                                cursor: "pointer",
                                position: "relative",
                                top: "5px",
                              }}
                              size={24}
                              onClick={() =>
                                downloadJsonFromString(
                                  thisForm.values.iaSubmissionResponseJson,
                                  "IASubmission.json"
                                )
                              }
                            />
                          ) : (
                            ""
                          )}
                        </Text>
                      </div>
                      <hr />

                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Text fw={700} fz="xs">
                          Created Date:{' '}
                          <Text component="span" fw={500} color="black">
                            {thisForm.values.iaCreatedDate &&
                              thisForm.values.iaCreatedDate !== "-"
                              ? formatDate(thisForm.values.iaCreatedDate)
                              : ""}
                          </Text>
                        </Text>
                        <Text fw={700} fz="xs">
                          Updated Date:{' '}
                          <Text component="span" fw={500} color="black">
                            {thisForm.values.iaUpdatedDate &&
                              thisForm.values.iaUpdatedDate !== "-"
                              ? formatDate(thisForm.values.iaUpdatedDate)
                              : ""}
                          </Text>
                        </Text>
                      </div>
                      <hr />
                      <Text fw={700} fz="xs">
                        MeasurementId:{' '}
                        <Text component="span" fw={500} color="black">
                          {thisForm.values.iaMeasuremenrSetId &&
                            thisForm.values.iaMeasuremenrSetId !== "-"
                            ? thisForm.values.iaMeasuremenrSetId
                            : ""}
                        </Text>
                      </Text>

                    </>
                  )}
              </Box>
            </fieldset>
          </Grid.Col>
          <Grid.Col span={3}>
            <fieldset
              style={{
                border: "1px solid #ced4da",
                borderRadius: "8px",
                padding: "20px",
                backgroundColor: "#f1f3f5",
                position: "relative",
                height: '280px'

              }}
            >
              <legend
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '10px',
                  fontWeight: 600,
                  padding: '5px 10px',
                  backgroundColor: '#3d893d',
                  color: 'white',
                  borderRadius: '5px',
                }}
              >
                <span>PI</span>

                <Button
                  type="submit"
                  data-category="PiView"
                  style={{ backgroundColor: 'white', color: '#3d893d' }}
                  size="xs"
                >
                  View
                </Button>


                {thisForm.values.scoreResult === 'success' &&
                  (thisForm.values.piContNum !== 0.0 ||
                    thisForm.values.piContDen !== 0.0 ||
                    thisForm.values.piStanNum !== 0.0 ||
                    thisForm.values.piStanDen !== 0.0) && (
                    <Button
                      type="submit"
                      data-category="PI"
                      style={{ backgroundColor: 'white', color: '#3d893d' }}
                      size="xs"
                    >
                      Submit
                    </Button>
                  )}
              </legend>
              <Box>
                {(thisForm.values.piContNum !== 0.0 ||
                  thisForm.values.piContDen !== 0.0 ||
                  thisForm.values.piStanNum !== 0.0 ||
                  thisForm.values.piStanDen !== 0.0) && (
                    <>
                      <Text fw={700} fz="sm">
                        PI Contributions:{" "}
                        <Text component="span" fw={500} color="black">
                          {(thisForm.values.piContNum).toFixed(2)}/{thisForm.values.piContDen}
                        </Text>
                      </Text>

                      <Text fw={700} fz="sm">
                        PI Standalone:{" "}
                        <Text component="span" fw={500} color="black">
                          {(thisForm.values.piStanNum).toFixed(2)}/{thisForm.values.piStanDen}
                        </Text>
                      </Text>
                      <hr />
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Text fw={700} fz="sm" mt={thisForm.values.piResult && thisForm.values.piResult.trim() !== "" ? 10 : 0}>
                          Status:{' '}
                          {thisForm.values.piResult && thisForm.values.piResult.trim() !== "" && (
                            <Text component="span" fw={500} color="black">
                              {thisForm.values.piResult}
                            </Text>
                          )}

                        </Text>
                        <Text fw={700} fz="sm">
                          Json:{' '}
                          {thisForm.values.piJson &&
                            thisForm.values.piJson.trim() !== "" ? (
                            <IconFileText
                              style={{
                                color: "#3d8a3d",
                                cursor: "pointer",
                                position: "relative",
                                top: "5px",
                              }}
                              size={24}
                              onClick={() =>
                                downloadJsonFromString(
                                  thisForm.values.piJson,
                                  "PiJson.json"
                                )
                              }
                            />
                          ) : (
                            ""
                          )}
                        </Text>
                        <Text fw={700} fz="sm">
                          Response:{' '}
                          {thisForm.values.piSubmissionResponseJson &&
                            thisForm.values.piSubmissionResponseJson.trim() !== "" ? (
                            <IconFileText
                              style={{
                                color: "#3d8a3d",
                                cursor: "pointer",
                                position: "relative",
                                top: "5px",
                              }}
                              size={24}
                              onClick={() =>
                                downloadJsonFromString(
                                  thisForm.values.piSubmissionResponseJson,
                                  "PiSubmission.json"
                                )
                              }
                            />
                          ) : (
                            ""
                          )}
                        </Text>
                      </div>
                      <hr />

                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Text fw={700} fz="xs">
                          Created Date:{' '}
                          <Text component="span" fw={500} color="black">
                            {thisForm.values.piCreatedDate &&
                              thisForm.values.piCreatedDate !== "-"
                              ? formatDate(thisForm.values.piCreatedDate)
                              : ""}
                          </Text>
                        </Text>
                        <Text fw={700} fz="xs">
                          Updated Date:{' '}
                          <Text component="span" fw={500} color="black">
                            {thisForm.values.piUpdatedDate &&
                              thisForm.values.piUpdatedDate !== "-"
                              ? formatDate(thisForm.values.piUpdatedDate)
                              : ""}
                          </Text>
                        </Text>
                      </div>
                      <hr />
                      <Text fw={700} fz="xs">
                        MeasurementId:{' '}
                        <Text component="span" fw={500} color="black">
                          {thisForm.values.piMeasuremenrSetId &&
                            thisForm.values.piMeasuremenrSetId !== "-"
                            ? thisForm.values.piMeasuremenrSetId
                            : ""}
                        </Text>
                      </Text>
                    </>
                  )}
              </Box>
            </fieldset>
          </Grid.Col>

          <Grid.Col span={3}>
            <fieldset
              style={{
                border: "1px solid #ced4da",
                borderRadius: "8px",
                padding: "20px",
                backgroundColor: "#f1f3f5",
                position: "relative",
                height: '280px',

              }}
            >
              <legend
                style={{
                  fontWeight: 600,
                  padding: "5px 10px",
                  backgroundColor: "#3d893d",
                  color: "white",
                  borderRadius: "5px",
                }}
              >
                Submission Data Category
              </legend>
              <Box>
                <Checkbox
                  label="Quality"
                  disabled
                  {...thisForm.getInputProps("qa", { type: "checkbox" })}
                />
                <Checkbox
                  label="IA"
                  disabled
                  mt="sm"
                  {...thisForm.getInputProps("ia", { type: "checkbox" })}
                />
                <Checkbox
                  label="PI"
                  disabled
                  mt="sm"
                  {...thisForm.getInputProps("pi", { type: "checkbox" })}
                />
              </Box>
            </fieldset>
          </Grid.Col>
        </Grid>

        <Divider my="md" label="Actions" labelPosition="center" />

        {/* Third Row: Buttons */}
        <Grid align="center">
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div style={{ width: "43%", marginRight: "80px", }}>
              <div style={{ marginBottom: "20px", textAlign: "left", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Text style={{ marginBottom: "5px" }}>
                  <>
                    Created Date:{" "}
                    {thisForm.values.createdDate &&
                      thisForm.values.createdDate !== "-"
                      ? thisForm.values.createdDate
                      : ""}
                    {thisForm.values.createdDate &&
                      thisForm.values.createdDate !== "-" && (
                        <>
                          (
                          <span style={{ fontWeight: "bold", color: "green" }}>
                            {[
                              thisForm.values.qa && "Quality",
                              thisForm.values.ia && "IA",
                              thisForm.values.pi && "PI",
                            ]
                              .filter(Boolean)
                              .join(" - ")}
                          </span>
                          )
                        </>
                      )}
                  </>
                </Text>
                <Text style={{ marginBottom: "5px" }}>
                  <>

                    Updated Date:{" "}
                    {thisForm.values.updatedDate &&
                      thisForm.values.updatedDate !== "-"
                      ? thisForm.values.updatedDate
                      : ""}
                    {thisForm.values.updatedDate &&
                      thisForm.values.updatedDate !== "-" && (
                        <>
                          (
                          <span style={{ fontWeight: "bold", color: "green" }}>
                            {[
                              thisForm.values.qa && "Quality",
                              thisForm.values.ia && "IA",
                              thisForm.values.pi && "PI",
                            ]
                              .filter(Boolean)
                              .join(" - ")}
                          </span>
                          )
                        </>
                      )}
                  </>
                </Text>
              </div>
              <div style={{ marginBottom: "20px", textAlign: "left", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Text>
                  Validation Status:{" "}
                  {thisForm.values.scoreResult.trim() !== "" && (
                    <span
                      style={{
                        fontWeight: "bold",
                        color:
                          thisForm.values.scoreResult.toLowerCase() ===
                            "success"
                            ? "green"
                            : "red",
                      }}
                    >
                      {thisForm.values.scoreResult}
                    </span>
                  )}
                </Text>

                <Text>
                  Prepared Json :{" "}
                  {thisForm.values.scoreSubmissionjson &&
                    thisForm.values.scoreSubmissionjson.trim() !== "" ? (
                    <IconFileText
                      style={{
                        color: "#3d8a3d",
                        cursor: "pointer",
                        position: "relative",
                        top: "5px",
                      }}
                      size={24}
                      onClick={() =>
                        downloadJsonFromString(
                          thisForm.values.scoreSubmissionjson,
                          "PreparedData.json"
                        )
                      }
                    />
                  ) : (
                    // <span style={{ color: "gray" }}>No Data Available</span>
                    ""
                  )}
                </Text>

                <Text>
                  Validation Response :{" "}
                  {thisForm.values.scoreResponsejson &&
                    thisForm.values.scoreResponsejson.trim() !== "" ? (
                    <IconFileText
                      style={{
                        color: "#3d8a3d",
                        cursor: "pointer",
                        position: "relative",
                        top: "5px",
                      }}
                      size={24}
                      onClick={() =>
                        downloadJsonFromString(
                          thisForm.values.scoreResponsejson,
                          "SubmissionResponse.json"
                        )
                      }
                    />
                  ) : (
                    ""
                  )}
                </Text>

              </div>

              <Button
                style={{
                  width: "100%",
                  height: "45px",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                color="orange"
                onClick={handlePrepareJson}
              >
                Prepare JSON
              </Button>
            </div>


            <div style={{ width: "30%" }}>
              <div style={{ marginBottom: "20px", textAlign: "left", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Text style={{ marginBottom: "5px" }}>
                  <>
                    Submission Id:{" "}
                    {thisForm.values.submissionId}
                  </>
                </Text>

              </div>
              <div style={{ marginBottom: "20px", textAlign: "left", display: "flex", justifyContent: "space-between", alignItems: "center" }}>



                <Checkbox
                  label="Verified"
                  {...thisForm.getInputProps("submissionVerified", {
                    type: "checkbox",
                  })}
                  onChange={(event) =>
                    handleChangeSubmit(event.target.checked)
                  }
                />




                <Text fw={700} fz="sm">
                  Token:{" "}
                  <Text
                    component="span"
                    fw={500}
                    color="black"
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => handleShowToken(thisForm.values.submissionToken)}
                  >
                    {thisForm.values.groupName}
                  </Text>
                </Text>



              </div>
              <Button
                style={{
                  width: "100%",
                  height: "45px",
                  backgroundColor: "#4CAF50",
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginTop: "5px"
                }}
                disabled={
                  thisForm.values.qualityId === 0 &&
                  thisForm.values.iaId === 0 &&
                  thisForm.values.piId === 0
                }
                type="button"
                onClick={() => getSubmission(thisForm.values.submissionId)}
              >
                Get Submission
              </Button>

            </div>




          </Grid.Col>
        </Grid>
      </Box>
    </form>
  );
};
export default SubmissionForm;
