import { useEffect, useMemo, useState } from 'react';
import {
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import axios from 'axios';
import { StatusDropDown } from '../Shared/DropDownOptions/DropDownList';
import { RowAction } from '../Shared/RowAction';
import useConfirm from '../Shared/ConfirmModel';
import { ApiCall } from '../Shared/ApiCall/ApiCall';

const Test = () => {
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

 const confirm = useConfirm();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'firstName',
        header: 'First Name',
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: StatusDropDown,
          searchable: false,
          clearable: true
        },
      },

      {
        accessorKey: 'lastName',
        header: 'Last Name',
      },
      {
        accessorKey: 'address',
        header: 'Address',
      },
      {
        accessorKey: 'state',
        header: 'State',
      },
      {
        accessorKey: 'phoneNumber',
        header: 'Phone Number',
      },
    ],
  );


  useEffect(() => {
    fetchData();
  }, [columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting]);

  const fetchData = async () => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    let customeParam = {
      start: pagination.pageIndex * pagination.pageSize,
      size: pagination.pageSize,
      filters: JSON.stringify(columnFilters ?? []),
      globalFilter: globalFilter ?? '',
      sorting: sorting ?? []
    }

    
    
    const url = new URL(
      'https://www.mantine-react-table.com/api/data',
    );
    url.searchParams.set(
      'start',
      `${pagination.pageIndex * pagination.pageSize}`,
    );
    url.searchParams.set('size', `${pagination.pageSize}`);
    url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
    url.searchParams.set('globalFilter', globalFilter ?? '');
    url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
            
    try {
      const response = await ApiCall.axiosInstance.get(url.href);
      const json = response.data;
      
      setData(json.data);
      setRowCount(json.meta.totalRowCount);
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const onUpdate = (data)=> {
    
  }

  const onDelete = async (data) => {
    const choise = await confirm({title: "Delete Record", description: "Are you sure to delete?"})
    if(choise){     
    }
  }
  
  const table = useMantineReactTable({
    columns,
    data,
    // enableRowSelection: true,
    getRowId: (row) => row.phoneNumber,
    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    enableRowActions: true,
    positionActionsColumn: 'first',
    renderRowActions: (row) => (
      <RowAction onUpdate={() => { onUpdate(row.row.original); debugger;}} onDelete={() => onDelete(row.row.original)} />
    ),
    mantineToolbarAlertBannerProps: isError
      ? { color: 'red', children: 'Error loading data' }
      : undefined,
  });

  return <MantineReactTable table={table} />;
};


export default Test;