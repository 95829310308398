export const RequirementSetColumn = [

    {
        header: "Date",
        accessorFn: (row) => {
            let sDay = new Date(row.date);
            var day = sDay.getDate();
            var month = sDay.getMonth() + 1; // Month is zero-based, so we add 1
            var year = sDay.getFullYear();
            sDay = (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day + '-' + year;
            return sDay;
        },
        id: 'date',
        Cell: ({ cell }) => cell.getValue(), //render Date as a string




    },
    {
        header: "Tittle",
        accessorKey: "title",
    },
    {
        header: "Status",
        accessorKey: "status",
        Cell: ({ cell }) => {
            return <div>{cell.getValue() ? "Display" : "Hide"}</div>;
        },
    },

];
