import { ActionIcon, Button, Grid, Group } from "@mantine/core";
import { IconCheck, IconEdit, IconRadioactive, IconTrash } from "@tabler/icons-react";

export const RowAction = (props) => {
    return (
        <Grid>
            <Button.Group>
                
                {!props.isSoft && <Button variant="filled" color='red' size="xs" onClick={props.onDelete} >
                    <IconTrash size="1.2rem"></IconTrash>
                </Button>}

                {!props.isSoft && <Button variant="filled" color='green' size="xs" onClick={props.onUpdate} >
                    <IconEdit size="1.2rem"></IconEdit>
                </Button>}

                {props.isSoft && <Button variant="filled" color='green' size="xs" onClick={props.onActive} >
                    <IconCheck size="1.2rem"></IconCheck>
                </Button>}
            </Button.Group>

        </Grid>
    );
}