import { Button, Group, Text, Collapse, Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCaretDown } from '@tabler/icons-react';
import { Accordion } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addClinicForSeletedGroup } from '../utils/patenerTreeSlice';
export const MultiLevelSelect = ({partenerTreeItems}) => {
  const [opened, { toggle }] = useDisclosure(false);
  const [value, setValue] = useState(null);
  const ref = useRef();
  const dispatch = useDispatch();

 
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        toggle();
      }
    };

    if (opened) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [opened]);


  // filter clinic based on group selection

  const filterClinicsByGroupId = (groupId) => {
    const filteredClinics = partenerTreeItems.reduce((filtered, item) => {
      if (item.group && Array.isArray(item.group)) {
        const selectedGroup = item.group.find((group) => group.id === groupId);
  
        if (selectedGroup && selectedGroup.clinic) {
          filtered.push(...selectedGroup.clinic);
        }
      }
      return filtered;
    }, []);

    return filteredClinics;
  }

  const selectValue = (name, id) => {
    setValue(name);
    const selectedGroupItem = filterClinicsByGroupId(id);
    dispatch(addClinicForSeletedGroup(selectedGroupItem));
    toggle();
  };

  return (
    <Box w={300} mx="auto" sx={{ background: opened ? '#f1f3f5' : 'transparent' }} ref={ref}>
      <Group position="right" mb={5}>
        <Button  w={300} variant="outline" onClick={toggle} rightIcon={<IconCaretDown />}>
          {value ?? 'Choose value '}
        </Button>
      </Group>

      <Collapse in={opened}>
        {/* <Text> */}
          <Accordion radius="md" multiple>
            {partenerTreeItems.length && partenerTreeItems.map(item => (
              <Accordion.Item value={item?.id.toString()}>
                <Accordion.Control style={{color: 'green'}}>
                 <Text size="sm">{item?.name}</Text> 
                </Accordion.Control>
                <Box mah={150}  sx={{ paddingLeft: 10, overflowY:'auto'}}>
                  {item.group.length && item.group.map(subItem => (
                    <Accordion.Panel
                      sx={{ cursor: 'pointer' }}
                      onClick={() => selectValue(subItem.name,subItem.id)}
                    >
                      <Text size="sm">{subItem.name}</Text>
                    </Accordion.Panel>
                  ))}
                </Box>
              </Accordion.Item>
            ))}
          </Accordion>
        {/* </Text> */}
      </Collapse>
    </Box>
  );
};
