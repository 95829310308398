import axios from "axios";
import { ApiLink } from "../Constant/ApiLink";
import { ApiCall } from "./ApiCall/ApiCall";

export const getFileUri = async (filePath) => {
    if (filePath !== null) {
      try {
        const formData = new FormData();
        formData.append("file", filePath);
        const response = await ApiCall.axiosInstance.post(
          `${process.env.REACT_APP_API_URL}/${ApiLink.FileUpload}`,
          formData
        );
        return response.data;
      } catch (error) {
        console.error("Failed Request:", error);
        throw error; 
      }
    }
  };

  export const getFileUriOnMeasureUpload = async (filePath) => {
    if (filePath !== null) {
      try {
        const formData = new FormData();
        formData.append("file", filePath);
        const response = await ApiCall.axiosInstance.post(
          `${process.env.REACT_APP_API_URL}/${ApiLink.MeasureCSV}`,
          formData
        );
        return response.data;
      } catch (error) {
        console.error("Failed Request:", error);
        return error; 
      }
    }
  };


 export const label = (message) => {
    return <h4 style={{marginTop: '0px', color: 'green'}}>{message}</h4>;
  };