// PIFormUtils.js

import Swal from "sweetalert2";

/* Helper Functions */

export function computePoints(selectionGroup, measure) {
  if (!selectionGroup) return 0;
  if (Array.isArray(selectionGroup.selected)) {
    if (!selectionGroup.selected.includes(measure.measureId)) return 0;
  } else {
    if (selectionGroup.selected !== measure.measureId) return 0;
  }
  if (measure.metrictype === "Proportion") {
    const num = parseFloat(selectionGroup.num) || 0;
    const den = parseFloat(selectionGroup.den) || 0;
    if (den === 0) return 0;
    return (num / den) * measure.point;
  } else {
    return measure.point;
  }
}

export function buildPayloadForMeasures(selectionState, measures) {
  let totalPoints = 0;
  let payload = [];

  for (const groupKey in selectionState) {
    const groupData = selectionState[groupKey];

    if (Array.isArray(groupData.selected)) {
      for (const measureId of groupData.selected) {
        const foundMeasure = measures.find(
          (m) => m.group === groupKey && m.measureId === measureId
        );
        if (foundMeasure) {
          const points = computePoints(groupData, foundMeasure);
          totalPoints += points;
          payload.push({
            group: groupKey,
            measureId: foundMeasure.measureId,
            metrictype: foundMeasure.metrictype,
            points,
            num: groupData.num || 0,
            den: groupData.den || 0,
          });
        }
      }
    } else {
      const selectedMeasure = measures.find(
        (m) => m.group === groupKey && m.measureId === groupData.selected
      );
      if (selectedMeasure) {
        const points = computePoints(groupData, selectedMeasure);
        totalPoints += points;
        payload.push({
          group: groupKey,
          measureId: selectedMeasure.measureId,
          metrictype: selectedMeasure.metrictype,
          points,
          num: groupData.num || 0,
          den: groupData.den || 0,
        });
      }
    }
  }
  return { payload, totalPoints };
}

export function validateProportionFields(selectionState, measures) {
  for (let groupKey in selectionState) {
    const groupData = selectionState[groupKey];
    if (Array.isArray(groupData.selected)) {
      for (let measureId of groupData.selected) {
        const measure = measures.find(
          (m) => m.group === groupKey && m.measureId === measureId
        );
        if (measure && measure.metrictype === "Proportion") {
          if (!groupData.num || !groupData.den) {
            return { valid: false, group: groupKey, measureId: measure.measureId };
          }
        }
      }
    } else {
      if (groupData.selected) {
        const measure = measures.find(
          (m) => m.group === groupKey && m.measureId === groupData.selected
        );
        if (measure && measure.metrictype === "Proportion") {
          if (!groupData.num || !groupData.den) {
            return { valid: false, group: groupKey, measureId: measure.measureId };
          }
        }
      }
    }
  }
  return { valid: true };
}

/* Constant Data */

// Mandatory Measures
export const mandatoryMeasures = [
  { measureId: "PI_PPHI_1", measure: "Security Risk Analysis", required: "Required", value: false },
  { measureId: "PI_PPHI_2", measure: "SAFER Guides High Priority Practices Guide", required: "Required", value: false },
  { measureId: "PI_ONCDIR_1", measure: "ONC Direct Review Attestation", required: "Required", value: false },
  { measureId: "PI_INFBLO_1", measure: "Actions to Limit or Restrict the Compatibility of CEHRT", required: "Required", value: false },
];

// Public Health & Clinic Data
// Public Health & Clinic Data
export const publichealthandClinicData = [
  // ------------------- GROUP 8 -------------------
  {
    measureId: "PI_PHCDRR_1",
    measure: "Immunization Registry Reporting",
    metrictype: "Boolean",
    required: "Required",
    group: "group8",
    point: 12.5,
    exclusion: "",
  },
  {
    measureId: "PI_PHCDRR_1_PRE",
    measure: "Immunization Registry Reporting Active Engagement Level 1",
    metrictype: "Boolean",
    required: "Required",
    group: "group8",
    point: 0,
    exclusion: "",
  },
  {
    measureId: "PI_PHCDRR_1_PROD",
    measure: "Immunization Registry Reporting Active Engagement Level 2",
    metrictype: "Boolean",
    required: "Required",
    group: "group8",
    point: 0,
    exclusion: "",
  },
  {
    measureId: "PI_PHCDRR_1_EX_1",
    measure: "Immunization Registry Reporting Exclusion",
    metrictype: "Boolean",
    required: "Required",
    group: "group8",
    isExclusion: true,
    point: 12.5,
    exclusion: "Exclusion",
  },
  {
    measureId: "PI_PHCDRR_1_EX_2",
    measure: "Immunization Registry Reporting Exclusion",
    metrictype: "Boolean",
    required: "Required",
    group: "group8",
    isExclusion: true,
    point: 12.5,
    exclusion: "Exclusion",
  },
  // ------------------- GROUP 9 -------------------
  {
    measureId: "PI_PHCDRR_3",
    measure: "Electronic Case Reporting",
    metrictype: "Boolean",
    required: "Required",
    group: "group9",
    point: 12.5,
    exclusion: "",
  },
  {
    measureId: "PI_PHCDRR_3_PRE",
    measure: "Electronic Case Reporting Active Engagement Level 1",
    metrictype: "Boolean",
    required: "Required",
    group: "group9",
    point: 0,
    exclusion: "",
  },
  {
    measureId: "PI_PHCDRR_3_PROD",
    measure: "Electronic Case Reporting Active Engagement Level 2",
    metrictype: "Boolean",
    required: "Required",
    group: "group9",
    point: 0,
    exclusion: "",
  },
  {
    measureId: "PI_PHCDRR_3_EX_1",
    measure: "Electronic Case Reporting Exclusion",
    metrictype: "Boolean",
    required: "Required",
    group: "group9",
    isExclusion: true,
    point: 12.5,
    exclusion: "Exclusion",
  },
  {
    measureId: "PI_PHCDRR_3_EX_2",
    measure: "Electronic Case Reporting Exclusion",
    metrictype: "Boolean",
    required: "Required",
    group: "group9",
    isExclusion: true,
    point: 12.5,
    exclusion: "Exclusion",
  },
  {
    measureId: "PI_PHCDRR_3_EX_3",
    measure: "Electronic Case Reporting Exclusion",
    metrictype: "Boolean",
    required: "Required",
    group: "group9",
    isExclusion: true,
    point: 12.5,
    exclusion: "Exclusion",
  },
  // ------------------- GROUP 10 (Multiple Optional) -------------------
  {
    measureId: "PI_PHCDRR_4",
    measure: "Public Health Registry Reporting",
    metrictype: "Boolean",
    required: "Optional",
    group: "group10",
    point: 5,
    exclusion: "",
  },
  {
    measureId: "PI_PHCDRR_4_PRE",
    measure: "Public Health Registry Reporting Active Engagement Level 1",
    metrictype: "Boolean",
    required: "Required",
    group: "group10",
    point: 0,
    exclusion: "",
  },
  {
    measureId: "PI_PHCDRR_4_PROD",
    measure: "Public Health Registry Reporting Active Engagement Level 2",
    metrictype: "Boolean",
    required: "Required",
    group: "group10",
    point: 0,
    exclusion: "",
  },
  // Main measure #2
  {
    measureId: "PI_PHCDRR_5",
    measure: "Clinical Data Registry Reporting",
    metrictype: "Boolean",
    required: "Optional",
    group: "group11",
    point: 5,
    exclusion: "",
  },
  {
    measureId: "PI_PHCDRR_5_PRE",
    measure: "Clinical Data Registry Reporting Active Engagement Level 1",
    metrictype: "Boolean",
    required: "Required",
    group: "group11",
    point: 0,
    exclusion: "",
  },
  {
    measureId: "PI_PHCDRR_5_PROD",
    measure: "Clinical Data Registry Reporting Active Engagement Level 2",
    metrictype: "Boolean",
    required: "Required",
    group: "group11",
    point: 0,
    exclusion: "",
  },
  // Main measure #3
  {
    measureId: "PI_PHCDRR_2",
    measure: "Syndromic Surveillance Reporting",
    metrictype: "Boolean",
    required: "Optional",
    group: "group12",
    point: 5,
    exclusion: "",
  },
  {
    measureId: "PI_PHCDRR_2_PRE",
    measure: "Syndromic Surveillance Reporting Active Engagement Level 1",
    metrictype: "Boolean",
    required: "Required",
    group: "group12",
    point: 0,
    exclusion: "",
  },
  {
    measureId: "PI_PHCDRR_2_PROD",
    measure: "Syndromic Surveillance Reporting Active Engagement Level 2",
    metrictype: "Boolean",
    required: "Required",
    group: "group12",
    point: 0,
    exclusion: "",
  }
];

// Electronic Prescribing Measures
export const electronicPrescribingMeasures = [
  { measureId: "PI_EP_1", measure: "e-Prescribing", metrictype: "Proportion", required: "Required", value: false, group: "group1", point: 10, exclusion: "" },
  { measureId: "PI_LVPP_1", measure: "e-Prescribing Exclusion", metrictype: "Boolean", required: "Required", value: false, group: "group1", point: 10, exclusion: "Exclusion" },
  { measureId: "PI_EP_2", measure: "Query of the Prescription Drug Monitoring Program (PDMP)", metrictype: "Boolean", required: "Required", value: false, group: "group2", point: 10, exclusion: "" },
  { measureId: "PI_EP_2_EX_1", measure: "Query of the Prescription Drug Monitoring Program (PDMP) Exclusion", metrictype: "Boolean", required: "Required", value: false, group: "group2", point: 10, exclusion: "Exclusion" },
  { measureId: "PI_EP_2_EX_2", measure: "Query of the Prescription Drug Monitoring Program (PDMP) Exclusion", metrictype: "Boolean", required: "Required", value: false, group: "group2", point: 10, exclusion: "Exclusion" },
];

// Health Information Exchange Measures
export const healthInformationExchange = [
  { measureId: "PI_HIE_1", measure: "Support Electronic Referral Loops By Sending Health Information", metrictype: "Proportion", required: "Required", value: false, group: "group3", point: 15, exclusion: "" },
  { measureId: "PI_LVOTC_1", measure: "Support Electronic Referral Loops By Sending Health Information Exclusion", metrictype: "Boolean", required: "Required", value: false, group: "group3", point: 15, exclusion: "Exclusion" },
  { measureId: "PI_HIE_4", measure: "Support Electronic Referral Loops By Receiving and Reconciling Health Information", metrictype: "Proportion", required: "Required", value: false, group: "group4", point: 15, exclusion: "" },
  { measureId: "PI_LVITC_2", measure: "Support Electronic Referral Loops By Receiving and Reconciling Health Information Exclusion", metrictype: "Boolean", required: "Required", value: false, group: "group4", point: 15, exclusion: "Exclusion" },
  { measureId: "PI_HIE_5", measure: "Health Information Exchange", metrictype: "Boolean", required: "Required", value: false, group: "group5", point: 30, exclusion: "" },
  { measureId: "PI_HIE_6", measure: "Enabling Exchange Under TEFCA", metrictype: "Boolean", required: "Required", value: false, group: "group6", point: 30, exclusion: "" },
];

// Provider To Patient Exchange Measures
export const providerToPatientExchange = [
  {
    measureId: "PI_PEA_1",
    measure: "Provide Patients Electronic Access to Their Health Information",
    metrictype: "Proportion",
    required: "Required",
    value: false,
    group: "group7",
    point: 25,
  },
];

//DummyPayloadUseEffectData
export const savedPayloadArray = [
  {
    "group": "",
    "measureId": "PI_PPHI_1",
    "metrictype": "Mandatory",
    "points": 0,
    "num": 0,
    "den": 0,
    "category": "Mandatory",
    "ElectronicPrescribing": 15,
    "HealthInformationExchange": 22.5,
    "ProviderToPatientExchange": 12.5,
    "PublicHealthAndClinic": 30,
    "Combined": 80,
    "clinicId": 483,
    "groupId": 26,
    "year": "2024"
  },
  {
    "group": "",
    "measureId": "PI_PPHI_2",
    "metrictype": "Mandatory",
    "points": 0,
    "num": 0,
    "den": 0,
    "category": "Mandatory",
    "ElectronicPrescribing": 15,
    "HealthInformationExchange": 22.5,
    "ProviderToPatientExchange": 12.5,
    "PublicHealthAndClinic": 30,
    "Combined": 80,
    "clinicId": 483,
    "groupId": 26,
    "year": "2024"
  },
  {
    "group": "",
    "measureId": "PI_ONCDIR_1",
    "metrictype": "Mandatory",
    "points": 0,
    "num": 0,
    "den": 0,
    "category": "Mandatory",
    "ElectronicPrescribing": 15,
    "HealthInformationExchange": 22.5,
    "ProviderToPatientExchange": 12.5,
    "PublicHealthAndClinic": 30,
    "Combined": 80,
    "clinicId": 483,
    "groupId": 26,
    "year": "2024"
  },
  {
    "group": "",
    "measureId": "PI_INFBLO_1",
    "metrictype": "Mandatory",
    "points": 0,
    "num": 0,
    "den": 0,
    "category": "Mandatory",
    "ElectronicPrescribing": 15,
    "HealthInformationExchange": 22.5,
    "ProviderToPatientExchange": 12.5,
    "PublicHealthAndClinic": 30,
    "Combined": 80,
    "clinicId": 483,
    "groupId": 26,
    "year": "2024"
  },
  {
    "group": "group1",
    "measureId": "PI_EP_1",
    "metrictype": "Proportion",
    "points": 5,
    "num": 50,
    "den": 100,
    "category": "ElectronicPrescribing",
    "ElectronicPrescribing": 15,
    "HealthInformationExchange": 22.5,
    "ProviderToPatientExchange": 12.5,
    "PublicHealthAndClinic": 30,
    "Combined": 80,
    "clinicId": 483,
    "groupId": 26,
    "year": "2024"
  },
  {
    "group": "group2",
    "measureId": "PI_EP_2_EX_1",
    "metrictype": "Boolean",
    "points": 10,
    "num": 0,
    "den": 0,
    "category": "ElectronicPrescribing",
    "ElectronicPrescribing": 15,
    "HealthInformationExchange": 22.5,
    "ProviderToPatientExchange": 12.5,
    "PublicHealthAndClinic": 30,
    "Combined": 80,
    "clinicId": 483,
    "groupId": 26,
    "year": "2024"
  },
  {
    "group": "group3",
    "measureId": "PI_LVOTC_1",
    "metrictype": "Boolean",
    "points": 15,
    "num": 0,
    "den": 0,
    "category": "HealthInformationExchange",
    "ElectronicPrescribing": 15,
    "HealthInformationExchange": 22.5,
    "ProviderToPatientExchange": 12.5,
    "PublicHealthAndClinic": 30,
    "Combined": 80,
    "clinicId": 483,
    "groupId": 26,
    "year": "2024"
  },
  {
    "group": "group4",
    "measureId": "PI_HIE_4",
    "metrictype": "Proportion",
    "points": 7.5,
    "num": 50,
    "den": 100,
    "category": "HealthInformationExchange",
    "ElectronicPrescribing": 15,
    "HealthInformationExchange": 22.5,
    "ProviderToPatientExchange": 12.5,
    "PublicHealthAndClinic": 30,
    "Combined": 80,
    "clinicId": 483,
    "groupId": 26,
    "year": "2024"
  },
  {
    "group": "group7",
    "measureId": "PI_PEA_1",
    "metrictype": "Proportion",
    "points": 12.5,
    "num": 25,
    "den": 50,
    "category": "ProviderToPatientExchange",
    "ElectronicPrescribing": 15,
    "HealthInformationExchange": 22.5,
    "ProviderToPatientExchange": 12.5,
    "PublicHealthAndClinic": 30,
    "Combined": 80,
    "clinicId": 483,
    "groupId": 26,
    "year": "2024"
  },
  {
    "group": "group8",
    "measureId": "PI_PHCDRR_1",
    "metrictype": "Boolean",
    "points": 12.5,
    "num": 0,
    "den": 0,
    "category": "PublicHealthAndClinic",
    "ElectronicPrescribing": 15,
    "HealthInformationExchange": 22.5,
    "ProviderToPatientExchange": 12.5,
    "PublicHealthAndClinic": 30,
    "Combined": 80,
    "clinicId": 483,
    "groupId": 26,
    "year": "2024"
  },
  {
    "group": "group9",
    "measureId": "PI_PHCDRR_3",
    "metrictype": "Boolean",
    "points": 12.5,
    "num": 0,
    "den": 0,
    "category": "PublicHealthAndClinic",
    "ElectronicPrescribing": 15,
    "HealthInformationExchange": 22.5,
    "ProviderToPatientExchange": 12.5,
    "PublicHealthAndClinic": 30,
    "Combined": 80,
    "clinicId": 483,
    "groupId": 26,
    "year": "2024"
  },
  {
    "group": "group10",
    "measureId": "PI_PHCDRR_4",
    "metrictype": "Boolean",
    "points": 5,
    "num": 0,
    "den": 0,
    "category": "PublicHealthAndClinic",
    "ElectronicPrescribing": 15,
    "HealthInformationExchange": 22.5,
    "ProviderToPatientExchange": 12.5,
    "PublicHealthAndClinic": 30,
    "Combined": 80,
    "clinicId": 483,
    "groupId": 26,
    "year": "2024"
  }
];
