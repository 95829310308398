import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import {
  TextInput,
  Button,
  Box,
  Grid,
  Select,
  Space,
  Checkbox,
  Group,
  ActionIcon,
  Text,
  Code,
} from "@mantine/core";
import { ApiLink } from "../../Constant/ApiLink";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Loader } from "@mantine/core";
import style from "../../Shared/Theme/CommanStyle.module.css";
import useAxios from "../../Shared/useApi";
import { DateInput } from "@mantine/dates";
import {
  DefaultCqmTemplates,
  DefaultSubmissionMethod,
} from "../../Shared/DropDownOptions/DropDownList";
import { IconTrash } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const RequirementSetForm = () => {
  const navigate = useNavigate();
  const { data, method } = useLocation().state;
  const [entityList, setEntityList] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [loading, setLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);

  const form = useForm({
    initialValues: {
      reqId: data?.reqId,
      title: data?.title,
      date: new Date(data?.date ?? new Date()),
      status: data?.status ?? true,
      mipsMethodThreshold: data?.mipsMethodThreshold ?? 0,
      mipsMethodBaseAdjustment: data?.mipsMethodBaseAdjustment ?? 0,
      apmFlatBaseAdjustment: data?.apmFlatBaseAdjustment ?? 0,
      nonParticipatingBaseAdjustment: data?.nonParticipatingBaseAdjustment ?? 0,
      normalFloorScore: data?.normalFloorScore ?? 0,
      smallPractiseFloorScore: data?.smallPractiseFloorScore ?? 0,
      dataCompletenessThreshold: data?.dataCompletenessThreshold ?? 0,
      year: data?.year ? data?.year : reportSelection.selectedYear,
      high: data?.high,
      low: data?.low,
      reqSetMIPS: [],
    },

    validate: {
      title: (value) =>
        value?.length < 2 || !value ? "Enter Title Name" : null,
    },
  });

  // calling APi for EntityTypList

  useEffect(() => {
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.Entity_Type}`)
      .then((response) => {
       
        setLoading(true);

        const newData = response.data.map((item) => {
          const {
            id,
            financailMethodType,
            type,
            defaultInvidualEntityType,
            lowVolumneIncluded,
            whatIfOption,
            ...rest
          } = item;
          // Add new fields to the newData object
          rest.entityId = id; // Renamed 'entityId' property
          rest.mipsPI = "";
          rest.mipsCqm = "";
          rest.mipsRu = "";
          rest.mipsCpia = "";
          rest.qppPi = false;
          rest.qppCqm = false;
          rest.qppRu = false;
          rest.qppCpia = false;
          rest.defaultSubmission = "";
          rest.defaultCqm = "";
          rest.cqmTarget = "";
          rest.cqmScore = "";
          rest.cpiaScore = "";
          rest.order = "";
          rest.isSelected = false;
          return rest;
        });

        if (data !== null) {
          var combinedData = newData.map((item1) => {
            const matchingItem2 = data.reqSetMIPS.find(
              (item2) => item1.entityId === item2.entityId
            );
            if (matchingItem2) {
              return { ...item1, ...matchingItem2, isSelected: true };
            }
            return item1;
          });
          // yaha par chk box handle mange hua hai
          setEntityList(combinedData);
          // for edit
          setCheckedItems(data);
        } else {
          setEntityList(newData);
         
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("get pecos clinic records Fails:", error);
        setLoading(false);
      });
  }, []);

  const handleSubmit = () => {
    //let filteredData = entityList.filter((item) => checkedItems[item.entityId] || item.isSelected);
    //form.setValues({ reqSetMIPS: filteredData })
    

    let formValues = form.values;
    var filteredData = entityList.filter(
      (item) => checkedItems[item.entityId] || item.isSelected
    );

    // Convert specific fields to integers in filteredData

    filteredData = filteredData.map((item) => ({
      ...item,
      mipsPI: parseInt(item.mipsPI, 10) || 0,
      mipsCqm: parseInt(item.mipsCqm, 10) || 0,
      mipsRu: parseInt(item.mipsRu, 10) || 0,
      mipsCpia: parseInt(item.mipsCpia, 10) || 0,
      cqmTarget: parseInt(item.cqmTarget, 10) || 0,
      cqmScore: parseInt(item.cqmScore, 10) || 0,
      cpiaScore: parseInt(item.cpiaScore, 10) || 0,
      order: parseInt(item.order, 10) || 0,
    }));

    // Merge the filtered data into abc.reqSetMIPS
    formValues.reqSetMIPS = [...formValues.reqSetMIPS, ...filteredData];

    setLoading(true);

    formValues.normalFloorScore = parseFloat(formValues.normalFloorScore);
    formValues.smallPractiseFloorScore = parseFloat(
      formValues.smallPractiseFloorScore
    );

    formValues.dataCompletenessThreshold = parseFloat(
      formValues.dataCompletenessThreshold
    );

    formValues.high = parseFloat(formValues.high);
    formValues.low = parseFloat(formValues.low);
    
    ApiCall.axiosInstance[method](
      `${process.env.REACT_APP_API_URL}${ApiLink.ReqSet_Uri}`,
      formValues
    )
      .then((response) => {
       
        setLoading(false);
        navigate(RouteLink.RequirementSet_Uri);
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        setLoading(false);
      });
  };

  const handleBack = () => {
    navigate(RouteLink.RequirementSet_Uri);
    setLoading(false);
  };

  //my Code

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [name]: checked,
    }));

    setEntityList((prevData) =>
      prevData.map((item) =>
        item.entityId === parseInt(name) ? { ...item, chk: checked } : item
      )
    );
  };

  const handleInputChange = (event, entityId, property) => {
    const { value } = event.target;

    const parsedValue = parseInt(value, 10);

    setEntityList((prevData) =>
      prevData.map((item) =>
        item.entityId === entityId ? { ...item, [property]: parsedValue } : item
      )
    );
  };

  const handleGenderChange = (event, entityId, property) => {
    const { value } = event.target;
    setEntityList((prevData) =>
      prevData.map((item) =>
        item.entityId === entityId ? { ...item, [property]: value } : item
      )
    );
  };

  const handleStatusChange = (event, entityId, property) => {
    const { checked } = event.target;
    setEntityList((prevData) =>
      prevData.map((item) =>
        item.entityId === entityId ? { ...item, [property]: checked } : item
      )
    );
  };

  //my code

  return (
    <Box className={style.mt2}>
      {loading && <Loader size={48} />}
      <h5>Requirement Set</h5>

      <Grid>
        <Grid.Col span={4}>
          <TextInput
            label="Tittle"
            withAsterisk
            {...form.getInputProps("title")}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <DateInput
            valueFormat="DD/MM/YYYY"
            label="Date input"
            placeholder="Date input"
            {...form.getInputProps("date")}
          />
        </Grid.Col>
      </Grid>

      <div className="container">
        <div className="scrolldiv">
          <table className="tbl-style">
            <thead>
              <tr>
                <td rowSpan={"2"} style={{ width: "250px" }}>
                  Entity Types
                </td>
                <td colSpan={"4"} style={{ textAlign: "center" }}>
                  MIPS Score Percent Weights
                </td>
                <td colSpan={"4"} style={{ textAlign: "center" }}>
                  QPP API Submission Selection
                </td>
                <td rowSpan={"2"}>Default Submission Method</td>
                <td rowSpan={"2"}>Default CQM Template</td>
                <td rowSpan={"2"}>CQM Target Selected #</td>
                <td rowSpan={"2"}>CQM Score Den.</td>
                <td rowSpan={"2"}>CPIA Score Den.</td>
                <td rowSpan={"2"}>Order</td>
              </tr>
              <tr>
                <td>PI</td>
                <td>CQM</td>
                <td>RU</td>
                <td>CPIA</td>
                <td>PI</td>
                <td>CQM</td>
                <td>RU</td>
                <td>CPIA</td>
              </tr>
            </thead>
            <tbody>
              {entityList.map((item) => (
                <tr key={item.entityId}>
                  <td className="fcol">
                    <label>
                      <input
                        type="checkbox"
                        name={item.entityId}
                        value={item.name}
                        checked={checkedItems[item.entityId] || item.isSelected}
                        onChange={handleCheckboxChange}
                        className="chkbox"
                      />
                      {item.name }  {item.mvpId && (
      <span>(<strong>{item.mvpId}</strong>)</span>
    )}
                    </label>
                  </td>
                  <td className="fco2">
                    <input
                      type="text"
                      value={item.mipsPI}
                      onChange={(e) =>
                        handleInputChange(e, item.entityId, "mipsPI")
                      }
                      className="txtbox"
                    />
                  </td>

                  <td className="fco2">
                    <input
                      type="text"
                      value={item.mipsCqm}
                      onChange={(e) =>
                        handleInputChange(e, item.entityId, "mipsCqm")
                      }
                      className="txtbox"
                    />
                  </td>
                  <td className="fco2">
                    <input
                      type="text"
                      value={item.mipsRu}
                      onChange={(e) =>
                        handleInputChange(e, item.entityId, "mipsRu")
                      }
                      className="txtbox"
                    />
                  </td>
                  <td className="fco2">
                    <input
                      type="text"
                      value={item.mipsCpia}
                      onChange={(e) =>
                        handleInputChange(e, item.entityId, "mipsCpia")
                      }
                      className="txtbox"
                    />
                  </td>
                  <td className="txtcenter">
                    <input
                      type="checkbox"
                      checked={item.qppPi}
                      onChange={(e) =>
                        handleStatusChange(e, item.entityId, "qppPi")
                      }
                      className="chkbox"
                    />
                  </td>
                  <td className="txtcenter">
                    <input
                      type="checkbox"
                      checked={item.qppCqm}
                      onChange={(e) =>
                        handleStatusChange(e, item.entityId, "qppCqm")
                      }
                      className="chkbox"
                    />
                  </td>

                  <td className="txtcenter">
                    <input
                      type="checkbox"
                      checked={item.qppRu}
                      onChange={(e) =>
                        handleStatusChange(e, item.entityId, "qppRu")
                      }
                      className="chkbox"
                    />
                  </td>
                  <td className="txtcenter">
                    <input
                      type="checkbox"
                      checked={item.qppCpia}
                      onChange={(e) =>
                        handleStatusChange(e, item.entityId, "qppCpia")
                      }
                      className="chkbox"
                    />
                  </td>

                  <td>
                    <select
                      value={item.defaultSubmission}
                      onChange={(e) =>
                        handleGenderChange(
                          e,
                          item.entityId,
                          "defaultSubmission"
                        )
                      }
                      className="ddldropdown"
                    >
                      {DefaultSubmissionMethod.map((method) => (
                        <option key={method.value} value={method.value}>
                          {method.label}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      value={item.defaultCqm}
                      onChange={(e) =>
                        handleGenderChange(e, item.entityId, "defaultCqm")
                      }
                      className="ddldropdown"
                    >
                      {DefaultCqmTemplates.map((method) => (
                        <option key={method.value} value={method.value}>
                          {method.label}
                        </option>
                      ))}
                    </select>
                  </td>

                  <td className="fco2">
                    <input
                      type="text"
                      value={item.cqmTarget}
                      onChange={(e) =>
                        handleInputChange(e, item.entityId, "cqmTarget")
                      }
                      className="txtbox"
                    />
                  </td>
                  <td className="fco2">
                    <input
                      type="text"
                      value={item.cqmScore}
                      onChange={(e) =>
                        handleInputChange(e, item.entityId, "cqmScore")
                      }
                      className="txtbox"
                    />
                  </td>
                  <td className="fco2">
                    <input
                      type="text"
                      value={item.cpiaScore}
                      onChange={(e) =>
                        handleInputChange(e, item.entityId, "cpiaScore")
                      }
                      className="txtbox"
                    />
                  </td>
                  <td className="fco2">
                    <input
                      type="text"
                      value={item.order}
                      onChange={(e) =>
                        handleInputChange(e, item.entityId, "order")
                      }
                      className="txtbox"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div style={{ marginTop: "1rem", float: "right" }}></div>
      <hr></hr>
      <div className="mycontainer">MIPS Financial Method Type Management</div>

      <Grid>
        <Grid.Col span={4}>
          <Text fz="lg">MIPS Financial Method Type</Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text fz="lg">MIPS Financial Base Threshold Percent</Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text fz="lg">MIPS Financial Base Adjustment Percent</Text>
        </Grid.Col>

        <Grid.Col span={12}>
          <hr></hr>
        </Grid.Col>

        <Grid.Col span={4}>
          <Text fz="lg" fw={700}>
            MIPS Method
          </Text>
        </Grid.Col>

        <Grid.Col span={4}>
          <TextInput {...form.getInputProps("mipsMethodThreshold")} />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput {...form.getInputProps("mipsMethodBaseAdjustment")} />
        </Grid.Col>
        <Grid.Col span={4}>
          <Text fz="lg" fw={700}>
            APM Flat Method
          </Text>
        </Grid.Col>

        <Grid.Col span={4}></Grid.Col>
        <Grid.Col span={4}>
          <TextInput {...form.getInputProps("apmFlatBaseAdjustment")} />
        </Grid.Col>
        <Grid.Col span={4}>
          <Text fz="lg" fw={700}>
            Non-Participating
          </Text>
        </Grid.Col>

        <Grid.Col span={4}></Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            {...form.getInputProps("nonParticipatingBaseAdjustment")}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Text fz="lg" fw={700}>
            Normal & Small Practice Floor Score
          </Text>
        </Grid.Col>

        <Grid.Col span={4}>
          <TextInput
            {...form.getInputProps("normalFloorScore")}
            placeholder="Normal Floor Score"
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            {...form.getInputProps("smallPractiseFloorScore")}
            placeholder="Small Practice Floor Score"
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Text fz="lg" fw={700}>
            Data Completeness Threshold
          </Text>
        </Grid.Col>

        <Grid.Col span={4}></Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            {...form.getInputProps("dataCompletenessThreshold")}
            placeholder="Data Completeness Threshold"
          />
        </Grid.Col>



        <Grid.Col span={2}>
          <Text fz="lg" fw={700}>
            IA HIGH
          </Text>
        </Grid.Col>

       
        <Grid.Col span={4}>
          <TextInput
            {...form.getInputProps("high")}
            placeholder="IA High"
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Text fz="lg" fw={700}>
            IA MEDIUM
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            {...form.getInputProps("low")}
            placeholder="IA Low"
          />
        </Grid.Col>

        
      </Grid>

      <div style={{ marginTop: "1rem", float: "right" }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div>
          <Button type="primary" onClick={() => handleBack()}>
            Back
          </Button>
          <Button
            type="submit"
            style={{ marginLeft: "10px" }}
            mt="sm"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>

      {/* <Button type="submit" onClick={() => handleSubmit(form.values)}>
        Submit
      </Button> */}
    </Box>
  );
};

export default RequirementSetForm;
