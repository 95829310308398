import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import { TextInput, Button, Box, Grid, Checkbox, Group } from "@mantine/core";
import { ApiLink } from "../../Constant/ApiLink";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Loader } from "@mantine/core";
import { useSelector } from "react-redux";
import { Radio } from "@mantine/core";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

function MeetingForm() {
  const navigate = useNavigate();
  const { data, method } = useLocation().state;
  const [loading, setLoading] = useState(false);
  const groupDropDownList = useSelector(
    (store) => store.partenerTree.groupDropDownList
  );
  const selectedGroupId = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );

  const [initialState, setIntitialState] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [checkedGroupStatus, setCheckedGroupStatus] = useState("");
  const [customGroupStatus, setCustomGroupStatus] = useState("");
  const [ststusGroupData, setStatusGroupData] = useState([]);

  const form = useForm({
    initialValues: {
      id: data?.id,
      group: data?.group ?? [],
      meetingStatusName: data?.meetingStatusName,
      statusGroup: data?.statusGroup,
    },

    validate: {
      meetingStatusName: (value) =>
        value === "" || !value ? "Meeting Name is required" : null,
    },
  });

  const getGroupStatusData = () => {
    setLoading(true);
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.StatusGroup}`)
      .then((response) => {
        setStatusGroupData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (groupDropDownList.length) {
      let initialState = [];

      if (method == "put") {
        let count = 0;
        groupDropDownList.forEach((element) => {
          let item = {};
          item["value"] = element.value;
          item["label"] = element.label;
          if (data?.group?.some((item) => item.id == element.value)) {
            item["checked"] = true;
            count++;
          } else {
            item["checked"] = false;
          }
          initialState.push(item);
        });
        if (count == groupDropDownList?.length) {
          setAllChecked(true);
        }
        setCheckedGroupStatus(data?.statusGroup);
      } else {
        groupDropDownList.forEach((element) => {
          let item = {};
          item["value"] = element.value;
          item["label"] = element.label;
          item["checked"] = false;

          initialState.push(item);
        });
      }
      setIntitialState(initialState);
      getGroupStatusData();
    }
  }, [groupDropDownList, data]);

  useEffect(() => {}, []);

  const items = initialState.map((ele) => (
    <Checkbox
      px={30}
      mt="xs"
      label={ele.label}
      key={ele.value}
      checked={ele.checked}
      onChange={(event) => getCheckData(ele, event.currentTarget.checked)}
    />
  ));

  const getCheckData = (currEle, checked) => {
    setIntitialState((prevData) =>
      prevData.map((item) =>
        item.value === currEle.value ? { ...item, ["checked"]: checked } : item
      )
    );
    setAllChecked(false);
    // setTimeout(() => {
    //   form.setValues({ group: initialState});
    // }, 200);
  };

  const getCheckAllData = (checked) => {
    setIntitialState((prevData) =>
      prevData.map((item) => (true ? { ...item, ["checked"]: checked } : item))
    );
    setAllChecked(!allChecked);
  };

  const getCheckedList = () => {
    let countIds = [];
    initialState?.forEach((ele) => {
      if (ele.checked) {
        countIds.push(ele.value);
      }
    });
    return countIds;
  };

  const getCheckedGroupData = async () => {
    let group = [];
    initialState.forEach((ele) => {
      let item = {};
      if (ele.checked) {
        item["id"] = ele.value;
        item["name"] = ele.label;
        group.push(item);
      }
    });
    return Promise.resolve(group);
  };

  const handleSubmit = async (formValues) => {
    // form.setValues({ group: initialState});
    if (method != "put") {
      formValues["mid"] = formValues.meetingStatusName
        .replace(/\s+/g, "")
        .toLowerCase()
        .trim();
    }
    const listIds = getCheckedList();
    if (!listIds.length) {
      alert("Please select at least one group.");
      return;
    }

    if (!checkedGroupStatus) {
      alert("Please select at least one group Status.");
      return;
    }

    formValues["group"] = await getCheckedGroupData();
    formValues["statusGroup"] = checkedGroupStatus;

    setLoading(true);
    ApiCall.axiosInstance[method](
      `${process.env.REACT_APP_API_URL}${ApiLink.Meeting}`,
      formValues
    )
      .then((response) => {
        setLoading(false);
        navigate(RouteLink.Meeting_Uri);
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        setLoading(false);
      });
  };

  const handleBack = () => {
    navigate(RouteLink.Meeting_Uri);
    setLoading(false);
  };

  const handleGroupStatusChnage = (e, element) => {
    setCheckedGroupStatus(element);
  };

  const saveGroupStatus = () => {
    setLoading(true);
    axios[method](`${process.env.REACT_APP_API_URL}${ApiLink.StatusGroup}`, {
      name: customGroupStatus,
    })
      .then((response) => {
        setLoading(false);
        getGroupStatusData();
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        setLoading(false);
      });
  };

  return (
    <Box>
      {loading && <Loader size={48} />}
      <h5></h5>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid>
          {/* <Grid.Col span={6}>
            <Grid>
              <Grid.Col span={9}>
                <TextInput
                  label="User Define Value"
                  value={customGroupStatus}
                  placeholder="User Define Value"
                  onChange={(e) => setCustomGroupStatus(e.currentTarget.value)}
                />
              </Grid.Col>
              <Grid.Col span={3} style={{ float: "right" }}>
                <Button mt={25} onClick={() => saveGroupStatus()}>
                  Add Value
                </Button>
              </Grid.Col>
            </Grid>
          </Grid.Col> */}
          <Grid.Col span={6}>
            <TextInput
              label="Name"
              withAsterisk
              {...form.getInputProps("meetingStatusName")}
            />
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col span={6} mt={30}>
            <label style={{ "font-size": "14px", fontWeight: 500 }}>
              Select User Define Field <span style={{ color: "red" }}>*</span>
            </label>
            {ststusGroupData.length &&
              ststusGroupData.map((ele) => (
                <Radio
                  key={ele.id}
                  px={30}
                  mt="xs"
                  onChange={(e) => handleGroupStatusChnage(e, ele)}
                  checked={checkedGroupStatus?.id == ele?.id}
                  label={ele.name}
                />
              ))}
          </Grid.Col>
          <Grid.Col span={6}>
            <Grid mt={30}>
              <Grid.Col span={1}>
                <Checkbox
                  mt={2}
                  checked={allChecked}
                  onChange={(event) =>
                    getCheckAllData(event.currentTarget.checked)
                  }
                />
              </Grid.Col>
              <Grid.Col span={5}>
                <label style={{ "font-size": "14px", fontWeight: 500 }}>
                  Select All Group <span style={{ color: "red" }}>*</span>
                </label>
              </Grid.Col>
            </Grid>
            {items}
          </Grid.Col>
        </Grid>

        <div style={{ marginTop: "1rem", float: "right" }}></div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div>
            <Button type="primary" onClick={handleBack}>
              Back
            </Button>
            <Button style={{ marginLeft: "10px" }} type="submit" mt="sm">
              Save
            </Button>
          </div>
        </div>
      </form>
    </Box>
  );
}

export default MeetingForm;
