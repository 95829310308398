import React, { useState, useRef, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate } from "react-router-dom";
import useAxios from "../../Shared/useApi";
import { ActionIcon, Box, Button, Grid } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { IconEdit, IconHttpDelete } from "@tabler/icons-react";
import { RowAction } from "../../Shared/RowAction";
import { useDisclosure } from "@mantine/hooks";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import { RequirementSetColumn } from "./RequirementSetColumn";
import axios from "axios";
import { ApiLink } from "../../Constant/ApiLink";
import { useSelector } from "react-redux";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";


const RequirementSetList = () => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  var navigate = useNavigate();

  const reqColumnDefs = useMemo(() => RequirementSetColumn);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (reportSelection.selectedYear != undefined) {
      getRequirementSetData();
    }
  }, [reportSelection.selectedYear]);

  const getRequirementSetData = () => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.ReqSet_Uri}?year=${reportSelection.selectedYear}`
      )
      .then((response) => {
        setRowData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
  
  };

  const onCreate = () => {
    navigate(RouteLink.RequirementSet_Create_Uri, {
      state: { data: null, method: "post" },
    });
  };

  const onUpdate = (value) => {
    
    navigate(RouteLink.RequirementSet_Update_Uri, {
      state: { data: value, method: "put" },
    });
  };

  const [deletingRow, setDeletingRow] = useState({});
  const onDelete = (value) => {
    setDeletingRow(value);
    open();


  }
  const [opened, { close, open }] = useDisclosure(false);
  const [formLoading, setLoading] = useState(false);


  function IsConfirmed(value) {
    if (value === true) {
      setLoading(true);
      ApiCall.axiosInstance["delete"](
        `${process.env.REACT_APP_API_URL}${ApiLink.ReqSet_Uri}?id=${deletingRow.reqId}`
      )
        .then((response) => {
         
          setLoading(false);
          setRowData(oldValues => {
            return oldValues.filter(rowData => rowData.reqId !== deletingRow.reqId)
          })
        })
        .catch((error) => {
          console.error("Failed Request:", error);
          setLoading(false);
        });
    }

  }

  return (
    <Box style={{ marginTop: "15px" }}>
      <DeleteConfirmationModal IsConfirmed={IsConfirmed} opened={opened} open={open} close={close} />
      <Button type="primary" onClick={() => onCreate()}>
        Create Requirement Set
      </Button>

     
      <MantineReactTable
        columns={reqColumnDefs}
        data={rowData ?? []}
        state={{ isLoading: isLoading }}
        enableRowActions
        positionActionsColumn='first'
        renderRowActions={(row) => (
          <RowAction onUpdate={() => onUpdate(row.row.original)} onDelete={() => onDelete(row.row.original)} />
        )}
        mantinePaperProps={{ shadow: '0', withBorder: false }}
      />

    </Box>
  );
};

export default RequirementSetList;
