import { isNotEmpty, useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import {
  TextInput,
  Button,
  Box,
  Grid,
  Select,
  Space,
  NumberInput,
  Textarea,
  FileInput,
  Anchor,
  LoadingOverlay,
  Checkbox,
  Text,
} from "@mantine/core";
import { ApiLink } from "../../Constant/ApiLink";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Loader } from "@mantine/core";
import { Abbrevation } from "../../Shared/DropDownOptions/AbbrevationList";
import { ShowHideStatus } from "../../Shared/DropDownOptions/ShowHideStatus";
import { Sandivider } from "../../Shared/Theme/Sandivider";
import style from "../../Shared/Theme/CommanStyle.module.css";
import { ActiveStatus } from "../../Shared/DropDownOptions/ActiveStatus";
import useAxios from "../../Shared/useApi";
import { DateInput } from "@mantine/dates";
import { v4 as uuidv4 } from "uuid";
import { BlobServiceClient } from "@azure/storage-blob";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {

  StatusDropDown,
} from "../../Shared/DropDownOptions/DropDownList";
import { IconDownload } from "@tabler/icons-react";
import { getFileUri } from "../../Shared/Constant";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";


function ActionItemForm() {
  const navigate = useNavigate();
  const { data, method } = useLocation().state;
  const [userData, setUserData] = useState([]);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const groupDropDownList = useSelector((store) => store.partenerTree.groupDropDownList);
  const SelectedClinicList = useSelector((store) => store.partenerTree.selectedGroupClnics);
  const [isGroupChecked, setIsGroupChecked] = useState(data?.group != null);
  const selectedGroupId = useSelector((store) => store.partenerTree.selectedGroupId);
  const {user}  = useSelector(store => store.auth);

  const [loading, setLoading] = useState(false);
  const [dropdowndata, setdropdowndata] = useState([]);


  const { response, loading1, error } = useAxios({
    method: "get",
    url: "/Category",
    headers: null,
    body: null,
  });

  //Category
  useEffect(() => {
    if (response !== null) {
      // setClinic(SelectedClinicList);
      setdropdowndata(response);
    }
    if (error) {
     
    }
  }, [response, error]);


  useEffect(() => {
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.User_Uri}`
      )
      .then((response) => {
        const records = response.data;
        console.error("Get User Details:", records);
        setUserData(records);
      })
      .catch((error) => {
        console.error("Get User Details:", error);
      });
  }, []);

  useEffect(()=>{
    const data = SelectedClinicList.find(ele => ele.value == form.values.clinicId);
    if(data){
      form.setValues({
        clinicId: data.value,
      })
    }else{
      form.setValues({
        clinicId:  user.level === 3 ? reportSelection.clinicId : 0,
      })
    }
  },[SelectedClinicList])


  // Save Records
  const handleSubmit = async (formValues) => {
    formValues['year'] = reportSelection.selectedYear;
    if (isGroupChecked) {
      formValues['enrollmentId'] = 0;
    } else {
      formValues['groupId'] = 0;
    }

    const selectedClinicLebel = SelectedClinicList.find(
      (ele) => ele.value === formValues.enrollmentId
    )?.label;
   
    formValues.attestationClinic = selectedClinicLebel;
    setLoading(true);

    try {
      let filepath = form.values.filepathFile;
      if (filepath !== null) {
        const fileUri = await getFileUri(form.values.filepathFile);
        form.values.filepath = fileUri;
        setLoading(false);
        if (fileUri) {
          saveActionItem(formValues);
        }
      } else
      {
        saveActionItem(formValues);
      }
    } 
    catch (error) 
    {
      console.error("Failed Request:", error);
      setLoading(false);
    }
  };

  const saveActionItem = (formValues) => {
    if((formValues.clinicId == 0) && (formValues.groupId == 0)){
      window.alert("Clinic does not exists in selected Group!");
      setLoading(false);
      return;
    }
    ApiCall.axiosInstance[method](
      `${process.env.REACT_APP_API_URL}/${ApiLink.ActionItem}`,
      formValues
    ).then(() => {
     
      setLoading(false);
      navigate(`${RouteLink.ActionItemList}/${user.level === 3 ? reportSelection.clinicId : 'all'}`);
    }).catch(error => {
      console.error(error);
      setLoading(false);
    })
  }

  const handleBack = () => {
    navigate(`${RouteLink.ActionItemList}/${user.level === 3 ? reportSelection.clinicId : 'all'}`);
    setLoading(false);
  };

  const form = useForm({
    initialValues: {
      id: data?.id,
      enrollmentId: data?.enrollmentId,
      clinicId: data?.clinicId? data?.clinicId : 0,
      attestationClinic: data?.attestationClinic,
      category: data?.category ? parseInt(data.category, 10) : 0,
      descriptions: data?.descriptions,
      filepath: data?.filepath,
      filepathFile: null,
      assign: data?.assign,
      assignstatus: data?.assignstatus,
      process: data?.process,
      date: new Date(data?.date ?? new Date()),
      year: reportSelection.selectedYear,
      targetDate: new Date(data?.targetDate ?? new Date()),
      groupId: data?.group?.id ? data?.group?.id : 0
    },

    validate: {
      
        date: () => {
          let targetDateT = new Date(form.values.targetDate).getTime();
          let createdtDateT = new Date(form.values.date).getTime();
          if(targetDateT < createdtDateT)
            return 'Created date should be less then Target Date'
        },
        targetDate: () => {
          let targetDateT = new Date(form.values.targetDate).getTime();
          let createdtDateT = new Date(form.values.date).getTime();
          if(targetDateT < createdtDateT)
            return 'Created date should be less then Target Date'
        },
    },
  });

  const renameFile = (file, newFileName) => {
    const fileNameParts = file?.name?.split(".");
    const fileExtension = fileNameParts?.pop();
    const newName = `${newFileName}.${fileExtension}`;

    return new File([file], newName, { type: file.type });
  };

  const generateUniqueId = () => {
    return uuidv4();
  };

  // upload image function
  async function uploadImage(containerName, file) {
    const blobServiceClient = new BlobServiceClient(
      "https://macrafilestorage.blob.core.windows.net/macrafiles?sp=racwdl&st=2023-08-25T16:32:39Z&se=2023-12-31T00:32:39Z&sv=2022-11-02&sr=c&sig=d%2FOsbbexy37cCqPnbkQGp3zaRhtn%2BAZ0%2BwKSVvE8mk4%3D"
    );
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(file.name);
    const blockBlobClient = blobClient.getBlockBlobClient();
    const result = await blockBlobClient.uploadBrowserData(file, {
      blockSize: 4 * 1024 * 1024,
      concurrency: 20,
      onProgress: (ev) => ev,
    });
   
    
  }

  const customLableStyle = {
    fontSize: ".90rem",
    fontWeight: "500",
    color: "#212529",
    marginRight: "10px",
  };

  const customAnchoreTagStyle = {
    fontWeight: '500',
    fontSize: "14px",
    marginTop: "10px"
  }


  const handleClickAttestation = (value) => {

    form.setValues({ clinicId: value })
    setLoading(true);
    // SelectedClinicList
  
    ApiCall.axiosInstance.get(`${process.env.REACT_APP_API_URL}${ApiLink.Clinic_Uri_Physicians}/${value}`)
      .then(response => {
        // setAttestation(response.data);
       
        setLoading(false);

      })
      .catch(error => {
        console.error('Error EntityType fetching data:', error);
        setLoading(false);
      });
  };

  const handleChangeAttestion = (e) => {
    if(e.currentTarget.checked){
      form.setValues({ enrollmentId: 0,
      groupId: selectedGroupId })
      setIsGroupChecked(true)
    } else {
      setIsGroupChecked(false)
    }
  }


  return (
    <Box className={style.mt2}>

      {loading &&
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      }
       {user.level <= 1 && (
      <Checkbox label="Group" disabled={method == 'put'} checked={data?.group != null || isGroupChecked} fw={500} mb={20} onChange={(e) => handleChangeAttestion(e)}></Checkbox>)}
      <Space></Space>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid>
          { user.level <=2 && (<Grid.Col span={6}>
          {!isGroupChecked && <Select
              label="Attestation Clinics"
              withAsterisk
              {...form.getInputProps("clinicId")}
              data={ SelectedClinicList}
              onChange={(v) => handleClickAttestation(v)}
              disabled={method == 'put'}
              searchable
            />}
            {isGroupChecked && <Select
              label="Group"
              withAsterisk
              {...form.getInputProps("groupId")}
              data={groupDropDownList}
              disabled={true}
            />}
          </Grid.Col>
          )}
          <Grid.Col span={6}>
            <Select
              label="Category"
              withAsterisk
              {...form.getInputProps("category")}
              data={dropdowndata.map((ele) => ({
                value: ele.categoryId,
                label: ele.catName,
              }))}

            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Textarea
              minRows={10}
              label="Description"
              placeholder="Description"
              {...form.getInputProps("descriptions")}
            />
          </Grid.Col>
          <Grid.Col span={3}>

            <FileInput
              label="Upload Document"
              placeholder="Upload File"
              {...form.getInputProps("filepathFile")}
            />
            {form.values.filepath && <Anchor target="_blank" href={form.values.filepath}>
              <span style={customAnchoreTagStyle}>Download <IconDownload style={{ marginTop: '10px' }} /></span>
            </Anchor>}
            {!form.values.filepath && method !== 'post' && <span>File not uploaded</span>}
          </Grid.Col>

          <Grid.Col span={3}>
            <Select
              label="Assign To"
              withAsterisk
              {...form.getInputProps("assign")}
              data={userData?.map(ele => ({
                value: ele.id,
                label: ele.firstName + " " + ele.lastName,
              }))}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <div>
              <label style={customLableStyle}>Status</label>
              {/* {!form.values.concent && (
                <Anchor fw={500} fs="0.90rem" href={form.values.concent}>
                  Add
                </Anchor>
              )} */}
            </div>
            <Select
              withAsterisk
              {...form.getInputProps("assignstatus")}
              data={StatusDropDown}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <DateInput
              dateParser={(input) => {
                if (input === "WW2") {
                  return new Date(1939, 8, 1);
                }
                return new Date(input);
              }}
              valueFormat="MM/DD/YYYY"
              label="Created Date"
              placeholder="Date input"
              {...form.getInputProps("date")}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <DateInput
              dateParser={(input) => {
                if (input === "WW2") {
                  return new Date(1939, 8, 1);
                }
                return new Date(input);
              }}
              valueFormat="MM/DD/YYYY"
              label="Target Date"
              placeholder="Date input"
              {...form.getInputProps("targetDate")}
            />
          </Grid.Col>
        </Grid>

        <div style={{ marginTop: "1rem", float: "right" }}></div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div>
            <Button type="primary" onClick={() => handleBack()}>
              Back
            </Button>
            <Button style={{ marginLeft: "10px" }} type="submit" mt="sm">
              Save
            </Button>
          </div>
        </div>
      </form>
    </Box>
  );
}

export default ActionItemForm;
