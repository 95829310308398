import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Grid,
  Select,
  Space,
  Loader,
  FileInput,
  LoadingOverlay,
  TextInput,
  Anchor,
  Text,
  Group,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router";
import Swal from "sweetalert2";
import axios from "axios";

import { getFileUriOnMeasureUpload } from "../../../Shared/Constant";
import { ApiLink } from "../../../Constant/ApiLink";
import { DateInput } from "@mantine/dates";
import { useSelector } from "react-redux";
import { IconDownload } from "@tabler/icons-react";
import { v4 as uuidv4 } from "uuid";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";

export const CsvUpload = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formatDDlist, setFormatDDList] = useState([]);
  const { data, method } = useLocation().state;
  const [fileData, setFileData] = useState([]);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const selectedGroupId = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );

  const form = useForm({
    initialValues: {
      id: data?.id,
      name: data?.name,
      csvFormatId: data?.csvFormatId,
      createdDate: new Date(data?.createdDate ?? new Date()),
      year: data?.year ? data?.year : reportSelection.selectedYear,
      filePath: null,
      file: null,
      groupId: selectedGroupId,
      clinicId: reportSelection.clinicId,
      entityType: data?.entityType,
      category: data?.category,
      submissionType: data?.submissionType,
    },
    validate: {
      // meetingStatus: (value) => (!value || value == "" ? "MeetingStatus is required!" : null),
    },
  });

  useEffect(() => {
    getCSVdataList();
  }, []);

  const getCSVdataList = () => {
    setLoading(true);
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.CSVFormat}`)
      .then((response) => {
        const csvFormateDD = response.data.map((ele) => {
          return {
            value: ele.id,
            label: ele.formatName,
          };
        });
        setFormatDDList(csvFormateDD);
        console.log("set", csvFormateDD);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    const { csvFormatId, file, submissionType } = form.values;

    const fileExtension = file.name.split(".").pop().toLowerCase();

    //QRDA
    if (csvFormatId == 1) {
      if (
        submissionType != "ElectronicHealthRecord" ||
        fileExtension != "json"
      ) {
        Swal.fire({
          icon: "error",
          title: "QRDA",
          text: "Upload JSON files only or select 'Electronic Health Record' as the Submission Type.",
        });
        return;
      }
    }

    //JSON
    // if (csvFormatId != 1) {
    //   if (submissionType != "Registry") {
    //     Swal.fire({
    //       icon: "error",
    //       title: "Submission Type",
    //       text: "Type supports only Registry submissions.",
    //     });
    //     return;
    //   }
    // }

    //JSON
    if (csvFormatId == 0) {
      if (fileExtension != "json") {
        Swal.fire({
          icon: "error",
          title: "Invalid File",
          text: "Only JSON file uploads are permitted when the selected format is JSON.",
        });
        return;
      }
    }

    setLoading(true);
    form.setValues({
      year: reportSelection.selectedYear,
    });
    try {
      const response = await ApiCall.axiosInstance["post"](
        `${process.env.REACT_APP_API_URL}${ApiLink.MeasureCSVUpload}`,
        form.values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoading(false);

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Record Saved Successfully...",
        timer: 1000, // Modal will auto-close after 1 second
        showConfirmButton: false, // Hides the confirm button
      }).then(() => {
        // Navigate after the modal is closed
        navigate("/user/quality/upload-list");
      });
    } catch (error) {
      console.error("Error Group fetching data:", error);
      window.alert("Record is already created!");
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/user/quality/upload-list");
    setLoading(false);
  };

  const handleDownload = async () => {
    try {
      const selectedCsvFormatId = form.values.csvFormatId;

      if (selectedCsvFormatId === 0) {
        alert("Select Only CSV Format");
        return;
      }
      const selectedFormat = formatDDlist.find(
        (item) => item.value === selectedCsvFormatId
      );
      if (!selectedFormat) {
        alert("Invalid format selected. Please try again.");
        return;
      }
      const response = await ApiCall.axiosInstance.get(
        `${process.env.REACT_APP_API_URL}${ApiLink.CSVFormatDownloadCSVFormat}/${selectedCsvFormatId}`,
        {
          headers: {
            Accept: "*/*",
          },
          responseType: "blob", // Ensure the response is treated as a blob
        }
      );
      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      // Use the selected format's label as the file name
      const fileName = `${selectedFormat.label}.csv`;
      // Create a temporary <a> element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // Set the file name dynamically
      document.body.appendChild(link);
      // Programmatically click the link to trigger the download
      link.click();
      // Remove the link after triggering the download
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the CSV file:", error);
      alert("Failed to download the file. Please try again.");
    }
  };

  return (
    <Box>
      {loading && (
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      )}
      <Space></Space>

      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="File Name: "
              placeholder="Fomat Name"
              withAsterisk
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <DateInput
              dateParser={(input) => {
                if (input === "WW2") {
                  return new Date(1939, 8, 1);
                }
                return new Date(input);
              }}
              valueFormat="MM/DD/YYYY"
              label="Last Validation Date"
              placeholder="Date input"
              {...form.getInputProps("createdDate")}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Group
              spacing="xs"
              style={{ alignItems: "center", marginBottom: "0.5rem" }}
            >
              <label style={{ fontSize: "14px", fontWeight: "bold" }}>
                Select Format
              </label>
              <Anchor
                href="#"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default anchor behavior
                  handleDownload();
                }}
                style={{
                  fontSize: "14px",
                  color: "green",
                  textDecoration: "underline",
                }}
              >
                (Download)
              </Anchor>
            </Group>
            <Select
              {...form.getInputProps("csvFormatId")}
              data={formatDDlist}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <FileInput
              mt={0}
              label="Upload Json or CSV"
              accept=".csv,.json"
              {...form.getInputProps("file")}
              placeholder="Upload file"
              rightSectionPointerEvents="none"
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select
              label="Category"
              {...form.getInputProps("category")}
              data={[
                { label: "Quality", value: "Quality" },
                { label: "IA", value: "IA" },
              ]}
            />
            
          </Grid.Col>
          <Grid.Col span={4}>
            <Select
              label="Entity Type"
              {...form.getInputProps("entityType")}
              data={[
                { label: "Group", value: "Group" },
                { label: "Individual", value: "Individual" },
              ]}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select
              label="Submission Type"
              {...form.getInputProps("submissionType")}
              data={[
                { label: "Registry", value: "Registry" },
                {
                  label: "Electronic HealthRecord",
                  value: "ElectronicHealthRecord",
                },
              ]}
            />
          </Grid.Col>
        </Grid>

        <div style={{ marginTop: "1rem", float: "right" }}></div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div>
            <Button type="primary" onClick={() => handleBack()}>
              Back
            </Button>
            <Button style={{ marginLeft: "10px" }} type="submit" mt="sm">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Box>
  );
};

export default CsvUpload;
