import { Button, Table } from "@mantine/core";
import React, { useState } from "react";
import { AttestationRow } from "./AttestationRow";
import { useEffect } from "react";
import { ApiLink } from "../../Constant/ApiLink";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

export default function AttestationHistory({ phyEnrollId, pecos, year }) {
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);

  const [addNew, setaddNew] = useState(true);
  // const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [attestationRows, setAttestationRows] = useState([]); // State to store attestation rows

  // Callback function to update addNew
  const updateAddNew = (newValue) => {
    setaddNew(newValue);
    GetAttestationHistory(phyEnrollId);
    setAttestationRows([]); //reseting attestationRows
  };

  //show attestation div base on conditions
  useEffect(() => {
    GetAttestationHistory(phyEnrollId);
  }, [phyEnrollId, year]);

  const GetAttestationHistory = (param) => {
    setLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.GetAttestationHistory}${param}/${year}`
      )
      .then((response) => {
        setLoading(false);
        setdata(response.data);
      })
      .catch((error) => {
        console.error("get pecos clinic records Fails:", error);
        setLoading(false);
      });
  };

  const handleDeleteItem = (itemId) => {
    if (
      !window.confirm(
        "Are you sure to delete Attestation History?\nThis action delete the physician for this year."
      )
    ) {
      return;
    }
    ApiCall.axiosInstance["delete"](
      `${process.env.REACT_APP_API_URL}${ApiLink.AttestationHistory}?id=${itemId.id}`
    )
      .then((response) => {
        const updatedComponents = data.filter((comp) => comp.id !== itemId.id);
        GetAttestationHistory(phyEnrollId);
        alert("Record Deleted SuccesFully");
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        setLoading(false);
      });
  };

  const addNewRow = () => {
    // Create a new row component and append it to the attestationRows state
    if (addNew === true) {
      const newRow = (
        <AttestationRow
          key={attestationRows.length} // Use a unique key for the new row
          enrollmentid={phyEnrollId}
          pecos={pecos}
          onDelete={handleDeleteItem}
          addNew={addNew}
          updateAddNew={updateAddNew}
        />
      );
      setAttestationRows([newRow]);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mycontainer "
      >
        <span>Attestation History </span>{" "}
        <Button onClick={() => addNewRow()}>Add</Button>{" "}
      </div>
      <div>
        <Table
          verticalSpacing="xs"
          fontSize="xs"
          striped
          highlightOnHover
          withBorder
          withColumnBorders
        >
          <thead>
            <tr>
              <th>Fiscal Year</th>
              <th>Attestation Clinic</th>
              <th>EHR Interface Map</th>
            </tr>
          </thead>
          <tbody>
            {/* added one row in table for creating record; */}
            {attestationRows[0]}

            {loading && <h6>Loading...</h6>}
            {!loading &&
              data.map((form, index) => (
                <AttestationRow
                  key={index}
                  enrollmentid={phyEnrollId}
                  pecos={pecos}
                  attestions={form}
                  onDelete={handleDeleteItem}
                  updateAddNew={updateAddNew}
                />
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}
