import { RouteLink } from "../Constant/RouterLink";
import CategoryForm from "../Pages/Category/CategoryForm";
import CategoryList from "../Pages/Category/CategoryList";
import ClinicForm from "../Pages/Clinic/ClinicForm";
import ClinicList from "../Pages/Clinic/ClinicList";
import EntityForm from "../Pages/Entity/EntityForm";
import EntityList from "../Pages/Entity/EntityList";
import GroupForm from "../Pages/Groups/GroupForm";
import GroupList from "../Pages/Groups/GroupList";
import Login from "../Pages/Login/Login";
import PartnerForm from "../Pages/Partner/PartnerForm";
import PartnerList from "../Pages/Partner/PartnerList";
import PhysicianForm from "../Pages/Physician/PhysicianForm";
import PhysicianList from "../Pages/Physician/PhysicianList";
import Registration from "../Pages/Users/Registration";
import { UserList } from "../Pages/Users/UserList";
import { DashboardContent } from "../components/DashboardContent";
import { AdminTabs } from "../components/AdminTabs";
import { HomeLayout } from "../layout/HomeLayout";
import { UserLayout } from "../layout/UserLayout";
import SubCategoryList from "../Pages/SubCategory/SubCategoryList";
import SubCategoryForm from "../Pages/SubCategory/SubCategoryForm";
import PublicFilesForm from "../Pages/PublicFiles/PublicFilesForm";
import PublicFilesList from "../Pages/PublicFiles/PublicFilesList";
import RequirementList from "../Pages/Requirement/RequirementList";
import RequirementForm from "../Pages/Requirement/RequirementForm";
import RequirementSetForm from "../Pages/RequirementSet/RequirementSetForm";
import RequirementSetList from "../Pages/RequirementSet/RequirementSetList";
import { FinancialTabs } from "../components/FinancialTabs";
import ReportingEntities from "../Pages/Financial/ReportingEntities";
import ComponentDetails from "../Pages/Financial/ComponentDetails";
import FinancialSummary from "../Pages/Financial/FinancialSummary";
import TINComparisons from "../Pages/Financial/TINComparisons";
import TINSummary from "../Pages/TinSummary/TINSummary";
import QPPMismatch from "../Pages/Financial/QPPMismatch";
import QPPDataConfirmation from "../Pages/Financial/QPPDataConfirmation";
import AssumptionsTargetResults from "../Pages/Financial/AssumptionsTargetResults";
import Example from "../utils/Example";
import ActionItem from "../Pages/ActionItem/ActionItem";
import ActionItemForm from "../Pages/ActionItem/ActionItemForm";
import ActionItemList from "../Pages/ActionItem/ActionItemList";
import CcmList from "../Pages/CCM/CcmList";
import CcmForm from "../Pages/CCM/CcmForm";
import CCM from "../Pages/Financial/CCM";
import CCMGapAnalysis from "../Pages/Financial/CCMGapAnalysis";
import ActionCalenterView from "../Pages/ActionItem/ActionCalenterView";
import MeetingList from "../Pages/MeetingStatus/MeetingList";
import MeetingForm from "../Pages/MeetingStatus/MeetingForm";
import GuiList from "../Pages/GuiYear/GuiList";
import GuiYearForm from "../Pages/GuiYear/GuiYearForm";
import UtilitiTabs from "../components/UtilitiTabs";
import PhysicianIncomeForm from "../Pages/Utilities/PhysicianIncome/PhysicianIncomeForm";
import PhysicianIncomeList from "../Pages/Utilities/PhysicianIncome/PhysicianIncomeList";
import Test from "../components/Test";
import CsvFormate from "../Pages/Utilities/CsvFormate/CsvFormate";
import QualityTab from "../components/QualityTab";

import CsvFormatList from "../Pages/Utilities/CsvFormate/CsvFormatList";
import CsvUpload from "../Pages/Quality/MeasureCsvUpload/CsvUpload";
import CsvUploadList from "../Pages/Quality/MeasureCsvUpload/CsvUploadList";
import CqmSelector from "../Pages/Quality/CQM/CqmSelector";
import MeasureLog from "../Pages/Quality/MeasureCsvUpload/MeasureLog";
import QualityHomeList from "../Pages/Quality/QualityHome/QualityHomeList";
import ECComparisionTool from "../Pages/Quality/ECComparision/ECComparisionTool";
import GroupCQMDetails from "../Pages/Quality/GroupCQM/GroupCQMDetails";
import GroupCQMSummary from "../Pages/Quality/GroupCQMSummary/GroupCQMSummary";
import ECSubmissionStatus from "../Pages/Quality/ECSubmission/ECSubmissionStatus";
import QualityFormula from "../Pages/Utilities/QualityFormula/QualityFormula";
import FormulaMappingList from "../Pages/Utilities/QualityFormula/FormulaMappingList";
import BenchmarkForm from "../Pages/Utilities/Benchmark/BenchmarkForm";
import BenchmarkList from "../Pages/Utilities/Benchmark/BenchmarkList";
import ViewBenchmark from "../Pages/Utilities/Benchmark/ViewBenchmark";
import TinCqmDetail from "../Pages/Quality/TinCQMDetails/TinCqmDetail";
import MeasureCqmDetails from "../Pages/Quality/MeasureCQMDetails/MeasureCqmDetails";
import QualityCalGroupWise from "../Pages/Quality/CalculationGroupWise/QualityCalGroupWise";
import CCMEnable from "../Pages/Utilities/CCMEnable";
import GroupLocationList from "../Pages/Utilities/GroupLocation/GroupLocationList";
import GroupLocationForm from "../Pages/Utilities/GroupLocation/GroupLocationForm";
import CCMTabs from "../components/CCMTabs";
import CcmGroupAnalysis from "../Pages/CCMReports/CcmGroupAnalysis";
import PhysicianScore from "../Pages/Quality/PhysicianScore/PhysicianScore";
import ForgetPassword from "../Pages/ForgetPassword/ForgetPassword";
import { CPIATabs } from "../components/CPIATabs";
import CPIAForm from "../Pages/CPIA/CPIAForm";
import CPIAList from "../Pages/CPIA/CPIAList";
import { SubmissionTabs } from "../components/SubmissionTabs";
import SubmissionList from "../Pages/Submission/SubmissionList";
import SubmissionForm from "../Pages/Submission/SubmissionForm";
import { PITabs } from "../components/PITabs";
import PIForm from "../Pages/PI/PIForm";
import PIList from "../Pages/PI/PIList";
import MeasureAtlas from "../Pages/MeasureAtlas/Measureatlas";
import QualityNotes from "../Pages/Quality/QualityNotes";
import QualityNotesForm from "../Pages/Quality/QualityNotesForm";


export const Router = [
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/forgetpassword",
        element: <ForgetPassword />,
      },
      {
        path: "/user",
        element: <UserLayout />,
        children: [
          { path: RouteLink.Dashboard_Uri, element: <DashboardContent /> },
          { path: RouteLink.RestPassword_Uri, element: <ForgetPassword /> },
          {
            path: "/user/admin",
            element: <AdminTabs />,
            children: [
              {
                path: "/user/admin/Test",
                element: <Test />,
              },
              { path: RouteLink.Group_Uri, element: <GroupList /> },
              { path: RouteLink.Group_Create_Uri, element: <GroupForm /> },
              { path: RouteLink.Group_Update_Uri, element: <GroupForm /> },
              { path: RouteLink.Physician_Uri, element: <PhysicianList /> },
              {
                path: RouteLink.Physician_Create_Uri,
                element: <PhysicianForm />,
              },
              {
                path: RouteLink.Physician_Update_Uri,
                element: <PhysicianForm />,
              },
              { path: RouteLink.Clinic_Uri, element: <ClinicList /> },
              { path: RouteLink.Clinic_Create_Uri, element: <ClinicForm /> },
              { path: RouteLink.Clinic_Upadte_Uri, element: <ClinicForm /> },
              { path: RouteLink.Entity_Uri, element: <EntityList /> },
              { path: RouteLink.Entity_Create_Uri, element: <EntityForm /> },
              { path: RouteLink.Entity_Upadte_Uri, element: <EntityForm /> },
              { path: RouteLink.Category_Uri, element: <CategoryList /> },
              {
                path: RouteLink.Catagory_Create_Uri,
                element: <CategoryForm />,
              },
              { path: RouteLink.Partners_Uri, element: <PartnerList /> },
              { path: RouteLink.Users_Uri, element: <UserList /> },
              { path: RouteLink.CreatePartner_Uri, element: <PartnerForm /> },
              { path: RouteLink.CreateUser_Uri, element: <Registration /> },
              { path: RouteLink.UpdatePartner_Uri, element: <PartnerForm /> },
              { path: RouteLink.UpdateUser_Uri, element: <Registration /> },
              { path: RouteLink.SubCategory_Uri, element: <SubCategoryList /> },
              {
                path: RouteLink.SubCatagory_Create_Uri,
                element: <SubCategoryForm />,
              },
              {
                path: RouteLink.SubCatagory_Update_Uri,
                element: <SubCategoryForm />,
              },
              { path: RouteLink.PublicFile_Uri, element: <PublicFilesList /> },
              {
                path: RouteLink.PublicFile_Create_Uri,
                element: <PublicFilesForm />,
              },
              {
                path: RouteLink.PublicFile_Update_Uri,
                element: <PublicFilesForm />,
              },
              { path: RouteLink.Requirement_Uri, element: <RequirementList /> },
              {
                path: RouteLink.Requirement_Create_Uri,
                element: <RequirementForm />,
              },
              {
                path: RouteLink.Requirement_Update_Uri,
                element: <RequirementForm />,
              },
              {
                path: RouteLink.RequirementSet_Uri,
                element: <RequirementSetList />,
              },
              {
                path: RouteLink.RequirementSet_Create_Uri,
                element: <RequirementSetForm />,
              },
              {
                path: RouteLink.RequirementSet_Update_Uri,
                element: <RequirementSetForm />,
              },
              // {
              //   path: RouteLink.RequirementSet_Create_Uri,
              //   element: <RequirementSetFormSecond />,
              // },
              // {
              //   path: RouteLink.RequirementSet_Update_Uri,
              //   element: <RequirementSetFormSecond />,
              // },
              {
                path: RouteLink.CCM_Uri,
                element: <CcmList />,
              },
              {
                path: RouteLink.CCM_Create_Uri,
                element: <CcmForm />,
              },
              {
                path: RouteLink.CCM_Update_Uri,
                element: <CcmForm />,
              },
              {
                path: RouteLink.Meeting_Uri,
                element: <MeetingList />,
              },
              {
                path: RouteLink.Meeting_Create_Uri,
                element: <MeetingForm />,
              },
              {
                path: RouteLink.Meeting_Update_Uri,
                element: <MeetingForm />,
              },
            ],
          },
          {
            path: "/user/financial",
            element: <FinancialTabs />,
            children: [
              {
                path: RouteLink.ReportingEntities_Uri,
                element: <ReportingEntities />,
              },
              {
                path: RouteLink.ComponentDetails_Uri,
                element: <ComponentDetails />,
              },
              {
                path: RouteLink.FinancialSummary_Uri,
                element: <FinancialSummary />,
              },
              {
                path: RouteLink.TINComparisons_Uri,
                element: <TINComparisons />,
              },
              { path: RouteLink.QPPMismatch_Uri, element: <QPPMismatch /> },
              {
                path: RouteLink.QPPDataConfirmation_Uri,
                element: <QPPDataConfirmation />,
              },
              // { path: RouteLink.CCM, element: <CCM /> },
              // { path: RouteLink.CCMGapAnalysis, element: <CCMGapAnalysis /> },
              {
                path: RouteLink.AssumptionsTarget,
                element: <AssumptionsTargetResults />,
              },
            ],
          },
          {
            path: "/user/cpia",
            element: <CPIATabs />,
            children: [
              {
                path: RouteLink.CPIAList_Uri,
                element: <CPIAList />,
              },
              {
                path: RouteLink.IA_Uri,
                element: <CPIAForm />,
              }

            ],
          },
          {
            path: "/user/pi",
            element: <PITabs />,
            children: [
              {
                path: RouteLink.PiList_Uri,
                element: <PIList />,
              },
              {
                path: RouteLink.Piform_Uri,
                element: <PIForm />,
              }

            ],
          },
          {
            path: "/user/utilities",
            element: <UtilitiTabs />,
            children: [
              // { path: RouteLink.Copy_Tin, element: <CopyTinYear />, },
              { path: RouteLink.Formula_Uri, element: <QualityFormula /> },
              {
                path: RouteLink.Formula_List_Uri,
                element: <FormulaMappingList />,
              },
              {
                path: RouteLink.Physician_Income,
                element: <PhysicianIncomeList />,
              },
              {
                path: RouteLink.Physician_Income_Update,
                element: <PhysicianIncomeForm />,
              },
              {
                path: RouteLink.Physician_Income_Create,
                element: <PhysicianIncomeForm />,
              },

              {
                path: RouteLink.Gui_Uri,
                element: <GuiList />,
              },
              {
                path: RouteLink.Gui_Create_Uri,
                element: <GuiYearForm />,
              },
              {
                path: RouteLink.Gui_Update_Uri,
                element: <GuiYearForm />,
              },
              {
                path: RouteLink.CSV_Uri,
                element: <CsvFormatList />,
              },
              {
                path: RouteLink.CSV_Create_Uri,
                element: <CsvFormate />,
              },
              {
                path: RouteLink.Benchmark_Uri,
                element: <BenchmarkForm />,
              },
              {
                path: RouteLink.Benchmark_List_Uri,
                element: <BenchmarkList />,
              },
              {
                path: RouteLink.Benchmark_view_Single,
                element: <ViewBenchmark />,
              },
              {
                path: RouteLink.Group_Location_List,
                element: <GroupLocationList />,
              },
              {
                path: RouteLink.Group_Location,
                element: <GroupLocationForm />,
              },
              {
                path: RouteLink.CCM_Enable,
                element: <CCMEnable />,
              },
            ],
          },
          {
            path: "/user/tin-Summary",
            element: <TINSummary />,
          },
          {
            path: "/user/measure-atlas",
            element: <MeasureAtlas />,
          },
          {
            path: "/user/quality",
            element: <QualityTab />,
            children: [
              { path: RouteLink.CSV_Upload_List, element: <CsvUploadList /> },
              { path: RouteLink.CSV_Upload_uri, element: <CsvUpload /> },
              {
                path: RouteLink.Quality_Home_Uri,
                element: <QualityHomeList />,
              },
              { path: RouteLink.CQM_Selector_Uri, element: <CqmSelector /> },
              {
                path: RouteLink.EC_Comparision_Uri,
                element: <ECComparisionTool />,
              },
              {
                path: RouteLink.Group_CQM_Details_Uri,
                element: <GroupCQMDetails />,
              },
              {
                path: `${RouteLink.Measure_Log_List}/:id`,
                element: <MeasureLog />,
              },
              {
                path: RouteLink.Group_CQM_Summary,
                element: <GroupCQMSummary />,
              },
              {
                path: RouteLink.EC_Submission_status_Uri,
                element: <ECSubmissionStatus />,
              },
              {
                path: RouteLink.Tin_CQM_Details_Uri,
                element: <TinCqmDetail />,
              },
              {
                path: RouteLink.Measure_CQM_Details_Uri,
                element: <MeasureCqmDetails />,
              },
              {
                path: RouteLink.Group_CQM_Details,
                element: <QualityCalGroupWise />,
              },
              {
                path: RouteLink.Quaity_PhysicianScore,
                element: <PhysicianScore />,
              },
              {
                path: RouteLink.Quaity_QualityNotes,
                element: <QualityNotes />,
              },
              { path: RouteLink.Create_QualityNotes, element: <QualityNotesForm /> },
            ],
          },
          {
            path: "/user/ccm",
            element: <CCMTabs />,
            children: [
              { path: RouteLink.CCM, element: <CCM /> },
              { path: RouteLink.CCMGapAnalysis, element: <CCMGapAnalysis /> },
              {
                path: RouteLink.CCMGroupAnalysis,
                element: <CcmGroupAnalysis />,
              },
            ],
          },
          {
            path: "/user/submission",
            element: <SubmissionTabs />,
            children: [
              {
                path: RouteLink.SubmissionList_Uri,
                element: <SubmissionList />,
              },
              {
                path: RouteLink.SubmissionForm_Uri,
                element: <SubmissionForm />,
              }

            ],
          },
          {
            path: "/user/action-item",
            element: <ActionItem />,
            children: [
              {
                path: `${RouteLink.ActionItemList}/:id`,
                element: <ActionItemList />,
              },
              { path: RouteLink.CreateActionItem, element: <ActionItemForm /> },
              { path: RouteLink.updateActionItem, element: <ActionItemForm /> },
              { path: RouteLink.calendar, element: <ActionCalenterView /> },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "example",
    element: <Example />,
  },
  {
    path: "test",
    element: <Test />,
  },
  {
    path: "*",
    element: <>Page not found</>,
  },
];
