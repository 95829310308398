import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiCall } from '../../Shared/ApiCall/ApiCall';

const persistedToken = localStorage.getItem('authToken');
const persistedUser = localStorage.getItem('authUser');

const initialState = {
    user: persistedUser ? JSON.parse(persistedUser) : null,
    token: persistedToken || null,
    isLoading: false,
    error: null,
    isLoggedIn: !!persistedToken,
};

export const login = createAsyncThunk(`auth/login`, async (credentials) => {
    const response = await ApiCall.userLogin(credentials);
    if (response.status > 199 && response.status < 300) {
        return response.data;
    } else {
        throw new Error(response.data.operationResults[0].message);
    }
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.user = null;
            state.token = null;
            state.error = null;
            state.isLoggedIn = false;
            localStorage.removeItem('authToken');
            localStorage.removeItem('authUser');
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isLoggedIn = true;
                state.user = {
                    email: action.payload.email,
                    name: action.payload.name,
                    securityLevel: action.payload.securityLevel,
                    level: 0,
                    roles: action.payload.roles,
                    organizationId: action.payload.organizationId,
                };
                switch (true) {
                    case state.user.securityLevel.toLowerCase().includes('super'):
                        state.user.level = 0;
                        break;
                    case state.user.securityLevel.toLowerCase().includes('partner'):
                        state.user.level = 1;
                        break;
                    case state.user.securityLevel.toLowerCase().includes('group'):
                        state.user.level = 2;
                        break;
                    case state.user.securityLevel.toLowerCase().includes('tin'):
                        state.user.level = 3;
                        break;
                }
                state.token = action.payload.token;
                localStorage.setItem('authToken', action.payload.token);
                localStorage.setItem('authUser', JSON.stringify(state.user));
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;


// Old Code Done By Bhaiya with out local Storage which  create a problem Error Reading Level

// // authSlice.js
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';
// import { ApiLink } from '../../Constant/ApiLink';
// import { ApiCall } from '../../Shared/ApiCall/ApiCall';

// // Define an initial state
// const initialState = {
//     user: null,
//     token: null,
//     isLoading: false,
//     error: null,
//     isLoggedIn: false,
// };

// // Create an async thunk for login
// export const login = createAsyncThunk(`auth/login`, async (credentials) => {

//     var response = await ApiCall.userLogin(credentials)
//     if (response.status > 199 && response.status < 300) {
//         return response.data;
//     }
//     else {
//         throw new Error(response.data.operationResults[0].message);
//     }

// });

// // Create a slice
// const authSlice = createSlice({
//     name: 'auth',
//     initialState,
//     reducers: {
//         logout: (state) => {
//             state.user = null;
//             state.token = null;
//             state.error = null;
//             state.isLoggedIn = false;
//         },
//     },
//     extraReducers: (builder) => {
//         builder
//             .addCase(login.pending, (state) => {
//                 console.log("pending");
//                 state.isLoading = true;
//                 state.error = null;
//             })
//             .addCase(login.fulfilled, (state, action) => {
//                 console.log("fulfilled", action);
//                 state.isLoading = false;
//                 state.isLoggedIn = true;
//                 state.user = {
//                     email: action.payload.email, // Adjust based on your API response
//                     name: action.payload.name,
//                     securityLevel: action.payload.securityLevel,
//                     level:0,
//                     roles: action.payload.roles,
//                     organizationId: action.payload.organizationId,
//                 } // Adjust based on your API response
//                 switch(true){
//                     case state.user.securityLevel.toLowerCase().includes('super'):
//                         state.user.level = 0;
//                         break;
//                     case state.user.securityLevel.toLowerCase().includes('partner'):
//                         state.user.level = 1;
//                         break;
//                     case state.user.securityLevel.toLowerCase().includes('group'):
//                         state.user.level = 2;
//                         break;
//                     case state.user.securityLevel.toLowerCase().includes('tin'):
//                         state.user.level = 3;
//                         break;
//                 }
//                 state.token = action.payload.token; // Adjust based on your API response
//                 localStorage.setItem('authToken', action.payload.token); // Adjust based on your token storage method
//             })
//             .addCase(login.rejected, (state, action) => {
//                 console.log("rejected");
//                 state.isLoading = false;
//                 state.error = action.error.message;
//             });
//     },
// });

// // Export actions and reducer
// export const { logout } = authSlice.actions;
// export default authSlice.reducer;