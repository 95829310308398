import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Select,
  TextInput,
  Grid,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Switch, Group, ActionIcon, Text, Code } from "@mantine/core";
import { randomId } from "@mantine/hooks";
import { IconTrash } from "@tabler/icons-react";
import { ApiLink } from "../../Constant/ApiLink";
import useAxios from "../../Shared/useApi";
import { RouteLink } from "../../Constant/RouterLink";
import axios from "axios";
import { useSelector } from "react-redux";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const AssumptionsTargetResults = () => {
  var navigate = useNavigate();
  const { data, method } = useLocation().state;
  const percentageDestributionData = useSelector(
    (store) => store.calculatoin.percentageDistribution
  );
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [loading, setLoading] = useState(false);

  // const {
  //   response: response,
  //   loading,
  //   error,
  // } = useAxios({
  //   method: "get", // will beautify this url soon
  //   url: `${process.env.REACT_APP_API_URL}${ApiLink.GetAssumtionTarget}${data}&year=${reportSelection.selectedYear}`,
  //   headers: null,
  //   body: null,
  // });

  // useEffect(() => {
  //   if (data == undefined || data == null) {
  //     alert("Clinic not selected");
  //     handleBack();
  //   }

  //   if (response !== null) {
  //     form.setValues({ assumptionTarget: response })
  //   }
  //   if (error) {
  //   }
  // }, [response, error]);

  useEffect(() => {
    setLoading(true);
    if (data == undefined || data == null) {
      alert("Clinic not selected");
      handleBack();
    }

    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.GetAssumtionTarget}${data}&year=${reportSelection.selectedYear}`
      )
      .then((response) => {
        if (response?.data !== null) {
          form.setValues({ assumptionTarget: response?.data });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error Clinic fetching data:", error);
        setLoading(false);
      });
  }, [reportSelection.selectedYear]);

  const calculateNewValue = (value, percentage) => {
    return (value * percentage) / parseFloat(100);
  };

  // const form = useForm({
  //   initialValues: {
  //     pi: data?.pi,
  //     cqm: data?.cqm,
  //     resource: data?.nresourcepi,
  //     cpia: data?.cpia,
  //     floorScore: data?.floorScore,
  //     clinic: data?.clinic
  //   },
  // });

  // latest chnage
  const form = useForm({
    initialValues: {
      assumptionTarget: [],
    },
  });

  const setCalculatedFormData = (item) => {
    form.values?.assumptionTarget.find((ele) => {
      if (ele.entityId == item.entityId) {
        ele.finalScore =
          calculateNewValue(ele.pi, ele.mipsPI) +
          +calculateNewValue(ele.cqm, ele.mipsCqm) +
          calculateNewValue(ele.resource, ele.mipsRu) +
          calculateNewValue(ele.cpia, ele.mipsCpia);
      }
    });
  };

  const fields = form.values.assumptionTarget?.map((item, index) => (
    <Grid key={item.entityId}>
      <Grid.Col span={4}>
        <TextInput
          withAsterisk
          style={{ flex: 2 }}
          {...form.getInputProps(`assumptionTarget.${index}.entityTypeName`)}
        />
      </Grid.Col>
      <Grid.Col span="auto">
        <TextInput
          withAsterisk
          // style={{ flex: 2 }}
          onChange={setCalculatedFormData(item, index)}
          {...form.getInputProps(`assumptionTarget.${index}.pi`)}
        />
      </Grid.Col>
      <Grid.Col span="auto">
        <TextInput
          withAsterisk
          // style={{ flex: 2 }}
          onChange={setCalculatedFormData(item, index)}
          {...form.getInputProps(`assumptionTarget.${index}.cqm`)}
        />
      </Grid.Col>
      <Grid.Col span="auto">
        <TextInput
          withAsterisk
          // style={{ flex: 2 }}
          onChange={setCalculatedFormData(item, index)}
          {...form.getInputProps(`assumptionTarget.${index}.resource`)}
        />
      </Grid.Col>
      <Grid.Col span="auto">
        <TextInput
          withAsterisk
          // style={{ flex: 2 }}
          onChange={setCalculatedFormData(item, index)}
          {...form.getInputProps(`assumptionTarget.${index}.cpia`)}
        />
      </Grid.Col>
      <Grid.Col span="auto">
        <TextInput
          withAsterisk
          // style={{ flex: 2 }}
          {...form.getInputProps(`assumptionTarget.${index}.finalScore`)}
        />
      </Grid.Col>
    </Grid>
  ));

  const handleBack = () => {
    navigate(RouteLink.ReportingEntities_Uri);
  };

  const handleSubmit = (formValue) => {
    let payloadData = formValue?.assumptionTarget;
    for (let i = 0; i < payloadData?.length; i++) {
      payloadData[i].year = reportSelection.selectedYear;
    }
    const method = "post";
    ApiCall.axiosInstance[method](
      `${process.env.REACT_APP_API_URL}${ApiLink.UpdateAssumtionTarget}`,
      formValue.assumptionTarget
    )
      .then((response) => {
        navigate(RouteLink.ReportingEntities_Uri);
      })
      .catch((error) => {
        console.error("Failed Request:", error);
      });
  };

  return (
    <Box style={{ marginTop: "15px" }}>
      <div className="mycontainer">Assumptions Target Results</div>
      <br />

      {/* <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid grow>
          <Grid.Col span="auto">
            <TextInput
              label="PI Floor"
              {...form.getInputProps("pi")}
            />
          </Grid.Col>
          <Grid.Col span="auto">
            <TextInput
              label="CQM Floor"
              {...form.getInputProps("cqm")}
            />
          </Grid.Col>
          <Grid.Col span="auto">
            <TextInput
              label="RESOURCE Floor"
              {...form.getInputProps("resource")}
            />
          </Grid.Col>
          <Grid.Col span="auto">
            <TextInput
              label="CPIA Floor"
              {...form.getInputProps("cpia")}
            />
          </Grid.Col>
          <Grid.Col span="auto">
            <TextInput
              label="Floor Score"
              {...form.getInputProps("floorScore")}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={2}>
            <Button >Save</Button>

          </Grid.Col>
          <Grid.Col span={2}>

            <Button onClick={handleBack}>Back</Button>
          </Grid.Col>
        </Grid>
      </form> */}

      {fields.length && (
        <Grid>
          <Grid.Col span={4}>
            <Text size="sm">Entity Type</Text>
          </Grid.Col>
          <Grid.Col span="auto">
            <Text size="sm"> PI Floor</Text>
          </Grid.Col>
          <Grid.Col span="auto">
            <Text size="sm"> CQM Floor</Text>
          </Grid.Col>
          <Grid.Col span="auto">
            <Text size="sm">Cost Floor</Text>
          </Grid.Col>
          <Grid.Col span="auto">
            <Text size="sm">CPIA Floor</Text>
          </Grid.Col>
          <Grid.Col span="auto">
            <Text size="sm">Final Score</Text>
          </Grid.Col>
        </Grid>
      )}
      {loading && <Text fw={500}>Loading..</Text>}
      {fields}

      <Grid mt={2}>
        <Grid.Col span={2}>
          <Button onClick={() => handleSubmit(form.values)}>Save</Button>
        </Grid.Col>
        <Grid.Col span={2}>
          <Button onClick={handleBack}>Back</Button>
        </Grid.Col>
      </Grid>
    </Box>
  );
};
export default AssumptionsTargetResults;
