import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { Provider } from "react-redux";
import { store } from "./utils/store";
import { ConfirmDialogProvider } from "./Shared/ConfirmModel";
import InactivityHandler from "./Pages/Login/InActivityHandler";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        colorScheme: "light",
        colors: {
          blue: [
            "#e0f0e0",
            "#bfdfbf",
            "#a3cea3",
            "#80bd80",
            "#66ac66",
            "#4a9b4a",
            "#3d8a3d",
            "#307930",
            "#216121",
            "#0a4a0a",
          ],
        },
      }}
    >
       
      <BrowserRouter basename="/">
        <ConfirmDialogProvider>
          {/* for logout after 60minute */}
        <InactivityHandler timeout={3600000} warningTime={60000} redirectPath="/" /> 
          <App />
        </ConfirmDialogProvider>
      </BrowserRouter>
    </MantineProvider>
  </Provider>
);
// i remove StricMode which wrap MantineProvider componensts
// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
