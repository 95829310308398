import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from './counterSlice'

function Example() {
  const count = useSelector((state) => state.counter.value)
  const dispatch = useDispatch()
  const partenerTreeItems = useSelector(store => store.partenerTree.partenerTreeItem);
  return (
    <div>
      <div>
        <button
          aria-label="Increment value"
          onClick={() => dispatch(increment())}
        >
          Increment
        </button>
        <span>{count}</span>
        <button
          aria-label="Decrement value"
          onClick={() => dispatch(decrement())}
        >
          Decrement
        </button>

        <div>
             
        </div>
      </div>
    </div>
  )
}

export default Example;