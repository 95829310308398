import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import {
  TextInput,
  Button,
  Box,
  Grid,
  Select,
  Space,
  FileInput,
  Loader,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router";
import { ApiLink } from "../../../Constant/ApiLink";
import { RouteLink } from "../../../Constant/RouterLink";
import { YearPickerInput } from "@mantine/dates";
import axios from "axios";
import style from "../../../Shared/Theme/CommanStyle.module.css";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";


function PhysicianIncomeForm() {

  //Define variable and state
  const navigate = useNavigate();
  const { data, method } = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [value, setYearValue] = useState(new Date());

  const form = useForm({
    initialValues: {
      id: data?.id,
      uuid: data?.uuid,
      year: data?.year? new Date(data?.year+'-12-31') :  new Date(),
      name: data?.name
    },
  });

  const handleSubmit = (formValues) => {
    const customPayload = {
        id: formValues?.id,
        uuid: formValues.uuid,
        year: formValues?.year?.getFullYear(),
        name: data?.name,
    }
    setLoading(true);
    ApiCall.axiosInstance[method](
      `${process.env.REACT_APP_API_URL}${ApiLink.GUIDYear}`,
      customPayload
    )
      .then((response) => {
        setLoading(false);
        navigate(RouteLink.Physician_Income);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleBack = () => {
    navigate(RouteLink.Physician_Income);
    setLoading(false);
  };

  const handleYearSelection =(value) => {

    setYearValue(value?.getFullYear());
    form.setValues({
        year: value
    })
  }
 
  return (
    <Box className={style.mt2}>
      {loading && <Loader size={48} />}
      <Space></Space>

      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid>
            <Grid.Col span={6}>
            <YearPickerInput mx={2}
                label="Year"
                placeholder="Pick Year"
                value={value}
                {...form.getInputProps("year")}
                onChange={(v) => handleYearSelection(v)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Guid"
              withAsterisk
              {...form.getInputProps("uuid")}
            />
          </Grid.Col>
        </Grid>

        <div style={{ marginTop: "1rem", float: "right" }}></div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div>
            <Button type="primary" onClick={() => handleBack()}>
              Back
            </Button>
            <Button style={{ marginLeft: "10px" }} type="submit" mt="sm">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Box>
  );
}

export default PhysicianIncomeForm;
