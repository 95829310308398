import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Box, Grid, Group, Radio, Tooltip } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useSelector } from "react-redux";
import { ApiLink } from "../../../Constant/ApiLink";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";

const PhysicianScore = () => {
  const [rowData, setRowData] = useState();
  const [submissionType, setSubmissionType] = useState("Registry");
  const [loading, setIsLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);

  const columnDef = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "physicianName",
      },
      {
        header: "NPI",
        accessorKey: "npi",
      },
      {
        header: "TIN",
        accessorKey: "tin",
      },
      {
        header: "Reporting Category",
        accessorKey: "reportingCategory",
      },
      {
        header: "PI",
        accessorKey: "pi",
      },
      {
        header: "CPIA",
        accessorKey: "cpia",
        Cell: ({ row }) => {
          return 0;
        },
      },
      {
        header: "CQM",
        accessorKey: "cqm",
        filterFn: "includesString", // Adjust this based on your table's filter logic
        Cell: ({ row }) => {
          const wtScore = parseFloat(row.original.wtScore || 0);
          const cqmscore = parseFloat(row.original.cqmScore || 0);
          const percentge = (wtScore / cqmscore) * 100;
          const tooltipContent = `${wtScore}/${cqmscore}`;
          // Check if the value is NaN and return a blank string if it is
          const displayValue = isNaN(percentge)
            ? ""
            : `${percentge.toFixed(2)}`;

          return (
            <Tooltip label={tooltipContent} withArrow>
              <span>{displayValue && `${displayValue}%`}</span>
            </Tooltip>
          );
        },
        // Add an accessor function to provide a raw value for filtering
        accessorFn: (row) => {
          const wtScore = parseFloat(row.wtScore || 0);
          const cqmscore = parseFloat(row.cqmScore || 0);
          const percentge = (wtScore / cqmscore) * 100;
          return isNaN(percentge) ? "" : percentge.toFixed(2);
        },
      },
      {
        header: "Cost",
        accessorKey: "cpia",
        Cell: ({ row }) => {
          const cost = parseFloat(row.original.clinicCost || 0); // Assuming `cpia` represents cost
          const tooltipContent = `${row.original.cost.toFixed(
            2
          )}/${row.original.cpia.toFixed(2)}`;

          return (
            <Tooltip label={tooltipContent} withArrow>
              <span>{cost.toFixed(2)}</span>
            </Tooltip>
          );
        },
      },
      {
        header: "Total",
        id: "total",
        Cell: ({ row }) => {
          const cqm = parseFloat(row.original.wtScore || 0);
          const cost = parseFloat(row.original.cost || 0);
          const total = cqm + cost;
          return total.toFixed(2);
        },
      },
    ],
    []
  );

  const getQualityCalculationTinWise = useCallback(() => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.QualityPhysicianScore}${reportSelection.clinicId}/${reportSelection.selectedYear}/${submissionType}`
      )
      .then((response) => {
        setRowData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching quality calculation:", error);
      });
  }, [reportSelection.clinicId, reportSelection.selectedYear, submissionType]);

  useEffect(() => {
    if (
      submissionType !== undefined &&
      reportSelection.selectedYear !== undefined &&
      reportSelection.clinicId !== undefined
    ) {
      getQualityCalculationTinWise();
    }
  }, [
    getQualityCalculationTinWise,
    reportSelection.selectedYear,
    reportSelection.clinicId,
    submissionType,
  ]);

  return (
    <Box style={{ marginTop: "15px" }}>
      <Grid>
        <Grid.Col span={6} ml={20}>
          <Radio.Group
            value={submissionType}
            onChange={setSubmissionType}
            name="favoriteFramework"
            label="Select Submission Method"
            withAsterisk
          >
            <Group mt="xs">
              <Radio value="Registry" label="Registry" />
              <Radio
                value="ElectronicHealthRecord"
                label="Electronic Health Record"
              />
            </Group>
          </Radio.Group>
        </Grid.Col>
      </Grid>
      <MantineReactTable
        columns={columnDef}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};
export default PhysicianScore;
