import { useLocation, useNavigate } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { Box, Button, Checkbox, Container, Divider, LoadingOverlay, Select, Text, TextInput } from "@mantine/core";
import { Table } from "@mantine/core";
import { PIScoringType } from "../../Shared/DropDownOptions/PIScoringType";
import { YesNo } from "../../Shared/DropDownOptions/YesNo";
import { Grid } from "@mantine/core";
import { ScalingFactor } from "../../Shared/DropDownOptions/Arrays";
import { IconDownload } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { store } from "../../utils/store";
import { MantineReactTable } from "mantine-react-table";
import { FinancialSummaryColumns } from "./Columns/FinancialSummaryColumns";
import axios from "axios";
import { ApiLink } from "../../Constant/ApiLink";
import { getDynamicClass, nFormatter } from "../../Shared/NumberFunctions";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const FinancialSummary = () => {
  var navigate = useNavigate();
  const reportSelection = useSelector((store) => store.selectedClinic.item); // coming from redux
  const entityTypeDD = useSelector(store => store.selectedClinic.selectedClinicEntityDDD);

  const [loading, setloading] = useState(false);
  const [financialSummary, setfinancialSummary] = useState(); //table row
  const columnDefs = useMemo(() => FinancialSummaryColumns);
  const [entityTypePercentageDestribution, setEntityTypePercentageDestribution] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(entityTypePercentageDestribution[0]?.id);
  const [mipsScoreToggle, setMipsScoreToggle] = useState(true);

  useEffect(() => {
    getEntityTypePercentageDestribution();
  }, [reportSelection]);


  const handleFinancialSummaryOnEntity = (value) => {
    setfinancialSummary([]);
    setSelectedEntity(value);
    setloading(true);
    ApiCall.axiosInstance.get(`${process.env.REACT_APP_API_URL}${ApiLink.Get_FinancialSummary}/${reportSelection.clinicId}/${reportSelection.scallingFactor}?entityid=${value}&financialYear=${reportSelection.selectedYear}`)
      .then(response => {
        setfinancialSummary(response.data);
        setloading(false);
      })
      .catch(error => {
        console.error('Error Clinic fetching data:', error);
        setloading(false);
      });
  }

  const getEntityTypePercentageDestribution = () => {
    setloading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.EntityTypewithReqSetByTin}/${reportSelection.clinicId}/${reportSelection.selectedYear}`
      )
      .then((response) => {
        setEntityTypePercentageDestribution(response.data);
        handleFinancialSummaryOnEntity(response.data[0]?.entityId);
      })
      .catch((error) => {
        console.error("Error EntityType fetching data:", error);
        setloading(false);
      });
  };

  const [calculationMethod,setCalculationMethod]=useState("target");

  const actualClicked=()=>{
    setCalculationMethod("actual");
    setMipsScoreToggle(false);
  }
  const targetClicked=()=>{
    setCalculationMethod("target");
    setMipsScoreToggle(true);
  }



  return (
    <Box style={{ marginTop: "15px" }}>
      {loading &&
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      }
     <div className="mycontainer">Physician Financials {reportSelection.clinicEnrollLavel && <span>({reportSelection.clinicEnrollLavel})</span>}</div><br />
      {/* {reportSelection.clinicId}
      <br></br>
      {reportSelection.scallingFactor} */}
      <Grid >
        <Grid.Col span={3}>
          <Select size="xs"
            label="Entity Type"
            withAsterisk
            value={selectedEntity}
            data={entityTypePercentageDestribution?.map((ele) => ({
              value: +ele.id,
              label: ele.name,
            }))}
            onChange={(value) => handleFinancialSummaryOnEntity(value)}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          
          <Button.Group mt={20}>
            <Button variant={calculationMethod=="actual"?"filled": "outline"} onClick={actualClicked} >Actual</Button>
            <Button variant={calculationMethod=="target"?"filled": "outline"}  onClick={targetClicked}>Target</Button>
          </Button.Group>
          
          </Grid.Col>
        {/* <Grid.Col span={3}>
          <Select size="xs"
            label=" Attestation Clinic Type(UDF) "
            withAsterisk
            data={ScalingFactor}
          />
        </Grid.Col> */}
        {/* <Grid.Col span={2}>
          <Checkbox
            label="Include Low Volumes"
            className="mt-5"
          />
        </Grid.Col> */}
      </Grid>
      <Divider my="sm" />

      {/* <MantineReactTable
        columns={columnDefs}
        data={financialSummary ?? []}
        state={{ isLoading: loading }}
        positionActionsColumn='first'
        mantinePaperProps={{ shadow: '0', withBorder: false }}
      /> */}

      <Table striped highlightOnHover withBorder withColumnBorders className="greenhead">
        <thead >
          <tr>
            <th>EC Name</th>
            <th>Submission Method	</th>
            <th>TIN</th>
            <th>NPI</th>
            <th> MIPS score</th>
            <th>{reportSelection.selectedYear - 2} Medicare
            </th>
            <th>Regulatory Low</th>
            
            <th>Results</th>
            <th>Regulatory High</th>
            
            
          
          </tr>
        </thead>
        <tbody>
        {loading && <Text fw={500}>Loading..</Text>}
        {financialSummary ? (
          financialSummary.map((item, index) => (
              <tr key={index}>
                <td>{item.ecName}</td>
                <td>{item.submissionMethod}</td>
                <td>{item.tin}</td>
                <td>{item.npi}</td>
              
                {/* <td>{item.CMSMIPSScore}</td> */}
                <td>{mipsScoreToggle ? item.targetScore : '-'}</td>
                <td className={getDynamicClass(item.basicMedicare)}>{nFormatter(item.basicMedicare, 2)}</td>
                <td className={getDynamicClass(item.regulatoryLow)}>{nFormatter(item.regulatoryLow, 2)}</td>

                <td className={getDynamicClass(item.targetResult)}>{mipsScoreToggle ? nFormatter(item.targetResult, 2) : '-'}</td>
                <td className={getDynamicClass(item.regulatoryHigh)}>{nFormatter(item.regulatoryHigh, 2)}</td>
               
              
              </tr>
            ))
          ) : (
            !loading && <tr>
              <td colSpan="7">No data available</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Box>
  );
};
export default FinancialSummary;
