import { Divider } from '@mantine/core';
import React from 'react'
import { Outlet } from 'react-router'

export const ActionItem = () => {
  return (
    <>
      <div className="mycontainer">Action Item </div><br />
      <Divider></Divider>
      <Outlet></Outlet>
    </>
  )
}

export default ActionItem;