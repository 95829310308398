import React, { useState, useRef, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate } from "react-router-dom";
import useAxios from "../../Shared/useApi";
import { ActionIcon, Box, Button, Grid, Switch } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { IconEdit, IconHttpDelete } from "@tabler/icons-react";
import { RowAction } from "../../Shared/RowAction";
import { useDisclosure } from "@mantine/hooks";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import PublicFilesColumn from "./PublicFilesColumn";
import { ApiLink } from "../../Constant/ApiLink";
import { useSelector } from "react-redux";
import axios from "axios";
import { label } from "../../Shared/Constant";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

const PublicFilesList = () => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  var navigate = useNavigate();
  const publicFilesColumnDefs = useMemo(() => PublicFilesColumn);
  const [checked, setChecked] = useState(true);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [loading, setIsLoading] = useState(false);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    flex: 1,
  }));

  useEffect(() => {
    if (checked) {
      getPublicData(reportSelection.selectedYear)
    } else {
      getPublicData('0')
    }
  }, [checked, reportSelection]);

  const getPublicData = (year) => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.PublicFiles}/${year}`
      )
      .then((response) => {
       setRowData(response.data);
       setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    
  };

  const onCreate = () => {
    navigate(RouteLink.PublicFile_Create_Uri, {
      state: { data: null, method: "post" },
    });
  };

  const onUpdate = (value) => {
    
    navigate(RouteLink.PublicFile_Update_Uri, {
      state: { data: value, method: "put" },
    });
  };

  const onDelete=(value)=>{
    
    open();

  }
  const [opened, { close, open }] = useDisclosure(false);
  function IsConfirmed(value){

  }
  return (
    <Box style={{ marginTop: "15px" }}>
      <DeleteConfirmationModal IsConfirmed={IsConfirmed} opened={opened} open={open} close={close} />
     

      <Grid>
      <Grid.Col span={3}>
      <Button type="primary" onClick={() => onCreate()}>
        Create Public Files
      </Button>
      </Grid.Col>
      <Grid.Col span={9}>
          <Switch  style={{float: 'right'}}
            label={label('Show Yearly Data')}
            checked={checked}
            onChange={(event) => setChecked(event.currentTarget.checked)}
          />
        </Grid.Col>
      </Grid>

      
      <MantineReactTable
        columns={publicFilesColumnDefs}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn='first'
        renderRowActions={(row) => (
          <RowAction onUpdate={()=>onUpdate(row.row.original)} onDelete={()=>onDelete(row.row.original)} />
        )}
        mantinePaperProps={{ shadow: '0', withBorder: false }}
      />

    </Box>
  );
};

export default PublicFilesList;
