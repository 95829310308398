import { Anchor, Badge, Box, Card, Divider, Flex, Grid, Stack, ThemeIcon } from '@mantine/core';
import { useSelector } from 'react-redux';
import YouTubeCarousel from './YouTubeCarousel'; // Make sure to create/import this component
import { useEffect, useState } from 'react';
import { InlineWidget } from 'react-calendly';
import { IconInputSearch } from '@tabler/icons-react';

export function DashboardContent() {
  const reportclinic = useSelector((store) => store.selectedClinic.item);
  const reportSelection = useSelector((store) => store.partenerTree);
  const selectedGroupId = useSelector((store) => store.partenerTree.selectedGroupId);
  const { user } = useSelector((state) => state.auth);
  const [name, setName] = useState('');
  // main object with all data
  const partenerTreeItems = useSelector((state) => state.partenerTree.partenerTreeItem);

  useEffect(() => {
    setName('');

    // Guard clause for base checks
    if (
      !user ||
      !reportSelection?.groupDropDownList?.length ||
      !partenerTreeItems?.length
    ) return;

    switch (user.level) {
      case 0: // Admin
        setName('Admin');
        break;

      case 1: // Partner
        {
          const result = partenerTreeItems[0]?.name;
          setName(result || 'Partner');
        }
        break;

      case 2: // Group
        {
          const group = partenerTreeItems[0]?.group?.find(
            group => group?.id === Number(selectedGroupId)
          );
          setName(group?.name || 'Group');
        }
        break;

      case 3: // TIN
        {
          const group = partenerTreeItems[0]?.group?.find(
            g => g.id === Number(selectedGroupId)
          );

          const clinicId = Number(localStorage.getItem('clinicId')); // ✅ safe fetch
          const clinic = group?.clinic?.find(c => c.id === reportclinic.clinicId);

          setName(clinic?.name || 'Clinic');
        }
        break;

      default:
        setName('User');
        break;
    }
  }, [user, selectedGroupId, reportSelection, partenerTreeItems]);

  return (


    <>
      {/* First Row: Heading + Calendly */}
      <Grid>
        {/* Left: Heading */}
        <Grid.Col md={8} sm={12}>
          <h1>
            Welcome to MACRA Monitor <br />
            <Badge color="blue" size="xl">{name}</Badge>
          </h1>
          <Divider
  my="lg"
  color="#3d893d"     
  size="sm"           
  
  style={{ borderTopStyle: 'dashed' }} // Optional: dashed effect
/>


          <fieldset
            style={{
              border: "1px solid #ced4da",
              borderRadius: "8px",
              padding: "20px",
              backgroundColor: "#e0f0e0",
              position: "relative",
              top: "25px"

            }}
          >
            <legend
              style={{
                fontWeight: 600,
                padding: "5px 10px",
                backgroundColor: "#3d893d",
                color: "white",
                borderRadius: "5px",

              }}
            >
              Useful links
            </legend>

            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Anchor
                href="https://qpp.cms.gov/resources/resource-library"
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'flex', alignItems: 'center', gap: '8px', fontWeight: 500 }}
              >
                <ThemeIcon size="sm"  >
                  <IconInputSearch size={16} />
                </ThemeIcon>
                QPP Resource Library

              </Anchor>

              <Anchor
                href="https://cmsqualitysupport.servicenowservices.com/ccsq_support_central"
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'flex', alignItems: 'center', gap: '8px', fontWeight: 500 }}
              >
                <ThemeIcon size="sm"  >
                  <IconInputSearch size={16} />
                </ThemeIcon>
                CCSQ

              </Anchor>
            </div>
          </fieldset>

         
      
           <Grid.Col span={12} mt={40}>
           <Divider
  my="lg"
  color="#3d893d"     
  size="sm"           
  
  style={{ borderTopStyle: 'dashed' }} // Optional: dashed effect
/>
           <Badge color="blue" size="xl" my={20}>Informational Videos</Badge>
          <YouTubeCarousel />
          </Grid.Col>
        

        </Grid.Col>

        {/* Right: Calendly */}
        <Grid.Col md={1} sm={12}></Grid.Col>
        <Grid.Col md={3} sm={12}>
          <Flex justify="flex-end">
            <Card shadow="sm" padding="lg" radius="md" withBorder w="100%">
              <InlineWidget url="https://calendly.com/jay-fisher-c3/c3-partners-macra-optimization" />
            </Card>
          </Flex>
        </Grid.Col>
      </Grid>

      {/* Second Row: Carousel */}
      {/* <Grid>
        <Grid.Col span={12}>
          <h2>Informational Videos</h2>
          <YouTubeCarousel />
        </Grid.Col>
      </Grid> */}
    </>


  );
}



{/* <Grid.Col md={4} sm={12}>
        <ProfileCard />
      </Grid.Col>
      <Grid.Col md={8} sm={12}>
        <Flex direction="column" h="100%" justify="space-between">
          <WelcomeCard />
        </Flex>
      </Grid.Col>
      <Grid.Col md={8} sm={12}>
        <BalanceCard />
      </Grid.Col>
      <Grid.Col md={4} sm={12}>
        <OverviewCard />
      </Grid.Col> */}