import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Anchor,
  Box,
  Grid,
  Group,
  Radio,
  Select,
  Text,
  Tooltip,
} from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useSelector } from "react-redux";
import { ApiLink } from "../../../Constant/ApiLink";
import axios from "axios";
import getMeasureData from "../../../services/measureService";
import MeasureDetailsModel from "./MeasureDetailsModel";
import { IconEye } from "@tabler/icons-react";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";
import { label } from "../../../Shared/Constant";

const GroupCQMDetails = () => {
  const { id } = useParams();
  const [rowData, setRowData] = useState();
  const [qualityPhysician, setQualityPhysician] = useState([]);
  const [submissionType, setSubmissionType] = useState("Registry");
  const [physicianType, setPhysicianType] = useState();
  const [weightage, setWeightage] = useState({});

  const [loading, setIsLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [uniqueMeasureIds, setUniqueMeasureId] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [headerText, setHeaderText] = useState("");
  const [entitytype, setentitytype] = useState([]);
  const selectedGroupClnicsList = useSelector(
    (store) => store.partenerTree.selectedGroupClnics
  );

  const defaultVisibleColumns = useMemo(
    () => ({
      measureId: true,
      title: true,
      eMeasureId: false,
      performanceMet: true,
      eligiblePopulation: false,
      nonEligiblePopulation: false,
      prPercentage: true,
      measurePotential: false,
      decileValue: true,
      measureType: false,
      metricType: false,
      npi: false,
      dataCompletenessNotMet: false,
      performanceNotMet: false,
      excl: false,
      excp: false,
      dataCompletnessValue: true,
      IsToppedOut: false,
      IsHighPriority: false,
      IsToppedOutByProgram: false,
      noBenchmarkScore: false,
      isInverse: false,
      startDate: true,
      endDate: true,
      numerator: false,
      denominator: true,
    }),
    []
  );

  const [columnVisibility, setColumnVisibility] = useState(
    defaultVisibleColumns
  );

  const columnDef = useMemo(() => [
    {
      header: "Measure Id",
      accessorKey: "measureId",
      filterVariant: "select",
      mantineFilterSelectProps: {
        data: uniqueMeasureIds,
      },
    },
    {
      header: "Title",
      accessorFn: (row) => (
        <Tooltip multiline w={400} label={row.title}>
          <span
            className="text-ellipsiss"
            style={{
              display: "inline-block",
              maxWidth: "200px",
              minWidth: "200px",
              overflow: "hidden",
              whiteSpace: "normal", // allows wrapping to multiple lines
              textOverflow: "ellipsis",
              lineClamp: 2, // limits text to two lines
              WebkitLineClamp: 2, // for webkit browsers
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
            }}
          >
            {row.title}
          </span>
        </Tooltip>
      ),
      size: 10,
    },

    {
      header: "E-Measure Id",
      accessorKey: "eMeasureId",
    },
    {
      header: "Performance Rate",
      accessorKey: "prPercentage",
    },
    {
      header: "Decile",
      accessorKey: "decileValue",
    },
    {
      header: "Performance Met",
      accessorKey: "performanceMet",
      Cell: ({ row }) => (
        <Anchor onClick={() => handleRowClick(row.original, "performance_met")}>
          {row.original.performanceMet}
        </Anchor>
      ),
    },
    {
      header: "Denominator",
      accessorKey: "denominator",
      // accessorFn: (row) => {
      //   return (row?.eligiblePopulation -row?.excp)
      // },
    },
    {
      header: "Data Completness Rate",
      accessorKey: "dataCompletnessValue",
      accessorFn: (row) => {
        return parseFloat(row?.dcPercentage).toFixed(2);
      },
    },
    {
      header: "Numerator",
      accessorKey: "numerator",
    },
    {
      header: "Eligible Population",
      accessorKey: "eligiblePopulation",
      Cell: ({ row }) => (
        <Anchor
          onClick={() => handleRowClick(row.original, "eligible_population")}
        >
          {row.original.eligiblePopulation}
        </Anchor>
      ),
    },

    {
      header: "Non Eligible Population",
      accessorKey: "nonEligiblePopulation",
      Cell: ({ row }) => (
        <Anchor
          onClick={() =>
            handleRowClick(row.original, "non_eligible_population")
          }
        >
          <IconEye />
        </Anchor>
      ),
    },

    {
      header: "Measure Potential",
      accessorKey: "measurePotential",
    },

    {
      header: "Measure Type",
      accessorKey: "measureType",
    },
    {
      header: "Metric Type",
      accessorKey: "metricType",
    },
    {
      header: "NPI",
      accessorKey: "npi",
    },
    {
      header: "Performance Not Met",
      accessorKey: "performanceNotMet",
      Cell: ({ row }) => (
        <Anchor
          onClick={() => handleRowClick(row.original, "performance_not_met")}
        >
          {row.original.performanceNotMet}
        </Anchor>
      ),
    },
    {
      header: "Excl",
      accessorKey: "excl",
      Cell: ({ row }) => (
        <Anchor
          onClick={() => handleRowClick(row.original, "denominator_exclusion")}
        >
          {row.original.excl}
        </Anchor>
      ),
    },
    {
      header: "Excp",
      accessorKey: "excp",
      Cell: ({ row }) => (
        <Anchor
          onClick={() => handleRowClick(row.original, "denominator_exception")}
        >
          {row.original.excp}
        </Anchor>
      ),
    },
    {
      header: "Data Completeness Not Met",
      accessorKey: "dataCompletenessNotMet",
      Cell: ({ row }) => {
        const { original } = row;
        const value =
          original.dataCompletenessNotMet === 0 ||
          original.dataCompletenessNotMet === null
            ? original.eligiblePopulation -
              (original.performanceMet +
                original.performanceNotMet +
                original.excp)
            : original.dataCompletenessNotMet;

        return (
          <Anchor
            onClick={() => handleRowClick(original, "datacompleteness_not_met")}
          >
            {value}
          </Anchor>
        );
      },
    },

    {
      header: "IsToppedOut",
      accessorKey: "IsToppedOut",
      accessorFn: (row) => {
        return row?.IsToppedOut == true ? "Yes" : "No";
      },
    },
    {
      header: "IsHighPriority",
      accessorKey: "IsHighPriority",
      accessorFn: (row) => {
        return row?.IsHighPriority == true ? "Yes" : "No";
      },
    },
    {
      header: "IsToppedOutByProgram",
      accessorKey: "IsToppedOutByProgram",
      accessorFn: (row) => {
        return row?.IsToppedOutByProgram == true ? "Yes" : "No";
      },
    },
    {
      header: "No. Benchmark Score",
      accessorKey: "noBenchmarkScore",
    },
    {
      header: "Is Inverse",
      accessorKey: "isInverse",
      accessorFn: (row) => {
        return row?.isInverse == true ? "Yes" : "No";
      },
    },
    {
      header: "StartDate",
      accessorKey: "startDate",
      accessorFn: (row) => {
        if (row?.startDate) {
          const [year, month, day] = row.startDate.split("-");
          const formattedDate = `${month}-${day}-${year}`;
          return formattedDate;
        }
        return row?.startDate;
      },
    },

    {
      header: "End Date",
      accessorKey: "endDate",
      accessorFn: (row) => {
        if (row?.endDate) {
          const [year, month, day] = row.endDate.split("-");
          const formattedDate = `${month}-${day}-${year}`;
          return formattedDate;
        }
        return row?.endDate;
      },
    },
  ]);

   // Runs only on first load and when submissionType changes
 useEffect(() => {
   QualityPhysician();
 }, [submissionType]);

  useEffect(() => {
    // getGroupCQMDetails();
    getEntityTypeData();
  }, [reportSelection.selectedYear, reportSelection.clinicId]);

  useEffect(() => {
    if (submissionType != undefined && physicianType != undefined) {
      getGroupCQMDetails();
    }
  }, [submissionType, physicianType]);
  const getEntityTypeData = () => {
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.Entity_Type}`)
      .then((response) => {
        setentitytype(response?.data);
      })
      .catch((error) => {
        console.error("Error Entity fetching data:", error);
      });
  };
  const handleRowClick = async (singleRowData, colName) => {
    
    const currentClinicId = selectedGroupClnicsList.find(
      (ele) => ele.value === reportSelection.clinicId
    )?.tinID;
   
    setIsLoading(true);
    try {
      const params = {
        metric: colName,
        npi: singleRowData?.npi,
        measure: singleRowData?.measureId,
        entity_type: "individual",
      };

      const response = await getMeasureData(params, currentClinicId);
     
      // Handle the different response data as needed
      setRecords(response?.data?.encounters);
      setIsModalOpen(true);
      setHeaderText("Measure Details");
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch different measure data:", error);
      setIsLoading(false);
    }
  };

  const getGroupCQMDetails = () => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.QualityCalculationPhysicianWise}${reportSelection.clinicId}/${reportSelection.selectedYear}/${physicianType}/${submissionType}`
        // `${process.env.REACT_APP_API_URL}${ApiLink.QualityCalculation}74/2024/1609816750?submissionMethod=Registry`
      )
      .then((response) => {
        const modifiedData = response?.data?.map((item) => {
          // Assuming 'numerostr' is a field in the 'performancemet' object
          const updatedItem = {
            ...item,
            numerator: item.performanceMet,
            denominator: item.eligiblePopulation - item.excp,
          };

          // Return the item with the updated performancemet
          delete updatedItem.performancemet;
          return updatedItem;
        });
        // setRowData(response.data);
        setRowData(modifiedData);
        const uniqueMeasures = [
          ...new Set(response?.data?.map((item) => item.measureId)),
        ].sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        setUniqueMeasureId(uniqueMeasures);
        setWeightage({
          weightageValue: response?.data[0]?.weightageValue,
          finalTarget: response?.data[0]?.finalTarget,
          cqmWeightage: response?.data[0]?.cqmWeightage,
          mipsScore: response?.data[0]?.mipsScore,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const QualityPhysician = () => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.QualityPhysician}${reportSelection.clinicId}/${reportSelection.selectedYear}/${submissionType}`
        // `${process.env.REACT_APP_API_URL}${ApiLink.QualityPhysician}74/2024`
      )
      .then((response) => {
        let physicianData = response.data?.map((ele) => ({
          value: ele.id,
          label: ele.name,
        }));
        setQualityPhysician(physicianData);
        setPhysicianType(physicianData[0]?.value);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  function calculatePercentage(numerator, denominator) {
    if (denominator === 0) {
      throw new Error("Denominator cannot be zero");
    }
    const percentage = (numerator / denominator) * 100;
    return percentage.toFixed(2); // Rounds to two decimal places
  }

  const handleColumnVisibilityChange = (updatedColumnVisibility) => {
    setColumnVisibility(updatedColumnVisibility);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Box style={{ marginTop: "15px" }}>
      <Grid>
        <Grid.Col span={4}>
          <Select
            maw="80%"
            label="Select Physician"
            withAsterisk
            placeholder="Pick value"
            data={qualityPhysician}
            value={physicianType}
            searchable
            onChange={setPhysicianType}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          {" "}
          <Radio.Group
            value={submissionType}
            onChange={setSubmissionType}
            name="favoriteFramework"
            label="Select Submission Method"
            withAsterisk
          >
            <Group mt="xs">
              <Radio value="Registry" label="Registry" />
              <Radio value="ElectronicHealthRecord" label="EHR" />
            </Group>
          </Radio.Group>
        </Grid.Col>
        <Grid.Col span={4} style={{ textAlign: "right" }}>
          {weightage && (
            <Tooltip
              label={
                // weightage.weightageValue + "/" + weightage.cqmWeightage
                calculatePercentage(
                  weightage.weightageValue,
                  weightage.cqmWeightage
                ) + "%"
              }
              position="top"
              withArrow
            >
              <Text>
                <span style={{ fontWeight: "bold" }}>
                  Quality Contributions:
                </span>{" "}
                {
                  weightage.weightageValue + "/" + weightage.cqmWeightage
                  // calculatePercentage(
                  //   weightage.weightageValue,
                  //   weightage.cqmWeightage
                  // ) + "%"
                }
              </Text>
            </Tooltip>
          )}
          {weightage && (
            <Tooltip
              label={
                // weightage.mipsScore + "/" + weightage.finalTarget * 10
                calculatePercentage(
                  weightage.mipsScore,
                  weightage.finalTarget * 10
                ) + "%"
              }
              position="top"
              withArrow
            >
              <Text>
                <span style={{ fontWeight: "bold" }}>Standalone Quality:</span>{" "}
                {
                  weightage.mipsScore + "/" + weightage.finalTarget * 10
                  //  calculatePercentage(
                  //   weightage.mipsScore,
                  //   weightage.finalTarget * 10
                  // ) + "%"
                }
              </Text>
            </Tooltip>
          )}
        </Grid.Col>
      </Grid>
      <MantineReactTable
        columns={columnDef}
        data={rowData ?? []}
        state={{ isLoading: loading, columnVisibility }}
        enableHiding={true}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />

      {isModalOpen && (
        <MeasureDetailsModel
          records={records}
          header={headerText}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  );
};

export default GroupCQMDetails;
