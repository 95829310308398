import { Paper } from "@mantine/core";
import { Tabs } from "@mantine/core";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import React from "react";
// import CommonLayout from "../Shared/CommonLayout";
import { RouteLink } from "../Constant/RouterLink";
import { useSelector } from "react-redux";

export const FinancialTabs = () => {
  const linkStyle = {
    textDecoration: "none",
    color: "#000",
    fontSize: "15px",
    marginBottom: "0px",
    paddingBottom: "0px",
  };
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  if (location.pathname === RouteLink.Financial_Uri) {
    navigate(RouteLink.ReportingEntities_Uri);
  }

  const adminRouteWithIndex = [
    {text: "TIN Financials", routeTo: RouteLink.ReportingEntities_Uri, index: "1", level: 3},
    {text: "Physician Scores", routeTo: RouteLink.ComponentDetails_Uri, index: "2", level: 3},
    {text: "Physician Financials", routeTo: RouteLink.FinancialSummary_Uri, index: "3", level: 3},
    {text: "QPP Data Confirmation", routeTo: RouteLink.QPPDataConfirmation_Uri, index: "4", level: 3},
  ];

  const getActiveTabValue = () => {
    const path = location.pathname;
    if (path === RouteLink.ReportingEntities_Uri) return "1";
    else if (path === RouteLink.ComponentDetails_Uri) return "2";
    else if (path === RouteLink.FinancialSummary_Uri) return "3";
    else if (path === RouteLink.QPPDataConfirmation_Uri) return "4";
  };

  const activeTabValue = getActiveTabValue();

  return (
    <Paper withBorder radius="md" p="md">
      <Tabs defaultValue={activeTabValue}>
        <Tabs.List>
          {adminRouteWithIndex.map((row) => {
            if (row.level >= user.level)
              return (
                <Link to={row.routeTo} style={linkStyle} key={row.index}>
                  <Tabs.Tab value={row.index} key={row.index}>
                    {row.text}
                  </Tabs.Tab>
                </Link>
              );
          })}

          {/* <Tabs.Tab value="13">Third tab</Tabs.Tab> */}
        </Tabs.List>

        <Outlet></Outlet>
      </Tabs>
    </Paper>
  );
};
