import React, { useState, useRef, useEffect, useMemo } from "react";

import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Switch } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useDisclosure } from "@mantine/hooks";

import { ApiLink } from "../../../Constant/ApiLink";
import { useSelector } from "react-redux";
import axios from "axios";
import { RowAction } from "../../../Shared/RowAction";
import { RouteLink } from "../../../Constant/RouterLink";
import { DeleteConfirmationModal } from "../../../Shared/DeleteConfirmationModal";
import { csvdata } from "./FormData";
import useConfirm from "../../../Shared/ConfirmModel";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";

const CsvFormatList = () => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  var navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const confirm = useConfirm();

  const reportSelection = useSelector((store) => store.selectedClinic.item);

  const reqColumnDefs = useMemo(() => [
    {
      header: "Format Name",
      accessorKey: "formatName",
    },
    {
      header: "Format Type",
      accessorKey: "formatType",
    },
  ]);

  useEffect(() => {
    getCSVdataList();
  }, []);

  const getCSVdataList = () => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(`${process.env.REACT_APP_API_URL}${ApiLink.CSVFormat}`)
      .then((response) => {
        setRowData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const onCreate = () => {
    navigate(RouteLink.CSV_Create_Uri, {
      state: { data: csvdata, method: "post" },
    });
  };

  const onUpdate = (value) => {
    
    navigate(RouteLink.CSV_Create_Uri, {
      state: { data: value, method: "put" },
    });
  };

  const onDelete = async (data) => {
    const choise = await confirm({
      title: "Delete Record",
      description: "Are you sure to delete?",
    });
    if (choise) {
      setIsLoading(true);
      ApiCall.axiosInstance
        .delete(
          `${process.env.REACT_APP_API_URL}${ApiLink.CSVFormat}?id=${data.id}`
        )
        .then((response) => {
          setRowData(response.data);
          setIsLoading(false);
          getCSVdataList();
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Box style={{ marginTop: "15px" }}>
      <Grid>
        <Grid.Col span={3}>
          <Button type="primary" onClick={() => onCreate()}>
            Add New CSV Format
          </Button>
        </Grid.Col>
      </Grid>
      <MantineReactTable
        columns={reqColumnDefs}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn="first"
        renderRowActions={(row) => {
          return (
            <>
              {row.row.original.formatType != "Json" && (
                <RowAction
                  onUpdate={() => onUpdate(row.row.original)}
                  onDelete={() => onDelete(row.row.original)}
                />
              )}
            </>
          );
        }}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};

export default CsvFormatList;
