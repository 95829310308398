import { isNotEmpty, useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import { TextInput, Button, Box, Grid, Select, Space, NumberInput } from "@mantine/core";
import { ApiLink } from "../../Constant/ApiLink";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Loader } from "@mantine/core";
import { Abbrevation } from "../../Shared/DropDownOptions/AbbrevationList";
import { ShowHideStatus } from "../../Shared/DropDownOptions/ShowHideStatus";
import { Sandivider } from "../../Shared/Theme/Sandivider";
import style from "../../Shared/Theme/CommanStyle.module.css";
import { ActiveStatus } from "../../Shared/DropDownOptions/ActiveStatus";
import useAxios from "../../Shared/useApi";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

function SubCategoryForm() {
  const navigate = useNavigate();
  const { data, method } = useLocation().state;

  

  const [loading, setLoading] = useState(false);
  const [dropdowndata, setdropdowndata] = useState([]);


  const { response, loading1, error } = useAxios({
    method: "get",
    url: "/Category",
    headers: null,
    body: null,
  });

  useEffect(() => {
    if (response !== null) 
    {
      setdropdowndata(response);
    }
    if (error) 
    {
      
    }
  }, [response, error]);
  


  const handleSubmit = (formValues) => {
    
    setLoading(true);
    ApiCall.axiosInstance[method](
      `${process.env.REACT_APP_API_URL}${ApiLink.SubCategory_Uri}`,
      formValues
    )
      .then((response) => {
       
        setLoading(false);
        navigate(RouteLink.SubCategory_Uri);
      })
      .catch((error) => {
        console.error("Failed Request:", error);
        setLoading(false);
      });
  };

  const handleBack = () => {
    navigate(RouteLink.SubCategory_Uri);
    setLoading(false);
  };

  const thisForm = useForm({
    initialValues: {
      subCategoryId: data?.subCategoryId,
      categoryId: data?.category.id,
      subCatName: data?.subCatName,
      description:data?.description,
      abbrevation: data?.abbrevation,
      sortOrder: data?.sortOrder,
      status: data?.status,
      subCatStatus: data?.subCatStatus,
      account: data?.account,
    },

    validate: {
      subCatName: isNotEmpty('Name cannot be empty'),
      categoryId: isNotEmpty('Select Any Category'),
      subCatStatus: isNotEmpty('Select Status '),
      account: isNotEmpty('Select Account  Status'),
    },

  });

  return (
    <Box className={style.mt2}>
      {loading && <Loader size={48} />}
      
      <h5>Sub Category : Add New</h5>
      <Space></Space>

      <form onSubmit={thisForm.onSubmit((values) => handleSubmit(values))}>
        <Grid>
        <Grid.Col span={4}>
            <TextInput
              label="Sub Category"
              withAsterisk
              {...thisForm.getInputProps("subCatName")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
           <Select
              label="Category"
              withAsterisk
              {...thisForm.getInputProps("categoryId")}
              data={dropdowndata.map(ele => ({
                value: ele.categoryId,
                label: ele.catName,
              }))}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput
              label="Abbrevation"
              {...thisForm.getInputProps("abbrevation")}
            />
          </Grid.Col>
          <Grid.Col span={10}>
            <TextInput
              label="Description"
              {...thisForm.getInputProps("description")}
            />
          </Grid.Col>
      
       
          <Grid.Col span={2}>
             <NumberInput
              label="Sort Order"
              withAsterisk
              min={0}
              max={10}
              {...thisForm.getInputProps("sortOrder")}
            />
          </Grid.Col>
        
        </Grid>
        
        <Grid>
          <Grid.Col span={3}>
            <Select
              label="Status"
              withAsterisk
              {...thisForm.getInputProps("subCatStatus")}
              data={ActiveStatus}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Select
              label="Account Status:"
              withAsterisk
              defaultValue={data?.account}
              {...thisForm.getInputProps("account")}
              data={ActiveStatus}
            />
          </Grid.Col>
          
        </Grid>
       
        <div style={{ marginTop: "1rem", float: "right" }}></div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div>
            <Button type="primary" onClick={() => handleBack()}>
              Back
            </Button>
            <Button style={{ marginLeft: "10px" }} type="submit" mt="sm">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Box>
  );
}

export default SubCategoryForm;



