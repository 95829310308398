export const formatDate = (dateString) => {
    // Check for invalid dates including the .NET default "0001-01-01..." value
    if (
      !dateString ||
      dateString.startsWith("01/01/0001") ||
      dateString.startsWith("01-01-0001") ||
      dateString.startsWith("1/1/0001") ||
      dateString.startsWith("0001-01-01")
    )
      return ""; // Return blank for invalid dates
  
    let date;
  
    try {
      // If dateString contains a "T", assume it's in ISO format
      if (dateString.includes("T")) {
        date = new Date(dateString);
      }
      // Check for "M/D/YYYY" format (with slashes)
      else if (dateString.includes("/") && dateString.split("/").length === 3) {
        const parts = dateString.split(" ")[0].split("/"); // Extract the date portion
        const month = parts[0].padStart(2, "0");
        const day = parts[1].padStart(2, "0");
        const year = parts[2];
        date = new Date(`${year}-${month}-${day}`);
      }
      // Check for "MM-DD-YYYY" format (with dashes)
      else if (dateString.includes("-") && dateString.split("-").length === 3) {
        const parts = dateString.split(" ")[0].split("-");
        const month = parts[0].padStart(2, "0");
        const day = parts[1].padStart(2, "0");
        const year = parts[2];
        date = new Date(`${year}-${month}-${day}`);
      } else {
        return ""; // Return blank if format is unknown
      }
  
      return isNaN(date.getTime())
        ? ""
        : date.toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          });
    } catch (error) {
      console.error("Date parsing error:", error);
      return "";
    }
  };
  