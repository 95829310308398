import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useSelector } from "react-redux";
import { ApiLink } from "../../../Constant/ApiLink";
import axios from "axios";
import { ApiCall } from "../../../Shared/ApiCall/ApiCall";

const GroupCQMSummary = () => {
  const { id } = useParams();
  const [rowData, setRowData] = useState();
  const [loading, setIsLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const selectedGroupId = useSelector(
    (store) => store.partenerTree.selectedGroupId
  );

  const columnDef = useMemo(
    () => [
      {
        header: "Reqest Name",
        accessorKey: "reqName",
      },
      {
        header: "Numerator",
        accessorKey: "numer",
      },
      {
        header: "Denominator",
        accessorKey: "denom",
      },
      {
        header: "Current Value",
        accessorKey: "currentValue",
      },
      {
        header: "Target Value",
        accessorKey: "targetValue",
      },
      {
        header: "Decile Point",
        accessorKey: "decilePoint",
      },
    ],
    []
  );

  const defaultVisibleColumns = useMemo(
    () => ({
      reqName: true,
      numer: true,
      denom: false,
      currentValue: false,
      targetValue: false,
      decilePoint: false,
    }),
    []
  );

  const [columnVisibility, setColumnVisibility] = useState(
    defaultVisibleColumns
  );

  useEffect(() => {
    getGroupCQMSummary();
  }, [reportSelection.selectedYear, selectedGroupId, reportSelection.clinicId]);

  const getGroupCQMSummary = () => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.GroupCQMSummary}${selectedGroupId}/${reportSelection.clinicId}/${reportSelection.selectedYear}`
      )
      .then((response) => {
        setRowData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleColumnVisibilityChange = (updatedColumnVisibility) => {
    setColumnVisibility(updatedColumnVisibility);
  };

  return (
    <Box style={{ marginTop: "15px" }}>
      <MantineReactTable
        columns={columnDef}
        data={rowData ?? []}
        state={{ isLoading: loading, columnVisibility }}
        enableHiding={true}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};

export default GroupCQMSummary;
