import React, { useState, useRef, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Modal, Button, Box } from "@mantine/core";

const ManualPecosModel = ({ records, onClose, onSelect }) => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [columnDefs] = useState([
    {
      headerName: "Name",
      field: "orgName",
      resizable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: [], // Hide the buttons (Clear, Apply)
        debounceMs: 200, // Delay in ms before filter is applied
        suppressAndOrCondition: true, // Hide additional filter options
      },
    },
    {
      headerName: "Pac Id",
      field: "pacId",
      resizable: true,
    },
    {
      headerName: "NPI",
      field: "npi",
      resizable: true,
    },
    {
      headerName: "Action",
      checkboxSelection: true,
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      wrapHeaderText: true,
      filter: true, // Enable filtering by default for all columns
    }),
    []
  );

  useEffect(() => {
    setRowData(records);
  }, [records]);

  const handleSelect = () => {
    onSelect(selectedRows);
    onClose();
  };

  const closeWindow = () => {
    onClose();
  };

  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    setSelectedRows(selectedRows);
  };

  return (
    <Modal
      onClose={onClose}
      title="Select Manual Pecos"
      size="80%"
      opened
      style={{ minHeight: "60vh" }}
    >
      <div className="ag-theme-alpine" style={{ height: 350, marginTop: 10 }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          pagination={true}
          rowMultiSelectWithClick={true}
          animateRows={true}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          onSelectionChanged={onSelectionChanged}
        />
      </div>
      <Box mt={10} mb={10}>
        <Button onClick={closeWindow}>Close</Button>
        <Button onClick={handleSelect} style={{ float: "right" }}>
          Import Selected
        </Button>
      </Box>
    </Modal>
  );
};

export default ManualPecosModel;
